import { Component, Prop, Vue } from 'vue-property-decorator';
import * as mws from '../../shared/MwsViewModels';
import template from './related-funds.vue';

@Component({
    mixins: [template]
})
export default class RelatedFunds extends Vue {
    @Prop()
    data!: mws.RelatedFunds;

    getBackgroundImage(fund: mws.Fund): string {
        return Boolean(fund.backgroundImage) ? fund.backgroundImage.url : '';
    }

    getTop3Funds(): mws.Fund[] {
        return this.data.relatedFunds.filter(relatedFund => Boolean(relatedFund.url)).slice(0, 3);
    }

}
