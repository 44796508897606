import { Component, Prop } from 'vue-property-decorator';
import template from './fund-literature-library-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import FundLiteratureAccordionItem from './fund-literature-accordion-item/FundLiteratureAccordionItem';
import Translatable from '../../../translation/Translatable';

@Component({
  mixins: [template],
  components: {
    FundLiteratureAccordionItem,
  },
})
export default class FundLiteratureLibraryPanel extends Translatable {
    @Prop()
    data!: mws.FundLiteratureLibraryPanel;

    async created() {
      await this.loadDictionary(['bgfunds_literature_library_title']);
    }
}
