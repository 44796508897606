import { Vue, Component, Prop } from 'vue-property-decorator';
import InsightsCarousel from '../../../insights/insights-carousel/InsightsCarousel';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-intellectual-capital-panel.vue';

@Component({
  mixins: [template],
  components: {
    InsightsCarousel,
  },
})
export default class FundIntellectualCapitalPanel extends Vue {
  @Prop()
  data: mws.FundIntellectualCapitalPanel;

  includeViewMoreTile = false;

  mounted(){
    this.includeViewMoreTile = this.data?.viewMoreInsightsCard?.url && this.data?.includeViewMoreTile;
  }
}
