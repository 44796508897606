import { Component, Prop } from 'vue-property-decorator';
import template from './top-holdings-panel.vue';
import Translatable from '../../../translation/Translatable';
import * as mws from '../../../shared/MwsViewModels';
import { HoldingType } from './HoldingType';
import TopHoldingsTable from './top-holdings-table/TopHoldingsTable';

@Component({
  mixins: [template],
  components: {
    TopHoldingsTable,
  },
})
export default class TopHoldingsPanel extends Translatable {
  @Prop()
  data!: mws.TopHoldingsPanel;

  holdingsNotShownCount: number;

  buttonAllText = '';

  buttonValuationText = '';

  topTenActive = true;

  canShowMoreHoldings = false;

  showOnlyTop = false;

  private saintsFundName = 'Scottish American Investment Company';

  created() {
    if (
      !this.data.topHoldingsType
      || this.data.topHoldingsType.toLowerCase() === HoldingType.TopTenHoldings
    ) {
      this.showOnlyTop = true;
    }

    this.setFilterButtonTexts();
  }

  private setFilterButtonTexts(): void {
    this.buttonAllText = `All${this.data.fundName}` === this.saintsFundName ? ' Equity Holdings' : '';

    this.buttonValuationText = `Full Portfolio${this.data.fundName}` === this.saintsFundName
      ? ' Including Property'
      : ' Valuation';
  }

  public showFurtherHoldings(): void {
    this.topTenActive = false;
  }

  public showTopTenHoldings(): void {
    this.topTenActive = true;
  }

  public notShownHoldingsCount(holdingsNotShownCount: number): void {
    this.canShowMoreHoldings = this.data.showFurtherHoldings && holdingsNotShownCount > 0;
  }
}
