import { Vue, Component } from 'vue-property-decorator';
import axios from 'axios';

export interface AddressEntry {
  id: string;
  name: string;
}

export interface AddressDetails {
  address1: string;
  address2: string;
  town: string;
  county: string
}

@Component
export default class AddressSelectorMixin extends Vue {
  public async findAddressesByPostcode(postcode: string): Promise<AddressEntry[]> {
    if (postcode == null || postcode === '') {
      return [];
    }
    const urlParam = encodeURIComponent(postcode);
    const url = `/getAddresses?postcode=${urlParam}`;
    try {
      const response = await axios.get<AddressEntry[]>(url);
      return response.data != null ? response.data : [];
    } catch (_exc) {
      return [];
    }
  }

  public async findAddressDetails(addressId: string): Promise<AddressDetails> {
    const id = encodeURIComponent(addressId);
    const url = `/getAddress/${id}`;

    const response = await axios.get<AddressDetails>(url);
    return response.data;
  }
}
