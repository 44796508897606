import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './one-column-award-listing-panel.vue';
import { OneColumnAwardListingPanel as model } from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
})
export default class OneColumnAwardListingPanel extends Vue {
  @Prop()
  data!: model;
}
