import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './block-item-base.vue';
import { ILink } from '../../../../shared/MwsViewModels';
import GenericLink from '../../../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class BlockItemBase extends Vue {
  @Prop()
  link!: ILink;
}
