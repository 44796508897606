import { Literature, LiteratureCategory } from '@/shared/MwsViewModels';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './fund-literature-accordion-item.vue';
import GenericLink from '../../../../shared/components/link/GenericLink';
import Translatable from '../../../../translation/Translatable';
import AccordionMixin from '../../../../shared/accordion/accordionMixin';
import LiteratureOrderMixin from '../../../../shared/literature-order/LiteratureOrderMixin';

@Component({
  mixins: [
    template,
    AccordionMixin,
  ],
  components: {
    GenericLink,
  },
})
export default class FundLiteratureAccordionItem extends Mixins(Translatable, LiteratureOrderMixin) {
  @Prop()
  data: LiteratureCategory;

  async created() {
    await this.loadDictionary(['literature_library_results_download']);
  }

  order(document: Literature): void {
    this.addLiteratureId(document.id);
  }
}
