import { GlobalNaviagtion } from '@/shared/MwsViewModels';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  openWarningPopup({ commit }, data) {
    const payload = {
      isOpen: true,
      title: data.title,
      message: data.message,
    };

    commit('toggleWarningPopup', payload);
  },
  closeWarningPopup({ commit }) {
    const payload = {
      isOpen: false,
      title: '',
      message: '',
    };

    commit('toggleWarningPopup', payload);
  },
  openRiskWarningPopup({ commit }, data) {
    var multiRiskWarningIntroText = getRiskWarningPopupIntro(data);
    var multiRiskWarningHeading = getRiskWarningPopupHeader(data);

    const payload = {
      isOpen: true,
      riskWarnings: data.riskWarnings,
      acceptUrl: data.acceptUrl,
      multiRiskWarningIntroText: multiRiskWarningIntroText,
      multiRiskWarningHeading: multiRiskWarningHeading
    };

    commit('toggleRiskWarningPopup', payload);
  },
  closeRiskWarningPopup({ commit }) {
    const payload = {
      isOpen: false,
      riskWarning: null,
      multiRiskWarningIntroText: '',
      multiRiskWarningHeading: ''
    };

    commit('toggleRiskWarningPopup', payload);
  },
  populateSelectLocationPopup({ commit }, globalNavigation: GlobalNaviagtion) {
    commit('populateSelectLocationPopup', globalNavigation);
  },
  openSelectLocationPopup({ commit }, onCloseRedirectUrl: string) {
    commit('toggleSelectLocationPopup', { isOpen: true, onCloseRedirectUrl });
  },
  closeSelectLocationPopup({ commit }) {
    commit('toggleSelectLocationPopup', { isOpen: false });
  },
  setCountry({ commit }, country: string) {
    commit('setLocationByIP', country);
  },
  setPendingGeoIp2Request({ commit }, pendingGeoIp2Request: boolean) {
    commit('setPendingGeoIp2Request', pendingGeoIp2Request);
  },
  populateRedirectSelectorPopUp({ commit }, globalNavigation: GlobalNaviagtion) {
    commit('populateRedirectSelectorPopUp', globalNavigation);
  },
  openRedirectSelectorPopUp({ commit }, onCloseRedirectUrl: string) {
    commit('toggleRedirectSelectorPopUp', { isOpen: true, onCloseRedirectUrl });
  },
  closeRedirectSelectorPopUp({ commit }) {
    commit('toggleRedirectSelectorPopUp', { isOpen: false });
  },
  openMenuPopup({ commit }) {
    commit('toggleMenuPopup', { isOpen: true });
  },
  closeMenuPopup({ commit }) {
    commit('toggleMenuPopup', { isOpen: false });
  },
  updateMenuPopupData({ commit }, payload) {
    commit('updateMenuPopupData', payload);
  },
  openAthenaeumPopup({ commit }, athenaeumContentData) {
    const payload = {
      isOpen: true,
      athenaeumContentData,
    };

    commit('toggleAthenaeumPopup', payload);
  },
  closeAthenaeumPopup({ commit }) {
    const payload = {
      isOpen: false,
      athenaeumContentData: {},
      athenaeumDatum: {},
    };

    commit('toggleAthenaeumPopup', payload);
  },
  openClientLoginPopup({ commit }, clientLoginData) {
    const payload = {
      isOpen: true,
      linkUrl: clientLoginData.linkUrl,
    };

    commit('toggleClientLoginPopup', payload);
  },
  closeClientLoginPopup({ commit }) {
    const payload = {
      isOpen: false,
      linkUrl: '',
    };
    commit('toggleClientLoginPopup', payload);
  },
  openVideoPopup({ commit }, payload) {
    commit('toggleVideoPopup', { data: payload.data, isOpen: true, config: payload.config });
  },
  closeVideoPopup({ commit }) {
    commit('toggleVideoPopup', { data: null, isOpen: false, config: null });
  },
  openLiteratureOrderPopup({ commit }) {
    commit('toggleLiteratureOrderPopup', { isOpen: true });
  },
  closeLiteratureOrderPopup({ commit }) {
    commit('toggleLiteratureOrderPopup', { isOpen: false });
  },
  openFundSubscriptionPopup({ commit }) {
    commit('toggleFundSubscriptionPopup', { isOpen: true });
  },
  closeFundSubscriptionPopup({ commit }) {
    commit('toggleFundSubscriptionPopup', { isOpen: false });
  },
  setFundSubscriptionPopupLink({ commit }, link: string) {
    commit('setFundSubscriptionPopupLink', link);
  }
};

function getRiskWarningPopupHeader(data){
  var heading = '';
  if(data.multiRiskWarningHeading)
  {
    heading = data.multiRiskWarningHeading;
  }
  else{
    heading = data.riskWarnings && data.riskWarnings.length > 0 ? data.riskWarnings[0].multiRiskWarningHeading : '';
  }

  return heading;
};
function getRiskWarningPopupIntro(data){
  var heading = '';
  if(data.multiRiskWarningIntroText)
  {
    heading = data.multiRiskWarningIntroText;
  }
  else{
    heading = data.riskWarnings && data.riskWarnings.length > 0 ? data.riskWarnings[0].multiRiskWarningIntroText : '';
  }

  return heading;
}

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
