import * as mws from '../../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './usm-performance-and-portfolio-tables.vue';

@Component({
  mixins: [template]
})
export default class UsmPerformanceAndPortfolioTables extends Vue {
  @Prop()
  tables: mws.Table[];
}