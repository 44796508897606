import { render, staticRenderFns } from "./podcast-panel.vue?vue&type=template&id=1561b6a6&"
var script = {}
import style0 from "../../assets/styles/pages/_Insights.scss?vue&type=style&index=0&lang=scss&"
import style1 from "../../assets/styles/pages/_ArticleDetail.scss?vue&type=style&index=1&lang=scss&"
import style2 from "../../assets/styles/pages/_PodcastPanel.scss?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports