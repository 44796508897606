import classnames from 'classnames/bind'
import {
  ColourPickerElement,
  SupercardDesign2,
} from '@/shared/MwsViewModels'
import RichText from '../RichText'
import SectionContainer from '../SectionContainer'
import StyledLink from '../StyledLink'
import * as styles from './PromoterCTA.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface PromoterCTAProps {
  coloursOverride: ColourPickerElement
  items: SupercardDesign2[]
}

interface PromoterProps {
  card: SupercardDesign2
}

function Promoter({ card }: PromoterProps) {
  const ctaLink = card?.cta

  return (
    <div className={cx('promoter')}>
      {card?.title && (
        <h2
          className={cx('heading', 'promoterHeading')}
          dangerouslySetInnerHTML={{ __html: card.title }}
        />
      )}
      {card?.description && (
        <RichText
          className={cx('description', 'spacing__sm-top')}
          size="big"
          copy={card?.description}
        />
      )}
      {ctaLink?.url && (
        <div className={cx('spacing__sm-top')}>
          <StyledLink
            type="linkLarge"
            data={ctaLink}
          />
        </div>
      )}
    </div>
  )
}

const PromoterCTA = ({ coloursOverride, items }: PromoterCTAProps) => {
  const promoterItems = (Array.isArray(items) ? items : []).slice(0, 1)

  return (
    <SectionContainer coloursOverride={coloursOverride}>
      {promoterItems.map((card, index) => {
        return (
          <div
            key={`Promoter-CTA-${index}`}
            className={cx('borderTop')}
          >
            <Promoter card={card} />
          </div>
        )
      })}
    </SectionContainer>
  )
}

export default PromoterCTA
