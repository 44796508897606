import { Component, Vue, Prop } from 'vue-property-decorator';
import * as mws from '../../../../../shared/MwsViewModels';
import HistoryCarouselContent from './HistoryCarouselContent';
import template from './athenaeum-history-carousel.vue';

@Component({
  mixins: [template],
})
export default class AthenaeumHistoryCarousel extends Vue {
  @Prop()
  historyCarousels!: any;

  public getHeadersAndContents(historyCarousel: mws.AthenaeumHistoryCarousel): HistoryCarouselContent[] {
    if (historyCarousel) {
      return historyCarousel.headers.map((header, index) => {
        if (historyCarousel.contents[index]) {
          return <HistoryCarouselContent> {
            header,
            content: historyCarousel.contents[index],
          };
        }
        return <HistoryCarouselContent>{}
      });
    }
      
    return [];
  }

  get getHistoryCarousels(): any[] {
    return [ 
      {
        headers: this.historyCarousels.historyCarouselHeadersOne.map(x => x.bodyText),
        contents: this.historyCarousels.historyCarouselContentsOne.map(x => x.bodyText),
      },
      {
        headers: this.historyCarousels.historyCarouselHeadersTwo.map(x => x.bodyText),
        contents: this.historyCarousels.historyCarouselContentsTwo.map(x => x.bodyText),
      },
      {
        headers: this.historyCarousels.historyCarouselHeadersThree.map(x => x.bodyText),
        contents: this.historyCarousels.historyCarouselContentsThree.map(x => x.bodyText),
      },
      {
        headers: this.historyCarousels.historyCarouselHeadersFour.map(x => x.bodyText),
        contents: this.historyCarousels.historyCarouselContentsFour.map(x => x.bodyText),
      }
    ].map((carouselData) => carouselData);
  } 
}
