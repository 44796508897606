import { Vue, Component } from 'vue-property-decorator';
import { IPanel } from '../MwsViewModels';

@Component
export default class PanelIdentifierMixin extends Vue {
  public getUniquePanelId(panelIndex: number, panels: IPanel[]): string {
    const { panelName } = panels[panelIndex];
    const previousPanelsOfTheSameTypeCount = this.getPanelNamesBefore(panelIndex, panels)
      .filter((name) => panelName === name)
      .length;
    const idSuffix = previousPanelsOfTheSameTypeCount === 0 ? '' : `-${previousPanelsOfTheSameTypeCount}`;
    return this.toKebabCase(panelName) + idSuffix;
  }

  private toKebabCase(pascalCase: string): string {
    return pascalCase
      .split(/(?=[A-Z])/)
      .join('-')
      .toLowerCase();
  }

  private getPanelNamesBefore(panelIndex: number, panels: IPanel[]): string[] {
    return panels.slice(0, panelIndex).map((panel) => panel.panelName);
  }
}
