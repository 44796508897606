import { Vue, Component } from 'vue-property-decorator';
import EventBus from '../EventBus';
import LiteratureOrderEvents from './LiteratureOrderEvents';
import Translatable from '../../translation/Translatable';
import { Helpers } from '../../assets/scripts/utilities/formatHelpers';
import { SelectedChannel } from '../global-navigation/SelectedChannel';

interface LiteratureItem {
  channelId: number;
  id: number;
}

@Component
export default class LiteratureOrderMixin extends Translatable {

  public readonly literatureMaxRequests = 10;

  private readonly cookieName = 'saved-literature';

  wasMounted = false;

  async created() {
    await this.loadDictionary(['literature_form_max_requests_error']);
  }

  mounted() {
    this.wasMounted = true;
  }

  public addLiteratureId(id: number): void {
    if (!this.wasMounted) return;

    const currentlySelectedItems = this.getLiteratureIds();
    if (currentlySelectedItems.length >= this.literatureMaxRequests) {
      this.warnItemLimit();
      return;
    }

    if (currentlySelectedItems.indexOf(id) === -1) {
      currentlySelectedItems.push(id);
      this.setLiteratureIds(currentlySelectedItems);
    }
  }

  public removeLiteratureId(id: number): void {
    if (!this.wasMounted) return;

    const currentlySelectedItems = this.getLiteratureIds();
    const index = currentlySelectedItems.indexOf(id);
    if (index !== -1) {
      currentlySelectedItems.splice(index, 1);
      this.setLiteratureIds(currentlySelectedItems);
    }
  }

  public removeAllLiteratureIds(): void {
    this.setLiteratureIds([]);
  }

  public getLiteratureIds(): number[] {
    if (!this.wasMounted) return [];

    const storedValue = this.$cookies.get(this.cookieName)?.toString();
    const items = storedValue == null
      ? []
      : storedValue.split('|').map((item) => {
        const parts = item.split(':');
        return { id: parseInt(parts[0], 10), channelId: parseInt(parts[1], 10) };
      });
    return this.filterValidLiteratureItems(items)
      .map((item) => item.id);
  }

  private setLiteratureIds(ids: number[]): void {
    const items = ids.map((id) => ({ id: id, channelId: this.selectedChannelId }));
    const newValue = this.filterValidLiteratureItems(items)
      .map((item) => `${item.id}:${item.channelId}`)
      .join('|');
    this.$cookies.set(this.cookieName, newValue);
    EventBus.$emit(LiteratureOrderEvents.selectItem);
  }

  private warnItemLimit(): void {
    const fallbackMessage = 'A maximum of {0} documents can be ordered at one time';
    const message = this.getTranslationOrDefault('literature_form_max_requests_error', fallbackMessage);
    alert(Helpers.format(message, this.literatureMaxRequests.toString()));
  }

  private filterValidLiteratureItems(items: LiteratureItem[]): LiteratureItem[] {
    return items
      .filter((item) => item.channelId === this.selectedChannelId);
  }

  private get selectedChannelId(): number {
    const channel = this.$store.state.globalSelector?.selectedChannel as SelectedChannel;
    return channel?.channelId || null;
  }
}
