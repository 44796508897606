import { Component, Prop, Vue } from 'vue-property-decorator';
import * as mws from '../../../shared/MwsViewModels';
import template from './social-sharing-panel.vue';

const svgPath = '/assets/images/svg/sprite/'
const linkSvg = `${svgPath}share-link-black.svg`;
const tickSvg = `${svgPath}tick-black.svg`;
const emailSvg = `${svgPath}email-black.svg`;
const facebookSvg = `${svgPath}facebook-black.svg`;
const twitterSvg = `${svgPath}twitter-black.svg`;
const linkedInSvg = `${svgPath}linkedin-black.svg`;

@Component({
    mixins: [template]
})
export default class SocialSharingPanel extends Vue {
    showTick = false;
    linkSvg = linkSvg;
    tickSvg = tickSvg;
    emailSvg = emailSvg;
    facebookSvg = facebookSvg;
    twitterSvg = twitterSvg;
    linkedInSvg = linkedInSvg;

    @Prop()
    data: mws.SocialSharingPanel;

    get linkSrc(): string {
        return this.showTick ? this.tickSvg : this.linkSvg;
    }

    async copyLink(): Promise<void> {
        this.showTick = true;
        setTimeout(() => {
            this.showTick = false;
        }, 1000);
        await navigator.clipboard.writeText(this.data.linkShareUrl);
    }
}
