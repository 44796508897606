import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './fixed-anchor.vue';

@Component({
  mixins: [template],
})
export default class FixedAnchor extends Vue {
  @Prop({ default: null })
  identifier: string | null;
}
