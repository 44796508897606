import $ from 'jquery';
import Globalize from 'globalize';
import { cldrFallbackJson } from './fallback-cldr';

let cldrPromise: JQueryPromise<any> = null;
const defaultLocale = 'en-GB';
let actualLocale = defaultLocale;

function fetchCldrData(locale: string) {
  return [
    $.getJSON('/public/static/cldr/likelySubtags.json'),
    $.getJSON(`/public/static/cldr/${locale}/numbers.json`),
    $.getJSON('/public/static/cldr/numberingSystems.json'),
    $.getJSON(`/public/static/cldr/${locale}/ca-gregorian.json`),
    $.getJSON(`/public/static/cldr/${locale}/timeZoneNames.json`),
    $.getJSON('/public/static/cldr/timeData.json'),
    $.getJSON('/public/static/cldr/weekData.json'),
  ];
}

export default function loadCldrPromise(): JQueryPromise<any> {
  if (cldrPromise == null) {
    cldrPromise = $.when(...fetchCldrData(actualLocale))
    .then(function func() {
      return [].slice
        .apply(arguments, [0])
        .map((result) => Globalize.load(result[0]));
    },
    () => {
      actualLocale = defaultLocale;
      Globalize.load(cldrFallbackJson);
      return $.Deferred().resolve();
    })
    .then(() => Globalize.locale(actualLocale));
  }
  
  return cldrPromise;
}
