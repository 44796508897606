import { Component, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './redirect-selector-popup.vue';
import Translatable from '../../../translation/Translatable';
import Region from './components/Region';

@Component({
  mixins: [template],
  components: {
    Region,
  },
})
export default class RedirectSelectorPopup extends Mixins(Translatable) {
  public readonly columnsCount: number = 3;
  private selectedRegionId: string = '';

  async created() {
    await this.loadDictionary(['global_navigation_secondary_regions_lead_text']);
  }
  public closePopup(): void {
    this.$store.dispatch('closeRedirectSelectorPopUp');
  }
  public getRegions(column: number): mws.Region[] {
    const start = column * this.columnSize;
    const end = start + this.columnSize;
    return this.allMainRegions.slice(start, end);
  }
  public getAdditional(column: number): mws.Region[] {
    return column === this.columnsCount - 1 ? this.allAdditionalRegions : [];
  }
  public get isOpen(): boolean {
    return this.$store.state.popups.redirectSelectorPopUp.isOpen;
  }
  private get allMainRegions(): mws.Region[] {
    const regions = this.globalNavigation.main;
    return regions == null ? [] : regions;
  }
  private get allAdditionalRegions(): mws.Region[] {
    const regions = this.globalNavigation.additional;
    return regions == null ? [] : regions;
  }
  private get globalNavigation(): mws.GlobalNaviagtion {
    const navigation = this.$store.state.popups.redirectSelectorPopUp.globalNavigation;
    return navigation == null ? {} as mws.GlobalNaviagtion : navigation;
  }
  private get columnSize(): number {
    const regions = this.allMainRegions.length;
    const additional = this.allAdditionalRegions.length;
    return Math.floor(((regions + additional) / this.columnsCount) + 1);
  }
  onRegionClick(regionId): void {
    this.selectedRegionId = regionId;
  }
}
