import { throttle } from 'underscore';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DynamicTextFit extends Vue {
  private _parentEl: Element = null;
  private _bottomEl: Element = null;
  private _textEl: Element = null;
  private _fullText = '';
  private _minSpace = 0;
  private _eventHandler = null;
  private _maxWordCount: number = null;

  /**
   * Register a 'resize' event that will fit text to available space whenever a browser window is resized.
   * @param parentEl A parent element whose overflow should be prevented.
   * @param bottomEl It's used to detect parent element's overflow.
   * @param textEl It's internal text will be shortened if there isn't enough space.
   * @param fullText Full text expected to be placed in `textEl`.
   * @param minSpace Minimum acceptable space (in px) between `parentEl` and `bottomEl`.
   */
  public registerDynamicTextFitEvent(
    parentEl: Element,
    bottomEl: Element,
    textEl: Element,
    fullText: string,
    minSpace: number,
    maxWordCount: number = null,
  ): void {
    if (parentEl == null || bottomEl == null || textEl == null) {
      console.error('Cannot fit description to block: null element.');
      return;
    }

    this._parentEl = parentEl;
    this._bottomEl = bottomEl;
    this._textEl = textEl;
    this._fullText = fullText;
    this._minSpace = minSpace;
    this._maxWordCount = maxWordCount;

    this._eventHandler = throttle(this.fitDescriptionToBlock, 100);

    this.fitDescriptionToBlock();
    window.addEventListener('resize', this._eventHandler);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this._eventHandler);
    this._eventHandler = null;
  }

  public fitDescriptionToBlock(): void {
    if (this._fullText == null) return;
    let words = this._fullText.split(' ');
    const wordsCount = words.length;
    words = (this._maxWordCount && this._maxWordCount > 0)
      ? words.slice(0, this._maxWordCount) : words;

    this._textEl.innerHTML = this.formatText(words, wordsCount);
  }

  public formatText(words:string[], originalLength:number) :string {
    return this._maxWordCount && this._maxWordCount > 0 && originalLength > words.length
      ? `${words.join(' ')} ...` : words.join(' ');
  }
}
