export function filterUniqueByPredicate<TEntity, TPrimitive>(
  collection: TEntity[],
  predicate: (t: TEntity) => TPrimitive,
): TEntity[] {
  return collection
    .filter((value, index, self) => index === self.findIndex((entity) => predicate(entity) === predicate(value)));
}

export function filterUnique<TPrimitive>(collection: TPrimitive[]): TPrimitive[] {
  return collection
    .filter((value, index, self) => index === self.findIndex((item) => item === value));
}
