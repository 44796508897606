import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import * as mws from '../../shared/MwsViewModels';
import template from './video-panel.vue';
import VideoPlayerMixin from '../../shared/video-player/VideoPlayerMixin';

@Component({
  mixins: [template],
})
export default class VideoPanel extends Mixins(VideoPlayerMixin) {
    @Prop()
    data: mws.IcItemVideo;

    @Prop()
    shareIcons: mws.IcSharePanel;

    @Prop({default: false})
    isArchived: boolean;

    get showShareIcons(): boolean {
      return this.shareIcons ? this.shareIcons.showShareIcons : false;
    }

    mounted() {
      const videoContainer = this.$refs.videoContainer as HTMLElement;
      const config = {
        playlist: this.data.url,
        autostart: false,
        height: 'auto',
        aspectratio: '16:9',
      };
      this.initialiseVideoPlayer(videoContainer, config);
    }
}
