import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-manager.vue';
import AccordionMixin from '../../../../shared/accordion/accordionMixin';

@Component({
  mixins: [
    template,
    AccordionMixin,
  ],
})
export default class FundManager extends Vue {
  @Prop()
  manager: mws.Person;

  get managerImageUrl(): string {
    return this.manager.image ? this.manager.image.url : '';
  }
}
