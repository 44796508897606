import classnames from 'classnames/bind'
import { FullWidthQuotePanel as IFullWidthQuotePanel } from '@/shared/MwsViewModels'
import SectionContainer from '../../lib/SectionContainer'
import * as styles from './FullWidthQuotePanel.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface FullWidthQuotePanelProps {
  data: IFullWidthQuotePanel
}

export interface PersonProps {
  fullName: string
  role?: string
}

const Person = ({ fullName, role = '' }: PersonProps) => {
  return (
    <>
      {fullName && (
        <figcaption className={cx('person-fullName')}>
          {fullName}
          {role && <cite className={cx('person-role')}>{role}</cite>}
        </figcaption>
      )}
    </>
  )
}

const FullWidthQuotePanel = ({ data }: FullWidthQuotePanelProps) => {
  return (
    <SectionContainer
      coloursOverride={data?.colours}
      className={cx('quote-container')}
    >
      <div className="util__narrow-container">
        {data?.quote && (
          <blockquote className={cx('quote-text')}>{data.quote}</blockquote>
        )}
        {data?.quote && data?.quoteSource && (
          <Person
            fullName={data.quoteSource}
            role={data.quoteSourceJobRole}
          />
        )}
      </div>
    </SectionContainer>
  )
}

export default FullWidthQuotePanel
