import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-list-item.vue';
import GenericLink from '../../../../shared/components/link/GenericLink';

@Component({
    mixins: [template],
    components: {
        GenericLink,
      },
  })

  export default class FundListItem extends Vue {
    @Prop()
    fund: mws.Fund;
  }