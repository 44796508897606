import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './full-width-quote-panel.vue';
import { ReactInVue  } from 'vuera'
import FullWidthQuotePanelReact from '../../../react/rebrand-lite/panels/FullWidthQuotePanel';

@Component({
  mixins: [template],
  components: {
    'FullWidthQuotePanelReact': ReactInVue(FullWidthQuotePanelReact)
  },
})

export default class FullWidthQuotePanel extends Vue {
  @Prop()
  data: mws.FullWidthQuotePanel;
}