import { Mixins, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import IdGenerationMixin from '../../../../shared/id-generation/IdGenerationMixin';
import template from './expandable-content-item.vue';

@Component({
  mixins: [template],
})
export default class ExpandableContentItem extends Mixins(IdGenerationMixin) {
  @Prop()
  data!: mws.ExpandableContentItem;

  expandableContentId: string | null = null;

  // Default setup for when JS is disabled.
  isExpanded = true;

  contentHeight = 'auto';

  itemClasses = ['expanded'];

  mounted() {
    this.setExpanded(false);
    this.expandableContentId = `region-${this.$ids.generate()}`;
  }

  click(): void {
    this.setExpanded(!this.isExpanded);
  }

  get additionalTextStyles(): { [key: string]: string } {
    const color = this.data.backgroundColor;
    return typeof color === 'undefined' ? {} : { 'background-color': color };
  }

  get additionalTextClasses(): { [key: string]: boolean } {
    return {
      columns: this.data.showAdditionalTextColumns,
      // eslint-disable-next-line eqeqeq
      'has-background': this.data.backgroundColor != undefined,
    };
  }

  private setExpanded(isExpanded: boolean) {
    const content = this.$refs.content as Element;

    this.isExpanded = isExpanded;
    this.contentHeight = isExpanded ? `${content.scrollHeight}px` : '0';
    this.itemClasses = isExpanded ? ['expanded'] : [];
  }
}
