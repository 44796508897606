import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-side-contact-panel.vue';
import createMailtoLink from '../../../shared/email/createMailtoLink';

@Component({
  mixins: [template],
})
export default class FundSideContactPanel extends Vue {
  @Prop()
  data: mws.FundSideContactPanel;

  get emailLink(): string {
    return createMailtoLink(this.data.emailAddress, this.data.emailSubject);
  }
}
