import React from 'react'
import classnames from 'classnames/bind'
import { Capability, EnhancedTextLink } from '@/shared/MwsViewModels'
import ActiveLink from '../../../lib/ActiveLink'
import * as styles from './NavigationLinks.module.scss'
import { NavigationLink } from '../NavigationLink/NavigationLink'
import { NavigationMegaSubMenu } from '../NavigationMegaSubMenu'
import { NavigationStandardSubMenu } from '../NavigationStandardSubMenu'

const cx = classnames.bind(styles)

type LinkType = 'primary-nav' | 'secondary-nav'

interface NavigationLinksProps {
  links: EnhancedTextLink[]
  linkType: LinkType
  expandedSubmenu: string
  handleExpandedMenu: (menukey: string) => void
  handleCloseBtnClick?: () => void
  strategiesMegaMenu?: Capability[][]
  selectedSubMenuGroup?: Capability[] | null
  setSelectedSubMenuGroup?: React.Dispatch<React.SetStateAction<Capability[] | null>>
  labels: { [key: string]: string }
}

export default function NavigationLinks({
  links,
  linkType = 'secondary-nav',
  expandedSubmenu,
  handleExpandedMenu,
  handleCloseBtnClick,
  strategiesMegaMenu,
  selectedSubMenuGroup,
  setSelectedSubMenuGroup,
  labels,
}: NavigationLinksProps) {
  return (
    <ul
      arial-label={linkType}
      data-testid={linkType}
      className={cx({
        navigationLinks: true,
        navigationLinksSecondary: linkType === 'secondary-nav',
        'util__reset-list': true,
      })}
    >
      {links.map((linkItem, index) => {
        const childrenLinks = linkItem.childLinks || []
        const isStrategiesLink = linkItem.linkText === 'Strategies'
        const hasStrategiesMegaMenu =
          isStrategiesLink &&
          strategiesMegaMenu &&
          strategiesMegaMenu.length !== 0

        if (childrenLinks.length > 0 || hasStrategiesMegaMenu) {
          return (
            <li
              className={cx('navigationLinksItem')}
              key={linkItem?.url}
            >
              <NavigationLink
                itemLink={linkItem}
                itemKey={`${linkType}-${index}`}
                linkType={linkType}
                expandedSubmenuKey={expandedSubmenu}
                handleExpandedMenu={handleExpandedMenu}
                handleCloseBtnClick={handleCloseBtnClick}
                labels={labels}
                renderSubMenu={(props) => {
                  if (
                    hasStrategiesMegaMenu &&
                    selectedSubMenuGroup !== undefined &&
                    setSelectedSubMenuGroup
                  ) {
                    return (
                      <NavigationMegaSubMenu
                        strategiesMegaMenu={strategiesMegaMenu}
                        selectedGroup={selectedSubMenuGroup}
                        setSelectedGroup={setSelectedSubMenuGroup}
                        {...props}
                      />
                    )
                  }

                  return <NavigationStandardSubMenu {...props} />
                }}
              />
            </li>
          )
        }

        return (
          <li
            className={cx('navigationLinksItem')}
            key={linkItem?.url}
          >
            <ActiveLink
              isButton={false}
              className={cx('primaryNavLinkBaseStyle')}
              link={linkItem}
            />
          </li>
        )
      })}
    </ul>
  )
}