import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useCallback, useRef } from 'react'
import useExpandCollapse from '../../hooks/useExpandCollapse'
import { useGlobalStoreContext } from '../../hooks/useGlobalStore'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import { ILink } from '@/shared/MwsViewModels'
import StyledButton from '../StyledButton'
import StyledLink from '../StyledLink'
import * as styles from './TabLinks.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export type TabLinksType = ILink[]

export interface TabLinksProps {
  links: TabLinksType
  className?: string
}

const TabLinks = ({ links, className }: TabLinksProps) => {
  const hasLinks = links.length > 0
  const dropdownRef = useRef<HTMLDivElement>(null)
  const {
    elementExpandedState,
    elementHeightValue,
    setCollapseState,
    handleButtonClick,
  } = useExpandCollapse(dropdownRef)

  const router = useRouter()
  const currentPath = router?.asPath || ''
  const { globalStore } = useGlobalStoreContext()

  function checkIsSamePath(linkUrl: string) {
    return currentPath === linkUrl
  }

  const activeLinkTab = links?.find((linkItem) => {
    return checkIsSamePath(linkItem?.url)
  })

  const handleOutsideCallback = useCallback(() => {
    setCollapseState()
  }, [setCollapseState])

  useOutsideAlerter(dropdownRef, handleOutsideCallback, elementExpandedState)

  return hasLinks ? (
    <div className={cx('tabLinks', className, 'tabLinksContainer')}>
      <div className={cx('container')}>
        <div className={cx('buttonWrapper')}>
          <StyledButton
            type="text"
            customIcon={elementExpandedState ? 'chevronUp' : 'chevronDown'}
            className={cx('dropdownButton')}
            callback={handleButtonClick}
            aria-expanded={elementExpandedState}
            aria-controls={'dropdown-tablinks'}
            aria-label={
              elementExpandedState
                ? 'Close Dropdown Navigation'
                : 'Expand Dropdown Navigation'
            }
          >
            <span className={cx('dropdownButtonLabel')}>
              {activeLinkTab ? activeLinkTab?.linkText : 'Navigation'}
            </span>
          </StyledButton>
        </div>
        <div
          ref={dropdownRef}
          className={cx({
            dropdown: true,
            dropdownActive: elementExpandedState,
          })}
          style={{ height: elementHeightValue }}
          id={'dropdown-tablinks'}
          data-testid="dropdown-tablinks"
          aria-hidden={globalStore.isMobile ? !elementExpandedState : false}
        >
          <ul className={cx('linksList', 'util__reset-list')}>
            {links.map((linkItem, index) => {
              return (
                <li
                  key={'TabLink-' + index}
                  className={cx('linksListItem')}
                >
                  <StyledLink
                    type="text"
                    aria-label={
                      checkIsSamePath(linkItem?.url)
                        ? `Selected - ${linkItem?.linkText}`
                        : undefined
                    }
                    className={cx({
                      linktab: true,
                      isActive: checkIsSamePath(linkItem?.url),
                    })}
                    data={linkItem}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  ) : null
}

export default TabLinks
