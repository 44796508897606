import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './error-page.vue';
import * as mws from './ErrorViewModels';

const logoImageSrc = '/assets/images/common/BG_logo_WHITE.svg';

@Component({
  mixins: [template],
})
export default class ErrorPage extends Vue {
  logoImageSrc = '';

  @Prop()
  page: mws.ErrorPage;

  get errorMessage(): mws.ErrorMessage {
    return this.page?.errorMessage || { titleLines: [], message: '' };
  }

  created() {
    this.logoImageSrc = logoImageSrc;
  }
}
