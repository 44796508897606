import { Component, Mixins, Watch } from 'vue-property-decorator';
import { GlobalNavigationSelectorPopupLink, ILink } from '@/shared/MwsViewModels';
import { SelectedChannel } from '@/shared/global-navigation/SelectedChannel';
import template from './menu-popup.vue';
import Translatable from '../../translation/Translatable';
import GenericLink from '../../shared/components/link/GenericLink';

const logoImgSrc = '/assets/images/common/BG_logo_BLACK.svg';
const closeIconSrc = '/assets/images/common/cross-black.svg';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class MenuPopup extends Mixins(Translatable) {
  secondary: ILink[] = [];

  additional: ILink[] = [];

  selectedChannel: SelectedChannel | null = null;

  globalSelector: GlobalNavigationSelectorPopupLink = null;

  logoImgSrc = '';

  closeIconSrc = '';

  async created() {
    this.logoImgSrc = logoImgSrc;
    this.closeIconSrc = closeIconSrc;

    await this.loadDictionary([
      'header_menu_mobile_investor_channel_label',
      'header_menu_title',
      'header_menu_important_information',
      'header_menu_terms_conditions',
    ]);
  }

  get isOpen(): boolean {
    return this.$store.state.popups.menuPopup.isOpen;
  }

  @Watch('isOpen')
  onIsOpenChange(): void {
    this.secondary = this.$store.state.popups.menuPopup.secondaryLinks;
    this.additional = this.$store.state.popups.menuPopup.additionalLinks;
    this.globalSelector = this.$store.state.popups.menuPopup.globalSelector;
    this.selectedChannel = this.$store.state.globalSelector.selectedChannel;
  }

  close(): void {
    this.$store.dispatch('closeMenuPopup');
  }
}
