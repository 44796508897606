import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './canada-fund-facts-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import GlossaryPanel from '../glossary/GlossaryPanel'

@Component({
  mixins: [template],
  components: {
    GlossaryPanel
  },
})
export default class CanadaFundFactsPanel extends Vue {
  @Prop()
  data: mws.CanadaFundFactsPanel;
}
