import { Component, Mixins, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './literature-library-overall-panel.vue';
import Translatable from '../../../translation/Translatable';
import LiteratureLibraryFilter from './literature-library-filter/LiteratureLibraryFilter';
import LiteratureLibraryCarousel from './literature-library-carousel/LiteratureLibraryCarousel';
import LiteratureLibraryResults from './literature-library-results/LiteratureLibraryResults';
import PostbackMixin from '../../../shared/postback/PostbackMixin';

@Component({
  mixins: [template],
  components: {
    LiteratureLibraryFilter,
    LiteratureLibraryCarousel,
    LiteratureLibraryResults,
  },
})
export default class LiteratureLibraryOverallPanel extends Mixins(PostbackMixin, Translatable) {
  @Prop()
  data: mws.LiteratureLibraryOverallPanel;

  async created() {
    await this.loadDictionary(['literature_library_title']);
  }

  async onSearch(dto: mws.LiteratureDto): Promise<void> {
    const model = this.createRequestModel(this.data, dto);
    const response = await this.fetchPostback(model);
    await this.mergePostback(response);
  }
}
