import PerformanceTableRow from './performance-table-row';
import * as mws from '../../../shared/MwsViewModels';

function getPerformanceRows(performanceTable: mws.Table): PerformanceTableRow[] {
  const rowsHeaderAndValues = performanceTable.rows.map((row) => {
    const [header, ...values] = row.columns;
    return { header, values } as PerformanceTableRow;
  });

  return rowsHeaderAndValues;
}

export default getPerformanceRows;
