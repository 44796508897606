import { Module } from 'vuex';
import { AthenaeumPanel, GlobalNaviagtion, RiskWarning } from '../../shared/MwsViewModels';
import actions from './popups/Popups.actions';
import getters from './popups/Popups.getters';
import mutations from './popups/Popups.mutations';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    userLocationByIP: '',
    pendingGeoIp2Request: false,
    warningPopup: {
      isOpen: false,
      message: '',
      title: '',
    },
    riskWarningPopup: {
      isOpen: false,
      riskWarnings: {} as RiskWarning[],
      acceptUrl: null,
      multiRiskWarningIntroText: '',
      multiRiskWarningHeading: ''
    },
    selectLocationPopup: {
      isOpen: false,
      globalNavigation: { main: [], additional: [] } as GlobalNaviagtion,
      onCloseRedirectUrl: null,
    },
    redirectSelectorPopUp: {
      isOpen: false,
      globalNavigation: { main: [], additional: [] } as GlobalNaviagtion,
      onCloseRedirectUrl: null
    },
    menuPopup: {
      isOpen: false,
      secondaryLinks: [],
      additionalLinks: [],
      globalSelector: null
    },
    athenaeumPopup: {
      isOpen: false,
      athenaeumContentData: {} as AthenaeumPanel,
    },
    clientLoginPopup: {
      isOpen: false,
      linkUrl: '',
    },
    videoPopup: {
      data: null,
      isOpen: false,
      config: null,
    },
    literatureOrderPopup: {
      isOpen: false,
    },
    fundSubscriptionPopup: {
      isOpen: false,
      link: '',
    },
  },
  actions,
  getters,
  mutations,
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
