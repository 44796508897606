import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './region.vue';
import RiskWarningMixin from '../../../risk-warning-popup/RiskWarningMixin';

@Component({
  mixins: [template],
})
export default class Region extends Mixins(RiskWarningMixin) {
  @Prop()
  public region?: mws.Region;

  @Prop()
  public isVisible = false;

  public toggleVisibility(): void {
    this.$emit("regionClicked");
  }

  public onChannelClick($event: Event, channel: mws.Channel): void {
    const { riskWarnings, url, multiRiskWarningIntroText , multiRiskWarningHeading } = channel;

    var riskAcceptedUrl = this.riskAcceptedUrl(riskWarnings);
    if (riskAcceptedUrl == 'NA') {
      $event.preventDefault();
      this.$store.dispatch('closeSelectLocationPopup');
      this.$store.dispatch('closeRedirectSelectorPopUp');
      this.$store.dispatch('openRiskWarningPopup', {
        riskWarnings,
        acceptUrl: url,
        multiRiskWarningIntroText: multiRiskWarningIntroText,
        multiRiskWarningHeading: multiRiskWarningHeading,
      });
    }
    //Risk warning has accepted. If accept URL is provided user should be navigated to target page
    else if (riskAcceptedUrl != '') {
      window.location.href = riskAcceptedUrl;
    }
  }

  public riskAcceptedUrl(riskWarnings): string
  {
    var response = 'NA';

    if(riskWarnings && riskWarnings.length > 0)
    {
      riskWarnings.forEach((riskWarning) => {
        var multiRiskSessionId =
          riskWarning.id.toString() + '$$' + (riskWarning.acceptCallToAction ? riskWarning.acceptCallToAction.url : '');

        if (
          riskWarning &&
          (this.hasAcceptedRisk(riskWarning.id.toString()) ||
            this.hasAcceptedRisk(multiRiskSessionId))
        ) {
            response = riskWarning.acceptCallToAction ? riskWarning.acceptCallToAction.url : '';
        }
      });
    }

    return response;
  }
}
