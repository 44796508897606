import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import defaultDictionary from './DefaultDictionary';

@Component
export default class Translatable extends Vue {
  public getTranslation(key: string): string {
    const translation = this.$store.getters.translations[key];
    return translation || `[${key}]`;
  }

  public getTranslationOrDefault(key: string, defaultValue: string = null): string {
    const translation = this.$store.getters.translations[key];
    return (
      translation || defaultValue || defaultDictionary.find((d) => d.key === key)?.value || `[${key}]`
    );
  }

  public async loadDictionary(keys: string[]): Promise<void> {
    if (typeof window === "undefined") {
      // Return early during SSR
      return;
    }
    
    const culture: string = this.$store.getters.pageData.requestMetadata?.culture || 'en-GB';
    const url: string = `/dictionary-key?${Translatable.getKeys(keys)}&culture=${culture}`;
    
    return axios.get(url).then((response) => {
      const translations = {};
      response.data
        .forEach((data) => {
          translations[data.key] = data.value;
        });

      this.$store.dispatch('setTranslations', translations);
    }).catch((err) => console.warn(`Failed to load translation: ${err}`));
  }

  private static getKeys(keys: string[]): string {
    let url = '';

    keys.forEach((key, i) => {
      url += `key=${key}${i < keys.length - 1 ? '&' : ''}`;
    });

    return url;
  }
}
