import { Component, Prop, Vue } from 'vue-property-decorator';
import template from './disclaimer-wrapper.vue';

@Component({
  mixins: [template],
})
export default class DisclaimerWrapper extends Vue {
  @Prop()
  disclaimerText: string;

}
