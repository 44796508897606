import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './square-block-item.vue';
import BlockItemBase from '../block-item-base/BlockItemBase';
import * as mws from '../../../../shared/MwsViewModels';

@Component({
  mixins: [template],
  components: {
    BlockItemBase,
  },
})
export default class CircularBlockItem extends Vue {
  @Prop()
  link!: mws.CircleBlockLinkElement;
}
