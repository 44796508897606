import React from 'react'
import classnames from 'classnames/bind'
import Image from 'next/future/image'
import {
  FooterWithImagesAndLinksPanel as IFooterWithImagesAndLinksPanel,
  ILink,
} from '@/shared/MwsViewModels'
import { safeArray } from '../../utils/helpers'
import StyledLink from '../../lib/StyledLink'
import * as styles from './FooterWithImagesAndLinksPanel.module.scss'

const cx = classnames.bind(styles)

export interface FooterWithImagesAndLinksPanelProps {
  data: IFooterWithImagesAndLinksPanel
}

const FooterWithImagesAndLinksPanel = ({
  data,
}: FooterWithImagesAndLinksPanelProps) => {
  const socialLinks = safeArray(data?.socialMediaContentLinks).filter(
    (socialLink) => socialLink?.image?.url
  )
  const hasSocialLinks = socialLinks.length > 0

  const hasContactLink = data?.contactUsLink?.url

  return (
    <footer className={cx('footer', 'rebrand-lite')}>
      <div className={cx('footer-container')}>
        <div className={cx('gridLinksRow')}>
          <div className={cx('columnMain', 'gridLinksRowColumn')}>
            {data?.usefulLinksHeading && (
              <h2
                className={cx('footer-heading', 'spacing__md', 'type__h4')}
                dangerouslySetInnerHTML={{ __html: data.usefulLinksHeading }}
              />
            )}
            <LinksGroup
              ariaLabel="Primary Footer Links"
              className={cx('twoColumnsList')}
              links={data?.usefulLinks}
            />
          </div>
          <div className={cx('columnMiddle', 'gridLinksRowColumn')}>
            {data?.legalNoticesHeading && (
              <h2
                className={cx('footer-heading', 'spacing__md', 'type__h4')}
                dangerouslySetInnerHTML={{ __html: data.legalNoticesHeading }}
              />
            )}
            <LinksGroup
              ariaLabel="Website Links"
              className={cx('columnMiddleLinkGroup')}
              heading={data?.subHeading1}
              links={data?.websiteLinks}
            />
            <LinksGroup
              ariaLabel="Company Links"
              className={cx('columnMiddleLinkGroup')}
              heading={data?.subHeading2}
              links={data?.companyLinks}
            />
          </div>
          <div className={cx('columnLast', 'gridLinksRowColumn')}>
            {hasSocialLinks && data?.usefulLinksHeading && (
              <h2
                className={cx('headingList', 'type__h4')}
                dangerouslySetInnerHTML={{
                  __html: data.socialMediaLinksHeading,
                }}
              />
            )}
            {hasSocialLinks && (
              <ul
                aria-label="Social Links"
                className={cx('util__reset-list', 'socialLinks')}
              >
                {socialLinks.map((socialLink) => {
                  return (
                    <li
                      className={'socialLinksItem'}
                      key={socialLink.url}
                    >
                      <StyledLink
                        aria-label={`Follow us on ${socialLink?.linkTitle}`}
                        type="socialIconCircle"
                        data={socialLink}
                      >
                        {socialLink?.image?.url && (
                          <Image
                            role="presentation"
                            alt={socialLink?.image?.altText || ''}
                            src={socialLink?.image?.url}
                            width="20"
                            height="20"
                            className={cx('socialIcon')}
                            unoptimized={true}
                          />
                        )}
                      </StyledLink>
                    </li>
                  )
                })}
              </ul>
            )}
            {hasContactLink && (
              <div
                data-testid="ContactUs"
                className={cx('contactLinkCallout')}
              >
                {data?.contactFooterHeading && (
                  <h2
                    className={cx('headingContact', 'type__h3')}
                    dangerouslySetInnerHTML={{
                      __html: data.contactFooterHeading,
                    }}
                  />
                )}
                <StyledLink
                  type="link"
                  data={data.contactUsLink}
                />
              </div>
            )}
          </div>
        </div>

        <div className={cx('gridLinksRow', 'gridLinksRowOutro')}>
          <div className={cx('columnMain')}>
            <Image
              alt="Baillie Gifford & Co"
              src="/assets/images/common/BG_logo_WHITE.svg"
              width="162"
              height="45"
              className={cx('logo', 'footer-logo')}
              priority={true}
              unoptimized={true}
            />
          </div>
          {data?.copyrightText && (
            <div className={cx('columnCopyright')}>
              <span className={cx('copyright')}>
                &#169; {data.copyrightText}
              </span>
            </div>
          )}
        </div>
      </div>
    </footer>
  )
}

interface LinksGroupProps {
  links: ILink[]
  heading?: string
  className?: string
  ariaLabel: string
}

function LinksGroup({ links, heading, ariaLabel, className }: LinksGroupProps) {
  const safeLinks = safeArray(links)
  const showLinks = safeLinks.length > 0

  return (
    <>
      {showLinks && (
        <div className={cx(className)}>
          {heading && (
            <h3 className={cx('subheading', 'type__h5')}>{heading}</h3>
          )}
          <ul
            aria-label={ariaLabel}
            className={cx('util__reset-list', 'listLinks')}
          >
            {links.map((linkOption) => {
              return (
                <li
                  className={cx('listLinksItem')}
                  key={linkOption.url}
                >
                  <StyledLink
                    type={'linkSmall'}
                    data={linkOption}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}

export default FooterWithImagesAndLinksPanel
