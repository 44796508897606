export default class CellData {
  constructor(text: string, whiteText: string, color: string,
    source: number, icon: string, type = 'AllBelow', lowerLine = false, secondSource = null) {
    this.text = text;
    this.whiteText = whiteText;
    this.color = color;
    this.source = source;
    this.icon = icon;
    this.type = type;
    this.lowerLine = lowerLine;
    this.secondSource = secondSource
  }

    show = true;

    source = 0.0;

    calculatedValue = 0.0;

    color = '';

    icon = '';

    text = '';

    whiteText = '';

    additionalClass = '';

    type = '';

    lowerLine = false;

    secondSource = null;

    secondCalculatedValue = 0.0;
}