export const StylesMap = {
  link: 'redesignLink',
  linkLight: 'linkLight',
  linkSmall: 'linkSmall',
  linkLarge: 'linkLarge',
  linkHeavy: 'linkHeavy',
  socialIconCircle: 'socialIconCircle',
  ghost: 'ghost',
  pill: 'pill',
  pillReverse: 'pillReverse',
  radioSelector: 'radioSelector',
  pagination: 'pagination',
  text: 'text',
  primaryNavigation: 'primaryNavigation',
  linkExternal: 'linkExternal',
} as const

export type LinkButtonStyles = keyof typeof StylesMap

export function provideButtonIcon(linkType: string, type: LinkButtonStyles) {
  if (['link', 'linkLarge', 'linkHeavy', 'linkLight', 'ghost', 'linkExternal'].includes(type)) {
    switch(linkType) {
      case 'LiteratureLink': 
        return 'document';
      default: 
        return type === 'linkExternal' ? 'linkExternal' : 'arrow'
    } 
  } else {
    return null
  }
}

export interface IBaseLink {
  url: string
  linkText: string
  linkTitle: string
  openInNewWindow: boolean
}
