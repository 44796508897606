import { render, staticRenderFns } from "./fund-donut-chart-panel.vue?vue&type=template&id=4cd98d32&scoped=true&"
var script = {}
import style0 from "../../../assets/styles/panels/_ChartsPanel.scss?vue&type=style&index=0&lang=scss&"
import style1 from "../../../assets/styles/panels/_AssetChart.scss?vue&type=style&index=1&lang=scss&"
import style2 from "../../../assets/styles/common/highcharts.scss?vue&type=style&index=2&id=4cd98d32&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd98d32",
  null
  
)

export default component.exports