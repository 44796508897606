import * as mws from '@/shared/MwsViewModels';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';
import AddressSelectorMixin, { AddressEntry } from '../../../../../src/popups/literature-order/AddressSelectorMixin';
import RequiredFieldLabel from '../../../../../src/popups/literature-order/required-field-label/RequiredFieldLabel';
import { FieldValidation } from '../../../../shared/forms/FieldValidation';
import FormSubmissionHistoryMixin from '../../../../shared/forms/FormSubmissionHistoryMixin';
import template from './trust-magazine-form.vue';

@Component({
  mixins: [template],
  components: {
    VueRecaptcha,
    RequiredFieldLabel
  },
})
export default class TrustMagazineForm extends Mixins(FormSubmissionHistoryMixin,AddressSelectorMixin) {
  @Prop()
  data: mws.TrustMagazineFormPanel;

  @Prop()
  formDisabled: boolean;

  validateRequired = false;
  cannotFindPostcode = false;
  ukPostcodeValid = true;

  addressesToSelect: AddressEntry[] = [];
  selectedAddressId = null;

  formData: mws.TrustMagazineFormDto = {

    title : '',
    forename : null,
    surname : null,
    postcode : null,
    addressLine1 : null,
    addressLine2 : null,
    town : null,
    county : null,
    emailAddress : null,
  }

  recaptchaResponse: string | null = null;

  useCaptcha = false;

  get canSubmit(): boolean {
    return !(this.useCaptcha && this.recaptchaResponse == null);
  }

  get sitekey(): string {
    return this.$store.getters.config.recaptchaSitekey;
  }

  shouldUseCaptcha(): boolean {
    return this.getRecentSubmissionsCount() > 1;
  }

  isRequiredValid(text: string): boolean {
    return !this.validateRequired || FieldValidation.validateNotEmpty(text);
  }

  validateUkPostcode(): void {
    this.ukPostcodeValid = !this.formData.postcode || this.formData.postcode.length === 0 || /^\s+$|^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i.test(this.formData.postcode);
  }

  formatUkPostcode(): void {
    if(this.formData.postcode.length === 0){
      return;
    }
    let formattedPostcode = `${this.formData.postcode}`;
    formattedPostcode = formattedPostcode.replace(/\s/g, '');
    if(formattedPostcode.length < 4) {
      this.formData.postcode = formattedPostcode;
    } else {
      this.formData.postcode = `${formattedPostcode.substring(0, formattedPostcode.length-3)} ${formattedPostcode.substring(formattedPostcode.length-3, formattedPostcode.length)}`;
    }
  }

  isTitleValid(): boolean { return this.isRequiredValid(this.formData.title); }
  isForenameValid(): boolean { return this.isRequiredValid(this.formData.forename); }
  isSurnameValid(): boolean { return this.isRequiredValid(this.formData.surname); }
  isPostcodeValid(): boolean { return this.isRequiredValid(this.formData.postcode) && this.ukPostcodeValid; }
  isAddress1Valid(): boolean { return this.isRequiredValid(this.formData.addressLine1); }
  isAddress2Valid(): boolean { return true; }
  isTownValid(): boolean { return this.isRequiredValid(this.formData.town); }
  isCountyValid(): boolean { return true; }
  isEmailValid(): boolean {
    const { emailAddress } = this.formData;
    return emailAddress == null || emailAddress === '' || FieldValidation.validateEmail(emailAddress);
  }

  async findAddress(): Promise<void> {
    this.ukPostcodeValid = true;
    this.addressesToSelect = await this.findAddressesByPostcode(this.formData.postcode);
    const addressCount = this.addressesToSelect.length;
    this.cannotFindPostcode = addressCount === 0;
    this.selectedAddressId = addressCount === 0 ? null : this.addressesToSelect[0].id;
  }

  async onSelectedAddressChange(): Promise<void> {
    this.addressesToSelect.length = 0;
    try {
      const details = await this.findAddressDetails(this.selectedAddressId);
      this.formData.addressLine1 = details.address1;
      this.formData.addressLine2 = details.address2;
      this.formData.county = details.county;
      this.formData.town = details.town;
      this.cannotFindPostcode = false;
    } catch (_exc) {
      this.cannotFindPostcode = true;
    }
  }

  onSubmit(): void {
    this.validateRequired = true;
    if ([
      this.isTitleValid(),
      this.isForenameValid(),
      this.isSurnameValid(),
      this.isPostcodeValid(),
      this.isAddress1Valid(),
      this.isAddress2Valid(),
      this.isTownValid(),
      this.isCountyValid(),
      this.isEmailValid(),
    ].some((x) => !x)) {
      return;
    }

    if (this.recaptchaResponse == null && this.shouldUseCaptcha()) {
      this.useCaptcha = true;
      return;
    }


    const dto: mws.TrustMagazineFormDto = {
      title: this.formData.title === '' ? undefined : this.formData.title,
      forename: this.formData.forename === '' ? undefined : this.formData.forename,
      surname: this.formData.surname === '' ? undefined : this.formData.surname,
      postcode: this.formData.postcode === '' ? undefined : this.formData.postcode,
      addressLine1: this.formData.addressLine1 === '' ? undefined : this.formData.addressLine1,
      addressLine2: this.formData.addressLine2 === '' ? undefined : this.formData.addressLine2,
      town: this.formData.town === '' ? undefined : this.formData.town,
      county: this.formData.county === '' ? undefined : this.formData.county,
      emailAddress: this.formData.emailAddress === '' ? undefined : this.formData.emailAddress
    };

    this.$emit('submit', dto);
  }

  onRecaptchaSuccess(response: string): void {
    this.recaptchaResponse = response;
  }

  onRecaptchaFailure(): void {
    this.recaptchaResponse = null;
  }
}
