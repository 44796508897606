import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './dr-message-button.vue';
import * as mws from '../../../shared/MwsViewModels';

const iconSrc = '/assets/images/quotes/exclamation-white.svg';

@Component({
  mixins: [template],
})
export default class DrMessageButton extends Vue {
    @Prop()
    data!: mws.DrMessage;

    iconSrc = '';

    public get message(): mws.DrMessage {
      return this.data;
    }

    created() {
      this.iconSrc = iconSrc;
    }

    openPopup(): void {
      this.$store.dispatch('openWarningPopup', {
        title: this.message.heading,
        message: this.message.body,
      });
    }

    public get isMessage(): boolean {
      return this.message != null;
    }
}
