import { Component, Vue } from 'vue-property-decorator';
import { SearchCriteria } from '@/shared/MwsViewModels';
import axios from 'axios';
import template from './search-popup.vue';
import SearchItems from '../search-items/SearchItems';

const clearIconSrc = '/assets/images/common/delete.svg';
const searchIconSrc = '/assets/images/common/search-white.svg';
const closeIconSrc = '/assets/images/crosses/cross-button-black.svg';

@Component({
  mixins: [template],
  components: {
    SearchItems,
  },
})
export default class SearchPopup extends Vue {
  clearIconSrc: string = clearIconSrc;

  searchIconSrc: string = searchIconSrc;

  closeIconSrc: string = closeIconSrc;

  searchCriteria = {} as SearchCriteria;

  searchInfo = '';

  bus = new Vue();

  async search(): Promise<void> {
    if (this.inputChanged()) {
      this.bus.$emit('searchStarted');
      this.searchInfo = `Results of search for "${this.searchCriteria.searchTerm}"`;
      this.searchCriteria.url = this.$router.currentRoute.path;

      const searchCriteria = { ...this.searchCriteria };
      await axios
        .post('/Search', searchCriteria)
        .then((response) => {
          const results = response.data;
          return this.$store.dispatch('updateSearchResults', { results, searchCriteria });
        }).catch((error) => {
          console.error(`Search failed: ${error}`);
        }).finally(() => {
          this.bus.$emit('searchFinished');
          this.setSearchResultInfo();
        });
    }
  }

  private inputChanged(): boolean {
    return this.searchCriteria.searchTerm !== this.$store.getters.searchCriteria.searchTerm;
  }

  private setSearchResultInfo() {
    const totalResults = this.$store.getters.totalSearchResults;
    this.searchInfo = totalResults > 0
      ? `${totalResults} results for "${this.searchCriteria.searchTerm}"`
      : `No results found for "${this.searchCriteria.searchTerm}"`;
  }

  clearInput(): void {
    this.searchCriteria.searchTerm = '';
  }

  closePopup(): void {
    this.resetDefaults();
    this.bus.$emit('searchClosed');
    this.$store.dispatch('closeSearchPopup');
  }

  private resetDefaults(): void {
    this.searchCriteria = {} as SearchCriteria;
    this.searchInfo = '';
  }

  public get isOpen(): boolean {
    const { isOpen } = this.$store.state.search.searchPopup;
    return isOpen;
  }

  public get currentChannel(): string {
    const { currentUrl } = this.$store.state.search;

    const currentSubPage = currentUrl.split('/').pop();
    if (currentSubPage && !currentUrl.includes('/insights/ic-')) {
      const currentSubPageCapitalized = currentSubPage
        .replace('-', ' ')
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      return `Search - ${currentSubPageCapitalized}`;
    }
    return '';
  }
}
