import { Component, Prop, Vue } from 'vue-property-decorator';
import * as mws from '../../shared/MwsViewModels';
import template from './side-related-insights.vue';

@Component({
    mixins: [template]
})
export default class SideRelatedInsights extends Vue {
    @Prop()
    data!: mws.RelatedInsights;

    get items(): mws.IInsightPageLink[] {
        return this.data && this.data.items ? this.data.items : [];
      }
  
      imageUrl(item: mws.IInsightPageLink): string {
        return item.image ? item.image.url : '';
      }
}
