import { Component, Prop } from 'vue-property-decorator';
import template from './page.vue';
import Home from '../homepage/Home';
import PanelPage from '../panel/PanelPage';
import DrMessageButton from './components/DrMessageButton/DrMessageButton';
import Translatable from '../translation/Translatable';
import Footer from '../footer/Footer';
import WarningPopup from '../popups/warning-popup/components/WarningPopup';
import RiskWarningPopup from '../popups/risk-warning-popup/components/RiskWarningPopup';
import GlobalSelectorPopup from '../popups/global-navigation-menu/components/GlobalSelectorPopup';
import RedirectSelectorPopup from '../popups/global-navigation-menu/components/RedirectSelectorPopup';
import * as mws from '../shared/MwsViewModels';
import SearchPopup from '../popups/search/search-popup/SearchPopup';
import AthenaeumPopup from '../popups/athenaeum/athenaeum-popup/AthenaeumPopup';
import ClientLoginPopup from '../popups/client-login/ClientLoginPopup';
import FundSubscriptionPopup from '../popups/fund-subscription/FundSubscriptionPopup';
import FundPage from '../fund/FundPage';
import VideoPopup from '../popups/video/VideoPopup';
import LiteratureOrderPopup from '../popups/literature-order/LiteratureOrderPopup';
import PageInfoButton from '../debug/page-info-button/PageInfoButton';
import FundInfoButton from '../debug/fund-info-button/FundInfoButton';
import InsightsVideoPage from '../insights/video/InsightsVideoPage';
import InsightsArticlePage from '../insights/insights-article-page/InsightsArticlePage';
import LiteratureOrderTab from '../shared/literature-order/literature-order-tab/LiteratureOrderTab';
import InsightsPodcastPage from '../insights/podcast/InsightsPodcastPage';
import getSelfAttestationData from '../react/rebrand-lite/lib/ModalSelfAttestation/getSelfAttestationData';
import { ModalOrchestration } from '../react/rebrand-lite/lib/ModalOrchestration/index';
import { ReactInVue } from 'vuera';
@Component({
  mixins: [template],
  components: {
    Home,
    PanelPage,
    DrMessageButton,
    Footer,
    WarningPopup,
    RiskWarningPopup,
    SelectLocationPopup: GlobalSelectorPopup,
    RedirectSelectorPopUp: RedirectSelectorPopup,
    SearchPopup,
    AthenaeumPopup,
    ClientLoginPopup,
    FundSubscriptionPopup,
    VideoPopup,
    LiteratureOrderPopup,
    FundPage,
    PageInfoButton,
    FundInfoButton,
    InsightsVideoPage,
    InsightsArticlePage,
    LiteratureOrderTab,
    InsightsPodcastPage,
    ModalOrchestration: ReactInVue(ModalOrchestration),
  },
})
export default class Page extends Translatable {
  @Prop()
  page: mws.Page;

  get isHome(): boolean {
    return this.page.pageType === 'HomePage'; // TODO: nameof()
  }

  get isPanel(): boolean {
    return (
      this.page.pageType === 'PanelPage' ||
      this.page.pageType === 'StrategyPanelPage' ||
      this.page.pageType === 'CapabilityPanelPage'
    );
  }

  get isFund(): boolean {
    return this.page.pageType === 'FundPanelPage';
  }

  get isInsightsVideo(): boolean {
    return this.page.pageType === 'InsightsVideoPage';
  }

  get isInsightsPodcast(): boolean {
    return this.page.pageType === 'InsightsPodcastPage';
  }

  get rebrandLiteFooter() {
    if (this.isPanel || this.isFund || this.isHome) {
      var panelGroups = (this.page as unknown as mws.IPageWithPanels).panelGroups;
      return panelGroups
        ? panelGroups
            .flatMap((x) => x.panels)
            .filter((p) => p.panelName === 'FooterWithImagesAndLinksPanel')[0]
        : null;
    }

    if (this.isInsightsArticle || this.isInsightsVideo || this.isInsightsPodcast) {
      return this.page?.footer?.insightFooterPanel;
    } else return null;
  }

  get isInsightsArticle(): boolean {
    return this.page.pageType === 'InsightsArticlePage';
  }

  get message(): mws.DrMessage {
    return this.page.drMessage;
  }

  get popupOpened(): boolean {
    return this.$store.getters.isAnyPopupOpened;
  }

  get pageClasses(): string[] {
    const smallNav: boolean = this.$store.state.window.navbarOverflow;
    return [smallNav ? 'small-nav' : 'large-nav'];
  }

  get modalSelfAttestationData() {
    return getSelfAttestationData(this.page);
  }

  displayDebugInfo(): boolean {
    return this.$store.getters.config.isDebug;
  }

  displayFundInfo(): boolean {
    return this.displayDebugInfo() && this.isFund;
  }

  mounted() {
    if (this.isHome) {
      const homePage: mws.HomePage = this.page as mws.HomePage;
      // If the home page is served with global navigation for redirect, then display it
      if (Boolean(homePage.redirectSelectorPopUp)) {
        this.$store.dispatch('populateRedirectSelectorPopUp', homePage.redirectSelectorPopUp);
        this.$store.dispatch('openRedirectSelectorPopUp', null);
        // It prevents duplicating locations list after accept Risk Warning
        (this.page as mws.HomePage).redirectSelectorPopUp = null;
      }
    }
  }
}
