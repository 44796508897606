import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './manifesto-body.vue';

@Component({
  mixins: [template],
})
export default class ManifestoBody extends Vue {
    @Prop({ default: '' })
    html!: string;
}
