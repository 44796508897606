import { Component, Vue, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './how-to-invest.vue';
import CarouselExternalLink from '../../../panel/components/carousel/carousel-items/CarouselExternalLink';

@Component({
  mixins: [template],
  components: {
    CarouselExternalLink,
  }
})
export default class HowToInvestPanel extends Vue {
  @Prop()
  data?: mws.CarouselPanel;
  
}
