import { Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './core-functions-panel.vue';
import Translatable from '../../../translation/Translatable';
import CoreFunctionBlock from './core-function-block/CoreFunctionBlock';

@Component({
  mixins: [template],
  components: {
    CoreFunctionBlock,
  },
})
export default class CoreFunctionsPanel extends Translatable {
  @Prop()
  data: mws.CoreFunctionsPanel;

  async created() {
    await this.loadDictionary(['core_functions_title']);
  }
}
