import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LiteraturePage, LiteratureRiskWarning } from '@/shared/MwsViewModels';
import Translatable from '../translation/Translatable';
import template from './risk-warning-page.vue';
import RiskWarningMixin from '../popups/risk-warning-popup/RiskWarningMixin';

@Component({
  mixins: [template],
})
export default class RiskWarningPage extends Mixins(Translatable, RiskWarningMixin) {
  @Prop()
  page: LiteraturePage;

  async created() {
    await this.loadDictionary([
      'document_risk_warning_title',
      'document_risk_warning_accept',
    ]);
  }

  handleClick(): void {
    this.acceptRisk(this.riskId, false);
    this.$router.push({ query: { rwAccept: '1' } });
    this.$router.go(0);
  }

  get heading(): string {
    const translation = this.getTranslationOrDefault('document_risk_warning_title', 'Important Information');
    return this.riskWarning.heading || translation;
  }

  get acceptText(): string {
    const translation = this.getTranslationOrDefault('document_risk_warning_accept', 'Continue to document');
    return this.riskWarning.callToActionButtonText || translation;
  }

  get riskWarning(): LiteratureRiskWarning {
    return this.page.riskWarning || {} as LiteratureRiskWarning;
  }

  private get riskId(): string {
    return this.riskWarning.id?.toString();
  }
}
