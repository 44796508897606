export default function createFundDonutChartOptions(
  colours: any[], data: any[], piePointEvents: {[key: string]: (event: any) => void},
): any {
  return {
    chart: {
      type: 'pie',
    },
    title: false,
    plotOptions: {
      pie: {
        size: '110%',
        allowPointSelect: false,
        dataLabels: {
          distance: -30,
          enabled: true,
          shadow: false,
          style: {
            textOutline: 'none',
            fontWeight: 400,
          },
          formatter(): number {
            if (this.y > 2.5) {
              return this.point.index + 1;
            }
            return undefined;
          },
        },
        point: {
          events: piePointEvents,
        },
      },
    },
    tooltip: {
      enabled: false,
      crosshairs: false,
    },
    credits: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    series: [
      {
        colors: colours,
        innerSize: '70%',
        data,
        states: {
          hover: {
            halo: false,
          },
        },
      },
    ],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 372,
        },
        chartOptions: {
          plotOptions: {
            pie: {
              dataLabels: {
                distance: '-25',
                style: {
                  fontSize: '9px',
                },
              },
            },
          },
        },
      }],
    },
  };
}
