import { DailyPricesFilterSelections } from '../../../shared/MwsViewModels';
import EventBus from '../../../shared/EventBus';
import Multiselect from 'vue-multiselect';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './daily-prices-search-column.vue';


interface MultiselectExt extends Multiselect {
  deactivate();
}

@Component({
  mixins: [template],
  components: {
    Multiselect
  }
})
export default class DailyPricesSearchColumn extends Vue {
  @Prop()
  header: string;

  @Prop()
  propName: string;

  @Prop()
  values: DailyPricesFilterSelections[];

  @Prop()
  disabledValues: string[];

  @Prop()
  name: string;

  @Prop()
  tabIndex: number;

  selections: { label: string, value: string, $isDisabled: boolean }[];
  prevSelections: { label: string, value: string, $isDisabled: boolean }[];

  created(): void {
    this.selections = [];
    this.prevSelections = [];

    EventBus.$on('fund-daily-price-selected-date-change', (tabIndex) => {
      if (tabIndex === this.tabIndex) {
        this.selections = [];
        this.prevSelections = this.selections;
      }
    });

    EventBus.$on('dp-table-all-filters-cleared', () => {
      this.selections = [];
      this.prevSelections = this.selections;
    });
  }

  onClose(): void {
    this.handleClose();
  }

  clearSelections() {
    if (this.prevSelections.length > 0) {
      EventBus.$emit('dp-table-filter-by-dropdown', this.tabIndex, this.propName, []);
    }
    this.selections = this.prevSelections = [];
    (this.$refs.multiselect as MultiselectExt).deactivate();
  }

  confirmAndClose() {
    this.handleClose();
    (this.$refs.multiselect as MultiselectExt).deactivate();
  }

  data() {
    return { selections: this.selections };
  }

  get options(): { value: string, label: string, $isDisabled: boolean }[] {
    return this.values?.map(x => { return { label: x.label, value: x.value, $isDisabled: this.disabledValues.includes(x.value) } });
  }

  private handleClose() {
    //emit change only if it actually changed
    if (this.selections.length !== this.prevSelections.length || this.selections.filter(item => this.prevSelections.indexOf(item) < 0).length > 0) {
      EventBus.$emit('dp-table-filter-by-dropdown', this.tabIndex, this.propName, this.selections.map(x => x.value));
      this.prevSelections = this.selections;
    }
  }
}