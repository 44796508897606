import { render, staticRenderFns } from "./base-info-button.vue?vue&type=template&id=3bd68db3&scoped=true&"
var script = {}
import style0 from "./base-info-button.scss?vue&type=style&index=0&id=3bd68db3&lang=scss&scoped=true&"
import style1 from "./syntax-highlighting.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd68db3",
  null
  
)

export default component.exports