import { Vue, Component } from 'vue-property-decorator';

export interface IVideoConfiguration {
  url?: string;
  onComplete?: () => void;
  autostart: boolean;
  imageUrl?: string;
  playlist?: string;
  width?: string;
  height?: string;
  aspectratio?: string;
}

@Component
export default class VideoPlayerMixin extends Vue {
  jwPlayerInstance: jwplayer.JWPlayer = null;
  dataLayer = null;
  isPlayed = false;
  isPlayerAvailable = true;

  mounted() {
    this.dataLayer = window.dataLayer || [];
    this.dataLayer = window.dataLayer || [];
  }

  public initialiseVideoPlayer(element: HTMLElement, config: IVideoConfiguration): void {
    // Check if Jwplayer cdn is available? It seems JWPlayer is blocked by China Great Wall!
    // So instead of throwing error we server the page, without the player/video
    if (typeof jwplayer == 'undefined') {
      this.isPlayerAvailable = false;
      return;
    }

    this.isPlayerAvailable = true;    

    this.jwPlayerInstance = jwplayer(element);
    this.jwPlayerInstance.setup({
      file: config.url,
      fullscreen: 'true',
      playlist: config.playlist,
      controls: true,
      primary: 'html5',
      autostart: config.autostart ? 'true' : 'false',
      stretching: 'uniform',
      height: config.height ? config.height : '100%',
      width: config.width ? config.width : '100%',
      image: config.imageUrl,
      aspectratio: config.aspectratio,
      playbackRateControls: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]
    });
    let passedTenPercentBreakpoint: Boolean = false;
    let passedTwentyFivePercentBreakpoint: Boolean = false;
    let passedFiftyPercentBreakpoint: Boolean = false;
    let passedSeventyFivePercentBreakpoint: Boolean = false;
    let passedNinetyPercentBreakpoint: Boolean = false;

    if (config.onComplete != null) this.jwPlayerInstance.on('complete', (config.onComplete));

    this.jwPlayerInstance.on('error', () => { this.pushToDataLayer('Podcast error') });
    this.jwPlayerInstance.on('play', () => {  this.isPlayed = true; this.pushToDataLayer('Played podcast'); });
    this.jwPlayerInstance.on('pause', () => { this.pushToDataLayer('Pause podcast') });
    this.jwPlayerInstance.on('complete', () => { this.pushToDataLayer('100%') });
    this.jwPlayerInstance.on('time', () => {
      const time = Math.floor(100 * this.jwPlayerInstance.getPosition() / this.jwPlayerInstance.getDuration());
        if (time === 10 && passedTenPercentBreakpoint === false) {
            passedTenPercentBreakpoint = true;
            this.pushToDataLayer(`${time} %`);
        } else if (time === 25 && passedTwentyFivePercentBreakpoint === false) {
          passedTwentyFivePercentBreakpoint = true;
            this.pushToDataLayer(`${time} %`);
        } else if (time === 50 && passedFiftyPercentBreakpoint === false) {
          passedFiftyPercentBreakpoint = true;
          this.pushToDataLayer(`${time} %`);
        } else if (time === 75 && passedSeventyFivePercentBreakpoint === false) {
          passedSeventyFivePercentBreakpoint = true;
          this.pushToDataLayer(`${time} %`);
        } else if (time === 90 && passedNinetyPercentBreakpoint === false) {
          passedNinetyPercentBreakpoint = true;
          this.pushToDataLayer(`${time} %`);
        }
    });
  }

  public hoverClicked():void{
    if(this.isPlayed)
      this.jwPlayerInstance.pause();
    else
      this.jwPlayerInstance.play();
  }

  public destroyVideoPlayer(): void {
    if (this.jwPlayerInstance) {
      this.jwPlayerInstance.remove();
    }
    this.jwPlayerInstance = null;
  }

  private pushToDataLayer(action: string) {
    if (this.jwPlayerInstance) {
      const title = this.jwPlayerInstance.getPlaylistItem(0).title ? this.jwPlayerInstance.getPlaylistItem(0).title : this.jwPlayerInstance.getPlaylistItem(0).file;
      this.dataLayer.push({
        "event": "Video",
        "eventCategory": "JW Player",
        "eventAction": action,
        "eventLabel": title
      });
    }
  }
}

declare global {
  interface Window { dataLayer: any[]; }
}
