import { Component, Prop, Vue } from 'vue-property-decorator';
import InsightsCarousel from '../insights-carousel/InsightsCarousel';
import * as mws from '../../shared/MwsViewModels';
import template from './related-insights.vue';

@Component({
  mixins: [template],
  components: {
    InsightsCarousel,
  },
})
export default class RelatedInsights extends Vue {
  @Prop()
  data: mws.RelatedInsights;
  @Prop()
  backUrl: string;
  @Prop()
  colourTheme: string;

  get items(): mws.IInsightPageLink[] {
    return this.data && this.data.items ? this.data.items : [];
  }

  get backLink(): mws.TextLink {
    return {
      linkText: 'All insights',
      type: 'TextLink',
      url: this.backUrl,
      openInNewWindow: false,
      linkRolloverRichText: 'test rollover text',
      linkSubtitle: '',
      overrideTileLinkBehaviour: false,
      linkTitle: '',
      addCircle: false,
      icon: '',
      isLinkLessThanOneMonthOld: false,
      buttonStyle: null,
      elementType: null,
      iconPosition: null,
      rolloverText: null,
    };
  }

  imageUrl(item: mws.IInsightPageLink): string {
    return item.image ? item.image.url : '';
  }
}
