import React from 'react'
import { NotFoundErrorPage as INotFoundErrorPage } from '@/shared/MwsViewModels'
import PanelGroup from '../../lib/PanelGroup'

export interface NotFoundErrorPageProps {
  data: INotFoundErrorPage
}

const NotFoundErrorPage = ({ data }: NotFoundErrorPageProps) => {
  return (
    <div class='react-not-found-page'>
      {data.panelGroups.map((panelGroup, pgIndex) => (
        <div>
          <PanelGroup panels={panelGroup.panels} />
        </div>
      ))}
    </div>
  )
}

export default NotFoundErrorPage
