import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './people-panel.vue';
import FundManager from '../fund-people/fund-manager/FundManager';

@Component({
  mixins: [template],
  components: {
    FundManager,
  },
})
export default class PeoplePanel extends Vue {
  @Prop()
  data: mws.FundPeoplePanel;
}