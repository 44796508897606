import { Module } from 'vuex';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    translations: {},
  },
  actions: {
    setTranslations({ commit }, translations) {
      commit('setTranslations', translations);
    },
  },
  getters: {
    translations: (state): {[key: string]: string} => state.translations,
  },
  mutations: {
    setTranslations(state, translations) {
      state.translations = { ...state.translations, ...translations };
    },
  },
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
