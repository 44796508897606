import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './home.vue';
import BackgroundPanel from './components/background-panel/BackgroundPanel';
import * as mws from '../shared/MwsViewModels';

@Component({
  mixins: [template],
  components: {
    BackgroundPanel,
  },
})
export default class Home extends Vue {
    @Prop()
    page!: mws.HomePage;

    get allPanels(): mws.IPanel[] {
      return this.page.panelGroups.flatMap((group) => group.panels);
    }
}
