/* eslint-disable global-require */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import * as mws from '../../../../../shared/MwsViewModels';
import template from './athenaeum-culture-panel.vue';
import { ReactInVue } from 'vuera'
import SectionContainer from '../../../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    'SectionContainer': ReactInVue(SectionContainer)
  }
})
export default class AthenaeumCulturePanel extends Vue {
  @Prop()
  data!: mws.AthenaeumCulturePanel;

  @Watch('$route')
  onUrlChange(newVal: Route, oldVal: Route) {
    if (!oldVal.query.content && newVal.query.content)
    this.handleContentQuery();
  }
  
  mounted() {
    this.handleContentQuery();
  }

  openAthenaeum() : void {
    this.data.athenaeumPanelName = 'culture';
    this.$store.dispatch('openAthenaeumPopup', this.data);
    (document.getElementsByTagName("body")[0] as HTMLElement).classList.add('noscroll');
    this.$router.push({query : {content: this.data.panelUrl.split('/').slice(-2, -1)[0]} }).catch(()=>{});
  }

  private handleContentQuery() {
    const currentRouteQuery = this.$router.currentRoute.query.content;
    const panelNameFromUrl = this.data.panelUrl.split('/').slice(-2, -1)[0];

    if (currentRouteQuery && currentRouteQuery === panelNameFromUrl) {
      this.openAthenaeum();
    }
  }
}
