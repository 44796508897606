import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './canada-charges-panel.vue';
import TabContainer, { TabViewModel } from '../../tab-container/TabContainer';

@Component({
  mixins: [template],
  components: {
    TabContainer,
  },
})
export default class CanadaChargesPanel extends Vue {s
  @Prop()
  data: mws.UsmChargesPanel;

  get containerTabs(): TabViewModel[] {
    return this.data.tabs
      .map((tab) => ({ heading: tab.heading, tableData: tab.tableData }));
  }

}
