import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-full-width-quote-panel.vue';

@Component({
  mixins: [template],
})
export default class FundFullWidthQuotePanel extends Vue {
  @Prop()
  data: mws.FundFullWidthQuotePanel;
}
