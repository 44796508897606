/* eslint-disable */
// @ts-nocheck
import $ from 'jquery';
import Globalize from 'globalize';
import { Helpers } from '../../utilities/formatHelpers';

namespace UmbracoLocalisation {
    export class Translations {
      public static getInstance(): Translations {
        return new Translations();
      }

      public getDictionaryValue(key: string, alternative: string) {
        return alternative;
      }
    }
}


const translations = UmbracoLocalisation.Translations.getInstance();

/*------------------------------------- Performance Line Chart -------------------------------------------*/
const defaultlineChartOptions = {
  chart: {
    type: 'line',
    backgroundColor: '#ffffff',
    height: 650,
    pinchType: '',
    marginRight: 0,
    panning: false,
    events: {
      load: chartLoad,
    },
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    showFirstLabel: true,
    showLastLabel: true,
    endOnTick: false,
    startOnTick: false,
    minPadding: 0,
    maxPadding: 0,
    minRange: 28 * 24 * 3600 * 1000,
    minTickInterval: 6 * 28 * 24 * 3600 * 1000,
    tickColor: '#dbdbcf',
    lineColor: '#b8b8af',
    labels: {
      formatter() {
        return Globalize.formatDate(new Date(this.value), { skeleton: 'yMMM' });
      },
      style: {
        color: '#000000',
        fontSize: '12px',
        fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif',
      },
      y: 20,
    },
    tickPositioner: endOfMonthTickPositioner,
    events: {
      afterSetExtremes: afterSetExtremesX,
    },
  },
  yAxis: {
    type: 'linear',
    opposite: false,
    tickPosition: 'outside',
    tickWidth: 1,
    tickLength: 5,
    tickColor: '#dbdbcf',
    lineColor: '#b8b8af',
    gridLineColor: '#dbdbcf',
    lineWidth: 1,
    showFirstLabel: true,
    showLastLabel: true,
    title: {
      text: translations.getDictionaryValue('line_chart_y_axis_title', 'Performance'),
      offset: 1,
      margin: 15,
      style: {
        color: '#000',
        visibility: 'hidden',
      },
    },
    labels: {
      x: -10,
      align: 'right',
      y: 5,
      style: {
        color: '#000000',
        fontSize: '12px',
        fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif',
      },
      formatter() {
        return `${this.value}%`;
      },
    },

  },
  legend: {
    verticalAlign: 'top',
    enabled: true,
    align: 'right',
    borderWidth: 0,
    borderRadius: 0,
    floating: true,
    x: 0,
    y: -5,
    itemStyle: {
      color: '#000000',
      fontSize: '12px',
      fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif',
      cursor: 'default',
    },
  },
  tooltip: {
    enabled: true,
    shared: true,
    useHTML: true,
    borderRadius: 0,
    borderWidth: 1,
    borderColor: '#000000',
    shadow: false,
    animation: false,
    crosshairs: false,
    formatter(e) {
      return tooltipFormatter.call(this, e, false, true);
    },
  },
  plotOptions: {
    line: {
      events: disableLegendItemClick,
    },
    series: {
      animation: true
    },
  },
  credits: {
    enabled: false,
  },
  navigator: {
    enabled: true,
    handles: {
      backgroundColor: '#000000',
      borderColor: '#ffffff',
    },
    series: {
      type: 'areaspline',
      fillOpacity: 1,
      color: '#6e2c90',
      lineWidth: 0,
    },
    outlineWidth: 1,
    outlineColor: '#b8b8af',
    maskFill: 'rgba(238, 238, 224, 0.6)',
    maskInside: false,
    xAxis: {
      type: 'datetime',
      gridLineWidth: 0,
      labels: {
        align: 'center',
        enabled: true,
        y: 30,
        style: {
          color: '#000000',
          fontSize: '12px',
          fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif',
          textOverflow: 'none',
        },
        formatter: yearLabelFormatter,
      },
      tickWidth: 1,
      lineWidth: 0,
      tickColor: '#b8b8af',
      minTickInterval: 28 * 24 * 3600 * 1000,
      tickPositioner: quarterTickPositioner,
      endOnTick: false,
      startOnTick: true,
    },
    baseSeries: 0,
    adaptToUpdatedData: true,
    margin: 110,
    height: 80,
  },
  rangeSelector: {
    enabled: true,
    allButtonsEnabled: true,
    selected: 0,
    buttonSpacing: 0,
    buttons: [],
    buttonPosition: {
      x: 44,
      y: 500,
    },
    buttonTheme: {
      fill: 'rgba(197, 197, 197, 0.3)',
      stroke: 'rgba(197, 197, 197, 0.3)',
      'stroke-width': 1,
      style: {
        color: '#000000',
        fontSize: '15px',
        fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif',
        fontWeight: 'bold',
      },
      states: {
        hover: {
          fill: '#ffffff',
          style: {
            color: '#000000',
          },
          stroke: 'rgba(197, 197, 197, 0.3)',
        },
        select: {
          fill: '#ffffff',
          style: {
            color: '#000000',
          },
          stroke: 'rgba(197, 197, 197)',
        },
      },
      width: 102,
      height: 20,
      r: 0,
      padding: 10,
    },
    inputEnabled: false,
    labelStyle: {
      display: 'none',
    },
  },
  scrollbar: {
    enabled: true,
    liveRedraw: false,
    buttonArrowColor: 'transparent',
    buttonBackgroundColor: 'transparent',
    buttonBorderWidth: 0,
    buttonBorderRadius: 0,
    buttonBorderColor: 'transparent',
    trackBackgroundColor: 'transparent',
    trackBorderColor: 'transparent',
    trackBorderWidth: 0,
    trackBorderRadius: 0,
    barBackgroundColor: '#000000',
    barBorderWidth: 1,
    barBorderRadius: 0,
    barBorderColor: '#000000',
    rifleColor: '#ffffff',
    height: 15,
  },
};

// Ensures that the chart initially shows all the data on the chart
function setInitialDates(chart, data) {
  if (data[0].data[0]) {
    const xAxis = chart.xAxis[0];

    const firstSeries = data[0];
    const firstDate = firstSeries.data[0].x;
    const lastDate = firstSeries.data[data[0].data.length - 1].x;

    if (xAxis.min > firstDate || xAxis.max < lastDate) {
      xAxis.setExtremes(firstDate, lastDate, true, false);
    }
  }
}

export function createLineChartOptions(data, additionalOptions) {
  if (data[0].data[0]) {
    const firstSeries = data[0];
    // a fresh data point is added per month
    const numberOfDatapoints = firstSeries.data.length;
    const breakpoint1Year = numberOfDatapoints >= 12;
    const breakpoint3Years = numberOfDatapoints >= 36;
    const breakpoint5Years = numberOfDatapoints >= 60;
    const breakpoint10Years = numberOfDatapoints >= 120;

    const buttons = {
      rangeSelector: {
        buttons: [
          {
            type: 'all',
            text: translations.getDictionaryValue('line_chart_all_years_button', 'Since Inception'),
          }, {
            type: 'year',
            count: 10,
            text: translations.getDictionaryValue('line_chart_ten_years_button', '10 Years'),
          }, {
            type: 'year',
            count: 5,
            text: translations.getDictionaryValue('line_chart_five_years_button', '5 Years'),
          }, {
            type: 'year',
            count: 3,
            text: translations.getDictionaryValue('line_chart_three_years_button', '3 Years'),
          }, {
            type: 'year',
            count: 1,
            text: translations.getDictionaryValue('line_chart_one_year_button', '1 Year'),
          }, {
            type: 'month',
            count: 3,
            text: translations.getDictionaryValue('line_chart_one_quarter_button', 'Quarter'),
          },
        ],
      },
    };

    if (additionalOptions !== 'isMutualFund' && additionalOptions !== 'isCanadaFund' && additionalOptions !== 'isWWFund') {
      // Only show 10 year and quarter buttons on us mutual funds
      buttons.rangeSelector.buttons.splice(1, 1);
      buttons.rangeSelector.buttons.splice(-1, 1);

      // 5 years plus, remove "since inception"
      if (breakpoint5Years) {
        buttons.rangeSelector.buttons.splice(0, 1);
      }// between 3 and 5 years, remove "5 years"
      else if (breakpoint3Years && !breakpoint5Years) {
        buttons.rangeSelector.buttons.splice(1, 1);
      }
      // between 1 and 3 years, remove "5 years" and "3 years"
      else if (breakpoint1Year && !breakpoint3Years) {
        buttons.rangeSelector.buttons.splice(1, 2);
      }
    } else {
      // 10 years plus, remove "since inception"
      if (breakpoint10Years) {
        buttons.rangeSelector.buttons.splice(0, 1);
      }
      // between 5 and 10 years, remove "10 years"
      else if (breakpoint5Years && !breakpoint10Years) {
        buttons.rangeSelector.buttons.splice(1, 1);
      }
      // between 3 and 5 years, remove "10 years" and "5 Years"
      else if (breakpoint3Years && !breakpoint5Years) {
        buttons.rangeSelector.buttons.splice(1, 2);
      }
      // between 1 and 3 years, remove "10 years", "5 Years" and "3 years"
      else if (breakpoint1Year && !breakpoint3Years) {
        buttons.rangeSelector.buttons.splice(1, 3);
      }
    }

    const withSeries = {
      series: data,
    };

    // merge defaultlineChartOptions with buttons
    const options = $.extend(true, {}, defaultlineChartOptions, buttons, withSeries);

    if (additionalOptions === 'isWWFund') {
      var wwfCustomOptions = {
          chart: {
              marginTop: 180,
              marginBottom: -30
          },
          rangeSelector: {
            buttonPosition: {
              y: 0
            }
          },
          navigator: {
              top: 40,
              margin: 0
          },
          legend: {
              y: 180
          }
      };

      $.extend(true, options, wwfCustomOptions);
    }

    if(additionalOptions === 'isActiveShareChart') {

      const customOptions = {
        yAxis: {
          min: 0,
          max: 100,
        },
      };
      // merge defaultlineChartOptions with replacmentSeries
      return $.extend(true, options, customOptions);
    }    

    if (additionalOptions === 'isDividendGrowthVsInflation') {

      const customOptions = {
        plotOptions: {
          series: {
            compare: "percent"
          }
        },
        tooltip: {
          formatter: function (e) {
            return tooltipFormatter.call(this, e, true, false);
          }
        },
        yAxis: {
          labels: {
            formatter: rebasedYAxisLabelsFormatter
          }
        },
        xAxis:{
          tickPositioner: endOfYearTickPositioner,
        }
      };
      return $.extend(true, options, customOptions);
    }

    if (additionalOptions !== 'isDiscountChart' && additionalOptions !== 'isActiveShareChart' && additionalOptions !== 'isDividendGrowthVsInflation') {
      //performance chart - therefore ensure that the 'campare' option 
      //is set below. This ensures that the rebasing to 100 takes effect.

      const customOptions = {
          plotOptions: {
              series: {
                  compare: 'percent'
              }
          },
          tooltip: {
              formatter: function (e) {
                  return tooltipFormatter.call(this, e, true, false, additionalOptions === 'isMutualFund' || additionalOptions === 'isCanadaFund');
              }
          },
          yAxis: {
              labels: {
                  formatter: rebasedYAxisLabelsFormatter
              }
          }
      };

      //merge defaultlineChartOptions with replacmentSeries
      return $.extend(true, options, customOptions);
    } else {
      //this this the discount chart.
      //it should not be rebased and therefore should not have the 'compare' option
      //set as it breaks the chart (does actually show the line!)
      return options;
    }
  }

  console.log('There was no data returned for this chart therefore date range buttons could not be displayed');
  return null;
}

function chartLoad() {
  const $container = $(this.container);
  const chart = this;

  const isWWFund = $container.parents('.chart-item.performance-chart').attr('data-additional-options') === 'isWWFund';
  const additionalClasss = isWWFund ? 'wwfund' : '';

  $container.append($('<div/>').addClass('scrollbarButton left ' + additionalClasss).on('mousedown touchdown', function () {
    moveTimeline(this, true, false);
    return false;
  }));
  $container.append($('<div/>').addClass('scrollbarButton right ' + additionalClasss).on('mousedown touchdown', function () {
    moveTimeline(this, false, true);
    return false;
  }));

  fixArrowPositions(chart, $container);

  setInitialDates(chart, chart.series);

  function moveTimeline(element, leftFlag, rightFlag) {
    const xAxis = chart.xAxis[0];
    let newMin = xAxis.min;
    let newMax = xAxis.max;
    const oldMinDate = toDate(xAxis.min);
    const oldMaxDate = toDate(xAxis.max);
    const dataMin = toDate(xAxis.dataMin);
    const dataMax = toDate(xAxis.dataMax);

    if (leftFlag === true) {
      newMin = getPreviousMonthLastDayOrMin(oldMinDate, dataMin);
      newMax = getPreviousMonthLastDayOrMin(oldMaxDate, dataMin);

      if (oldMinDate.getTime() === newMin.getTime()) {
        newMax = oldMaxDate;
      }
    } else if (rightFlag === true) {
      newMin = getNextMonthDataPointDay(oldMinDate, chart.series[0].data, dataMin, dataMax);
      newMax = getNextMonthDataPointDay(oldMaxDate, chart.series[0].data, dataMin, dataMax);

      if (oldMaxDate.getTime() === newMax.getTime()) {
        newMin = oldMinDate;
      }
    }

    dataMin.setHours(0, 0, 0, 0);
    dataMax.setHours(0, 0, 0, 0);
    newMin.setHours(0, 0, 0, 0);
    newMax.setHours(0, 0, 0, 0);
    oldMinDate.setHours(0, 0, 0, 0);
    oldMaxDate.setHours(0, 0, 0, 0);
    if (daydiff(newMin, newMax) < 31 && newMin.getMonth() == newMax.getMonth() && newMin == dataMin) {
      newMax = getNextMonthLastDay(getLastDayOfMonth(newMin));
    }
    // move to the next or previous month if at least one of the values has changed and we haven't reached the edges
    if ((oldMinDate.getTime() != newMin.getTime() || oldMaxDate.getTime() != newMax.getTime()) && newMin >= dataMin && newMax <= dataMax) {
      xAxis.setExtremes(newMin, newMax, true, false);
    }
  }
}

function toDate(date) {
  return new Date(date);
}

function fixArrowPositions(chart, $container) {
  const parent = $container.closest('.line-container .chart-item');
  const leftArrow = parent.find('.scrollbarButton.left');
  let leftArrowPosition = chart.plotLeft;
  const firstButton = chart.rangeSelector.buttons[0];

  if (firstButton != null) leftArrowPosition = firstButton.x;

  leftArrow.css('left', `${leftArrowPosition}px`);
}

function daydiff(first, second) {
  return (second - first) / (1000 * 60 * 60 * 24);
}

function getNextMonthLastDay(date) {
  if (date instanceof Date) {
    // If the date is not the last day of the month, then return the last day of the month
    if (date.getDate() != getLastDayOfMonth(date).getDate()) return getLastDayOfMonth(date);
    if (date.getMonth() == 11) return new Date(date.getFullYear() + 1, 0, 31, 0, 0, 0, 0);

    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, 0);
    return getLastDayOfMonth(nextMonth);
  }
  return date;
}

function getNextMonthDataPointDay(date, points, dataMinimum, dataMaximum) {
  if (date instanceof Date) {
    let retDate;

    let i = 0;
    while (i < points.length - 1 && date.getTime() >= points[i].x) {
      i++;
    }

    retDate = toDate(points[i].x);

    if (retDate <= dataMinimum) return dataMinimum;
    if (retDate >= dataMaximum) return dataMaximum;

    return retDate;
  }
  return date;
}

function getPreviousMonthLastDayOrMin(date, dataMin) {
  if (date instanceof Date) {
    let returnDate = date;
    if (date.getMonth() == 0) returnDate = new Date(date.getFullYear() - 1, 11, 31, 0, 0, 0, 0);
    else {
      const previousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0, 0);
      returnDate = getLastDayOfMonth(previousMonth);
    }
    if (returnDate <= dataMin) return dataMin;
    return returnDate;
  }
  return date;
}

function getLastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), daysInMonth(date.getMonth(), date.getFullYear()), 0, 0, 0, 0);
}

function daysInMonth(month, year) {
  const monthStart = new Date(year, month, 1);
  const monthEnd = new Date(year, month + 1, 1);
  return daydiff(monthStart, monthEnd);
}

function rebasedYAxisLabelsFormatter() {
  return Helpers.formatNumberFixed(this.value + 100, 1);
}

function disableLegendItemClick() {
  return false;
}

function quarterTickPositioner(min, max) {
  // Position ticks on quarters
  const ticks = [];
  let currentDate = new Date(min);
  // Strip time and go to start of the year
  currentDate = new Date(currentDate.getFullYear(), 0, 1);
  let currentTick = 0;
  let i = 0;
  while (currentTick < max) {
    // Last day of previous month
    const tickDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    currentTick = tickDate.getTime();
    if (currentTick > min) {
      ticks.push(currentTick);
    }
    currentDate.setMonth(currentDate.getMonth() + 3);

    // Escape in case a funny range is passed to the method
    if (i++ > 300) {
      break;
    }
  }

  ticks.info = this.tickPositions.info;
  return ticks;
}

function yearLabelFormatter() {
  const date = new Date(this.value);
  if (date.getMonth() == 11 && date.getDate() == 31) {
    return Globalize.formatDate(date, { skeleton: 'y' });
  }
  return '';
}

function endOfMonthTickPositioner(min, max) {
  const higherRanks = {};
  let tickPositions;
  const tickPixelIntervalOption = this.options.tickPixelInterval;

  tickPositions = this.getTimeTicks(this.normalizeTimeTickInterval(this.tickInterval), min, max, this.options.startOfWeek);

  for (let k = 0; k < tickPositions.length; k++) {
    const curDate = toDate(tickPositions[k]);
    tickPositions[k] = getLastDayOfMonth(curDate).getTime();
  }

  // Don't show ticks within a gap in the ordinal axis, where the space between
  // two points is greater than a portion of the tick pixel interval
  if (tickPixelIntervalOption !== undefined && tickPixelIntervalOption !== null) { // check for squashed ticks
    const { length } = tickPositions;
    let i = length;
    let itemToRemove;
    let translated;
    const translatedArr = [];
    let lastTranslated;
    let medianDistance;
    let distance;
    const distances = [];
    let firstLabelEndPosition;
    let secondLabelStartPosition;
    let lastLabelStartPosition;
    let secondToLastEndPosition;
    let isFirstLabel;
    let isLastLabel;

    // Find median pixel distance in order to keep a reasonably even distance between
    // ticks (#748)
    while (i--) {
      translated = this.translate(tickPositions[i]);
      if (lastTranslated) {
        distances[i] = lastTranslated - translated;
      }
      translatedArr[i] = lastTranslated = translated;
    }
    distances.sort();
    medianDistance = distances[Math.floor(distances.length / 2)];
    if (medianDistance < tickPixelIntervalOption * 0.6) {
      medianDistance = null;
    }

    // Now loop over again and remove ticks where needed
    i = tickPositions[length - 1] > max ? length - 1 : length; // #817
    lastTranslated = undefined;
    while (i--) {
      translated = translatedArr[i];
      distance = lastTranslated - translated;

      isFirstLabel = isLastLabel = false;
      // If the calculated end position of the last label exceeds the width of the axis, then this means the actual end position
      // will be the axis width so the start position will be the axis width minus the label width (approximately tickPixelIntervalOption/2)
      if (lastTranslated && lastTranslated + tickPixelIntervalOption / 4 > this.width) {
        isLastLabel = true;
        lastLabelStartPosition = this.width - tickPixelIntervalOption / 2;
        secondToLastEndPosition = translated + tickPixelIntervalOption / 4;
      } else if (lastTranslated && translated >= 0 && translated - tickPixelIntervalOption / 4 < 0) {
        // If the calculated start position of the first label goes beyond the start of the axis (position 0),
        // then the actual start position will be 0 and the end position of the label will be the approximate label width (tickPixelIntervalOption/2)
        isFirstLabel = true;
        firstLabelEndPosition = tickPixelIntervalOption / 2;
        secondLabelStartPosition = lastTranslated - tickPixelIntervalOption / 4;
      }

      // Remove ticks that are closer than 0.6 times the pixel interval from the one to the right,
      // but not if it is close to the median distance (#748).
      // To avoid overlapping labels at the first and last tick, check the start and end positions of the first/second labels
      // and penultimate/last labels
      if (lastTranslated
                && (((isLastLabel && secondToLastEndPosition > lastLabelStartPosition)
                    || (isFirstLabel && firstLabelEndPosition > secondLabelStartPosition))
                    || (distance < tickPixelIntervalOption * 0.8
                        && (medianDistance === null || distance < medianDistance * 0.8)))) {
        // Is this a higher ranked position with a normal position to the right?
        // Is the first label overlapping with the second? Then remove the second and not first
        if ((higherRanks[tickPositions[i]] && !higherRanks[tickPositions[i + 1]]) || isFirstLabel) {
          // Yes: remove the lower ranked neighbour to the right
          itemToRemove = i + 1;
          lastTranslated = translated; // #709
        } else {
          // No: remove this one
          itemToRemove = i;
        }

        tickPositions.splice(itemToRemove, 1);
      } else {
        lastTranslated = translated;
      }
    }
  }

  return tickPositions;
}

let afterSetExtremesSetting = false;
function afterSetExtremesX() {
  if (afterSetExtremesSetting) {
    return;
  }
  const x = this;
  const { chart } = this;
  const xAxis = chart.xAxis[0];
  const startDate = toDate(x.min);
  const endDate = toDate(x.max);
  const dataMin = toDate(xAxis.dataMin);
  const dataMax = toDate(xAxis.dataMax);
  const { rangeSelector } = chart;

  highlightSelectedButton(startDate, endDate);

  // Get closest day that matches a point
  const min = getClosestPointOrDataMinMax(startDate, dataMin, dataMax, chart.series[0].data);
  const max = getClosestPointOrDataMinMax(endDate, dataMin, dataMax, chart.series[0].data);
  afterSetExtremesSetting = true;
  x.setExtremes(min.getTime(), max.getTime(), true, false); // chart xAxis
  highlightSelectedButton(min, max);
  afterSetExtremesSetting = false;

  function highlightSelectedButton(minDate, maxDate) {
    // Loop through the range selector buttons and if the selected time period fits to one of them, set it's state to selected
    const numberOfMonths = getDurationInMonths(toDate(minDate), toDate(maxDate));
    const totalNumberOfMonths = getDurationInMonths(toDate(x.dataMin), toDate(x.dataMax));
    let i;
    // Reset all buttons
    for (i = 0; i < rangeSelector.buttons.length; i++) {
      rangeSelector.buttons[i].setState(0);
    }
    if (Math.floor(numberOfMonths) == 0) return;
    if (Math.floor(numberOfMonths) == Math.floor(totalNumberOfMonths)) {
      rangeSelector.buttons[0].setState(2); // if since inception
    } else {
      for (i = 0; i < rangeSelector.buttons.length; i++) {
        let { count } = rangeSelector.buttonOptions[i];
        if (rangeSelector.buttonOptions[i].type == 'year') count *= 12;
        if (Math.floor(numberOfMonths) == count) {
          rangeSelector.buttons[i].setState(2);
          break;
        }
      }
    }
  }

  function getDurationInMonths(date1, date2) {
    return (date2.getMonth() + 12 * date2.getFullYear())
            - (date1.getMonth() + 12 * date1.getFullYear());
  }

  function getClosestPointOrDataMinMax(date, dataMinimum, dataMaximum, points) {
    const startPos = getFirstPoint(points);

    if (date instanceof Date && isDefined(points[startPos])) {
      let retDate = toDate(points[startPos].x);

      let i = startPos;
      while (i < points.length - 1 && date.getTime() > points[i].x) {
        retDate = toDate(points[i].x);
        i++;
      }

      if (daydiff(retDate, date) > 15) {
        retDate = toDate(points[i].x);
      }

      if (retDate <= dataMinimum) return dataMinimum;
      if (retDate >= dataMaximum) return dataMaximum;

      return retDate;
    }
    return null;
  }
}

function isDefined(x) { return !!x }

function getFirstPoint(points: Array<any>) {
  let startPos = 0;
  for (let point of points) {
      if (!isDefined(point)) {
          startPos++;
      }
  }
  return startPos;
}

function tooltipFormatter(e: { chart: HighchartsChartObject, options: HighchartsTooltipOptions }, rebase: boolean, showPercentage: boolean, isMutualOrCanadaFund = false) {
  const tooltip = this;
  const tooltipSeriesTdStyle = 'style="border-bottom: 1px solid #000000;padding: 5px 0"';
  const tooltipValueTdStyle = 'style="text-align: right; border-bottom: 1px solid #000000; padding: 5px 0 5px 10px"';
  const tooltipHeaderTdStyle = 'style="border-bottom: 1px solid #7c109a; padding:5px 0; border-top: 1px solid #7c109a; text-align:center; font: 13px HelveticaNowTextRegular, Arial, Helvetica, sans-serif" colspan="2"';
  const tooltipTableStyle = 'cellpadding="0" cellspacing="0" style="font: 12px HelveticaNowTextRegular, Arial, Helvetica, sans-serif; border-top:0; border-bottom:0"';

  const xAxisMin = e.chart.xAxis[0].min;
  const numberOfSeries = e.chart.series.length;

  const firstPoints = [];
  for (let j = 0; j < numberOfSeries; j++) {
    if (e.chart.series[j].name != 'Navigator' && e.chart.series[j].visible) {
      firstPoints.push(getFirstVisiblePoint(xAxisMin, e.chart.series[j]));
    }
  }

  let s = `<table ${tooltipTableStyle}><tr><td ${tooltipHeaderTdStyle}>`;
  s += `<strong>${Helpers.formatDateLong(tooltip.x, true)}</strong></td></tr>`;

  const decimalPoints = isMutualOrCanadaFund ? 2 : 1;

  $.each(tooltip.points, (i, point) => {
    let value;
    if (rebase) {
      value = (point.y * 100) / firstPoints[i];
    } else {
      value = point.y;
    }

    s += `<tr><td ${tooltipSeriesTdStyle}>${point.series.name
    }</td><td ${tooltipValueTdStyle}>${
      Helpers.formatNumberFixed(value, decimalPoints)}${showPercentage ? '%' : ''}</td></tr>`;
  });

  s += '</table>';

  return s;
}

function getFirstVisiblePoint(xAxisMin, series) {
  const { points } = series;
  let yValue = points[0].y;
  for (let i = 0; i < points.length; i++) {
    if (points[i].x >= xAxisMin) {
      yValue = points[i].y;
      break;
    }
    yValue = points[i].y;
  }
  return yValue;
}

function endOfYearTickPositioner(min, max) {
  // Position ticks on end of year
  var ticks = [];
  var currentDate = new Date(min);
  // Strip time and go to start of the year
  currentDate = new Date(currentDate.getFullYear(), 0, 1);
  var currentTick = 0;
  var i = 0;
  while (currentTick < max) {
    // Last day of previous month
    var tickDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    currentTick = tickDate.getTime();
    if (currentTick >= min) {
      ticks.push(currentTick);
    }
    currentDate.setMonth(currentDate.getMonth() + 12);

    // Escape in case a funny range is passed to the method
    if (i++ > 300) {
      break;
    }
  }
  return ticks;
}


/* -------------------------------- Year Line Chart (Dividend vs Inflation) ------------------------------*/ 

/*class AxisRange {
  min: number;
  max: number;

  constructor(min: number, max: number) {
    this.min = min;
    this.max = max;
  }
}

class DateAxisItem {
  range: AxisRange;
  date: Date;

  constructor(date: Date, min: number, max: number) {
    this.range = new AxisRange(min, max);
    this.date = date;
  }
}

//var translations = UmbracoLocalisation.Translations.getInstance();
var defaultYearLineChartOptions = {
  chart: {
    type: 'line',
    backgroundColor: $('body').hasClass('white-background') ? '#fff' : '#ffffef',
    height: 650,
    pinchType: '',
    marginRight: 0,
    panning: false,
    events: {
      load: yearLineChartLoad
    }
  },
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    showFirstLabel: true,
    showLastLabel: true,
    endOnTick: false,
    startOnTick: false,
    minPadding: 0,
    maxPadding: 0,
    tickColor: '#dbdbcf',
    lineColor: '#b8b8af',
    tickPositioner: endOfYearTickPositioner,
    labels: {
      formatter: function() {
        return Globalize.formatDate(new Date(this.value), { skeleton: 'yMMM' });
      },
      style: {
        color: '#000000',
        fontSize: '12px',
        fontFamily: 'nimbus-sans, Arial, Helvetica-Black, sans-serif',
        textTransform: 'uppercase'
      },
      y: 20,
    },
    events: {
      afterSetExtremes: yearLineAfterSetExtremesX,
    }
  },
  yAxis: {
    floor: 0,
    allowDecimals: false,
    type: 'linear',
    opposite: false,
    tickPosition: 'outside',
    tickWidth: 1,
    tickLength: 5,
    tickColor: '#dbdbcf',
    lineColor: '#b8b8af',
    lineWidth: 1,
    showFirstLabel: true,
    showLastLabel: true,

    title: {
      text: translations.getDictionaryValue('year_line_chart_y_axis_title', 'Performance'),
      offset: 1,
      margin: 15,
      style: {
        color: '#000',
        visibility: 'hidden',
      },
    },
    labels: {
      x: -10,
      align: 'right',
      y: 5,
      style: {
        color: '#000000',
        fontSize: '12px',
        fontFamily: 'nimbus-sans, Arial, Helvetica-Black, sans-serif'
      },
      formatter: function() {
        return `${this.value}%`;
      }
    }
  },
  legend: {
    verticalAlign: 'top',
    enabled: true,
    align: 'right',
    borderWidth: 0,
    borderRadius: 0,
    floating: true,
    x: 0,
    y: -5,
    itemStyle: {
      color: '#000000',
      fontSize: '12px',
      fontFamily: 'nimbus-sans, Arial, Helvetica-Black, sans-serif',
      cursor: 'default',
    },
  },
  tooltip: {
    enabled: true,
    shared: true,
    useHTML: true,
    borderRadius: 0,
    borderWidth: 1,
    borderColor: '#000000',
    shadow: false,
    animation: false,
    crosshairs: false,
    formatter(e) {
      return yearLineTooltipFormatter.call(this, e, false, true);
    },
  },
  plotOptions: {
    line: {
      events: yearLineDisableLegendItemClick,
    },
    series: {},
  },
  credits: {
    enabled: false,
  },
  navigator: {
    enabled: true,
    handles: {
      backgroundColor: '#000000',
      borderColor: '#ffffff',
    },
    series: {
      type: 'areaspline',
      fillOpacity: 1,
      color: '#6e2c90',
      lineWidth: 0,
    },
    outlineWidth: 1,
    outlineColor: '#b8b8af',
    maskFill: 'rgba(238, 238, 224, 0.6)',
    maskInside: false,
    xAxis: {
      type: 'datetime',
      gridLineWidth: 0,
      labels: {
        align: 'center',
        enabled: true,
        y: 30,
        style: {
          color: '#000000',
          fontSize: '12px',
          fontFamily: 'nimbus-sans, Arial, Helvetica-Black, sans-serif',
          textTransform: 'uppercase',
        },
        formatter: function() {
          return Globalize.formatDate(new Date(this.value - 1), { skeleton: 'y' });
        },
      },
      tickWidth: 1,
      lineWidth: 0,
      tickColor: '#b8b8af',
      tickInterval: 3.154e10,

      endOnTick: false,
      startOnTick: true,
    },
    baseSeries: 0,
    adaptToUpdatedData: true,
    margin: 110,
    height: 80,
  },
  rangeSelector: {
    enabled: true,
    allButtonsEnabled: true,
    selected: 0,
    buttonSpacing: 0,
    buttons: [],
    buttonPosition: {
      x: 44,
      y: 500,
    },
    buttonTheme: {
      fill: 'rgba(197, 197, 197, 0.3)',
      stroke: 'rgba(197, 197, 197, 0.3)',
      'stroke-width': 1,
      style: {
        color: '#000000',
        fontSize: '15px',
        fontFamily: 'nimbus-sans, Arial, Helvetica-Black, sans-serif',
        fontWeight: 'bold',
      },
      states: {
        hover: {
          fill: '#ffffff',
          style: {
            color: '#000000'
          },
          stroke: 'rgba(197, 197, 197, 0.3)'
        },
        select: {
          fill: '#ffffff',
          style: {
            color: '#000000'
          },
          stroke: 'rgba(197, 197, 197)'
        },
      },
      width: 102,
      height: 20,
      r: 0,
      padding: 10,
    },
    inputEnabled: false,
    labelStyle: {
      display: 'none',
    },
  },
  scrollbar: {
    enabled: true,
    liveRedraw: false,
    buttonArrowColor: '#ffffff',
    buttonBackgroundColor: '#000000',
    buttonBorderWidth: 0,
    buttonBorderRadius: 0,
    buttonBorderColor: '#000000',
    trackBackgroundColor: 'transparent',
    trackBorderColor: 'transparent',
    trackBorderWidth: 0,
    trackBorderRadius: 0,
    barBackgroundColor: '#000000',
    barBorderWidth: 1,
    barBorderRadius: 0,
    barBorderColor: '#000000',
    rifleColor: '#ffffff',
    height: 15,
  }
};

function endOfYearTickPositioner(min, max) {
  // Position ticks on end of year
  var ticks = [];
  var currentDate = new Date(min);
  // Strip time and go to start of the year
  currentDate = new Date(currentDate.getFullYear(), 0, 1);
  var currentTick = 0;
  var i = 0;
  while (currentTick < max) {
    // Last day of previous month
    var tickDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    currentTick = tickDate.getTime();
    if (currentTick >= min) {
      ticks.push(currentTick);
    }
    currentDate.setMonth(currentDate.getMonth() + 12);

    // Escape in case a funny range is passed to the method
    if (i++ > 300) {
      break;
    }
  }
  return ticks;
}

export function createDividendGrowthVsInflationOptions(data) {
  const buttons = getButtons();
  const firstSeries = data[0];

  if (firstSeries.data[0] === undefined) {
    configureButtons(buttons, 0, 0);
  } else {
    const firstYear = new Date(firstSeries.data[0][0]).getFullYear();
    const lastYear = new Date(firstSeries.data[data[0].data.length - 1][0]).getFullYear();
    configureButtons(buttons, firstYear, lastYear);
  }
  const withSeries = {
    series: data
  };

  const options = $.extend(true, {}, defaultYearLineChartOptions, buttons, withSeries);
  const customOptions = getCustomOptions();

  //merge defaultlineChartOptions with replacmentSeries
  return $.extend(true, options, customOptions);
}

function getButtons() {
  return {
    rangeSelector: {
      buttons: [
        {
          type: 'all',
          text: translations.getDictionaryValue(
            'year_line_chart_all_years_button',
            'Since Inception'
          )
        },
        {
          type: 'year',
          count: 10,
          text: translations.getDictionaryValue('year_line_chart_ten_years_button', '10 Years')
        },
        {
          type: 'year',
          count: 5,
          text: translations.getDictionaryValue('year_line_chart_five_years_button', '5 Years')
        },
        {
          type: 'year',
          count: 3,
          text: translations.getDictionaryValue('year_line_chart_three_years_button', '3 Years')
        }
      ]
    }
  };
}

function getCustomOptions() {
  return {
    plotOptions: {
      series: {
        compare: 'percent',
      }
    },
    tooltip: {
      formatter: function(e) {
        return yearLineTooltipFormatter.call(this, e, true, false);
      },
    },
    yAxis: {
      labels: {
        formatter: yearLineRebasedYAxisLabelsFormatter,
      }
    }
  };
}

function configureButtons(buttons: any, firstYear: number, lastYear: number) {
  const yearsDifference = getNumberOfYears(firstYear, lastYear);
  const data3To5Years = yearsDifference >= 3 && yearsDifference < 5;
  const data5To10Years = yearsDifference >= 5 && yearsDifference < 9;
  if (data3To5Years) {
    //between 3 and 5 years worth of data - show 3 year buttons and since inception button
    //remove 5 and 10 year buttons
    buttons.rangeSelector.buttons.splice(1, 2);
  } else if (data5To10Years) {
    //between 5 and 10 years - show 3 and 5 year buttons
    //remove 10 year button only
    buttons.rangeSelector.buttons.splice(1, 1);
  } else {
    //over 10 years - show 1, 3, 5 year buttons but not since inception
    //remove since inception button only
    buttons.rangeSelector.buttons.splice(0, 1);
  }
}

function getNumberOfYears(startYear: number, endYear: number) {
  return endYear - startYear;
}

function yearLineChartLoad() {
  const $container = $(this.container);
  const chart = this;

  preventTimelineSwipe(chart);

  $container.append(
    $('<div/>')
      .addClass('scrollbarButton left')
      .on('mousedown touchdown', function() {
        yearLineMoveTimeline(this, true, false);
        return false;
      })
  );
  $container.append(
    $('<div/>')
      .addClass('scrollbarButton right')
      .on('mousedown touchdown', function() {
        yearLineMoveTimeline(this, false, true);
        return false;
      })
  );

  positionArrows(chart, $container);

  chart.xAxis[0].setExtremes(calcMin(chart.xAxis[0]), chart.xAxis[0].dataMax, true, false);

  function calcMin(xAxis) {
    const startDate = yearLineToDate(xAxis.dataMin);
    const endDate = yearLineToDate(xAxis.dataMax);
    const totalYears = getNumberOfYears(startDate.getFullYear(), endDate.getFullYear());

    if (totalYears > 10) {
      startDate.setFullYear(endDate.getFullYear() - 10);
    }
    return startDate.getTime();
  }

  function yearLineMoveTimeline(element, nextBtnClick: boolean, rightBtnClick: boolean) {
    const xAxis = chart.xAxis[0];

    let range: AxisRange;

    if (nextBtnClick) range = calcRange(xAxis, getPreviousYear, chart.series[0].data);
    else if (rightBtnClick) range = calcRange(xAxis, getNextYear, chart.series[0].data);
    else range = new AxisRange(xAxis.min, xAxis.max);

    if (rangeChanged(xAxis, range) && withinRangeConstraints(xAxis, range)) {
      xAxis.setExtremes(range.min, range.max, true, false);
    }
  }

  function rangeChanged(xAxis, range): boolean {
    return xAxis.min !== range.min || xAxis.max !== range.max;
  }

  function withinRangeConstraints(xAxis, range) {
    return range.min >= xAxis.dataMin && range.max <= xAxis.dataMax;
  }

  function calcRange(axis: any, getYear: Function, seriesData: any): AxisRange {
    const minItem = new DateAxisItem(yearLineToDate(axis.min), axis.dataMin, axis.dataMax);
    const maxItem = new DateAxisItem(yearLineToDate(axis.max), axis.dataMin, axis.dataMax);
    const newMin = updateYear(minItem, getYear, seriesData);
    const newMax = updateYear(maxItem, getYear, seriesData);
    return new AxisRange(newMin, newMax);
  }

  function updateYear(axisRangeItem: DateAxisItem, getYear: Function, seriesData: any): number {
    const newVal = axisRangeItem.date;

    newVal.setFullYear(getYear(axisRangeItem, seriesData));
    newVal.setHours(0, 0, 0, 0);
    return newVal.getTime();
  }
}

function preventTimelineSwipe(chart) {
  if (chart.container.addEventListener) {
    const scroller = chart.scroller;

    $(chart.container).on('mousemove touchmove', function(e: any) {
      e = chart.pointer.normalize(e);
      if (
        e.chartY > scroller.top &&
        e.chartY < scroller.top + scroller.height + scroller.scrollbarHeight
      ) {
        if (e.stopPropagation) {
          e.stopPropagation();
        }
      }
    });
  }
}

function yearLineToDate(date) {
  return new Date(date);
}

function positionArrows(chart, $container) {
  //Fix left arrow position
  const parent = $container.closest('.line-container .chart-item');
  const leftArrow = parent.find('.scrollbarButton.left');
  let leftArrowPosition = chart.plotLeft;
  const firstButton = chart.rangeSelector.buttons[0];

  if (firstButton != null) leftArrowPosition = firstButton.x;

  leftArrow.css('left', leftArrowPosition + 'px');
}

function getPreviousYear(axisItem: DateAxisItem, series: any): number {
  const date = axisItem.date;
  if (date instanceof Date) {
    const rSeries = series.slice();
    rSeries.reverse();
    for (let item of rSeries) {
      if (axisItem.date.getTime() > item.x) {
        const prevYear = yearLineToDate(item.x).getFullYear();
        return getDateWithinLimits(prevYear, axisItem);
      }
    }
    return axisItem.date.getTime();
  }
  console.log('getPreviousYear: Invalid parameter.');
  return null;
}

function getNextYear(axisItem: DateAxisItem, series: any): number {
  const date = axisItem.date;
  if (date instanceof Date) {
    for (let item of series) {
      if (date.getTime() < item.x) {
        const nextYear = yearLineToDate(item.x).getFullYear();
        return getDateWithinLimits(nextYear, axisItem);
      }
    }
    return axisItem.date.getTime();
  }
  console.log('getNextYear: Invalid parameter.');
  return null;
}

function getDateWithinLimits(year: number, axisItem: DateAxisItem): number {
  const minYear = yearLineToDate(axisItem.range.min).getFullYear();
  const maxYear = yearLineToDate(axisItem.range.max).getFullYear();
  if (year <= minYear) return minYear;
  else if (year > maxYear) return maxYear;
  return year;
}

function yearLineRebasedYAxisLabelsFormatter() {
  const chart = this.chart;
  if (chart.yAxis[0].min > this.value) {
    chart.yAxis[0].setExtremes(this.value, null, true, false);
  }

  return this.value + 100;
}

function yearLineDisableLegendItemClick() {
  return false;
}

let afterSetExtremesSetting = false;

function yearLineAfterSetExtremesX() {
  if (afterSetExtremesSetting) {
    return;
  }
  let x = this;
  const chart = this.chart;
  const xAxis = chart.xAxis[0];
  const startDate = yearLineToDate(x.min);
  const endDate = yearLineToDate(x.max);
  const startVal = startDate.getFullYear();
  const endVal = endDate.getFullYear();
  const dataMin = yearLineToDate(xAxis.dataMin);
  const dataMax = yearLineToDate(xAxis.dataMax);
  let rangeSelector = chart.rangeSelector;

  highlightSelectedButton(startVal, endVal);

  // Get closest day that matches a point
  const min = yearLineGetClosestPointOrDataMinMax(startDate, dataMin, dataMax, chart.series[0].data);
  const max = yearLineGetClosestPointOrDataMinMax(endDate, dataMin, dataMax, chart.series[0].data);

  if (
    min != null &&
    max != null &&
    (min.getTime() !== startDate.getTime() || max.getTime() !== endDate.getTime())
  ) {
    afterSetExtremesSetting = true;
    x.setExtremes(min.getTime(), max.getTime(), true, false); //chart xAxis
    highlightSelectedButton(min.getFullYear(), max.getFullYear());
    afterSetExtremesSetting = false;
  }

  function highlightSelectedButton(minYear, maxYear) {
    const numberOfYears = getNumberOfYears(minYear, maxYear);
    const totalNumberOfYears = getNumberOfYears(
      yearLineToDate(x.dataMin).getFullYear(),
      yearLineToDate(x.dataMax).getFullYear()
    );

    //Reset all buttons
    for (let i = 0; i < rangeSelector.buttons.length; i++) {
      rangeSelector.buttons[i].setState(0);
    }
    if (numberOfYears === 0) return;

    if (numberOfYears === totalNumberOfYears) {
      rangeSelector.buttons[0].setState(2);
    } else {
      for (let i = 0; i < rangeSelector.buttons.length; i++) {
        const count = rangeSelector.buttonOptions[i].count;
        if (numberOfYears === count) {
          rangeSelector.buttons[i].setState(2);
          break;
        }
      }
    }
  }

  function yearLineIsDefined(x) {
    return !!x;
  }

  function yearLineGetClosestPointOrDataMinMax(
    date: Date,
    minDate: Date,
    maxDate: Date,
    points: Array<any>
  ) {
    const startPos = yearLineGetFirstPoint(points);

    if (yearLineIsDefined(points[startPos])) {
      let retDate = yearLineToDate(points[startPos].x);

      let i = startPos;
      try {
        while (date.getTime() > points[i].x && i < points.length) {
          i++;
        }
        retDate = yearLineToDate(points[i].x);
      } catch (ex) {
        console.log(
          `yearLineGetClosestPointOrDataMinMax Error: point index: ${i} ${(ex as Error).message}`
        );
      }

      if (retDate <= minDate) return minDate;
      if (retDate >= maxDate) return maxDate;
      retDate.setFullYear(retDate.getFullYear());
      return retDate;
    }
  }

  function yearLineGetFirstPoint(points: Array<any>) {
    let startPos = 0;
    for (let point of points) {
      if (!yearLineIsDefined(point)) {
        startPos++;
      }
    }
    return startPos;
  }
}

function yearLineTooltipFormatter(e, rebase: boolean, showPercentage: boolean) {
  const tooltip = this;
  var tooltipSeriesTdStyle = 'style="border-bottom: 1px solid #000000;padding: 5px 0"';
  var tooltipValueTdStyle =
    'style="text-align: right; border-bottom: 1px solid #000000; padding: 5px 0 5px 10px"';
  const tooltipHeaderTdStyle =
    'style="border-bottom: 1px solid #7c109a; padding:5px 0; border-top: 1px solid #7c109a; text-align:center; font: 13px \'nimbus-sans\', Arial, Helvetica-Black, sans-serif;" colspan="2"';
  const tooltipTableStyle =
    'cellpadding="0" cellspacing="0" style="font: 12px \'nimbus-sans\', Arial, Helvetica-Black, sans-serif; border-top:0; border-bottom:0"';

  const xAxisMin = e.chart.xAxis[0].min;
  const numberOfSeries = e.chart.series.length;

  var firstPoints = [];
  for (let j = 0; j < numberOfSeries - 1; j++) {
    if (e.chart.series[j].name !== 'Navigator') {
      firstPoints.push(yearLineGetFirstVisiblePoint(xAxisMin, e.chart.series[j]));
    }
  }

  var s = `<table ${tooltipTableStyle}><tr><td ${tooltipHeaderTdStyle}>`;
  s += `<strong>${Helpers.formatDateLong(tooltip.x, true)}</strong></td></tr>`;

  $.each(tooltip.points, function(i, point) {
    let value = point.y;
    let valueSuffix = point.series.name == 'SAINTS Dividend' ? 'p' : '';
    s += `<tr><td ${tooltipSeriesTdStyle}>${
      point.series.name
    }</td><td ${tooltipValueTdStyle}>${Helpers.formatNumberFixed(value, 2)}${valueSuffix}${
      showPercentage ? '%' : ''
    }</td></tr>`;
  });

  s += '</table>';

  return s;
}

function yearLineGetFirstVisiblePoint(xAxisMin, series) {
  const points = series.points;
  let yValue = points[0].y;
  for (let i = 0; i < points.length - 1; i++) {
    if (points[i].x >= xAxisMin) {
      yValue = points[i].y;
      break;
    }
    yValue = points[i].y;
  }
  return yValue;
}
*/

/* eslint-enable */
