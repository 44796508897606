import ChapterSubtitles from '../subtitle-models/ChapterSubtitles';

export const ChapterOneSubtitles: ChapterSubtitles = {
  textChunks: [
    {
      paragraphs: [
        'A new millennium dawns. But with it a world of problems is ushered in.',
        'A severe bear market. The sub-prime mortgage scandal. The destruction of the twin towers. '
        + 'The second gulf war. A bloody war in Iraq. Confidence in the markets is not high.',
      ],
    },
    {
      paragraphs: [
        'Yet this generation of Baillie Gifford’s partners will go on to deliver unprecedented '
        + 'global growth for its clients. How?',
        'Four ways:',
      ],
    },
    {
      paragraphs: [
        'The successful surge in overseas business. A growing reputation for expertise in '
        + 'emerging market investing.',
        'The birth of a concentrated \'global best\' ideas portfolio that would prove to be a '
        + 'new way of looking at investing in the 21st century.',
      ],
    },
    {
      paragraphs: [
        'And a determination that despite a world in turmoil the firm must maintain its '
        + 'passion for investing, ultimately for the benefit of its clients.',
      ],
    },
  ],
  subtitleLines: 4,
};

export default ChapterOneSubtitles;
