import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import canUseDOM from '../utils/canUseDOM'
import * as React from 'react';

interface IGlobalStore {
  isMobile: boolean
  hasForcedAudienceSelection: boolean
}

interface IGlobalStoreContext {
  globalStore: IGlobalStore
  handleGlobalStoreUpdate: (newValue: IGlobalStore) => void
}

const GlobalStoreContext = createContext<IGlobalStoreContext>({
  globalStore: {
    isMobile: false,
    hasForcedAudienceSelection: false,
  },
  handleGlobalStoreUpdate: (val) => {
    val
  },
})

interface IGlobalStoreProviderProps {
  children: React.ReactNode
}

export const GlobalStoreProvider = ({
  children,
}: IGlobalStoreProviderProps) => {
  const [globalStore, setGlobalStore] = useState<IGlobalStore>({
    isMobile: false,
    hasForcedAudienceSelection: false
  })

  const handleGlobalStoreUpdate = useCallback((newValue: IGlobalStore) => {
    setGlobalStore(newValue)
  }, [])

  const contextValue = useMemo(
    () => ({
      globalStore,
      handleGlobalStoreUpdate,
    }),
    [globalStore, handleGlobalStoreUpdate]
  )

  // Define a callback function for the event listener.
  const handleMobileViewport = useCallback(
    (mql: MediaQueryListEvent | MediaQueryList) => {
      setGlobalStore((g) => ({ ...g, isMobile: mql?.matches }))
    },
    []
  )

  const setupMediaMatchQuery = useCallback(() => {
    const mediaQueryList = window.matchMedia('(max-width: 767px)')

    handleMobileViewport(mediaQueryList)

    // Add the callback function as a listener to the query list.
    mediaQueryList.addEventListener('change', handleMobileViewport, false)
  }, [handleMobileViewport])

  useEffect(() => {
    if (canUseDOM()) {
      setupMediaMatchQuery()
    }
  }, [setupMediaMatchQuery])

  return (
    <GlobalStoreContext.Provider value={contextValue}>
      {children}
    </GlobalStoreContext.Provider>
  )
}

export const useGlobalStoreContext = () => useContext(GlobalStoreContext)