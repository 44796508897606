import { Vue, Component } from 'vue-property-decorator';
import { gsap } from 'gsap/dist/gsap';

@Component
export default class FadeTransitionMixin extends Vue {
  private fadeDuration = 0.2; // arbitrary

  public async fadeHide(element: HTMLElement): Promise<void> {
    return new Promise((resolve) => {
      gsap.to(element, {
        duration: this.fadeDuration,
        opacity: 0,
        onComplete: () => {
          element.style.display = 'none';
          resolve();
        },
      });
    });
  }

  public async fadeShow(element: HTMLElement): Promise<void> {
    return new Promise((resolve) => {
      element.style.display = undefined;
      gsap.to(element, {
        duration: this.fadeDuration,
        opacity: 1,
        onComplete: resolve,
      });
    });
  }
}
