import classnames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import * as styles from './Modal.module.scss'
import React from 'react';

const cx = classnames.bind(styles)

type ModalSize = 'compact' | 'large'

export interface ModalSkeletonProps {
  children?: React.ReactNode
  size: ModalSize
}

function ModalSkeleton({ children, size = 'compact' }: ModalSkeletonProps) {
  return (
      <div
        aria-modal="true"
        role="dialog"
        tabIndex={0}
        className={cx('modal')}
      >
        <div
          className={cx({
            ['modalLayoutContext']: true,
             ['modalBodyCompact']: size === 'compact',
             ['modalBodyLarge']: size === 'large',
          })}
        >
          {children}
        </div>
      </div>
  )
}

export interface ModalProps {
  children?: React.ReactNode
  size?: ModalSize
}

const Modal = ({ children, size = 'compact' }: ModalProps) => {
  const onceRef = useRef(false)
  const [rootModal, setRootModal] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (document && onceRef.current === false) {
      onceRef.current = true
      const modalRoot = document?.getElementById('modal')
      setRootModal(modalRoot)
    }
  }, [])

  return (
    rootModal &&
    createPortal(
      <ModalSkeleton size={size}>{children}</ModalSkeleton>,
      rootModal
    )
  )
}

export interface ModalBodyProps {
  children?: React.ReactNode
}

function ModalTitle({ children }: ModalBodyProps) {
  return (
    <div
      role="heading"
      className={cx('modalTitle', 'type__h3')}
      aria-level={2}
    >
      {children}
    </div>
  )
}

function ModalBody({ children }: ModalBodyProps) {
  return <div className={cx('modalBody')}>{children}</div>
}
function ModalFooter({ children }: ModalBodyProps) {
  return <div className={cx('modalFooter', 'spacing__md-top')}>{children}</div>
}

Modal.Title = ModalTitle
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
