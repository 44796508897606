import Vue from 'vue';
import { Module } from 'vuex';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    query: "" as string
  },
  actions: {
    setQuery({ commit }, query) {
      commit('setQuery', query);
    }
  },
  getters: {
    query: (state): string => state.query
  },
  mutations: {
    setQuery(state, query) {
      Vue.set(state, 'query', query);
    }
  }
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
