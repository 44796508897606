/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  isAnyPopupOpened: (state) => (
    state.warningPopup.isOpen
    || state.riskWarningPopup.isOpen
    || state.selectLocationPopup.isOpen
    || state.athenaeumPopup.isOpen
    || state.clientLoginPopup.isOpen
    || state.menuPopup.isOpen
    || state.videoPopup.isOpen
    || state.literatureOrderPopup.isOpen
    || state.fundSubscriptionPopup.isOpen
  ),
  getLocationByIP: (state): string => state.userLocationByIP,
  getPendingGeoIp2Request: (state): boolean => state.pendingGeoIp2Request,
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
