import { useCallback, useRef } from 'react'
import { useLayoutEffect } from '../useIsomorphicLayoutEffect'
import canUseDOM from '../../utils/canUseDOM'
import * as styles from './UseScrollLock.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

function iOSDeviceTest() {
  return !!navigator?.userAgent && /iPad|iPhone|iPod/.test(navigator?.userAgent)
}

export default function useScrollLock() {
  const iOSDeviceDetected = useRef<boolean>(false)
  const scrollOffset = useRef<number>(0)

  useLayoutEffect(() => {
    if (canUseDOM()) {
      iOSDeviceDetected.current = iOSDeviceTest()
    }
  })

  const lockScrollWindow = useCallback(() => {
    document?.body?.classList?.add('scroll-locked')

    if (iOSDeviceDetected && iOSDeviceDetected.current) {
      scrollOffset.current = window.scrollY
      document.body.style.top = `-${scrollOffset.current}px`
    }
  }, [])

  const unLockScrollWindow = useCallback(() => {
    document?.body?.classList?.remove('scroll-locked')

    if (iOSDeviceDetected && iOSDeviceDetected.current) {
      document.body.style.top = ''
      window.scrollTo(0, scrollOffset.current)
    }
  }, [])

  return {
    lockScrollWindow,
    unLockScrollWindow,
  }
}
