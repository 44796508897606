import { Component, Vue, Prop } from 'vue-property-decorator';
import * as highcharts from 'highcharts/highstock';
import template from './charts-panel.vue';
import loadCldrPromise from '../../../assets/scripts/utilities/intialise';
import { createLineChartOptions } from '../../../assets/scripts/charting/options/lineChartOptions';

@Component({
  mixins: [template],
})
export default class ChartsPanel extends Vue {
  @Prop()
  data: any;

  mounted() {
    const chartData = this.data.data;
    document.addEventListener('DOMContentLoaded', () => {
      loadCldrPromise().done(() => {
        highcharts.stockChart('container', createLineChartOptions(chartData, {}));
      });
    });
  }
}
