import classnames from 'classnames/bind'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import canUseDOM from '../../utils/canUseDOM'
import * as styles from './DrawerTransition.module.scss'

const cx = classnames.bind(styles)

export interface DrawerTransitionProps {
  children?: React.ReactNode
  isDrawerOpen: boolean
}

const DrawerTransition = ({
  children,
  isDrawerOpen,
}: DrawerTransitionProps) => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const [DOMElementHeight, setDOMElementHeight] = useState<string>('0px')

  const handleTransitionEndExpand = useCallback(
    (event: TransitionEvent) => {
      event.preventDefault()

      setDOMElementHeight('auto')

      drawerRef.current?.removeEventListener(
        'transitionend',
        handleTransitionEndExpand,
        false
      )
    },
    [drawerRef]
  )

  const elementTransition = useCallback(
    (DrawerState: boolean) => {
      if (canUseDOM() && drawerRef?.current) {
        if (DrawerState) {
          const expandedHeight = drawerRef.current!.scrollHeight

          setDOMElementHeight(`${expandedHeight}px`)
          drawerRef.current!.addEventListener(
            'transitionend',
            handleTransitionEndExpand,
            false
          )
        } else {
          const expandedHeight = drawerRef.current!.scrollHeight

          setDOMElementHeight(`${expandedHeight}px`)

          window.requestAnimationFrame(() => {
            setDOMElementHeight(`${0}px`)
          })
        }
      }
    },
    [handleTransitionEndExpand]
  )

  useEffect(() => {
    elementTransition(isDrawerOpen)
  }, [isDrawerOpen, elementTransition])

  return (
    <div
      ref={drawerRef}
      className={cx({
        drawer: true,
        drawerExpanded: isDrawerOpen,
      })}
      style={{ height: DOMElementHeight }}
      id={'dropdown-tablinks'}
      aria-hidden={isDrawerOpen}
    >
      {children}
    </div>
  )
}

export default DrawerTransition;