import { Component, Prop, Mixins } from 'vue-property-decorator';
import { EnumItem } from '@/shared/Enums';
import template from './fund-filter-column.vue';
import Translatable from '../../../../../translation/Translatable';
import { FundFilterColumnModel } from '../../models/FundFilterColumnModel';
import Data from '../../Data';

@Component({
  mixins: [template],
})
export default class FundFilterColumn extends Mixins(Translatable) {
  @Prop()
  data: FundFilterColumnModel;

  selectedItem: EnumItem = {
    enumId: null,
    key: Data.defaultKey,
    value: null,
  };

  updated() {
    const selectedKey = this.data.selectedItemKey;
    [this.selectedItem] = this.getAllItems().filter((x) => x.key === selectedKey);
  }

  get header(): string {
    return this.data.header;
  }

  get internalColumnsNumber(): number {
    return this.data.internalColumnsNumber;
  }

  get columnCss(): string {
    return this.data.columnCss;
  }

  get isMobile(): boolean {
    return this.data.isMobile;
  }

  getAllItems(): EnumItem[] {
    return this.data.items.reduce((acc, val) => acc.concat(val), []);
  }

  items(index: number): EnumItem[] {
    return this.data.items[index];
  }

  isSelected(key: string): boolean {
    return key === this.selectedItem.key;
  }

  selectFilter(element: EnumItem): void {
    this.$emit('selectFilter', element);
  }

  selectFilterMobile(): void {
    if (this.selectedItem) {
      this.selectFilter(this.selectedItem);
    }
  }

  getUniqueId(key: string, header: string): string {
    return key == Data.defaultKey ? `${Data.defaultKey}-${header.replace(' ','-')}` : key;
  }
}
