import React from 'react' 
import classnames from 'classnames/bind'
import {
  ChildLink,
  EnhancedTextLink,
  ILink,
  ImageLink,
} from '@/shared/MwsViewModels'
import Icon, { IconOptions } from '../Icon'
import {
  IBaseLink,
  LinkButtonStyles,
  StylesMap,
  provideButtonIcon,
} from '../LinkButtonStyles'
import * as styles from './../LinkButtonStyles/LinkButtonStyles.module.scss'

const cx = classnames.bind(styles)

export interface StyledLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  type: LinkButtonStyles
  children?: React.ReactNode
  className?: string
  classNameForIcon?: string
  customIcon?: IconOptions | null
  isActive?: boolean
  isDisabled?: boolean
  data: ILink | ImageLink | EnhancedTextLink | ChildLink | IBaseLink
  hideIcon?: boolean
}

const StyledLink = ({
  data,
  type,
  className,
  classNameForIcon = '',
  children,
  customIcon = null,
  isActive = false,
  isDisabled = false,
  hideIcon = false,
  ...attributeProps
}: StyledLinkProps) => {
  if (!data) {
    return null
  }

  const text = data?.linkText
  const openInNewWindow =
    'openInNewWindow' in data ? 
      data.openInNewWindow :
      'openLinkInNewWindow' in data ? 
        data.openLinkInNewWindow : 
        undefined
  const linkType = 'elementType' in data ? data.elementType : 'internalLink'
  const generatedIcon = provideButtonIcon(linkType, type)
  const activeClass = isActive && 'isActive'
  const isDisabledClass = isDisabled && 'isDisabled'

  return (
    <a
      href={data?.url || ''}
      className={cx('rebrandLink', StylesMap[type], className, activeClass, isDisabledClass)}
      target={openInNewWindow ? '_blank' : undefined}
      rel={openInNewWindow ? 'noopener noreferrer' : undefined}
      {...attributeProps}
    >
      {type === 'radioSelector' && <span className={cx('radioLabelIcon')} />}
      {children ? children : text}
      {(generatedIcon || customIcon) && !hideIcon && (
        <Icon
          className={cx({
            [classNameForIcon]: true,
            generatedIconRight:
              ['arrow', 'document', 'linkExternal'].includes(generatedIcon || '') &&
              !classNameForIcon,
            generatedIconRightNonArrow:
              generatedIcon === 'document' && !classNameForIcon,
          })}
          type={customIcon || generatedIcon}
        />
      )}
    </a>
  )
}

export default StyledLink
