import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './intellectual-capital-panel.vue';
import NoResultsItem from './no-results-item/NoResultsItem';
import IcItemContainer from './ic-item-container/IcItemContainer';
import IcSearchBlock from './ic-search-block/IcSearchBlock';
import IcFilterBlock from './ic-filter-block/IcFilterBlock';
import IcThemeBlock from './ic-theme-block/IcThemeBlock';
import IcSignUpBlock from './ic-sign-up-block/IcSignUpBlock';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import { ReactInVue } from 'vuera';
import IntellectualCapitalPanelRebrandLite from '../../../react/rebrand-lite/panels/IntellectualCapitalPanel';

const articleWatchSvg = '/assets/images/svg/sprite/article-watch.svg';
const icPanelRebrandLiteLayoutType = 'INSIGHTS-HORIZONTAL GRID CARDS';

@Component({
  mixins: [template],
  components: {
    NoResultsItem,
    IcItemContainer,
    InsightsSearch: IcSearchBlock,
    InsightsFilter: IcFilterBlock,
    InsightsThemes: IcThemeBlock,
    InsightsSignUp: IcSignUpBlock,
    'IntellectualCapitalPanelRebrandLite': ReactInVue(IntellectualCapitalPanelRebrandLite)
  },
})
export default class IntellectualCapitalPanel extends Mixins(PostbackMixin) {
  @Prop()
  data!: mws.IntellectualCapitalPanel;

  articleWatchSvg = articleWatchSvg;

  private filterData: mws.IntellectualCapitalDto = <mws.IntellectualCapitalDto>{};
  isLoading = false;

  lastSearchTerm = null;
  searchTermResultCount = 0;

  get sidebarItems(): mws.IInsightsSide[] {
    return this.data.sidePanels
      .filter((panel) => panel !== null);
  }

  get searchBlockSide(): mws.IInsightsSide {
    return this.data.sidePanels.find((panel) => panel !== null && panel.type === 'InsightsSearch');
  }

  get displayResultsMessage(): boolean{
    if(this.lastSearchTerm) return true;
    if(this.data.items.length === 0) return true;
    return false;
  }

  get isRebrandLiteIcPanel(): boolean {
    return this.data.insightLayoutType.toUpperCase() === icPanelRebrandLiteLayoutType; 
  }

  async onSelect(data: any): Promise<void> {
    this.filterData = { ...this.filterData, ...data };
    await this.fetchData();
  }  

  private async fetchData(): Promise<void> {
    const postbackDto: mws.IntellectualCapitalDto = this.filterData;
    const model = this.createRequestModel(this.data, postbackDto);

    try {
      this.isLoading = true;
      const updatedPage = await this.fetchPostback(model);
      await this.mergePostback(updatedPage);
      this.isLoading = false;

      this.searchTermResultCount = this.data.items.length;
      this.lastSearchTerm = postbackDto.searchTerm;
    } catch {
      this.isLoading = false;
    }
  }
}