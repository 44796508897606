var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'panel-width-' + _vm.data.width,style:({ 'background-color': _vm.data.backgroundColour })},[_c('section',{staticClass:"section single-video-panel content-panel"},[_c('div',{staticClass:"rebrandContainerRedesign single-video-container"},[_c('div',{staticClass:"intro"},[(_vm.data.title)?_c('h2',[_vm._v("\n                "+_vm._s(_vm.data.title)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"rich-text-editor",domProps:{"innerHTML":_vm._s(_vm.data.description)}}),_vm._v(" "),_c('div',{staticClass:"video-transcript"},[(_vm.data.transcriptFileUrl)?_c('a',{staticClass:"transcript-button",attrs:{"href":_vm.data.transcriptFileUrl,"target":"_blank"}},[_vm._v("\n                    "+_vm._s(_vm.data.transcriptCta)+"\n                ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"separator"}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlayerAvailable),expression:"isPlayerAvailable"}],staticClass:"video-box no-print",class:[
                _vm.data.textColour == 'Black'
                    ? 'jw-player-black'
                    : _vm.data.textColour == 'Cream'
                    ? 'jw-player-cream'
                    : 'jw-player-white' ]},[_c('div',{ref:"singleVideoContainer",attrs:{"id":"js-bginsights-video-player","aria-labelledby":"bginsights-video-player-controls"}}),_vm._v(" "),_c('div',{staticClass:"video-player-overlay-box",class:_vm.data.textColour == 'Black'
                        ? 'overlay-color-black'
                        : _vm.data.textColour == 'Cream'
                        ? 'overlay-color-cream'
                        : 'overlay-color-white'},[_c('p',{staticClass:"u-visually-hidden",attrs:{"id":"bginsights-video-player-controls","aria-hidden":"true"}},[_vm._v("\n                    The following keyboard controls control the video player. - - Space -\n                    Pause/Play Video - - m - Mute/Unmute - - Up and Down arrows - adjust volume\n                    - - 0-9 - seek to percentage - - f - fullscreen - - c - toggle captions\n                ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPlayed),expression:"!isPlayed"}],staticClass:"overlay-contents",class:[
                    _vm.data.textColour == 'Black'
                        ? 'jw-overlay-contents-black'
                        : _vm.data.textColour == 'Cream'
                        ? 'jw-overlay-contents-cream'
                        : 'jw-overlay-contents-white' ]},[_c('p',[_vm._v(_vm._s(_vm.data.videoCta))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.duration))])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPlayerAvailable),expression:"!isPlayerAvailable"}]},[_vm._m(0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"playerErrorMessage"},[_c('span',[_vm._v("Video player CDN is not accessible")])])}]

export { render, staticRenderFns }