export function getClosest(element: Element, selector: string): Element | null {
  let currentElement = element;
  let parent: Element;

  while (currentElement) {
    parent = currentElement.parentElement;
    if (parent && parent.matches(selector)) {
      return parent;
    }
    currentElement = parent;
  }

  return null;
}

export function getFullHeight(element: HTMLElement): number {
  const height = element.offsetHeight;
  const style = getComputedStyle(element);
  return height + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
}

export function areColliding(first: DOMRect, other: DOMRect): boolean {
  return first.left < other.left + other.width
      && first.left + first.width > other.left
      && first.top < other.top + other.height
      && first.top + first.height > other.top;
}
