import { Table } from '@/shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './holdings-table.vue';

@Component({
  mixins: [template],
})
export default class HoldingsTable extends Vue {
  @Prop()
  table: Table;
}
