import React from 'react'
import {
  ContentPageHeaderPanel as IContentPageHeaderPanel,
  FullWidthQuotePanel as IFullWidthQuotePanel,
  GridPanel as IGridPanel,
  InsightCardPanel as IInsightCardPanel,
  IntellectualCapitalPanel as IIntellectualCapitalPanel,
  FooterWithImagesAndLinksPanel as IFooterWithImagesAndLinksPanel,
  INotificationPanel,
  IPanel,
  SectionHeaderPanel as ISectionHeaderPanel,
  TextWithBackgroundPanel as ITextWithBackgroundPanel,
  ShortlinkNotAvailablePanel as IShortlinkNotAvailablePanel,
} from '@/shared/MwsViewModels'
import ContentPageHeaderPanel from '../../panels/ContentPageHeaderPanel'
import FullWidthQuotePanel from '../../panels/FullWidthQuotePanel'
import GridPanel from '../../panels/GridPanel'
import InsightCardPanel from '../../panels/InsightCardPanel'
import IntellectualCapitalPanel from '../../panels/IntellectualCapitalPanel'
import NotificationPanel from '../../panels/NotificationPanel'
import SectionHeaderPanel from '../../panels/SectionHeaderPanel'
import TextWithBackgroundPanel from '../../panels/TextWithBackgroundPanel'
import FooterWithImagesAndLinksPanel from '../../panels/FooterWithImagesAndLinksPanel'
import ShortlinkNotAvailablePanel from '../../panels/ShortlinkNotAvailablePanel'

export interface PanelComponentProps {
  /**
   * Description of prop. This will display in Storybook
   */
  data: IPanel
}

const PanelComponent: React.FC<PanelComponentProps> = ({ data }: PanelComponentProps) => {
  const panelName = data?.panelName

  switch (panelName) {
    case 'ContentPageHeaderPanel':
      return <ContentPageHeaderPanel data={data as IContentPageHeaderPanel} />
    case 'FooterWithImagesAndLinksPanel':
      return <FooterWithImagesAndLinksPanel data={data as IFooterWithImagesAndLinksPanel} />
    case 'FullWidthQuotePanel':
      return <FullWidthQuotePanel data={data as IFullWidthQuotePanel} />
    case 'GridPanel':
      return <GridPanel data={data as IGridPanel} />
    case 'InsightCardPanel':
      return <InsightCardPanel data={data as IInsightCardPanel} />
    case 'IntellectualCapitalPanel':
      return <IntellectualCapitalPanel data={data as IIntellectualCapitalPanel} />
    case 'NotificationPanel':
      return <NotificationPanel data={data as INotificationPanel} />
    case 'SectionHeaderPanel':
      return <SectionHeaderPanel data={data as ISectionHeaderPanel} />
    case 'TextWithBackgroundPanel':
      return <TextWithBackgroundPanel data={data as ITextWithBackgroundPanel} />
    case 'ShortlinkNotAvailablePanel':
      return <ShortlinkNotAvailablePanel data={data as IShortlinkNotAvailablePanel} />
    default: return ('')
  }
}

export default PanelComponent
