import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-donut-chart-panel.vue';
import DonutChart from './donut-chart/DonutChart';
import AnalysisTable from './analysis-table/AnalysisTable';

@Component({
  mixins: [template],
  components: {
    DonutChart,
    AnalysisTable
  }
})
export default class FundDonutChartPanel extends Vue {
  @Prop()
  data: mws.FundDonutChartPanel;

  includesBenchmark(model: mws.DonutChartModel): boolean
  {
    return typeof (model.chart as mws.DonutChartWithBenchmark).benchmarkTotal === 'number';
  }
}
