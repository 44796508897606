import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './text-with-image-background-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import GradientPanel from '../gradient/GradientPanel';

@Component({
  mixins: [template],
  components: {
    GradientPanel,
  },
})
export default class TextWithImageBackgroundPanel extends Vue {
  @Prop()
  data!: mws.TextWithImageBackgroundPanel;
}
