import { Vue, Component, Prop } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import * as mws from '@/shared/MwsViewModels';
import template from './events-carousel.vue';
import EventInfoElement from './EventInfoElement';

@Component({
  mixins: [template],
  components: {
    VueSlickCarousel,
    EventInfoElement,
  },
})
export default class EventsCarousel extends Vue {
  @Prop({ default: () => [] })
  events!: mws.EventInfoElement;

  moveNext(): void {
    this.carousel.next();
  }

  movePrevious(): void {
    this.carousel.prev();
  }

  carouselSettings = {
    infinite: true,
    dots: false,
    arrows: false,
    slidesToShow: 1,
  };

  private get carousel(): VueSlickCarousel {
    return this.$refs.carousel;
  }
}
