import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-people-panel.vue';
import FundManager from './fund-manager/FundManager';

@Component({
  mixins: [template],
  components: {
    FundManager,
  },
})
export default class FundPeoplePanel extends Vue {
  @Prop()
  data: mws.FundPeoplePanel;
}
