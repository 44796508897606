import { Component, Prop } from 'vue-property-decorator';
import Translatable from '../../../../translation/Translatable';
import template from './top-holdings-table.vue';
import * as mws from '../../../../shared/MwsViewModels';

@Component({
  mixins: [template],
})
export default class TopHoldingsTable extends Translatable {
  @Prop()
  topTenActive!: boolean;

  @Prop()
  holdings!: mws.TopHolding[];

  @Prop()
  showOnlyTop!: boolean;

  topHoldings: mws.TopHolding[] = [];

  remainingHoldings: mws.TopHolding[] = [];

  private topCount = 10;

  private percentThreshold = 0.7;

  created() {
    if (this.showOnlyTop) this.setTopHoldings();
    else {
      this.setVisibleHoldings();
      this.setRemainingHoldings();
    }
  }

  private setVisibleHoldings(): void {
    this.topHoldings = this.holdings
      .filter((holding) => holding.percent >= this.percentThreshold)
      .slice(0, this.topCount);
  }

  private setRemainingHoldings(): void {
    this.remainingHoldings = this.holdings
      .filter((holding) => holding.percent >= this.percentThreshold)
      .slice(this.topCount);

    this.$emit('remainingHoldings', this.remainingHoldings.length);
  }

  private setTopHoldings(): void {
    this.topHoldings = this.holdings.slice(0, this.topCount);
  }

  public get topTenHoldingsTotal(): string {
    if (!this.topHoldings || this.topHoldings.length < 1) return '0';

    return this.topHoldings
      .map((holding) => holding.percent)
      .reduce((prev: number, current: number) => +prev + +current, 0)
      .toFixed(1);
  }

  public get remainderHoldingsTotal(): string {
    if (!this.remainingHoldings || this.remainingHoldings.length < 1) return '0';

    return this.remainingHoldings
      .map((holding) => holding.percent)
      .reduce((prev: number, current: number) => +prev + +current, 0)
      .toFixed(1);
  }
}
