import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './manual-table-panel.vue';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    'SectionContainer': ReactInVue(SectionContainer),
  }
})
export default class ManualTablePanel extends Vue {
  @Prop()
  data: mws.ManualTablePanel;
}
