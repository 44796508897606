/* eslint-disable */
// Condensed CLDR json for fallback purposes. Uses en-GB locale data. Only contains modules that are used,
// so any changes to the way Globalize is used will require changes to this file e.g. currencies
export var cldrFallbackJson: Record<string, any> = {
  main: {
    'en-GB': {
      identity: {
        version: {
          _number: '$Revision: 13722 $',
          _cldrVersion: '32.0.1',
        },
        language: 'en',
        territory: 'GB',
      },
      dates: {
        calendars: {
          gregorian: {
            months: {
              format: {
                abbreviated: {
                  1: 'Jan',
                  2: 'Feb',
                  3: 'Mar',
                  4: 'Apr',
                  5: 'May',
                  6: 'Jun',
                  7: 'Jul',
                  8: 'Aug',
                  9: 'Sep',
                  10: 'Oct',
                  11: 'Nov',
                  12: 'Dec',
                },
                narrow: {
                  1: 'J',
                  2: 'F',
                  3: 'M',
                  4: 'A',
                  5: 'M',
                  6: 'J',
                  7: 'J',
                  8: 'A',
                  9: 'S',
                  10: 'O',
                  11: 'N',
                  12: 'D',
                },
                wide: {
                  1: 'January',
                  2: 'February',
                  3: 'March',
                  4: 'April',
                  5: 'May',
                  6: 'June',
                  7: 'July',
                  8: 'August',
                  9: 'September',
                  10: 'October',
                  11: 'November',
                  12: 'December',
                },
              },
              'stand-alone': {
                abbreviated: {
                  1: 'Jan',
                  2: 'Feb',
                  3: 'Mar',
                  4: 'Apr',
                  5: 'May',
                  6: 'Jun',
                  7: 'Jul',
                  8: 'Aug',
                  9: 'Sep',
                  10: 'Oct',
                  11: 'Nov',
                  12: 'Dec',
                },
                narrow: {
                  1: 'J',
                  2: 'F',
                  3: 'M',
                  4: 'A',
                  5: 'M',
                  6: 'J',
                  7: 'J',
                  8: 'A',
                  9: 'S',
                  10: 'O',
                  11: 'N',
                  12: 'D',
                },
                wide: {
                  1: 'January',
                  2: 'February',
                  3: 'March',
                  4: 'April',
                  5: 'May',
                  6: 'June',
                  7: 'July',
                  8: 'August',
                  9: 'September',
                  10: 'October',
                  11: 'November',
                  12: 'December',
                },
              },
            },
            days: {
              format: {
                abbreviated: {
                  sun: 'Sun',
                  mon: 'Mon',
                  tue: 'Tue',
                  wed: 'Wed',
                  thu: 'Thu',
                  fri: 'Fri',
                  sat: 'Sat',
                },
                narrow: {
                  sun: 'S',
                  mon: 'M',
                  tue: 'T',
                  wed: 'W',
                  thu: 'T',
                  fri: 'F',
                  sat: 'S',
                },
                short: {
                  sun: 'Su',
                  mon: 'Mo',
                  tue: 'Tu',
                  wed: 'We',
                  thu: 'Th',
                  fri: 'Fr',
                  sat: 'Sa',
                },
                wide: {
                  sun: 'Sunday',
                  mon: 'Monday',
                  tue: 'Tuesday',
                  wed: 'Wednesday',
                  thu: 'Thursday',
                  fri: 'Friday',
                  sat: 'Saturday',
                },
              },
              'stand-alone': {
                abbreviated: {
                  sun: 'Sun',
                  mon: 'Mon',
                  tue: 'Tue',
                  wed: 'Wed',
                  thu: 'Thu',
                  fri: 'Fri',
                  sat: 'Sat',
                },
                narrow: {
                  sun: 'S',
                  mon: 'M',
                  tue: 'T',
                  wed: 'W',
                  thu: 'T',
                  fri: 'F',
                  sat: 'S',
                },
                short: {
                  sun: 'Su',
                  mon: 'Mo',
                  tue: 'Tu',
                  wed: 'We',
                  thu: 'Th',
                  fri: 'Fr',
                  sat: 'Sa',
                },
                wide: {
                  sun: 'Sunday',
                  mon: 'Monday',
                  tue: 'Tuesday',
                  wed: 'Wednesday',
                  thu: 'Thursday',
                  fri: 'Friday',
                  sat: 'Saturday',
                },
              },
            },
            dateFormats: {
              full: 'EEEE, d MMMM y',
              long: 'd MMMM y',
              medium: 'd MMM y',
              short: 'dd/MM/y',
            },
            timeFormats: {
              full: 'HH:mm:ss zzzz',
              long: 'HH:mm:ss z',
              medium: 'HH:mm:ss',
              short: 'HH:mm',
            },
            dateTimeFormats: {
              full: '{1} \'at\' {0}',
              long: '{1} \'at\' {0}',
              medium: '{1}, {0}',
              short: '{1}, {0}',
              availableFormats: {
                Bh: 'h B',
                Bhm: 'h.mm B',
                Bhms: 'h.mm.ss B',
                d: 'd',
                E: 'ccc',
                EBhm: 'E, h.mm B',
                EBhms: 'E, h.mm.ss B',
                Ed: 'E d',
                Ehm: 'E h:mm a',
                EHm: 'E HH:mm',
                Ehms: 'E h:mm:ss a',
                EHms: 'E HH:mm:ss',
                Gy: 'y G',
                GyMMM: 'MMM y G',
                GyMMMd: 'd MMM y G',
                GyMMMEd: 'E, d MMM y G',
                h: 'h a',
                H: 'HH',
                hm: 'h:mm a',
                Hm: 'HH:mm',
                hms: 'h:mm:ss a',
                Hms: 'HH:mm:ss',
                hmsv: 'h:mm:ss a v',
                Hmsv: 'HH:mm:ss v',
                hmv: 'h:mm a v',
                Hmv: 'HH:mm v',
                M: 'L',
                Md: 'dd/MM',
                MEd: 'E dd/MM',
                MMdd: 'dd/MM',
                MMM: 'LLL',
                MMMd: 'd MMM',
                MMMEd: 'E d MMM',
                MMMMd: 'd MMMM',
                'MMMMW-count-one': '\'week\' W \'of\' MMMM',
                'MMMMW-count-other': '\'week\' W \'of\' MMMM',
                ms: 'mm:ss',
                y: 'y',
                yM: 'MM/y',
                yMd: 'dd/MM/y',
                yMEd: 'E, dd/MM/y',
                yMMM: 'MMM y',
                yMMMd: 'd MMM y',
                yMMMEd: 'E, d MMM y',
                yMMMM: 'MMMM y',
                yQQQ: 'QQQ y',
                yQQQQ: 'QQQQ y',
                'yw-count-one': '\'week\' w \'of\' Y',
                'yw-count-other': '\'week\' w \'of\' Y',
              },
            },
          },
        },
      },
      numbers: {
        defaultNumberingSystem: 'latn',
        otherNumberingSystems: {
          native: 'latn',
        },
        minimumGroupingDigits: '1',
        'symbols-numberSystem-latn': {
          decimal: '.',
          group: ',',
          list: ';',
          percentSign: '%',
          plusSign: '+',
          minusSign: '-',
          exponential: 'E',
          superscriptingExponent: '×',
          perMille: '‰',
          infinity: '∞',
          nan: 'NaN',
          timeSeparator: ':',
        },
        'decimalFormats-numberSystem-latn': {
          standard: '#,##0.###',
        },
        'miscPatterns-numberSystem-latn': {
          atLeast: '{0}+',
          range: '{0}–{1}',
        },
        minimalPairs: {
          'pluralMinimalPairs-count-one': '{0} day',
          'pluralMinimalPairs-count-other': '{0} days',
          few: 'Take the {0}rd right.',
          one: 'Take the {0}st right.',
          other: 'Take the {0}th right.',
          two: 'Take the {0}nd right.',
        },
      },
      units: {
        short: {
          per: {
            compoundUnitPattern: '{0}/{1}',
          },
          'speed-mile-per-hour': {
            displayName: 'miles/hour',
            'unitPattern-count-one': '{0} mph',
            'unitPattern-count-other': '{0} mph',
          },
        },
      },
    },
  },
  supplemental: {
    version: {
      _number: '$Revision: 13699 $',
      _unicodeVersion: '10.0.0',
      _cldrVersion: '32.0.1',
    },
    numberingSystems: {
      latn: {
        _digits: '0123456789',
        _type: 'numeric',
      },
    },
    timeData: {
      GB: {
        _allowed: 'H h hb hB',
        _preferred: 'H',
      },
    },
    likelySubtags: {
      en: 'en-Latn-US',
      'en-Shaw': 'en-Shaw-GB',
      enn: 'enn-Latn-ZZ',
      enq: 'enq-Latn-ZZ',
    },
  },
};
/* eslint-enable */
