import * as mws from '../../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './usm-performance-and-portfolio-chart.vue';

@Component({
  mixins: [template]
})
export default class UsmPerformanceAndPortfolioChart extends Vue {
    @Prop()
    chartData: mws.UsmPerformanceAndPortfolioChart;

    @Prop()
    tabIndex: number;

    @Prop() 
    itemIndex: number;
}