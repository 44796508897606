import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AddressElement } from '@/shared/MwsViewModels';
import template from './office-address.vue';
import OfficeAddressData from '../OfficeAddressData';
import Translatable from '../../../../translation/Translatable';
import { AddressCssClasses } from '../AddressCssClasses';

@Component({
  mixins: [template],
})
export default class OfficeAddress extends Mixins(Translatable) {
  @Prop()
  data!: OfficeAddressData;

  async created() {
    await this.loadDictionary([
      'contact_map_telephone_label',
      'contact_map_fax_label',
      'contact_map_show',
    ]);
  }

  get address(): AddressElement {
    return this.data.address;
  }

  get addressCssClasses(): AddressCssClasses {
    return this.data.addressCssClasses;
  }

  get addressesNumber(): number {
    return this.data.addressesNumber;
  }

  get telephoneLabel(): string {
    return this.getTranslationOrDefault('contact_map_telephone_label');
  }

  get faxLabel(): string {
    return this.getTranslationOrDefault('contact_map_fax_label');
  }

  get showOnMapLabel(): string {
    return this.getTranslationOrDefault('contact_map_show');
  }

  getAddressUrl = (address: AddressElement): string => {
    const { latitude } = address;
    const { longitude } = address;
    return `https://www.google.co.uk/maps/?q=${latitude}, ${longitude}`;
  };

  getFormattedAddress = (address: AddressElement): string => {
    const addressPartsArray = [
      address.addressLine1,
      address.addressLine2,
      address.addressLine3,
      address.addressLine4,
      address.addressLine5,
      address.postcode,
    ];

    return addressPartsArray
      .filter((x) => !!x)
      .map((x) => `<span class="nowrap">${x}</span>`)
      .join(', ');
  };

  getCallablePhoneLink = (telephone: string): string => `tel:${telephone.replace(/[() ]/g, '')}`;

  showOnMap(address: AddressElement): void {
    this.$emit('showOnMap', address);
  }
}
