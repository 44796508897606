import * as mws from '../../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './canada-performance-and-portfolio-table.vue';

@Component({
  mixins: [template]
})
export default class CanadaPerformanceAndPortfolioTable extends Vue {
  @Prop()
  table: mws.Table;
}