import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './exception-in-panel.vue';

@Component({
  mixins: [template],
})
export default class ExceptionInPanel extends Vue {
  @Prop()
  data: mws.ExceptionInPanel;

  created(){
    console.error(`An error occurred when trying to load this panel: ${this.data.originalPanelName}`);
  }
}
