import { Component, Mixins, Vue } from 'vue-property-decorator';
import Meta from 'vue-meta';
import VueCookies from 'vue-cookies-ts';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { gsap } from 'gsap/dist/gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import VueAccessibleSelect from 'vue-accessible-select';
import template from './app.html';
import createRouter from './router';
import createStore from './store';
import './class-component-meta-hooks';
import MetaPage from './MetaPage';
import { Config } from './config';
import { getClientSideBrowserInfo } from './shared/browser/browser-info';
import ErrorPage from './error/ErrorPage';
import RiskWarningPage from './risk/RiskWarningPage';
import ErrorHandlerMixin from './error/ErrorHandlerMixin';
import * as VueGoogleMaps from '../node_modules/vue2-google-maps';
import { getInternalServerErrorData } from './error/errorPageData';
import { ReactInVue  } from 'vuera'
import NotFoundErrorPage from './react/rebrand-lite/pages/NotFoundErrorPage';

@template
@Component({
  components: {
    ErrorPage,
    RiskWarningPage,
    'NotFoundErrorPage': ReactInVue(NotFoundErrorPage)
  },
})
export default class App extends Mixins(MetaPage, ErrorHandlerMixin) {
  serverPrefetch() {
    this.loadConfig();
  }

  preload = true;

  created() {
    Vue.use(VueGoogleMaps, {
      load: {
        key: this.$store.getters.config.googleMapsKey,
        libraries: 'places',
      },
      installComponents: false,
    });
  }

  mounted() {
    this.$store.dispatch('setBrowserInfo', getClientSideBrowserInfo());

    document.addEventListener('DOMContentLoaded', () => {
      this.preload = false;
    })
  }

  isErrorPage(): boolean {
    return this.pageData.pageType === 'ErrorPage' || this.capturedError != null;
  }

  isNotFoundErrorPage(): boolean {
    return this.pageData.pageType === 'NotFoundErrorPage';
  }

  isRisk(): boolean {
    return this.pageData.pageType === 'LiteraturePage';
  }

  get pageData(): any {
    return this.$store.getters.pageData;
  }

  get errorPageData(): any {
    return this.capturedError == null ? this.pageData : getInternalServerErrorData().page;
  }

  private loadConfig(): void {
    const config: Config = {
      googleAnalyticsCode: process.env.VUE_APP_GOOGLE_ANALYTICS_CODE,
      googleMapsKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      recaptchaSitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      jwplayerKey: process.env.JWPLAYER_KEY,
      isDebug: process.env.VUE_APP_DEBUG_VIEW === 'true',
      preview: process.env.VUE_APP_PREVIEW === 'true'
    };
    this.$store.dispatch('setConfig', config);
  }
}

gsap.registerPlugin(ScrollToPlugin);

Vue.use(Meta);
Vue.use(VueCookies);
Vue.use(VueAccessibleSelect);

export const createApp = (): {app: any, router: VueRouter, store: Store<any>} => {
  const router = createRouter();
  const store = createStore();

  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  });

  return { app, router, store };
};
