import { Component, Vue, Prop } from 'vue-property-decorator';
import * as mws from '../shared/MwsViewModels';
import template from './fund-page.vue';
import FundHeader from './fund-header/FundHeader';
import FundContent from './fund-content/FundContent';
import MenuPopup from '../popups/menu/MenuPopup';

@Component({
  mixins: [template],
  components: {
    FundHeader,
    FundContent,
    MenuPopup
  },
})
export default class FundPage extends Vue {
  @Prop()
  page!: mws.FundPanelPage;

  @Prop({ default: false })
  debug: boolean;

  get headerPanelGroups(): mws.FundPanelGroup[] {
    return this.allGroups.filter((group) => group.isHeaderPanel);
  }

  get contentPanelGroups(): mws.FundPanelGroup[] {
    return this.allGroups.filter((group) => !group.isHeaderPanel);
  }

  private get allGroups() {
    return this.page.panelGroups as mws.FundPanelGroup[];
  }
}
