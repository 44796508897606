import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import template from './fund-daily-prices-shortlist-panel.vue';
import * as mws from '../../../../shared/MwsViewModels';
import Translatable from '../../../../translation/Translatable';
import PostbackMixin from '../../../../shared/postback/PostbackMixin';
import GenericLink from '../../../../shared/components/link/GenericLink';
import axios from 'axios';
import DailyPricesTable from '../daily-prices-table/DailyPricesTable';
import FundDailyPricesShortlistMixin from '../../../../shared/components/fund-daily-prices/FundDailyPricesShortlistMixin';
import { Helpers } from '../../../../assets/scripts/utilities/formatHelpers';
import loadCldrPromise from '../../../../assets/scripts/utilities/intialise';
import EventBus from '../../../../shared/EventBus';

const excelDownloadUrl = '/dailyprices/excelByShortlist';
const tableContainerSelector = 'shortlist_table_';

@Component({
  mixins: [template],
  components: {
    DailyPricesTable,
    GenericLink,
  },
})
export default class FundDailyPricesShortlistPanel extends Mixins(Translatable, PostbackMixin, FundDailyPricesShortlistMixin) {
  @Prop()
  data!: mws.FundDailyPricesShortlistPanel;

  shortlistFromCookie;
  regionAndChannelIdentifier = "";
  tableContainerSelector;
  dailyPricesPageUrlElement = "";
  viewLoading: boolean = false;
  noCookieSetOrNothingShortlisted: boolean = false;

  beforeMount() {
    this.tableContainerSelector = tableContainerSelector;
    this.shortlistFromCookie = this.getShortlistItemsFromCookie();
    this.regionAndChannelIdentifier = this.getRegionAndChannelIdentifier();

    loadCldrPromise()
      .always(() => {
        this.initPage();
      });

    window.addEventListener('resize', () => {
      this.setFooterPosition();
    });
  }

  mounted() {
    EventBus.$on('dp-dailyPrices-navitem-exist', (dailyPricesPageUrlElement) => {
      this.dailyPricesPageUrlElement = dailyPricesPageUrlElement;
    });
  }

  public initPage() {
    if (this.getCurrentRegionAndChannelShortlistCount() > 0) {
      this.viewLoading = true;
      this.fetchData().then(() => {
        this.viewLoading = false;
        this.setFooterPosition();
        Vue.nextTick(this.setTableInitHeight);
      })
    } else {
      this.noCookieSetOrNothingShortlisted = true;
    }

    this.setFooterPosition();
  }

  get priceModels(): mws.DailyPriceTab[] {
    return this.data.tabs;
  }

  async fetchData(): Promise<void> {

    const payload: mws.FundDailyPricesShortlistPanelDto = {
      shortlistIdentifiers: this.shortlistFromCookie[this.regionAndChannelIdentifier]
    };

    const model = this.createRequestModel(this.data, payload);
    try {
      const updatedPage = await this.fetchPostback(model);
      await this.mergePostback(updatedPage);
    } catch (exc) {
      console.error(`Failed to update: ${exc}`);
    }
  }

  getExportUrl() {

    let data: mws.FundDailyPricesShortlistExcelDto = {
      url: window.location.pathname,
      shortlistIdentifiers: this.shortlistFromCookie[this.regionAndChannelIdentifier]
    }

    const queryParams = `data=${encodeURIComponent(JSON.stringify(data))}`;
    return `${excelDownloadUrl}?${queryParams}`;
  }

  async onDownloadClick(): Promise<void> {
    let data: mws.FundDailyPricesShortlistExcelDto = {
      url: window.location.pathname,
      shortlistIdentifiers: this.shortlistFromCookie[this.regionAndChannelIdentifier]
    }

    const fileName: string = `Shortlisted-daily-prices-${this.regionAndChannelIdentifier.split('/').join("-")}`;

    await axios.post(excelDownloadUrl, data, { responseType: 'arraybuffer' })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url) // Release blob object
      })
      .catch((err) => console.log(`Failed to download document ${err}`));
  }

  public toggleTableVisibility(tableIndex: number, toggleEl: any = null) {
    let tableContainerElement = document.getElementById(`${tableContainerSelector}${tableIndex}`);

    if (tableContainerElement) {
      tableContainerElement.parentElement.classList.toggle('collapsed')

      if (tableContainerElement.clientHeight) {
        tableContainerElement.style.height = '0px';

      } else {
        var toggleAreaElement = tableContainerElement.firstElementChild;
        tableContainerElement.style.height = toggleAreaElement.clientHeight + 1 + "px";
      }

      let toggleButtonElement = (toggleEl.target as HTMLElement);
        
      if (toggleButtonElement) {
        toggleButtonElement.innerText = toggleButtonElement.innerText === 'Hide' ? 'View' : 'Hide';
      }
    }
  }

  public getDateFormat(date: string, fundType: string): string {
    if(fundType === 'UsMutualFund') {
      var dateValue = new Date(date);
      // For some reason Globalize() doesn't handle it.
      var dateFormat = `${('0' + (dateValue.getMonth()+1)).slice(-2)}/${('0' + dateValue.getDate()).slice(-2)}/${dateValue.getFullYear()}`
      return dateFormat;
    }
    return Helpers.formatDateLong(date, false, true);
  }
  
  private setTableInitHeight() {
    this.priceModels.forEach((tab, tableIndex) => {
      let tableContainerElement = document.getElementById(`${tableContainerSelector}${tableIndex}`);

      if (tableContainerElement) {
          var toggleAreaElement = tableContainerElement.firstElementChild;
          tableContainerElement.style.height = toggleAreaElement.clientHeight + 2 + "px";
      }
    });
  }

  private setFooterPosition() {
    let footerElement = document.getElementById('footer');

    if (footerElement) {
      // if footer is above of window bottom border
      if (this.viewLoading || this.noCookieSetOrNothingShortlisted) {
        footerElement.style.position = 'fixed';
        footerElement.style.bottom = '0';
      }
      else {
        footerElement.style.position = '';
        footerElement.style.bottom = '';
      }
    }
  }
}
