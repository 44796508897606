import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './oeic-investment-choices-and-charges-panel.vue';
import TabContainer, { TabViewModel } from '../../tab-container/TabContainer';
import EventBus from '../../../shared/EventBus';

@Component({
  mixins: [template],
  components: {
    TabContainer,
  },
})
export default class OeicInvestmentChoicesAndChargesPanel extends Vue {
  @Prop()
  data: mws.OeicInvestmentChoicesAndChargesPanel;

  mounted() {
    const shareClassValue = this.data.tabs[0].tableData.rows[0].columns[1].value;
    EventBus.$emit('new-share-class', shareClassValue);

    EventBus.$on('new-oeic-share-class', (newShareClassValue) => {
      const newOEICShareClassTab = this.data.tabs.find((x) => x.heading === newShareClassValue);
      const newShareClass = newOEICShareClassTab.tableData.rows[0].columns[1].value;
      EventBus.$emit('new-share-class', newShareClass);
    });
  }
  get containerTabs(): TabViewModel[] {
    return this.data.tabs.map((tab) => ({ heading: tab.heading, tableData: tab.tableData }));
  }
}
