import { Component, Mixins } from 'vue-property-decorator';
import TimeService from '../TimeService';

@Component
export default class FormSubmissionHistoryMixin extends Mixins(TimeService) {
  private readonly expirationTimeInSeconds = 60;

  private readonly itemName = 'formSubmissions';

  private readonly dateSeparator = ' ';

  public getRecentSubmissionsCount(): number {
    return this.getRecentSubmissionsDates().length;
  }

  public reportFormSubmission(): void {
    const recentDates = this.getRecentSubmissionsDates().concat(this.getTime());
    const newValue = recentDates
      .map((date) => date.toISOString())
      .join(this.dateSeparator);
    localStorage.setItem(this.itemName, newValue);
  }

  private getRecentSubmissionsDates(): Date[] {
    const now = this.getTime();
    const item = localStorage.getItem(this.itemName);

    return item == null ? [] : item.split(this.dateSeparator)
      .map((dateStr) => new Date(dateStr))
      .filter((date) => now.getTime() - date.getTime() < this.expirationTimeInSeconds * 1000);
  }
}
