import React from 'react'
import classnames from 'classnames/bind'
import Icon from '../../../lib/Icon'
import StyledLink from '../../../lib/StyledLink'
import * as styles from './NavigationStandardSubMenu.module.scss'
import { NavigationSubMenuProps } from '../NavigationLink/NavigationLink'


const cx = classnames.bind(styles)

export const NavigationStandardSubMenu = ({
  itemLink,
  itemKey,
  expandedSubmenuKey,
  handleExpandedMenu,
  linkType,
  handleCloseBtnClick,
  labels,
}: NavigationSubMenuProps) => {
  const childrenLinks = itemLink.childLinks || []

  return (
    <div
      className={cx({
        standardSubmenu: true,
        showSubmenu: expandedSubmenuKey === itemKey,
        menuPrimaryDropdown: linkType === 'primary-nav',
        menuSecondaryDropdown: linkType === 'secondary-nav',
      })}
      id={itemKey}
      aria-hidden={expandedSubmenuKey !== itemKey}
    >
      <div className={cx('subMenuLinksHeader')}>
        <button
          aria-label={labels.AriaMobileBackToNavigation}
          className={cx('goBackButton')}
          onClick={() => {
            handleExpandedMenu(itemKey)
          }}
        >
          <Icon
            className={cx('goBackButtonIcon')}
            type="chevronLeft"
          />
          {labels.MobileBackToMainNavigation}
        </button>
        <div className={cx('subMenuLinksHeaderTitle')}>
          <StyledLink
            type="linkHeavy"
            data={itemLink}
          />
        </div>
        <button
          aria-label="Close Navigation Item Dropdown"
          className={cx('subMenuCloseButton')}
          onClick={handleCloseBtnClick}
        >
          <Icon type="close" />
        </button>
      </div>

      <ul className={cx('subMenuLinks', 'util__reset-list')}>
        {childrenLinks.map((childLink, childIndex) => {
          return (
            <li
              className={cx('subMenuLinksItem')}
              key={`submenu-${childIndex}`}
            >
              <StyledLink
                type={childLink.openLinkInNewWindow ? 'linkExternal' : 'link'}
                hideIcon={
                  (linkType === 'secondary-nav' ||
                    linkType === 'primary-nav') &&
                  !childLink.openLinkInNewWindow
                }
                data={childLink}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
