import classnames from 'classnames/bind'
import {
  InsightCardPanel as IInsightCardPanel,
  ILink,
} from '@/shared/MwsViewModels'
import Heading from '../../lib/Heading'
import Icon from '../../lib/Icon'
import ImageComponent from '../../lib/ImageComponent'
import RichText from '../../lib/RichText'
import SectionContainer from '../../lib/SectionContainer'
import StyledLink from '../../lib/StyledLink'
import * as styles from './InsightCardPanel.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface InsightCardPanelProps {
  data: IInsightCardPanel
}

function getIconType(articleType: string) {
  switch (articleType) {
    case 'Article':
      return 'stopwatch'
    case 'Video':
      return 'play'
    case 'Podcast':
      return 'podcast'
    default:
      return ''
  }
}

const InsightCardPanel = ({ data }: InsightCardPanelProps) => {
  const insightLink = data?.insightLink

  const metaPipedList = [
    insightLink?.isArchived && 'Archived',
    insightLink?.itemType,
    { duration: insightLink?.duration },
  ].filter(Boolean)

  const hasImage = data?.image?.url
  const linkData = {
    linkText: data?.ctaText,
    linkTitle: data?.title,
    url: insightLink?.url,
    openInNewWindow: insightLink?.openInNewWindow,
  } as ILink

  const iconType = getIconType(insightLink?.itemType)

  return (
    <SectionContainer
      coloursOverride={data?.colours}
      customPaddingWithBackground={true}
      className={cx('cardPanelContainer')}
    >
      <div className={cx('cardPanel')}>
        {hasImage && (
          <div className={cx('cardPanelImage')}>
            <ImageComponent
              className={cx('cardPanelImageGrow')}
              ratio={'1:1'}
              data={data?.image}
            />
          </div>
        )}
        <div className={cx('cardPanelContent')}>
          <div className={cx('cardSpacing')}>
            <Heading
              className="type__h4"
              tag={'H3'}
              text={insightLink?.tileHeading || insightLink?.title}
            />
          </div>
          {data?.tileDescription && (
            <RichText
              className={cx('cardSpacing')}
              copy={data?.tileDescription}
            />
          )}
          <div className={cx('cardSpacing')}>
            <StyledLink
              type="link"
              data={linkData}
            />
          </div>
          <div className={cx('cardPanelMeta')}>
            {insightLink?.date && (
              <span className={cx('cardPanelDate')}>{insightLink?.date}</span>
            )}
            <div className={cx('cardPanelMetaList')}>
              {metaPipedList.map((meta) => {
                if (typeof meta === 'string') {
                  return (
                    <span
                      key={meta}
                      className={cx('cardPanelMetaListItem')}
                    >
                      {meta}
                    </span>
                  )
                }

                if (typeof meta === 'object' && meta?.duration) {
                  return (
                    <span
                      key={'Content Length'}
                      className={cx('cardPanelMetaListItem')}
                    >
                      {iconType && (
                        <Icon
                          type={iconType}
                          className={cx({
                            cardPanelMetaListIcon: true,
                            cardPanelMetaListIconPlay: iconType === 'play',
                            cardPanelMetaListIconPodcast:
                              iconType === 'podcast',
                          })}
                        />
                      )}
                      {meta?.duration}
                    </span>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  )
}

export default InsightCardPanel
