import {
  FundPanelPage as IFundPanelPage,
  IPage,
  PageNavigationPanel as IPageNavigationPanel,
  StrategyPanelPage as IStrategyPanelPage,
} from '@/shared/MwsViewModels';
import { isFundPage, isNavigationPanel, isStrategyPage } from '../../types/typeguards';
import { SelfAttestationData } from './index';

type IAttestationPanelPage = IStrategyPanelPage | IFundPanelPage;

interface AttestationLabels {
  description: string;
  accept: string;
  decline: string;
}

type INavigationPanel = IPageNavigationPanel | undefined;

const isInformationRequestsFundPanelPage = (navigationPanel: INavigationPanel, fundPanelPage: IFundPanelPage) => 
    fundPanelPage?.pageType === 'FundPanelPage' && navigationPanel?.channelName === 'Information Requests';

const populateLabels = (navigationPanel: INavigationPanel, fundPanelPage: IFundPanelPage) => {
  if (isInformationRequestsFundPanelPage(navigationPanel, fundPanelPage)) {
    return {
      description: navigationPanel?.labels?.InformationRequestSelfAttestationDescription || '',
      decline: navigationPanel?.labels?.InformationRequestSelfAttestationDecline || '',
      accept: navigationPanel?.labels?.InformationRequestSelfAttestationAccept || '',
    };
  }

  return {
    description: navigationPanel?.labels?.SelfAttestationDescription || '',
    decline: navigationPanel?.labels?.SelfAttestationDecline || '',
    accept: navigationPanel?.labels?.SelfAttestationAccept || '',
  }
};

const provideLabelsFromDictionary = (pageData: IFundPanelPage) => {
  const navigationPanel = pageData?.panelGroups?.flatMap((group) => group.panels).find(isNavigationPanel);
  return populateLabels(navigationPanel, pageData);
};

export default function getSelfAttestationData(pageData: IPage): SelfAttestationData {
  if (isFundPage(pageData) || isStrategyPage(pageData)) {
    const panelPage: IAttestationPanelPage = pageData as IAttestationPanelPage;
    const labels = provideLabelsFromDictionary(panelPage);
    const breacrumbs = panelPage?.breadcrumbs;

    const [parentLink] = breacrumbs.slice(-2);

    const panelStrategyName = isStrategyPage(pageData)
      ? (panelPage as IStrategyPanelPage)
      : ({} as IStrategyPanelPage);

    const navigationPanel = panelPage?.panelGroups?.flatMap((group) => group.panels).find(isNavigationPanel);

    let pageName: string;

    if (isInformationRequestsFundPanelPage(navigationPanel, panelPage as IFundPanelPage)) {
      pageName = navigationPanel?.labels?.InformationRequestSelfAttestationTitle;
    } else if (isFundPage(pageData)) {
      pageName = panelPage?.fund?.fundName;
    } else {
      pageName = panelStrategyName?.strategyName;
    }

    return {
      showSelfAttestation: panelPage?.showSelfAttestation,
      acceptLabel: labels?.accept,
      declineLabel: labels?.decline,
      description: labels?.description,
      pageType: isFundPage(pageData) ? 'fund' : 'strategy',
      pageName: pageName,
      pageId: panelPage?.id,
      breadcrumbLink: parentLink,
    };
  }
  return null;
}
