import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './multi-block-panel.vue';
import Block from './block/Block';
import * as mws from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
  components: {
    Block,
  },
})
export default class MultiBlockPanel extends Vue {
  @Prop()
  data!: mws.MultiBlockPanel;

  get blocksToDisplay(): mws.Block[] {
    return this.visibleBlocks
      .slice(0, this.numberOfBlocksToDisplay);
  }

  get panelClasses(): { [key: string]: boolean } {
    return {
      [this.multiBlockClass()]: true,
      'content-padding': this.data.hasContentPadding,
    };
  }

  get blockClasses(): { [key: string]: boolean } {
    return {
      [this.multiBlockClass()]: true,
    };
  }

  get numberOfBlocksToDisplay(): number {
    const possibleValues = [8, 6, 4, 3, 2, 1, 0];
    const blockCount = this.visibleBlocks.length;
    const result = this.data.displayAsSmallBlocks
      ? blockCount
      : possibleValues.find((n) => blockCount >= n);
    return result;
  }

  get multiClassValue(): number {
    return this.data.displayAsSmallBlocks ? 8 : this.numberOfBlocksToDisplay;
  }

  get blockHeadingClass(): { [a: string]: boolean } {
    const multiClassValue = this.multiClassValue;
    if (multiClassValue > 2) {
      return { 'small-block-heading': true };
    }
    if (multiClassValue > 1) {
      return { 'medium-block-heading': true };
    }
    return { 'extra-large-block-heading': true, heading: true };
  }

  private multiBlockClass(): string {
    return `multi-block-${this.multiClassValue}`;
  }

  private shouldDisplayBlock(block: mws.Block): boolean {
    return block != null && block.link != null;
  }

  private get visibleBlocks(): mws.Block[] {
    return this.data.blocks
      .filter(this.shouldDisplayBlock);
  }
}
