import React from 'react'
import { EnhancedTextLink } from '@/shared/MwsViewModels'
import StyledButton from '../StyledButton'
import StyledLink from '../StyledLink'

export interface ActiveLinkProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  link: EnhancedTextLink
  isButton: boolean
  isExpanded?: boolean
  callback?: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const ActiveLink = ({
  link,
  isButton = false,
  isExpanded = false,
  callback,
  className,
  ...attributeProps
}: ActiveLinkProps) => {
  const currentPath = window.location.pathname;
  const menuUrl = link?.url
  const isActiveLink = currentPath.startsWith(menuUrl)

  if (isButton) {
    return (
      <StyledButton
        data={link}
        isExpanded={isExpanded}
        callback={callback}
        isActive={isActiveLink}
        type="primaryNavigation"
        className={className}
        {...attributeProps}
      ></StyledButton>
    )
  } else {
    return (
      <StyledLink
        data={link}
        isActive={isActiveLink}
        className={className}
        type="primaryNavigation"
      ></StyledLink>
    )
  }
}

export default ActiveLink
