import { TableColumn, TaggedItem } from '../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './table-cell-content.vue';

@Component({
  mixins: [template],
})
export default class DailyPricesCell extends Vue {
  @Prop()
  cell: TableColumn;

  @Prop()
  linkWithArrow: boolean;

  static get TableUrlItem(): string {
    return "TableUrlItem";
  }

  static get TableTaggedItem(): string {
    return "TableTaggedItem";
  }

  get isUrlItem(): boolean {
    return this.type === DailyPricesCell.TableUrlItem;
  }

  get isTaggedItem(): boolean {
    return this.isUrlItem || this.type === DailyPricesCell.TableTaggedItem;
  }

  get value(): string | TaggedItem {
    return this.cell.value;
  }

  get type(): string {
    return this.cell.originalType;
  }

  isDefined(item): boolean {0
    return typeof(item) !== 'undefined' && item !== null;
  }

  get tag(): TaggedItem {
    return this.cell.value.tag;
  }

  get highlightedTag(): TaggedItem {
    return this.cell.value.highlightedTag;
  }
}
