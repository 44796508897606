import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './panel-group-heading.vue';

@Component({
  mixins: [template],
})
export default class PanelGroupHeading extends Vue {
  @Prop()
  heading: string;

  @Prop()
  border: string;

  get isAbove(): boolean {
    return this.border === 'Above' || this.border === 'Both';
  }

  get isBelow(): boolean {
    return this.border === 'Below' || this.border === 'Both';
  }
}
