import { useCallback, useState } from 'react';
import canUseDOM from '../utils/canUseDOM';
import * as React from 'react';

export default function useExpandCollapse(refElement: React.RefObject<HTMLDivElement>) {
  const [DOMElementExpanded, setDOMElementExpanded] = useState(false);
  const [DOMElementHeight, setDOMElementHeight] = useState<string>('0px');

  function handleUICollapseState(customValue = false) {
    setDOMElementExpanded(customValue);
    elementTransition(customValue);
  }

  function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setDOMElementExpanded(!DOMElementExpanded);
    elementTransition(!DOMElementExpanded);
  }

  function elementTransition(nextDOMElementState: boolean) {
    if (canUseDOM()) {
      if (nextDOMElementState) {
        const expandedHeight = refElement.current!.scrollHeight;

        setDOMElementHeight(`${expandedHeight}px`);
        refElement.current!.addEventListener('transitionend', handleTransitionEndExpand, false);
      } else {
        const expandedHeight = refElement.current!.scrollHeight;

        setDOMElementHeight(`${expandedHeight}px`);

        window.requestAnimationFrame(() => {
          setDOMElementHeight(`${0}px`);
        });
      }
    }
  }

  const handleTransitionEndExpand = useCallback(
    (event: TransitionEvent) => {
      event.preventDefault();

      setDOMElementHeight('auto');

      refElement.current?.removeEventListener('transitionend', handleTransitionEndExpand, false);
    },
    [refElement]
  );

  return {
    handleButtonClick,
    setCollapseState: handleUICollapseState,
    elementHeightValue: DOMElementHeight,
    elementExpandedState: DOMElementExpanded,
  };
}
