import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './image-above.vue';
import CellData from '../../CellData';

@Component({
  mixins: [template],
})
export default class ImageAbove extends Vue {
  @Prop()
  data!: CellData;

  @Prop()
  iconUrl: string;

  @Prop()
  counterText!: string;

  get cellData(): CellData {
    return this.data;
  }
}
