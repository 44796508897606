import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './circular-block-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import CircularBlockItem from './circular-block-item/CircularBlockItem';
import SquareBlockItem from './square-block-item/SquareBlockItem';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    CircularBlockItem,
    SquareBlockItem,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class CircularBlockPanel extends Vue {
  @Prop()
  data!: mws.CircularBlockPanel;

  public get descriptionClasses(): { [key: string]: boolean } {
    const hasSingle = this.data.hasSingleColumnDescription;
    return {
      column100: hasSingle,
      column50: !hasSingle,
    };
  }

  public get displayType(): string {
    return this.data.displayType.toLowerCase();
  }
}
