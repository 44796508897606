import React from 'react';
import classnames from 'classnames/bind';
import * as styles from './Icon.module.scss';
import ArrowLeft from '../../../../assets/images/rebrand-lite/arrow-left.svg'
import Arrow from '../../../../assets/images/rebrand-lite/arrow.svg';
import ChevronDown from '../../../../assets/images/rebrand-lite/chevron-down.svg';
import ChevronLeft from '../../../../assets/images/rebrand-lite/chevron-left.svg'
import ChevronRight from '../../../../assets/images/rebrand-lite/chevron-right.svg';
import ChevronRightThin from '../../../../assets/images/rebrand-lite/chevron-right-thin.svg'
import ChevronUp from '../../../../assets/images/rebrand-lite/chevron-up.svg'
import Close from '../../../../assets/images/rebrand-lite/close-icon.svg';
import Document from '../../../../assets/images/rebrand-lite/document.svg';
import Download from '../../../../assets/images/rebrand-lite/download.svg'
import Play from '../../../../assets/images/rebrand-lite/play.svg'
import Podcast from '../../../../assets/images/rebrand-lite/podcast.svg'
import Search from '../../../../assets/images/rebrand-lite/search.svg';
import Stopwatch from '../../../../assets/images/rebrand-lite/stopwatch.svg'
import LinkExternal from '../../../../assets/images/rebrand-lite/link-external.svg'

const cx = classnames.bind(styles);

const IconMapping = {
  arrow: Arrow,
  arrowLeft: ArrowLeft,
  document: Document,
  chevronRight: ChevronRight,
  chevronRightThin: ChevronRightThin,
  chevronLeft: ChevronLeft,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  close: Close,
  search: Search,
  stopwatch: Stopwatch,
  play: Play,
  podcast: Podcast,
  download: Download,
  linkExternal: LinkExternal
} as const

export type IconOptions = keyof typeof IconMapping

export interface IconProps {
  /**
   * Description of prop. This will display in Storybook
   */
  sampleTextProp?: string;
  className?: string;
  type: IconOptions;
}

const Icon = ({ type, className = '' }: IconProps) => {
  const ElementIcon = type && IconMapping[type];

  if (!ElementIcon) {
    console.warn('Icon Not Found');
    return null;
  }

  const iconsWithStroke = [
    'chevronRight',
    'chevronRightThin',
    'chevronLeft',
    'chevronDown',
    'chevronUp',
    'podcast',
    'search',
    'play'
  ]

  return (
    <ElementIcon
      role="presentation"
      aria-label={`${type} icon`}
      className={cx({
        svgIcon: true,
        [className]: true,
        strokeSVG: iconsWithStroke.includes(type),
        fillSVG: !iconsWithStroke.includes(type),
      })}
    ></ElementIcon>
  );
};

export default Icon;
