import { Component, Vue } from 'vue-property-decorator';
import template from './warning-popup.vue';
import * as mws from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
})
export default class WarningPopup extends Vue {
  get message(): mws.DrMessage {
    return <mws.DrMessage> this.$store.getters.pageData.drMessage;
  }

  get isAnyMessage(): boolean {
    return this.message != null;
  }

  public get isOpen(): boolean {
    return this.$store.state.popups.warningPopup.isOpen;
  }

  public get title(): string {
    return this.$store.state.popups.warningPopup.title;
  }

  public get content(): string {
    return this.$store.state.popups.warningPopup.message;
  }

  public closePopup(): void {
    this.$store.dispatch('closeWarningPopup');
  }

  public openMessage(): void {
    this.$store.dispatch('openWarningPopup', {
      title: this.message.heading,
      message: this.message.body,
    });
  }

  mounted(): void {
    this.openMessageOnStart();
  }

  private openMessageOnStart(): void {
    if (this.isAnyMessage) {
      const storedMessageDate = localStorage.getItem('drMessage');

      const messageDate = Date.parse(this.message.lastUpdated) / 1000;

      if (!storedMessageDate || messageDate > parseInt(storedMessageDate, 10)) {
        this.openMessage();

        localStorage.setItem('drMessage', messageDate.toString());
      }
    }
  }
}
