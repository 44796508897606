import * as mws from '../../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './canada-performance-and-portfolio-items.vue';
import CanadaPerformanceAndPortfolioTable from '../canada-performance-and-portfolio-table/CanadaPerformanceAndPortfolioTable';
import CanadaPerformanceAndPortfolioBarChart from '../canada-performance-and-portfolio-bar-chart/CanadaPerformanceAndPortfolioBarChart';
import CanadaPerformanceAndPortfolioChart from '../canada-performance-and-portfolio-chart/CanadaPerformanceAndPortfolioChart'
import CanadaPerformanceAndPortfolioDistributionsTable from '../canada-performance-and-portfolio-distributions-table/CanadaPerformanceAndPortfolioDistributionsTable';

@Component({
  mixins: [template],
  components: {
      CanadaPerformanceAndPortfolioTable,
      CanadaPerformanceAndPortfolioDistributionsTable,
      CanadaPerformanceAndPortfolioBarChart,
      CanadaPerformanceAndPortfolioChart
  }
})
export default class CanadaPerformanceAndPortfolioItems extends Vue {
  @Prop()
  items: mws.ICanadaPerformanceAndPortfolioItem[];

  @Prop()
  tabIndex: number;
}