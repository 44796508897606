import { ShortlinkNotAvailablePanel as IShortlinkNotAvailablePanel } from '@/shared/MwsViewModels'
import classnames from 'classnames/bind'
import * as styles from './ShortlinkNotAvailablePanel.module.scss'
import React from 'react'
import RichText from '../../lib/RichText'
import Heading from '../../lib/Heading'
import SectionContainer from '../../lib/SectionContainer'
import Icon from '../../lib/Icon'

const cx = classnames.bind(styles)

export interface ShortlinkNotAvailablePanelProps {
  data: IShortlinkNotAvailablePanel
}

const ShortlinkNotAvailablePanel = ({
  data,
}: ShortlinkNotAvailablePanelProps) => {
  const titleText = data.title.replace(
    '{{UserType}}',
    data.userType.toLowerCase()
  )
  const descText = data.description.replace(
    '{{UserType}}',
    data.userType.toLowerCase()
  )

  function handleCtaTextClick() {
    const nav = document.getElementById('audienceMenuToggleBtn')
    if (nav) {
      nav.click()
    }
  }

  return (
    <SectionContainer className={cx('componentPadding', 'shortlink-not-available-container')}>
      <div className={cx('columns')}>
        {titleText && (
          <div className={cx('columnLeft')}>
            <Heading
              text={titleText}
              className={cx('spacing__sm')}
              fontStyleClassName="type__h1-large"
            />
          </div>
        )}
        <div className={cx('columnRight', 'fittedContent')}>
          {descText && (
            <RichText
              size="prominent"
              className={'spacing__sm'}
              copy={descText}
            />
          )}

          <div className={cx('callToActionLink')}>
            <button
              className={cx('linkLarge')}
              onClick={handleCtaTextClick}
            >
              {data.ctaText}
              <Icon
                type="arrow"
                className={cx('arrow-icon', 'generatedIconRight')}
              />
            </button>
          </div>
        </div>
      </div>
    </SectionContainer>
  )
}

export default ShortlinkNotAvailablePanel
