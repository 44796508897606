import { Vue, Component, Prop } from 'vue-property-decorator';
import { EventElement } from '@/shared/MwsViewModels';
import template from './event-listing-item.vue';

@Component({
  mixins: [template],
})
export default class EventListingItem extends Vue {
  @Prop()
  item: EventElement;

  @Prop()
  active: boolean;
}
