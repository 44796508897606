import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './accordion-item.vue';
import * as mws from '../../../shared/MwsViewModels';
import AccordionMixin from '../../../shared/accordion/accordionMixin';

@Component({
    mixins: [template, AccordionMixin]

})

export default class AccordionItem extends Vue {
    @Prop()
    accordionItem: mws.AccordionItem;
    
    get accordionImageUrl(): string {
        return this.accordionItem.image ? this.accordionItem.image.url : '';
      }
  }