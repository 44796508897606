import { ShareClass } from '../../shared/MwsViewModels';
import Vue from 'vue';
import { Module } from 'vuex';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    ucitShareClass: {} as ShareClass,
    ucitChoiceAndChargesSelectedDate: "" as string,
    ucitPerformancePanelOverridingCurrency: "" as string
  },
  actions: {
    setUcitShareClass({ commit }, shareClass) {
      commit('setUcitShareClass', shareClass);
    },
    setUcitChoiceAndChargesSelectedDate({ commit }, selectedDate) {
      commit('setUcitChoiceAndChargesSelectedDate', selectedDate);
    },
    setUcitPerformancePanelOverridingCurrency({ commit }, overridingCurrency) {
      commit('setUcitPerformancePanelOverridingCurrency', overridingCurrency);
    }
  },
  getters: {
    ucitShareClass: (state): ShareClass => state.ucitShareClass,
    ucitChoiceAndChargesSelectedDate: (state): string => state.ucitChoiceAndChargesSelectedDate,
    ucitPerformancePanelOverridingCurrency: (state): string => state.ucitPerformancePanelOverridingCurrency,
  },
  mutations: {
    setUcitShareClass(state, shareClass) {
      Vue.set(state, 'ucitShareClass', shareClass);
    },
    setUcitChoiceAndChargesSelectedDate(state, selectedDate) {
      Vue.set(state, 'ucitChoiceAndChargesSelectedDate', selectedDate);
    },
    setUcitPerformancePanelOverridingCurrency(state, overridingCurrency) {
      Vue.set(state, 'ucitPerformancePanelOverridingCurrency', overridingCurrency);
    }
  }
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
