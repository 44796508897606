import { Vue, Component, Prop } from 'vue-property-decorator';
import { Person } from '@/shared/MwsViewModels';
import template from './person-details.vue';

@Component({
  mixins: [template],
})
export default class PersonDetails extends Vue {
  @Prop()
  person: Person;

  get name(): string {
    return this.person.title ? `${this.person.title} - ${this.person.name}` : this.person.name;
  }
}
