import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './canada-performance-and-portfolio-panel.vue'
import * as mws from '../../../shared/MwsViewModels';
import FadeTransitionMixin from '../../../shared/fade-transition/FadeTransitionMixin';
import CanadaPerformanceAndPortfolioItems from './canada-performance-and-portfolio-items/CanadaPerformanceAndPortfolioItems';
import loadCldrPromise from '../../../assets/scripts/utilities/intialise';
import * as highcharts from 'highcharts';
import * as stockHighcharts from 'highcharts/highstock';
import createFundBarChartOptions from '../../../shared/charts/BarChartOptions';
import { createLineChartOptions } from '../../../assets/scripts/charting/options/lineChartOptions';
import FundPerformanceChart from '../fund-performance-chart/FundPerformanceChart';
import { TabLineChart } from '../../../shared/charts/TabLineChart';
import { TabBarChart } from '../../../shared/charts/TabBarChart';

@Component({
  mixins: [template],
  components: {
    CanadaPerformanceAndPortfolioItems
  }
})
export default class CanadaPerformanceAndPortfolioPanel extends Mixins(FadeTransitionMixin) {
  @Prop()
  data: mws.CanadaPerformanceAndPortfolioPanel;

  currentTableIndex = 0;
  private tabsBarCharts: TabBarChart[] = [];
  private tabsLineCharts: TabLineChart[] = [];
  private cldrLoaded = false;

  async onTabClick(index: number): Promise<void> {
    if (index === this.currentTableIndex) {
      return;
    }

    const tableList = this.$refs.tableList as HTMLElement;
    const currentlySelectedTable = tableList.children[this.currentTableIndex] as HTMLElement;
    const newSelectedTable = tableList.children[index] as HTMLElement;

    await this.fadeHide(currentlySelectedTable);
    this.currentTableIndex = index;
    await this.fadeShow(newSelectedTable);

    if (this.cldrLoaded) {
        this.createLineChartsForCurrentTab();
        this.createBarChartsForCurrentTab(); 
    } else {
      loadCldrPromise().done(() => {
        if (!this.cldrLoaded) {
          this.createLineChartsForCurrentTab();
          this.createBarChartsForCurrentTab();
  
          this.cldrLoaded = true;
        }
      });
    }
  }

  mounted() {
    if (this.data && this.data.tabs) {
      this.data.tabs.forEach((tab, tabIndex) => {
        tab.items.forEach((item, itemIndex) => {
            if (item.type === 'CanadaPerformanceAndPortfolioBarChart') {
              this.tabsBarCharts.push(
                {
                  tabIndex: tabIndex,
                  barChartItemIndex: itemIndex,
                  barChart: (item as mws.CanadaPerformanceAndPortfolioBarChart).barChart
                });
            }
  
            if (item.type === 'CanadaPerformanceAndPortfolioChart') {
              this.tabsLineCharts.push(
                {
                  tabIndex: tabIndex,
                  lineChartItemIndex: itemIndex,
                  series: (item as mws.CanadaPerformanceAndPortfolioChart).series
                });
            }
        });
      });
    }

    document.addEventListener('DOMContentLoaded', () => {
      if (this.data && this.data.tabs) {
        loadCldrPromise().done(() => {
          if (!this.cldrLoaded) {
            this.createLineChartsForCurrentTab();
            this.createBarChartsForCurrentTab();
    
            this.cldrLoaded = true;
          }
        });
      }
    });

    window.addEventListener('resize', () => {
      if (this.data && this.data.tabs) {
        this.reflowCurrentTabBarCharts();
        this.reflowCurrentTabLineCharts();
      }
    });
  }
  
  private createLineChartsForCurrentTab() {
    this.data.tabs[this.currentTableIndex].items.forEach((item, itemIndex) => {
      let lineChartItem = this.tabsLineCharts.find(x => x.tabIndex === this.currentTableIndex && x.lineChartItemIndex === itemIndex && !x.created);
      if (lineChartItem) {
        const elementSelector = `line-chart-container${this.currentTableIndex}_${itemIndex}`;
        const highchartsSeries = FundPerformanceChart.mapToHighstockDataSeries(lineChartItem.series);
        let lineChart = stockHighcharts.stockChart(elementSelector, createLineChartOptions(highchartsSeries, "isCanadaFund"));
        if (lineChart) {
          lineChartItem.created = true;
          lineChartItem.highChartObj = lineChart;
        }
      }
  })
  }

  private createBarChartsForCurrentTab() {
    this.data.tabs[this.currentTableIndex].items.forEach((item, itemIndex) => {
      let barChartItem = this.tabsBarCharts.find(x => x.tabIndex === this.currentTableIndex && x.barChartItemIndex === itemIndex && !x.created);
      if (barChartItem) {
        const elementSelector = `bar-chart-container${this.currentTableIndex}_${itemIndex}`;
        const chartOptions = createFundBarChartOptions(barChartItem.barChart);
        let barChart = highcharts.chart(elementSelector, chartOptions);
        if (barChart) {
          barChartItem.created = true;
          barChartItem.highChartObj = barChart;
        }
      }
  })
  }

  private reflowCurrentTabBarCharts() {
    this.data.tabs[this.currentTableIndex].items.forEach((item, itemIndex) => {
      let barChartItem = this.tabsBarCharts.find(x => x.tabIndex === this.currentTableIndex && x.barChartItemIndex === itemIndex);
      if (barChartItem && barChartItem.highChartObj.container) {
        barChartItem.highChartObj.reflow();
      }
    })
  }

  private reflowCurrentTabLineCharts() {
    this.data.tabs[this.currentTableIndex].items.forEach((item, itemIndex) => {
      let lineChartItem = this.tabsBarCharts.find(x => x.tabIndex === this.currentTableIndex && x.barChartItemIndex === itemIndex);
      if (lineChartItem && lineChartItem.highChartObj.container) {
        lineChartItem.highChartObj.reflow();
      }
    })
  }
}