import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './manifesto-page-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import GenericLink from '../../../shared/components/link/GenericLink';
import VideoPlayerMixin from '../../../shared/video-player/VideoPlayerMixin';

interface VideoButton {
  url: string;
  text: string;
}

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class ManifestoPagePanel extends Mixins(VideoPlayerMixin) {
  @Prop()
  data: mws.ManifestoPagePanel;

  activeButtonIndex = 0;

  readonly videoButtons: VideoButton[] = [
    {
      url: 'http://video.bailliegifford.com/Active_Vs_Passive_720p.mp4',
      text: 'Actual\ninvestors.',
    }, {
      url: 'http://video.bailliegifford.com/Imaginative_Vs_Computer_720p.mp4',
      text: 'Imaginative\nthinking.',
    }, {
      url: 'http://video.bailliegifford.com/Past_Performance_720p.mp4',
      text: 'Past\nperformance.',
    }, {
      url: 'http://video.bailliegifford.com/The_Market_720p.mp4',
      text: 'What\nmarket?',
    }, {
      url: 'http://video.bailliegifford.com/Were_here_for_our_client_720p.mp4',
      text: 'Our\nclients.',
    },
  ];

  get activeVideoUrl(): string {
    return this.videoButtons[this.activeButtonIndex].url;
  }

  mounted() {
    const videoContainer = this.$refs.videoContainer as HTMLElement;
    this.initialiseVideoPlayer(videoContainer, {
      url: this.activeVideoUrl,
      autostart: false,
      imageUrl: this.data.videoBackgroundImage.url,
      onComplete: () => {
        if (this.activeButtonIndex + 1 < this.videoButtons.length) {
          this.onVideoSelection(this.activeButtonIndex + 1);
        }
      },
    });
  }

  beforeDestroy() {
    this.destroyVideoPlayer();
  }

  onVideoSelection(buttonIndex: number): void {
    this.activeButtonIndex = buttonIndex;
    this.loadVideo();
  }

  private loadVideo(): void {
    this.jwPlayerInstance.load(this.activeVideoUrl);
    this.jwPlayerInstance.play();
  }
}
