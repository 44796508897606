import classnames from 'classnames/bind'
import { SectionHeaderPanel as ISectionHeaderPanel } from '@/shared/MwsViewModels'
import Heading, { HeadingProps } from '../../lib/Heading'
import RichText from '../../lib/RichText'
import SectionContainer from '../../lib/SectionContainer'
import * as styles from './SectionHeaderPanel.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface SectionHeaderPanelProps {
  data: ISectionHeaderPanel
}

const SectionHeaderPanel = ({ data }: SectionHeaderPanelProps) => {
  const mainHeadingInfo: HeadingProps = {
    text: data?.heading || '',
    tag: data?.headingTagType,
  }
  return (
    <SectionContainer
      headerSpacing={true}
      coloursOverride={data?.colours}
    >
      <Heading
        {...mainHeadingInfo}
        className={cx('heading')}
      />
      {data?.description && (
        <RichText
          className={cx('description')}
          copy={data?.description}
        />
      )}
    </SectionContainer>
  )
}

export default SectionHeaderPanel
