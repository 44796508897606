import React from 'react'
import classnames from 'classnames/bind'
import { IntellectualCapitalPanel as IIntellectualCapitalPanel } from '@/shared/MwsViewModels'
import { safeArray } from '../../utils/helpers'
import ArticleCarousel from '../../lib/ArticleCarousel'
import Heading from '../../lib/Heading'
import SectionContainer from '../../lib/SectionContainer'
import StyledLink from '../../lib/StyledLink'
import * as styles from './IntellectualCapitalPanel.module.scss'
const cx = classnames.bind(styles)

export interface IntellectualCapitalPanelProps {
  data: IIntellectualCapitalPanel
}

const IntellectualCapitalPanelRebrandLite = ({ data }: IntellectualCapitalPanelProps) => {
  const heading = data?.heading
  const articles = safeArray(data?.items)
  const insightLink = data?.viewMoreInsightsLink

  const categories = articles.reduce((acc, articleItem) => {
    const themes = safeArray(articleItem?.themes)

    for (const theme of themes) {
      acc.add(theme)
    }

    return acc
  }, new Set<string>())

  return (
    <SectionContainer className={cx('carouselOverflow', 'rebrand-lite-ic-panel')}>
      {heading && (
        <Heading
          tag="H2"
          text={heading}
          className={cx('spacing__heading', 'rebrand-lite-ic-heading')}
        />
      )}
      <ArticleCarousel
        labels={data?.labels}
        categories={Array.from(categories)}
        items={articles}
        showCategories={data.showFilter}
      />
      {insightLink?.url && (
        <div className={cx('spacing__md-top', 'util__center-inline', 'view-more-btn')}>
          <StyledLink
            type="ghost"
            data={insightLink}
          />
        </div>
      )}
    </SectionContainer>
  )
}

export default IntellectualCapitalPanelRebrandLite
