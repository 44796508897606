import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './magazine-grid-with-news-items.vue';
import MagazineSupercard from '../magazine-supercard/MagazineSupercard';

@Component({
    mixins: [template],
    components: {
        MagazineSupercard
      },
})

export default class MagazineGridWithNewsItems extends Vue{
    @Prop()
    insights: mws.ISupercard[];

    @Prop()
    asideItems: mws.CallToActionListLink[];

    @Prop()
    heading: string;

    @Prop()
    displayDivider: boolean;

    mainInsights: mws.ISupercard[] = null;
    otherInsights: mws.ISupercard[] = null;
    
    isLinkNew(link: mws.ImageLink): boolean{
        return link.isLinkLessThanOneMonthOld;  
    }

    mounted() {
        this.mainInsights = this.insights.slice(0, 2);
        this.otherInsights = this.insights.slice(2);
    }
}