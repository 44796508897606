import classnames from 'classnames/bind'
import { Image, Region } from '@/shared/MwsViewModels'
import { safeArray } from '../../../utils/helpers'
import DrawerTransition from '../../DrawerTransition'
import ImageComponent from '../../ImageComponent'
import { IBaseLink } from '../../LinkButtonStyles'
import RadioSelector from '../../RadioSelector'
import StyledButton from '../../StyledButton'
import GlobalLinkButton from '../GlobalLinkButton'
import * as styles from './../AudienceSelector.module.scss'
import * as componentStyles from './RegionSelector.module.scss'
import * as React from 'react'

const cx = classnames.bind(Object.assign({}, styles, componentStyles))

type RegionChoice = Region | undefined

interface IRegionSelector {
  regionGroups: string[]
  labels: { [key: string]: string }
  selectedChannelRegion: RegionChoice
  allRegions: Map<string, Region[]>
  defaultChannelRegion: RegionChoice
  globalLink: string
  selectedRegion: string
  setSelectedRegion: React.Dispatch<React.SetStateAction<string>>
  updateRegionSelection: (region: RegionChoice) => void
  isDrawerOpenState: boolean
  handleDrawerToggle: () => void
  handleGlobalLink: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export default function RegionSelector({
  labels,
  regionGroups,
  selectedChannelRegion,
  defaultChannelRegion,
  allRegions,
  updateRegionSelection,
  globalLink,
  selectedRegion,
  setSelectedRegion,
  isDrawerOpenState,
  handleDrawerToggle,
  handleGlobalLink,
}: IRegionSelector) {
  const regionGroupOptions = safeArray(regionGroups)

  const countriesList =
    allRegions.get(selectedRegion) === undefined
      ? []
      : allRegions.get(selectedRegion)!.filter((regionChoice) => {
          return regionChoice?.name !== defaultChannelRegion?.name
        })

  function handleCountryUpdate(value: string) {
    if (selectedChannelRegion?.name === value) {
      return
    }

    const regionChannel = countriesList.find((regionChoice) => {
      return regionChoice?.name === value
    })

    const defaultRegion =
      value === defaultChannelRegion?.name ? defaultChannelRegion : undefined

    updateRegionSelection(regionChannel || defaultRegion)
  }

  function handleRegionSelection(
    event: React.MouseEvent<HTMLButtonElement>
  ): void {
    const target = event.target as HTMLButtonElement
    const selectedRegionName = target?.getAttribute('data-region') || ''

    setSelectedRegion(selectedRegionName)
  }

  function handleDrawerChange(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    handleDrawerToggle()
  }

  const globalSiteLink: IBaseLink = {
    url: globalLink || '',
    linkText: labels?.viewGlobalSiteCta,
    linkTitle: '',
    openInNewWindow: false,
  }

  const drawerTitle = isDrawerOpenState ? labels?.Hide : labels?.Change

  return (
    <div className={cx('selectorSection', 'selectorSection--region')}>
      <div className={cx('regionDetails', 'audienceContainer')}>
        <h2 className={cx('type__h5', 'selectorSectionHeading')}>
          {'01. ' + labels?.YourLocation}
        </h2>

        <strong
          data-testid="currentRegionLabel"
          className={cx('currentRegionLabel')}
        >
          {selectedChannelRegion?.name}
        </strong>

        <StyledButton
          type="link"
          customIcon={isDrawerOpenState ? 'chevronUp' : 'chevronDown'}
          className={cx({
            regionSelectionToggle: true,
            regionSelectionToggleExpanded: isDrawerOpenState,
          })}
          classNameForIcon={cx('regionSelectionToggleIcon')}
          callback={handleDrawerChange}
        >
          {drawerTitle}
        </StyledButton>
      </div>
      <DrawerTransition isDrawerOpen={isDrawerOpenState}>
        <div
          className={cx({
            regionDrawer: true,
            regionDrawerExpanded: isDrawerOpenState,
          })}
        >
          <div className={cx('audienceContainer')}>
            <h3 className={cx('type__h5', 'regionDrawerTitle')}>
              {labels?.SelectRegion}
            </h3>
            <ul
              role="toolbar"
              aria-controls="country-selections"
              className={cx('regionGroups', 'util__reset-list')}
            >
              {regionGroupOptions.map((regionGroupName) => {
                const isCurrent = regionGroupName === selectedRegion

                return (
                  <li
                    key={regionGroupName}
                    className={cx('regionGroupsOption')}
                  >
                    <StyledButton
                      type="radioSelector"
                      aria-pressed={isCurrent}
                      isActive={isCurrent}
                      callback={handleRegionSelection}
                      data-region={regionGroupName}
                    >
                      {regionGroupName}
                    </StyledButton>
                  </li>
                )
              })}
            </ul>
            <fieldset
              className={cx('regionCountries')}
              aria-live="polite"
              aria-label={labels?.Countries}
              id="country-selections"
            >
              {selectedRegion && (
                <div>
                  <h3 className={cx('type__h5', 'regionDrawerTitle')}>
                    {labels?.SelectCountry}
                  </h3>
                  <ul className={cx('countriesList', 'util__reset-list')}>
                    {selectedRegion ===
                      defaultChannelRegion?.regionGroupName && (
                        <CountryChoiceItem
                          name={defaultChannelRegion?.name}
                          flag={defaultChannelRegion?.flag}
                          isActiveFilter={selectedChannelRegion?.name || ''}
                          onChangeHandler={handleCountryUpdate}
                        />
                      )}
                    {countriesList.map((country) => {
                      return (
                        <CountryChoiceItem
                            name={country?.name}
                            flag={country?.flag}
                            isActiveFilter={selectedChannelRegion?.name || ''}
                            onChangeHandler={handleCountryUpdate}
                        />
                      )
                    })}
                  </ul>
                </div>
              )}
            </fieldset>
            {globalSiteLink?.url && selectedRegion && (
              <div className={cx('spacing__unit-top')}>
                <GlobalLinkButton
                  globalSiteLink={globalSiteLink}
                  promptLabel={labels?.CountryNotListed}
                  buttonClick={handleGlobalLink}
                />
              </div>
            )}
          </div>
        </div>
      </DrawerTransition>
    </div>
  )
}

interface CountryChoiceItemProps {
  name: string
  flag: Image
  isActiveFilter: string
  onChangeHandler: (value: string) => void
}

function CountryChoiceItem({
  name,
  flag,
  isActiveFilter,
  onChangeHandler,
}: CountryChoiceItemProps) {
  return (
    <li className={cx('countriesListItem')}>
      <RadioSelector
        name="country-selection"
        key={name}
        uniqueID={name}
        activeFilter={isActiveFilter}
        className={cx('countryChoiceSelector')}
        classNameActive={cx('countryChoiceSelectorActive')}
        onChangeHandler={onChangeHandler}
      >
        <span className={cx('countryChoice')}>
          {name}
          {flag?.url && (
            <ImageComponent
              data={flag}
              className={cx('countryChoiceFlag')}
              imageClassName={cx('countryChoiceFlagImage')}
            />
          )}
        </span>
      </RadioSelector>
    </li>
  )
}