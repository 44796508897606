import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './accordion-list-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import AccordionItem from './AccordionItem';

@Component({
    mixins: [template],
    components: {
        AccordionItem
      }
})

export default class AccordionPanel extends Vue {
    @Prop()
    data!: mws.AccordionListPanel;
  }