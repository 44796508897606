import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-top-holdings-panel.vue';
import BaseTopHoldings from './base-top-holdings/BaseTopHoldings';

@Component({
  mixins: [template],
  components: {
    BaseTopHoldings,
  },
})
export default class FundTopHoldingsPanel extends Vue {
  @Prop()
  data: mws.IFundTopHoldingsPanel;
}
