import React from 'react'
import { EnhancedTextLink } from '@/shared/MwsViewModels'
import ActiveLink from '../../../lib/ActiveLink'

export type LinkType = 'primary-nav' | 'secondary-nav'

export interface NavigationSubMenuProps {
    itemLink: EnhancedTextLink
    itemKey: string
    expandedSubmenuKey: string
    handleExpandedMenu: (menukey: string) => void
    handleCloseBtnClick?: () => void
    linkType: LinkType
    labels: { [key: string]: string }
  }

interface NavigationLinkProps extends NavigationSubMenuProps {
    renderSubMenu: (props: NavigationSubMenuProps) => JSX.Element
  }
  export const NavigationLink = ({
    renderSubMenu,
    ...subMenuProps
  }: NavigationLinkProps) => {
    const { itemLink, itemKey, expandedSubmenuKey, handleExpandedMenu, labels } =
      subMenuProps
    return (
      <div>
        <ActiveLink
          isButton={true}
          link={itemLink}
          isExpanded={expandedSubmenuKey === itemKey}
          aria-controls={itemKey}
          aria-label={
            expandedSubmenuKey === itemKey
              ? labels.AriaCollapseMegaMenu
              : labels.AriaExpandMegaMenu
          }
          aria-expanded={expandedSubmenuKey === itemKey}
          callback={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
            handleExpandedMenu(itemKey)
          }}
        />
        {renderSubMenu(subMenuProps)}
      </div>
    )
  }
  