import { Vue, Prop, Component } from 'vue-property-decorator';
import template from './discrete-performance.vue';
import * as mws from '../../../../shared/MwsViewModels';
import DisclaimerWrapper from '../disclaimer-wrapper/DisclaimerWrapper';
import PerformanceTableRow from '../performance-table-row';
import getPerformanceRows from '../get-performance-rows';

@Component({
  mixins: [template],
  components: {
    DisclaimerWrapper,
  },
})
export default class DiscretePerformance extends Vue {
  @Prop()
  discretePerformanceTable: mws.Table;

  getDiscretePerformanceRows(): PerformanceTableRow[] {
    return getPerformanceRows(this.discretePerformanceTable);
  }
}
