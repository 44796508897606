import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './kitchen-sink-notes-panel.vue';
import * as mws from '../../../shared/MwsViewModels';

@Component({
    mixins: [template]
})

export default class KitchenSinkNotesPanel extends Vue {
    @Prop()
    data!: mws.KitchenSinkNotesPanel;
}