import { Mixins, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './canada-share-price-panel.vue';
import loadCldrPromise from '../../../assets/scripts/utilities/intialise';
import TabContainer, { TabViewModel } from '../../tab-container/TabContainer';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import LoadingIndicator from '../../../shared/components/loading-indicator/LoadingIndicator';
import { Helpers } from '../../../assets/scripts/utilities/formatHelpers';
import EventBus from '../../../shared/EventBus';

@Component({
  mixins: [template],
  components: {
    TabContainer,
    LoadingIndicator,
  },
})
export default class CanadaSharePricePanel extends Mixins(PostbackMixin) {
  @Prop()
  data: mws.CanadaSharePricePanel;

  private oldSelectedDate: string = null;
  selectedDate: string = null;
  availableDates: { value: string; label: string }[] = [];
  isLoading = false;

  get containerTabs(): TabViewModel[] {
    return this.data.tabs.map((tab) => ({
      heading: tab.tableData.title,
      tableData: tab.tableData,
    }));
  }

  created() {
    this.selectedDate = undefined;
  }

  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      loadCldrPromise().done(() => {
        this.initialiseAvailableDates();
        this.selectedDate = this.availableDates[0].value;
        this.oldSelectedDate = this.selectedDate;
      });
    });

    const shareClassValue = this.data.tabs[0].tableData.title;
    EventBus.$emit('new-share-class', this.data.shareClasses[shareClassValue]);

    EventBus.$on('new-canada-share-class', (newShareClassValue) => {
      EventBus.$emit('new-share-class', this.data.shareClasses[newShareClassValue]);
    });
  }

  async onDateSelected(): Promise<void> {
    const requestedDate = this.selectedDate;
    if (this.oldSelectedDate === requestedDate) {
      return;
    }

    this.oldSelectedDate = requestedDate;
    const postbackData: mws.CanadaSharePricePanelDto = { selectedDate: requestedDate };
    const model = this.createRequestModel(this.data, postbackData);
    try {
      this.isLoading = true;
      const updatedPage = await this.fetchPostback(model);

      if (this.selectedDate === requestedDate) {
        await this.mergePostback(updatedPage);
        this.initialiseAvailableDates();
        this.isLoading = false;
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  private initialiseAvailableDates(): void {
    this.availableDates = this.data.availableDates.map((date: string) => ({
      value: date,
      label: `Date: ${date}`,
    }));
  }
}
