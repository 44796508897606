import React from 'react'
import classnames from 'classnames/bind'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { fetchGlobalTypeahead } from '../../data/fetchGlobalTypeahead'
import useDebounceValue from '../../hooks/useDebounceValue'
import {
  TypeaheadResponse,
  TypeaheadResult,
} from '../../types/GlobalTypeahead'
import { ChildLink } from '@/shared/MwsViewModels'
import { safeArray } from '../../utils/helpers'
import Hint from '../Hint'
import Icon from '../Icon'
import StyledLink from '../StyledLink'
import * as styles from './GlobalTypeahead.module.scss'
import { useRouter } from 'next/router'

const cx = classnames.bind(styles)

const htmlElementID = 'global-typeahead'
const MINIMUM_KEYWORD_LENGTH = 3
const ENTER_KEY = 'Enter'
const ESCAPE_KEY = 'Escape'

// TODO Figure out Search Landing URL When Page is built
const SEARCHURL = ''

export interface GlobalTypeaheadProps
  extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  heading: string
  placeholder: string
  seeAllResultsLabel: string
  clearSearchLabel: string
  hintLabel: string
  noMatchingResultsLabel: string
  isVisible: boolean
  channelLevelPath: string
  handleClose: () => void
}

export type TypeaheadHandlers = {
  focus: () => void
  resetInput: () => void
}

const GlobalTypeahead = forwardRef<TypeaheadHandlers, GlobalTypeaheadProps>(
  (
    {
      className = '',
      heading,
      placeholder,
      seeAllResultsLabel,
      clearSearchLabel,
      hintLabel = 'Enter a Keyword or phrase to search',
      noMatchingResultsLabel = 'No results found matching your search criteria',
      isVisible,
      channelLevelPath,
      handleClose,
      ...restOfAttributes
    },
    ref
  ) => {
    const [keyword, setKeyword] = useState<string>('')
    const [suggestions, setSuggestions] = useState<TypeaheadResult[]>([])
    const [showInputHint, setShowInputHint] = useState<boolean>(false)
    const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const debouncedValue = useDebounceValue(keyword, 250)

    useImperativeHandle(ref, () => ({
      focus() {
        if (inputRef?.current) {
          window.setTimeout(() => {
            inputRef?.current?.focus()
          }, 0)
        }
      },
      resetInput() {
        window.setTimeout(() => resetKeywordInput(), 0)
      },
    }))

    const router = useRouter()
    const urlToRedirect = SEARCHURL
      ? `${SEARCHURL}?keyword=${encodeURIComponent(debouncedValue)}`
      : null

    function redirectToSiteSearchPage() {
      if (keyword && urlToRedirect) {
        resetKeywordInput()
        handleClose()
        router.push(urlToRedirect)
      }
    }

    function handleSubmit(ev: React.SyntheticEvent) {
      ev.preventDefault()
      ev.stopPropagation()

      if (inputRef?.current?.value === '') {
        setShowInputHint(true)
      } else {
        redirectToSiteSearchPage()
      }
    }

    function handleTextChange(ev: React.KeyboardEvent<HTMLInputElement>) {
      ev.preventDefault()

      const keycode = ev.key

      switch (keycode) {
        case ENTER_KEY: {
          if (inputRef?.current?.value === '') {
            setShowInputHint(true)
          } else {
            redirectToSiteSearchPage()
          }
          break
        }
        case ESCAPE_KEY: {
          setShowInputHint(false)
          break
        }
        default: {
          setShowInputHint(false)
          if (inputRef?.current) {
            setKeyword(inputRef.current.value)
          }
          break
        }
      }
    }

    function handleTypeaheadClose() {
      resetKeywordInput()
      handleClose()
    }

    function handleButtonClose(ev: React.MouseEvent<HTMLButtonElement>) {
      ev.preventDefault()
      ev.stopPropagation()
      handleTypeaheadClose()
    }

    function closeHintBox(ev: React.MouseEvent<HTMLButtonElement>) {
      ev.preventDefault()
      ev.stopPropagation()

      setShowInputHint(false)

      if (debouncedValue) {
        resetKeywordInput()
        inputRef?.current?.focus()
      }
    }

    function resetKeywordInput() {
      if (inputRef?.current) {
        inputRef.current.value = ''
      }
      setKeyword('')
      setSuggestions([])
    }

    function handleKeywordClear(ev: React.MouseEvent<HTMLButtonElement>) {
      ev.preventDefault()
      ev.stopPropagation()

      resetKeywordInput()
    }

    const performTypeaheadRequest =
      debouncedValue.length >= MINIMUM_KEYWORD_LENGTH

    useEffect(() => {
      const abortController = new AbortController()
      const signal = abortController.signal

      if (performTypeaheadRequest) {
        setIsLoadingResults(true)
        fetchGlobalTypeahead(debouncedValue, channelLevelPath, { signal })
          .then(async (response) => {
            if (response.status === 200) {
              const data: TypeaheadResponse = await response.json()

              const suggestions = safeArray(data?.results)

              setSuggestions(suggestions)
              setIsLoadingResults(false)
              return
            } else {
              setIsLoadingResults(false)
              throw new Error('Failed to fetch')
            }
          })
          .catch((e) => console.error(e))
      }
      return () => {
        abortController.abort()
      }
    }, [debouncedValue, channelLevelPath, performTypeaheadRequest])

    const showNoResultsMatchingHint =
      performTypeaheadRequest && suggestions.length === 0 && !isLoadingResults

    const hintMessage = showNoResultsMatchingHint
      ? noMatchingResultsLabel
      : hintLabel

    return (
      <div
        id="global-search"
        className={cx({
          typeahead: true,
          showTypeahead: isVisible,
          [className]: className || undefined,
        })}
        {...restOfAttributes}
      >
        <div className={cx('search-header', 'search-section')}>
          {heading && (
            <label
              className={cx('headerLabel', 'type__h5')}
              htmlFor={htmlElementID}
            >
              {heading}
            </label>
          )}
          <button
            onClick={handleButtonClose}
            aria-label="Close Site Search box"
          >
            <Icon type="close" />
          </button>
        </div>
        <div className={cx('inputboxContainer')}>
          <div className={cx('inputbox')}>
            <Icon
              type="search"
              className={cx('searchIcon')}
            />
            <input
              ref={inputRef}
              id={htmlElementID}
              type="search"
              name="keyword"
              onKeyUp={handleTextChange}
              placeholder={placeholder}
              className={cx('input')}
            />
            <button
              aria-label="Perform Search Request"
              className={cx('submitButton')}
              type="submit"
              onClick={handleSubmit}
            >
              <Icon
                className={cx('submitIcon')}
                type="arrow"
              />
            </button>
          </div>
          {(showInputHint || showNoResultsMatchingHint) && (
            <Hint
              // role="alert"
              className={cx('typeaheadHint')}
              message={hintMessage}
              handleCloseButton={closeHintBox}
            />
          )}
        </div>
        {suggestions.length > 0 && (
          <SuggestionsView
            suggestions={suggestions}
            clearSearchLabel={clearSearchLabel}
            seeAllResultsLabel={seeAllResultsLabel}
            seeAllResultsUrl={urlToRedirect}
            handleTypeaheadClose={handleTypeaheadClose}
            handleKeywordClear={handleKeywordClear}
          />
        )}
      </div>
    )
  }
)

interface SuggestionsViewProps {
  clearSearchLabel: string
  seeAllResultsLabel: string
  seeAllResultsUrl: string | null
  handleTypeaheadClose: () => void
  handleKeywordClear: (ev: React.MouseEvent<HTMLButtonElement>) => void
  suggestions: TypeaheadResult[]
}

function SuggestionsView({
  clearSearchLabel,
  seeAllResultsLabel,
  seeAllResultsUrl,
  suggestions,
  handleTypeaheadClose,
  handleKeywordClear,
}: SuggestionsViewProps) {
  const seeAllLink: ChildLink = {
    linkText: seeAllResultsLabel,
    url: seeAllResultsUrl || '',
    openLinkInNewWindow: false,
    linkTitle: '',
  }

  function getDisplayText(suggestion: TypeaheadResult): string {
    if (suggestion?.highlight) {
      return suggestion.highlight;
    }
    return suggestion?.title?.split(' | ', 1)[0];
  }

  return (
    <div className={cx('suggestions', 'search-section')}>
      <button
        onClick={handleKeywordClear}
        className={cx('clearLink', 'type__tag')}
      >
        <Icon
          type="close"
          className={cx('clearLinkIcon')}
        />
        {clearSearchLabel}
      </button>
      <ul className={cx('suggestionsList', 'util__reset-list')}>
        {suggestions.map((suggestion, index) => {
          const suggestionLink: ChildLink = {
            linkText: suggestion.highlight,
            url: suggestion.url,
            openLinkInNewWindow: false,
            linkTitle: '',
          }
          return (
            <li
              onClick={handleTypeaheadClose}
              key={`${suggestion.url}-${index}`}
              className={cx('suggestionItem')}
            >
              {suggestion?.eyebrow && (
                <StyledLink
                  type="text"
                  data={suggestionLink}
                >
                  <span className={cx('suggestionTag', 'type__tag')}>
                    {suggestion.eyebrow}
                  </span>
                </StyledLink>
              )}
              <StyledLink
                className={cx('suggestion')}
                type="linkLight"
                data={suggestionLink}
              >
                <span
                  className={cx('suggestionLabel')}
                  dangerouslySetInnerHTML={{
                    __html: getDisplayText(suggestion)
                  }}
                />
              </StyledLink>
            </li>
          )
        })}
      </ul>

      {seeAllLink?.url && seeAllLink?.linkText && (
        <div className={'spacing__sm-top'}>
          <StyledLink
            data={seeAllLink}
            type="link"
          />
        </div>
      )}
    </div>
  )
}

GlobalTypeahead.displayName = 'GlobalTypeahead'

export default GlobalTypeahead
