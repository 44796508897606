import * as mws from '@/shared/MwsViewModels';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import template from './ic-theme-block.vue';

@Component({
  mixins: [template],
})
export default class IcThemeBlock extends Vue {
  @Prop()
  data: mws.InsightsThemes;
  selectedThemes = [];

  // watch for handling browser navigation
  @Watch('$route')
  onUrlChange(newVal: Route) {
    let urlThemesArr = newVal.query.themes ? newVal.query.themes.toString().split(',') : [];

    const containsAll = (arr1, arr2) => 
                arr2.every(arr2Item => arr1.includes(arr2Item))

    const sameMembers = (arr1, arr2) => 
                        containsAll(arr1, arr2) && containsAll(arr2, arr1);

    if (!sameMembers(urlThemesArr, this.selectedThemes)) {
      this.selectedThemes = urlThemesArr;
      this.emitPayload();
    }
  }

  emit(): void {
    this.emitPayload();
    this.generateQueryStringOnThemeSelectionChange();
  }

  private emitPayload() {
    // remove __ob__ when sending back to api - otherwise the backend thinks it's part of the postback
    // https://stackoverflow.com/a/59610690/12391267
    const selection = JSON.parse(JSON.stringify(Array.from(this.selectedThemes)));
    const payload = <mws.IntellectualCapitalDto>{ themes: selection };

    payload.filterFunds = [];

    if (this.$router.currentRoute.query.funds) {
      var fundsStrArr = this.$router.currentRoute.query.funds.toString().split(',');
      if (fundsStrArr.length > 0) {
        fundsStrArr.forEach(element => {
          payload.filterFunds.push(Number(element));
        });
      }
    }

    this.$emit('select', payload);
  }

  private generateQueryStringOnThemeSelectionChange() {
    this.$router.push({
      query : { 
        themes: this.selectedThemes.length > 0 ? this.selectedThemes.join(',') : undefined, 
        funds: this.$router.currentRoute.query.funds,
        order: this.$router.currentRoute.query.order,
        format: this.$router.currentRoute.query.format
       }
     }).catch(()=>{});
  }

  clearAll(): void {
    this.selectedThemes = [];
    this.emit();
  }

  get allThemes(): { value: string; label: string }[] {
    const themes = this.data?.themes;
    return themes ? Object.entries(themes).map((x) => ({ value: x[0], label: x[1] })) : [];
  }

  mounted() {
    const selectedThemesFromData = this.data?.selectedThemes ? Object.entries(this.data.selectedThemes).map((x) => ({
      value: x[0],
      label: x[1],
    })) : [];

    if (selectedThemesFromData != null) {
      selectedThemesFromData.forEach((item) => {
        if (!this.selectedThemes.includes(item.value)) {
          this.selectedThemes.push(item.value);
        }
      });
    }
  }
}
