/* eslint max-lines: ["error", {"max": 160, "skipBlankLines": true}]*/
import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './panel-page.vue';
import * as mws from '../shared/MwsViewModels';
import PanelIdentifierMixin from '../shared/panel-identification/PanelIdentificationMixin';
import LandingPageHeaderPanel from './components/landing-page-header/LandingPageHeaderPanel';
import ActualInvestorPanel from './components/actual-investor/ActualInvestorPanel';
import ActualAttributesPanel from './components/actual-attributes/ActualAttributesPanel';
import MultiBlockPanel from './components/multi-block/MultiBlockPanel';
import CircularBlockPanel from './components/circular-block/CircularBlockPanel';
import GenericTextPanel from './components/generic-text/GenericTextPanel';
import FullWidthQuotePanel from './components/full-width-quote/FullWidthQuotePanel';
import OneColumnTextImagePanel from './components/one-column-text-image/OneColumnTextImagePanel';
import GradientPanel from './components/gradient/GradientPanel';
import BottomLinkPanel from './components/bottom-link/BottomLinkPanel';
import ChartsPanel from './components/charts/ChartsPanel';
import ContentPageHeaderPanel from './components/content-page-header/ContentPageHeaderPanel';
import ContentPageHeaderImagePanel from './components/content-page-header-image/ContentPageHeaderImagePanel';
import MenuPopup from '../popups/menu/MenuPopup';
import PanelInfoButton from '../debug/panel-info-button/PanelInfoButton';
import LiteratureLibraryOverallPanel from './components/literature-library-overall/LiteratureLibraryOverallPanel';
import CarouselPanel from './components/carousel/CarouselPanel';
import ActualInvestor1BPanel from './components/actual-investor-1b/ActualInvestor1BPanel';
import ContactFormPanel from './components/contact-form/ContactFormPanel';
import IntellectualCapitalPanel from './components/intellectual-capital/IntellectualCapitalPanel';
import CalculatorPanel from './components/calculator/CalculatorPanel';
import ContactMapPanel from './components/contact-map/ContactMapPanel';
import AthenaeumPanel from './components/athenaeum/AthenaeumPanel';
import OverviewPanel from './components/overview/OverviewPanel';
import ExpandableContentPanel from './components/expandable-content/ExpandableContentPanel';
import PeoplePanel from './components/people/PeoplePanel';
import FundCentrePanel from './components/fund-centre/FundCentrePanel';
import FundDailyPricesPanel from './components/daily-prices/FundDailyPricesPanel';
import FundDailyPricesShortlistPanel from './components/daily-prices/shortlist/FundDailyPricesShortlistPanel';
import EventsPanel from './components/events/EventsPanel';
import TextWithBackgroundPanel from './components/text-with-background/TextWithBackgroundPanel';
import TopHoldingsPanel from './components/top-holdings/TopHoldingsPanel';
import CoreFunctionsPanel from './components/core-functions/CoreFunctionsPanel';
import EventListingPanel from './components/event-listing/EventListingPanel';
import PageNavigationPanel from './components/page-navigation/PageNavigationPanel';
import ContentPageVideoPanel from './components/content-page-video/ContentPageVideoPanel';
import CallToActionPanel from './components/call-to-action/CallToActionPanel';
import FullWidthImagePanel from './components/full-width-image/FullWidthImagePanel';
import HtmlScriptTagPanel from './components/html-script-tag/HtmlScriptTagPanel';
import ManifestoPagePanel from './components/manifesto-page/ManifestoPagePanel';
import OneColumnAwardListingPanel from './components/one-column-award-listing/OneColumnAwardListingPanel';
import TextWithImageBackgroundPanel from './components/text-with-image-background/TextWithImageBackgroundPanel';
import LandingPageVideoPanel from './components/landing-page-video/LandingPageVideoPanel';
import ManualTablePanel from './components/manual-table/ManualTablePanel';
import MarketoFormPanel from './components/marketo-form-panel/MarketoFormPanel';
import TrustMagazineCtaPanel from './components/trust-magazine-cta/TrustMagazineCtaPanel';
import AthenaeumHistoryPanel from './components/athenaeum/athenaeum-history/athenaeum-history-panel/AthenaeumHistoryPanel';
import AthenaeumCulturePanel from './components/athenaeum//athenaeum-culture/athenaeum-culture-panel/AthenaeumCulturePanel';
import ActualInvestorLandingPanel from './components/actual/actual-investor-landing/ActualInvestorLandingPanel';
import ActualInvestorInsightsPanel from './components/actual/actual-investor-insights/ActualInvestorInsightsPanel';
import ActualInvestorRelatedContentPanel from './components/actual/actual-investor-related-content/ActualInvestorRelatedContentPanel';
import ActualInvestorInnerPageVideoPanel from './components/actual/actual-investor-inner-page-video/ActualInvestorInnerPageVideoPanel';
import ActualInvestorInnerPageBottomPanel from './components/actual/actual-investor-inner-page-bottom/ActualInvestorInnerPageBottomPanel';
import TrustMagazineFormPanel from '../panel/components/trust-magazine-form/TrustMagazineFormPanel';
import ExceptionInPanel from '../shared/exception-in-panel/ExceptionInPanel';
import AthenaeumPositivePanel from './components/athenaeum/athenaeum-positive/athenaeum-positive-panel/AthenaeumPositivePanel';
import SingleVideoPanel from './components/single-video/SingleVideoPanel';
import SocialSharingPanel from './components/social-sharing/SocialSharingPanel';
import ArticleSeriesNavigationPanel from './components/article-series-navigation/ArticleSeriesNavigationPanel';
import CallToActionListPanel from './components/call-to-action/cta-list/CallToActionListPanel';
import PortfolioCardsPanel from './components/portofio-cards/PortfolioCardsPanel';
import AccordionListPanel from './components/accordion/AccordionListPanel';
import KitchenSinkNotesPanel from './components/kitchen-sink-notes/KitchenSinkNotesPanel';
import BackgroundPanel from '../homepage/components/background-panel/BackgroundPanel';
import MagazineGenericTextPanel from './components/generic-text/magazine-generic-text/MagazineGenericTextPanel';
import MagazineCallToActionPanel from './components/call-to-action/magazine-call-to-action/MagazineCallToActionPanel';
import MagazineFooterPanel from './components/footer/magazine-footer/MagazineFooterPanel';
import MagazineContentPageHeaderPanel from './components/content-page-header/magazine-content-page-header/MagazineContentPageHeaderPanel';
import MagazinePageNavigationPanel from './components/page-navigation/magazine-page-navigation/MagazinePageNavigationPanel';
import MagazineGridPanel from './components/grid/magazine-grid/MagazineGridPanel';
import SectionHeaderPanel from './components/section-header/SectionHeaderPanel';
import NotificationPanel from './components/notification-panel/NotificationPanel';
import InsightCardPanel from './components/insight-card-panel/InsightCardPanel';
import GridPanel from './components/grid/GridPanel';

@Component({
  mixins: [template],
  components: {
    PanelInfoButton,
    IntellectualCapitalPanel,
    ActualInvestor1BPanel,
    ContactFormPanel,
    CarouselPanel,
    LiteratureLibraryOverallPanel,
    MenuPopup,
    LandingPageHeaderPanel,
    ActualInvestorPanel,
    MultiBlockPanel,
    CircularBlockPanel,
    GenericTextPanel,
    FullWidthQuotePanel,
    OneColumnTextImagePanel,
    GradientPanel,
    BottomLinkPanel,
    ChartsPanel,
    ContentPageHeaderPanel,
    ContentPageHeaderImagePanel,
    CalculatorPanel,
    ContactMapPanel,
    AthenaeumPanel,
    OverviewPanel,
    ExpandableContentPanel,
    PeoplePanel,
    FundCentrePanel,
    EventsPanel,
    FundDailyPricesPanel,
    FundDailyPricesShortlistPanel,
    TextWithBackgroundPanel,
    TopHoldingsPanel,
    CoreFunctionsPanel,
    EventListingPanel,
    PageNavigationPanel,
    ContentPageVideoPanel,
    CallToActionPanel,
    FullWidthImagePanel,
    HtmlScriptTagPanel,
    ManifestoPagePanel,
    OneColumnAwardListingPanel,
    TextWithImageBackgroundPanel,
    LandingPageVideoPanel,
    ManualTablePanel,
    MarketoFormPanel,
    TrustMagazineCtaPanel,
    AthenaeumHistoryPanel,
    AthenaeumCulturePanel,
    AthenaeumPositivePanel,
    ActualInvestorLandingPanel,
    ActualInvestorInsightsPanel,
    ActualInvestorRelatedContentPanel,
    ActualInvestorInnerPageVideoPanel,
    ActualInvestorInnerPageBottomPanel,
    TrustMagazineFormPanel,
    ExceptionInPanel,
    SingleVideoPanel,
    ActualAttributesPanel,
    SocialSharingPanel,
    ArticleSeriesNavigationPanel,
    CallToActionListPanel,
    PortfolioCardsPanel,
    AccordionListPanel,
    KitchenSinkNotesPanel,
    BackgroundPanel,
    MagazineGenericTextPanel,
    MagazineCallToActionPanel,
    MagazineFooterPanel,
    MagazineContentPageHeaderPanel,
    MagazinePageNavigationPanel,
    MagazineGridPanel,
    SectionHeaderPanel,
    NotificationPanel,
    InsightCardPanel,
    GridPanel,
  },
})
export default class PanelPage extends Mixins(PanelIdentifierMixin) {
  @Prop()
  page!: mws.PanelPage;

  @Prop({ default: false })
  debug: boolean;

  get allPanels(): mws.IPanel[] {
    return this.page.panelGroups.flatMap((group) =>
      group.panels.filter((x) => x.panelName !== 'FooterWithImagesAndLinksPanel')
    );
  }

  mounted() {
    this.decorateHeaderPanels();
  }

  private decorateHeaderPanels(): void {
    const panelList = this.$refs.panelList as HTMLElement;
    const liItems = Array.from(panelList.children);
    liItems.forEach((liItem) => {
      const nodes = Array.from(liItem.children);
      if (nodes.some((node) => node.classList.contains('content-header-panel'))) {
        liItem.classList.add('content-header');
      }
    });
  }
}
