import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import EventInfoElement from './components/EventInfoElement';
import EventsCarousel from './components/EventsCarousel';
import EventDisplayTypes from './EventDisplayTypes';
import template from './events-panel.vue';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    EventInfoElement,
    EventsCarousel,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class EventsPanel extends Vue {
  @Prop()
  data!: mws.EventsPanel;

  get events(): mws.IEventElement[] {
    return this.data.events;
  }

  isCarousel(): boolean {
    return this.displayType === EventDisplayTypes.Carousel;
  }

  isSingleColumn(): boolean {
    return this.displayType === EventDisplayTypes.SingleColumn;
  }

  isTwoColumns(): boolean {
    return this.displayType === EventDisplayTypes.TwoColumns;
  }

  private get displayType(): string {
    return this.data.displayType.replace(/\s+/g, '');
  }
}
