import { Component, Vue, Prop } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import template from './people-panel.vue';
import { PeoplePanel as Model } from '../../../shared/MwsViewModels';
import PersonDetails from './components/PersonDetails';
import carouselSettings from './PeopleSlideCarouselSettings';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    VueSlickCarousel,
    PersonDetails,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class PeoplePanel extends Vue {
  @Prop()
  data: Model;

  settings = carouselSettings;

  get peopleCount(): number {
    return this.data.people.length;
  }

  get carouselAdditionalCssClass(): string {
    return this.peopleCount === 1 ? 'single-page' : '';
  }
}
