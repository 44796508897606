import { Vue, Component } from 'vue-property-decorator';

@Component
export default class IcFilteringMixin extends Vue {
    private readonly insightsSessionFilterCookieName: string = 'bg_insights_session_filter';

    public getIcHomeQuerystringFromCookie(): string {
        try {
            var cookieObj = this.getIcHomeQuerystringCookieValue();
            this.removeIcHomeQuerystringFromCookie();

            let qs = Object.keys(cookieObj).map(function (key) {
                return key + '=' + cookieObj[key]
            }).join('&');

            if (qs) {
                return `?${qs}`;
            }

        } catch (error) {
            return '';
        }

        return '';
    }

    public setIcHomeQuerystringCookie(value: string) {
        this.$cookies.set(this.insightsSessionFilterCookieName, value);
    }

    private removeIcHomeQuerystringFromCookie() {
        this.$cookies.remove(this.insightsSessionFilterCookieName);
    }

    private getIcHomeQuerystringCookieValue() {
        return this.$cookies.get(this.insightsSessionFilterCookieName);
    }
}
