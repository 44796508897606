import * as consts from './models/Consts';
import { FundFilterColumnModel } from './models/FundFilterColumnModel';

type ChoicesType = {[key: string]: string};

export default class Data {

  static defaultKey = 'NotSet';

  static investmentStyleData(isMobile: boolean, choices: ChoicesType, selectedKey: string): FundFilterColumnModel {
    const items = Object.keys(choices)
      .map((key) => ({ enumId: consts.investmentStyleId, key, value: choices[key] }));
    return {
      columnCss: '',
      header: 'Investment Style',
      internalColumnsNumber: 1,
      items: [items],
      isMobile,
      selectedItemKey: selectedKey || Data.defaultKey,
    };
  }

  static assetClassData(isMobile: boolean, choices: ChoicesType, selectedKey: string): FundFilterColumnModel {
    const items = Object.keys(choices)
      .map((key) => ({ enumId: consts.assetClassId, key, value: choices[key] }));
    return {
      columnCss: '',
      header: 'Asset Class',
      internalColumnsNumber: 1,
      items: [items],
      isMobile,
      selectedItemKey: selectedKey || Data.defaultKey,
    };
  }

  static fundTypeData(isMobile: boolean, choices: ChoicesType, selectedKey: string): FundFilterColumnModel {
    const items = Object.keys(choices)
      .map((key) => ({ enumId: consts.fundTypeId, key, value: choices[key] }));
    return {
      columnCss: '',
      header: 'Fund Type',
      internalColumnsNumber: 1,
      items: [items],
      isMobile,
      selectedItemKey: selectedKey || Data.defaultKey,
    };
  }

  static geographicRegionData(isMobile: boolean, choices: ChoicesType, selectedKey: string): FundFilterColumnModel {
    const enumItems = Object.keys(choices)
      .map((key) => ({ enumId: consts.geographicRegionId, key, value: choices[key] }));
    const items = consts.splitIntoColumns(enumItems);

    return {
      columnCss: 'geographic-list',
      header: 'Geographic Region',
      internalColumnsNumber: items.length,
      items,
      isMobile,
      selectedItemKey: selectedKey || Data.defaultKey,
    };
  }
}
