import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';
import template from './ucit-bar-chart-performance.vue'
import * as mws from '../../../../shared/MwsViewModels';
import FadeTransitionMixin from '../../../../shared/fade-transition/FadeTransitionMixin';
import UcitBarChartPerformanceSet from './ucit-bar-chart-performance-set/UcitBarChartPerformanceSet';
import loadCldrPromise from '../../../../assets/scripts/utilities/intialise';
import * as highcharts from 'highcharts';
import * as ucitBarChartOptions from '../../../../shared/charts/UcitBarChartOptions';
import EventBus from '../../../../shared/EventBus';

@Component({
    mixins: [template],
    components: {
        UcitBarChartPerformanceSet
    }
})
export default class UcitBarChartPerformance extends Mixins(FadeTransitionMixin) {
    @Prop()
    data: mws.UcitBarChartPerformance;

    chartSets: mws.UcitBarChartPerformancePeriod[] = [];
    currentTableTitle: string = '';
    currentTableIndex: number = 0;
    chartTabs: string[] = [];

    mounted() {
        loadCldrPromise().done(() => {
            this.initializeTables();
        });

        EventBus.$on('ucitPerformanceChanged', (isDataAvailable) => {
            if (isDataAvailable) {
                this.initializeTables();
                this.onTabClick(0);
                this.createBarChartsForCurrentTab();
            }
        });

        window.addEventListener('resize', () => {
            this.createBarChartsForCurrentTab(this.currentTableIndex, true);
        });
    }

    onBarChartPerformanceSetLoaded() {
        this.createBarChartsForCurrentTab();
    }

    private initializeTables() {
        this.chartSets = Object.entries(this.data.container.perPeriodType)
        .map((period) =>  {
            return {
                type: period[0],
                dataSet: period[1]
            }
        })

        if (!this.chartSets || this.chartSets.length == 0 || !this.chartSets[0]) {
            return;
        }

        this.currentTableTitle = this.chartSets[0].dataSet.chartHeader;   
        this.chartTabs = this.chartSets.map(x => x.dataSet.labels.timeFrame);
    }

    async onTabClick(index: number): Promise<void> {
        if (index === this.currentTableIndex) {
            return;
        }
        
        this.currentTableTitle = this.chartSets[index].dataSet.chartHeader;    
    
        const tableList = this.$refs.tableList as HTMLElement;
        const currentlySelectedTable = tableList.children[this.currentTableIndex] as HTMLElement;
        const newSelectedTable = tableList.children[index] as HTMLElement;

        this.currentTableIndex = index;
        this.createBarChartsForCurrentTab();

        await this.fadeHide(currentlySelectedTable);
        await this.fadeShow(newSelectedTable);
    }

    private createBarChartsForCurrentTab(index?: number, disableAnimation: boolean = false) {
        if (!this.chartSets || this.chartSets.length == 0) {
            return;
        }
        
        let currentTabSet =  this.chartSets[index ?? this.currentTableIndex];
        let barChartData: mws.BarChart = {
            header: currentTabSet.dataSet.header,
            series: [
                { 
                    color: '#6e2c90',
                    name: currentTabSet.dataSet.labels.data,
                    values: currentTabSet.dataSet.data
                },
                { 
                    color: '#b8b8af',
                    name: currentTabSet.dataSet.labels.benchmark,
                    values: currentTabSet.dataSet.benchmark
                }
            ] 
        }

        const elementSelector = `bar-chart-container${index ?? this.currentTableIndex}`;

        let element = document.getElementById(elementSelector);

        if (element) {
            const containerWidth = ucitBarChartOptions.getUcitPerformanceMainContainerWitdth();
            const chartOptions = ucitBarChartOptions.createUcitFundBarChartOptions(barChartData);
            if (containerWidth > 0) {
                chartOptions.chart.width = containerWidth;
            }
            if (disableAnimation) {
                chartOptions.plotOptions.series.animation = false;
            }

            highcharts.chart(elementSelector, chartOptions);
        }
    }
}