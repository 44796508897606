import { useEffect, useState } from 'react'

function useDebounceValue(value: string, time: number) {
  const [debounceValue, setDebounceValue] = useState<string>(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounceValue(value)
    }, time)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, time])

  return debounceValue
}

export default useDebounceValue
