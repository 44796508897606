import { Capability } from '@/shared/MwsViewModels'
import React from 'react'
import classnames from 'classnames/bind'
import * as styles from './NavigationMegaSubMenu.module.scss'
import { NavigationSubMenuProps } from '../NavigationLink/NavigationLink'
import Icon from '../../../lib/Icon'
import StyledLink from '../../../lib/StyledLink'
import StyledButton from '../../../lib/StyledButton'
import { NavigationMegaSubMenuGroup } from '../NavigationMegaSubMenuGroup'

const cx = classnames.bind(styles)

interface NavigationMegaSubMenuProps extends NavigationSubMenuProps {
    strategiesMegaMenu: Capability[][]
    selectedGroup: Capability[] | null
    setSelectedGroup: React.Dispatch<React.SetStateAction<Capability[] | null>>
    labels: { [key: string]: string }
  }

export const NavigationMegaSubMenu = ({
    itemLink,
    itemKey,
    expandedSubmenuKey,
    handleExpandedMenu,
    handleCloseBtnClick,
    strategiesMegaMenu,
    selectedGroup,
    setSelectedGroup,
    labels,
  }: NavigationMegaSubMenuProps) => {
    const defaultGroup = strategiesMegaMenu[0]
    const visibleGroup = selectedGroup || defaultGroup
  
    return (
      <div
        className={cx({
          megaSubmenu: true,
          showSubmenu: expandedSubmenuKey === itemKey,
        })}
        style={
          { '--number-of-groups': strategiesMegaMenu.length } as React.CSSProperties
        }
        id={itemKey}
        aria-hidden={expandedSubmenuKey !== itemKey}
      >
        <div className={cx('subMenuLinksHeader')}>
          <button
            aria-label="Go Back To Navigation"
            className={cx('goBackButton')}
            onClick={() => {
              handleExpandedMenu(itemKey)
              setSelectedGroup(null)
            }}
          >
            <Icon
              className={cx('goBackButtonIcon')}
              type="chevronLeft"
            />
            Back
          </button>
          <div className={cx('subMenuLinksHeaderTitle')}>
            <StyledLink
              type="linkHeavy"
              data={itemLink}
            >
              {labels['MegaNav.CtaText']}
            </StyledLink>
          </div>
          <button
            aria-label="Close Navigation Item Dropdown"
            className={cx('subMenuCloseButton')}
            onClick={handleCloseBtnClick}
          >
            <Icon type="close" />
          </button>
        </div>
        <div className={cx('subMenuContent')}>
          <div className={cx('tabHeaders')}>
            <ul className={cx('subMenuLinks', 'util__reset-list')}>
              {strategiesMegaMenu.map((group, groupIndex) => {
                const item = group[0]
                return (
                  item && (
                    <li
                      className={cx(
                        'subMenuLinksItem',
                        visibleGroup === group && 'isSelected'
                      )}
                      key={`submenu-${groupIndex}`}
                      onClick={() => {
                        setSelectedGroup(group)
                      }}
                    >
                      <StyledButton
                        type={'text'}
                        className={cx('subMenuLinksItemTitle')}
                        data-testid={`subMenuLinksItemTitle-${item.capabilityStyle}`}
                        aria-selected={visibleGroup === group}
                      >
                        {labels[`MegaNav.${item.capabilityStyle}`]}
                      </StyledButton>
                      <Icon
                        type={'chevronRightThin'}
                        className={cx('subMenuLinksItemIcon')}
                      />
                    </li>
                  )
                )
              })}
            </ul>
          </div>
          <div className={cx('subMenuSubGroups')}>
            {strategiesMegaMenu.map((group, index) => {
              return (
                <div
                  key={index}
                  className={cx({
                    tabPanel: true,
                    isMegaMenuExpanded: expandedSubmenuKey === itemKey,
                    isVisibleInMobile: selectedGroup !== null,
                    isSelected: group === visibleGroup,
                  })}
                >
                  <div
                    className={cx(
                      'subMenuLinksHeader',
                      'subMenuSubGroupLinksHeader'
                    )}
                  >
                    <button
                      aria-label={labels.AriaMobileBackToNavigation}
                      className={cx('goBackButton')}
                      onClick={() => {
                        setSelectedGroup(null)
                      }}
                    >
                      <Icon
                        className={cx('goBackButtonIcon')}
                        type="chevronLeft"
                      />
                      {labels.MobileBackToMainNavigation}
                    </button>
                    <div className={cx('subMenuLinksHeaderGroupTitle')}>
                      {labels[`MegaNav.${visibleGroup[0]?.capabilityStyle}`]}
                    </div>
                    <button
                      aria-label="Close Navigation Item Dropdown"
                      className={cx('subMenuCloseButton')}
                      onClick={handleCloseBtnClick}
                    >
                      <Icon type="close" />
                    </button>
                  </div>
  
                  <NavigationMegaSubMenuGroup group={group} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }