/* eslint max-lines: ["error", {"max": 400, "skipBlankLines": true}]*/
const defaultDictionary = [
  { key: 'announcement_date_header', value: 'Date' },
  { key: 'announcement_date_selector_label', value: 'Please select a date range:' },
  { key: 'announcement_headline_header', value: 'Headline' },
  { key: 'announcement_show_daily_nav', value: 'Show Daily Nav' },
  { key: 'announcement_time_header', value: 'Time' },
  { key: 'announcement_title', value: 'Announcements' },
  { key: 'athenaeum_link_text', value: 'View now' },
  { key: 'carousel_document_download', value: 'Download' },
  { key: 'carousel_document_find_out_more', value: 'Find out more' },
  { key: 'carousel_document_order', value: 'Order' },
  { key: 'carousel_document_read_more', value: 'Read more' },
  {
    key: 'chart_error_message',
    value: 'Unfortunately we were unable to load the chart. Please try again later.',
  },
  { key: 'choices_and_charges_currency_selector_label', value: 'Currency:' },
  { key: 'choices_and_charges_no_table_data_label', value: 'No data for selected criteria' },
  { key: 'competition_form_submit', value: 'Submit' },
  { key: 'competition_form_validation_message', value: 'This field is required.' },
  { key: 'contact_dropdown_contact_selector_placeholder', value: 'Please select:' },
  { key: 'contact_dropdown_email_label', value: 'Email:' },
  { key: 'contact_dropdown_telephone_label', value: 'Telephone:' },
  { key: 'contact_form_email_placeholder', value: 'Email address, required' },
  {
    key: 'contact_form_error_message',
    value: 'Some errors have occurred. Please check your submission below',
  },
  { key: 'contact_form_message_placeholder', value: 'Your message, required' },
  { key: 'contact_form_name_placeholder', value: 'Name, required' },
  { key: 'contact_form_phone_number_placeholder', value: 'Phone number' },
  {
    key: 'contact_form_submission_success_message',
    value: `Thank you for contacting Baillie Gifford. Your enquiry has been received
      and will be passed onto the best person to deal with your request.`,
  },
  { key: 'contact_form_submit', value: 'Submit' },
  { key: 'contact_map_fax_label', value: 'Fax:' },
  { key: 'contact_map_show', value: 'Show on map' },
  { key: 'contact_map_telephone_label', value: 'Telephone:' },
  { key: 'core_functions_title', value: 'Core Functions' },
  {
    key: 'daily_prices_date_selector_label',
    value: ' as at',
  },
  { key: 'daily_prices_export', value: 'Export to Excel' },
  { key: 'daily_prices_fund_selector_label', value: 'Fund Type' },
  { key: 'daily_prices_investment_trust_nav', value: 'NAV Per Share' },
  { key: 'daily_prices_investment_trust_premium_discount', value: 'Premium / Discount (+/-)' },
  { key: 'daily_prices_investment_trust_share_price', value: 'Price Per Share' },
  { key: 'daily_prices_life_company_factsheet', value: 'Factsheet' },
  { key: 'daily_prices_life_company_key_features', value: 'Key Features' },
  { key: 'daily_prices_life_company_pence_price', value: 'Price (p)' },
  { key: 'daily_prices_life_company_percent_change', value: '% Change' },
  {
    key: 'daily_prices_no_prices',
    value: 'There is no price data available for the date selected.',
  },
  { key: 'daily_prices_no_prices_for_search_text', value: 'No results found for' },
  { key: 'daily_prices_oeic_class', value: 'Class' },
  { key: 'daily_prices_oeic_ex_dividend', value: 'XD' },
  { key: 'daily_prices_oeic_income_accumulation', value: 'Inc / Acc' },
  { key: 'daily_prices_oeic_institutional_class', value: 'Class' },
  { key: 'daily_prices_oeic_institutional_income_accumulation', value: 'Inc / Acc' },
  { key: 'daily_prices_oeic_institutional_percent_change', value: '% Change' },
  { key: 'daily_prices_oeic_institutional_price_pence', value: 'Price (p)' },
  { key: 'daily_prices_oeic_institutional_sedol', value: 'SEDOL' },
  { key: 'daily_prices_oeic_percent_change', value: '% Change' },
  { key: 'daily_prices_oeic_price_pence', value: 'Price (p)' },
  { key: 'daily_prices_submit', value: 'Submit' },
  {
    key: 'daily_prices_table_error_message',
    value: 'There was an error loading data, please try again.',
  },
  { key: 'daily_prices_us_mutual_fund_details', value: 'Fund Details' },
  { key: 'daily_prices_us_mutual_month_end', value: 'Month End Performance' },
  { key: 'daily_prices_us_mutual_nav', value: 'NAV' },
  { key: 'daily_prices_us_mutual_percent_change', value: '% Change' },
  { key: 'daily_prices_us_mutual_share', value: 'Share' },
  { key: 'daily_prices_us_mutual_ticker', value: 'Ticker' },
  { key: 'daily_prices_us_mutual_view', value: 'View' },
  { key: 'daily_prices_view_factsheet', value: 'View' },
  { key: 'daily_prices_view_key_features', value: 'View' },
  { key: 'daily_prices_worldwide_fund_class', value: 'Class' },
  { key: 'daily_prices_worldwide_fund_cost_disclosure', value: 'Cost Disclosure' },
  { key: 'daily_prices_worldwide_fund_sfdr_product', value: 'SFDR Product**' },
  { key: 'daily_prices_worldwide_fund_currency', value: 'Currency' },
  { key: 'daily_prices_worldwide_fund_factsheet', value: 'Factsheet' },
  { key: 'daily_prices_worldwide_fund_income_accumulation', value: 'Inc / Acc' },
  { key: 'daily_prices_worldwide_fund_kiid', value: 'KIID' },
  { key: 'daily_prices_worldwide_fund_nav', value: 'NAV Per Share' },
  { key: 'daily_prices_worldwide_fund_percent_change', value: '% Change' },
  { key: 'document_risk_warning_accept', value: 'Continue to the document' },
  { key: 'document_risk_warning_title', value: 'Important Information' },
  { key: 'donut_chart_fund_percent', value: 'Fund %' },
  { key: 'donut_chart_total', value: 'Total' },
  { key: 'dr_message_close', value: 'Close' },
  { key: 'event_listing_details_label', value: 'Details:' },
  { key: 'event_listing_location_label', value: 'Location:' },
  { key: 'event_listing_view_location', value: 'View Location' },
  { key: 'footer_regulatory_information', value: 'Regulatory Information' },
  { key: 'fund_centre_results_block_read_more', value: 'Read more' },
  { key: 'fund_centre_results_no_results', value: 'No results found' },
  { key: 'fund_rating_document_download', value: 'Download' },
  { key: 'fund_selector_close', value: 'Close Fund Filter' },
  { key: 'fund_selector_header', value: 'Use the options below to filter your fund selection' },
  { key: 'fund_selector_open', value: 'Open Fund Filter' },
  { key: 'fund_selector_submit', value: 'Apply Selection' },
  {
    key: 'fund_selector_warning',
    value: `Please remember that the value of your investments and any income from them may go down
      as well as up and you may not get back the amount originally invested.`,
  },
  {
    key: 'global_navigation_secondary_regions_lead_text',
    value: 'If none of the previous options apply, please select Rest of World below:',
  },
  { key: 'header_menu', value: 'MENU' },
  { key: 'header_menu_important_information', value: 'Important Information' },
  { key: 'header_menu_mobile_investor_channel_label', value: 'Selected:' },
  { key: 'header_menu_terms_conditions', value: 'Terms and Conditions' },
  { key: 'header_menu_title', value: 'Navigation' },
  { key: 'homepage_button_seperator', value: 'or' },
  { key: 'homepage_menu', value: 'MENU' },
  { key: 'ic_filter_any_radio_label', value: 'Any' },
  { key: 'ic_filter_article_radio_label', value: 'Article' },
  { key: 'ic_filter_close', value: 'Close Filter' },
  {
    key: 'ic_filter_heading',
    value: 'Filter the content available on this page by selecting the options below',
  },
  { key: 'ic_filter_open', value: 'Open Filter' },
  { key: 'ic_filter_search_button', value: 'Apply Selection' },
  { key: 'ic_filter_search_label', value: 'Search' },
  { key: 'ic_filter_search_placeholder', value: 'type to search' },
  { key: 'ic_filter_video_radio_label', value: 'Video' },
  { key: 'ic_search_default_cta_text', value: 'Read more' },
  { key: 'ic_search_no_results', value: 'No results found' },
  { key: 'it_key_information_active_share', value: 'Active Share' },
  { key: 'it_key_information_agm_label', value: 'AGM:' },
  { key: 'it_key_information_agm_registrar', value: 'AGM and Registrar' },
  { key: 'it_key_information_aic', value: 'AIC Investment Sector' },
  { key: 'it_key_information_assets_heading_first_line', value: 'Total Assets' },
  { key: 'it_key_information_contact_details', value: 'Contact Details' },
  {
    key: 'it_key_information_error_message',
    value: 'There was an error loading data, please try again.',
  },
  { key: 'it_key_information_isin', value: 'ISIN Number' },
  { key: 'it_key_information_launch_date', value: 'Launch Date' },
  { key: 'it_key_information_phone_label', value: 'Phone:' },
  { key: 'it_key_information_registrar_label', value: 'Registrar:' },
  { key: 'it_key_information_sedol', value: 'SEDOL Number' },
  { key: 'it_key_information_shareholders_funds', value: 'Shareholders\' Funds' },
  { key: 'it_key_information_title', value: 'Key Information' },
  { key: 'it_key_information_view_agm_results', value: 'View latest results' },
  {
    key: 'it_share_price_error_message',
    value: 'There was an error loading data, please try again.',
  },
  { key: 'it_share_price_table_nav_book', value: 'NAV at Book' },
  { key: 'it_share_price_table_nav_fair', value: 'NAV at Fair' },
  { key: 'it_share_price_table_ongoing_charges', value: 'Ongoing Charges*' },
  {
    key: 'it_share_price_table_share_premium_discount_at_book',
    value: 'Premium / Discount (+/-) at Book',
  },
  {
    key: 'it_share_price_table_share_premium_discount_at_fair',
    value: 'Premium / Discount (+/-) at Fair',
  },
  { key: 'it_share_price_table_share_price_header', value: 'Share Price' },
  { key: 'it_share_price_title', value: 'Share Price' },
  { key: 'landing_page_header_default_cta_text', value: 'MORE' },
  {
    key: 'literature_form_cannot_post_document',
    value: 'This document cannot be sent by post and is download only',
  },
  {
    key: 'literature_form_country_change_warning',
    value:
      'Please be aware that if you change your country selection you will lose any saved literature items.',
  },
  { key: 'literature_form_download_document', value: 'Download' },
  { key: 'literature_form_heading', value: 'You have {0} saved documents' },
  {
    key: 'literature_form_max_requests_error',
    value: 'A maximum of {0} documents can be ordered at one time',
  },
  { key: 'literature_form_remove_document', value: 'Remove' },
  { key: 'literature_form_submit', value: 'Send Request' },
  { key: 'literature_form_thank_you_heading', value: 'Thank you for your request' },
  {
    key: 'literature_form_thank_you_sub_heading',
    value: 'Postal documents will be dispatched the next working day.',
  },
  { key: 'literature_library_default_search_heading', value: 'Search the {0} Literature Library' },
  { key: 'literature_library_fund_default', value: 'Any Fund' },
  { key: 'literature_library_literature_type_default', value: 'Any Category' },
  { key: 'literature_library_results_download', value: 'Download' },
  { key: 'literature_library_results_no_results', value: 'No documents were found.' },
  { key: 'literature_library_results_order', value: 'Order' },
  { key: 'literature_library_search', value: 'Search' },
  { key: 'literature_library_search_label', value: 'Search By:' },
  { key: 'literature_library_title', value: 'Literature' },
  { key: 'mutual_fund_download', value: 'Download' },
  { key: 'mutual_fund_funds', value: 'FUNDS' },
  { key: 'mutual_fund_portfolio_holdings', value: 'Porfolio Holdings' },
  {
    key: 'mutual_fund_portfolio_holdings_disclaimer',
    value: '* Current portfolio holdings are subject to change.',
  },
  { key: 'mutual_fund_summary_prospectus', value: 'Summary Prospectus' },
  { key: 'mutual_fund_view', value: 'View' },
  { key: 'numbers_eleven', value: 'ELEVEN' },
  { key: 'numbers_fifteen', value: 'FIFTEEN' },
  { key: 'numbers_five', value: 'FIVE' },
  { key: 'numbers_nine', value: 'NINE' },
  { key: 'numbers_one', value: 'ONE' },
  { key: 'numbers_seven', value: 'SEVEN' },
  { key: 'numbers_thirteen', value: 'THIRTEEN' },
  { key: 'numbers_three', value: 'THREE' },
  { key: 'oeic_choice_charges_accumulation', value: 'Acc' },
  { key: 'oeic_choice_charges_annual_charge', value: 'Annual Charge' },
  { key: 'oeic_choice_charges_gross', value: 'Gross' },
  { key: 'oeic_choice_charges_initial_charge', value: 'Initial Charge' },
  { key: 'oeic_choice_charges_isin', value: 'ISIN Number' },
  { key: 'oeic_choice_charges_min_investment', value: 'Min. Investment' },
  { key: 'oeic_choice_charges_min_top_up', value: 'Min. Top Up' },
  { key: 'oeic_choice_charges_net_gross', value: 'Net / Gross' },
  { key: 'oeic_choice_charges_no_min', value: 'no minimum' },
  { key: 'oeic_choice_charges_ongoing_charges', value: 'Ongoing Charges*' },
  { key: 'oeic_choice_charges_sedol', value: 'SEDOL Number' },
  { key: 'oeic_choice_charges_share_class', value: 'Share Class' },
  { key: 'oeic_choice_charges_title', value: 'Investment Choice and Charges' },
  { key: 'oeic_key_information_active_share', value: 'Active Share' },
  { key: 'oeic_key_information_benchmark', value: 'Benchmark' },
  { key: 'oeic_key_information_dealing_in_the_fund', value: 'Dealing in the Fund' },
  { key: 'oeic_key_information_distribution', value: 'Distribution' },
  { key: 'oeic_key_information_ex_dividend_date', value: 'XD Date' },
  { key: 'oeic_key_information_fund_manager_heading', value: 'Fund Manager' },
  {
    key: 'oeic_key_information_investment_association_sector',
    value: 'The Investment Association Sector',
  },
  { key: 'oeic_key_information_launch_date', value: 'Launch Date' },
  { key: 'oeic_key_information_pricing', value: 'Pricing' },
  { key: 'oeic_key_information_title', value: 'Key Information' },
  {
    key: 'oeic_share_price_error_message',
    value: 'There was an error loading data, please try again.',
  },
  { key: 'oeic_share_price_table_class', value: 'Class' },
  { key: 'oeic_share_price_table_distribution_yield', value: 'Distribution Yield' },
  { key: 'oeic_share_price_table_ex_dividend', value: 'XD' },
  { key: 'oeic_share_price_table_gross', value: 'Gross' },
  { key: 'oeic_share_price_table_income_accumulation', value: 'Inc/Acc' },
  { key: 'oeic_share_price_table_net', value: 'Net' },
  { key: 'oeic_share_price_table_net_gross', value: 'Net / Gross' },
  { key: 'oeic_share_price_table_percent_change', value: '% Change' },
  { key: 'oeic_share_price_table_price_pence', value: 'Price(p)' },
  { key: 'oeic_share_price_table_underlying_yield', value: 'Underlying Yield' },
  { key: 'oeic_share_price_table_yield', value: 'Yield' },
  { key: 'oeic_share_price_title_alternate', value: 'Investment Choices and Charges' },
  { key: 'page_risk_warning_remember_choice', value: 'Remember my choice' },
  {
    key: 'performance_data_annual_performance_figures',
    value: 'Annual percentage return (Updated Quarterly)',
  },
  { key: 'performance_data_periodic_figures', value: 'All figures to {0}' },
  { key: 'performance_data_valuation_date_suffix', value: ' each year' },
  { key: 'personal_details_form_country_placeholder', value: 'Please select' },
  { key: 'personal_details_form_find_address', value: 'Find Address' },
  { key: 'personal_details_form_postcode_error_message', value: 'Could not find postcode' },
  { key: 'risk_warning_popup_close', value: 'Close' },
  { key: 'scottish_mortgage_carousel_swipe', value: 'Use Scroll to Explore' },
  { key: 'search_input_placeholder', value: 'Search...' },
  { key: 'search_label', value: 'Type your search below' },
  { key: 'search_title', value: 'Search' },
  { key: 'search_view_result', value: 'View' },
  { key: 'text_with_background_default_cta_text', value: 'Find out more' },
  { key: 'top_holdings_fund_percent', value: 'Fund %' },
  { key: 'top_holdings_not_top_ten_title', value: 'All holdings of 0.7% and above' },
  { key: 'top_holdings_top_ten_button', value: 'Top 10' },
  { key: 'top_holdings_total_label', value: 'Total' },
  { key: 'trust_magazine_default_cta_text', value: 'Find out more' },
  { key: 'trust_magazine_form_submit', value: 'Submit' },
  {
    key: 'ucit_performance_table_error_message',
    value: 'There was an error loading data, please try again.',
  },
  {
    key: 'us_mutual_charges_error_message',
    value: 'There was an error loading data, please try again.',
  },
  { key: 'us_mutual_charges_title', value: 'Charges' },
  { key: 'us_mutual_fund_block_read_more', value: 'Read more' },
  { key: 'us_mutual_fund_class_picking_choose_share_class', value: 'CHOOSE SHARE CLASS' },
  {
    key: 'us_mutual_fund_class_picking_default_title',
    value: 'All figures quoted below are in US dollars, net of fees.',
  },
  {
    key: 'us_mutual_fund_facts_error_message',
    value: 'There was an error loading data, please try again.',
  },
  { key: 'us_mutual_fund_facts_figures', value: 'All figures to {0}' },
  {
    key: 'us_mutual_fund_facts_glossary_text',
    value: 'For a full glossary of definitions, please {0}',
  },
  { key: 'us_mutual_fund_facts_title', value: 'Fund Facts' },
  { key: 'us_mutual_fund_no_results', value: 'No results found' },
  { key: 'us_mutual_institutional', value: 'Institutional' },
  { key: 'us_mutual_k', value: 'K' },
  {
    key: 'us_mutual_portfolio_characteristics_error_message',
    value: 'There was an error loading data, please try again.',
  },
  { key: 'us_mutual_portfolio_characteristics_fund', value: 'Fund' },
  {
    key: 'us_mutual_portfolio_characteristics_glossary_text',
    value: 'For a full glossary of definitions, please {0}',
  },
  { key: 'us_mutual_portfolio_characteristics_title', value: 'Portfolio Characteristics' },
  {
    key: 'us_mutual_share_price_date_selector_label',
    value: 'To view historical data, please select a date',
  },
  {
    key: 'us_mutual_share_price_error_message',
    value: 'There was an error loading data, please try again.',
  },
  { key: 'us_mutual_share_price_table_nav', value: 'NAV' },
  { key: 'us_mutual_share_price_table_percent_change', value: '% Change' },
  { key: 'us_mutual_share_price_table_share', value: 'Share' },
  { key: 'us_mutual_share_price_table_ticker', value: 'Ticker' },
  { key: 'us_mutual_share_price_title', value: 'Share Price' },
  { key: 'usm_annualized_total_returns_benchmark_missing', value: 'n/a' },
  {
    key: 'usm_chart_error_message',
    value: 'Unfortunately we were unable to load the chart. Please try again later.',
  },
  { key: 'usm_top_ten_holdings_asset_price', value: 'Asset Price' },
  { key: 'usm_top_ten_holdings_asset_type', value: 'Asset Type' },
  { key: 'usm_top_ten_holdings_company', value: 'Company' },
  { key: 'usm_top_ten_holdings_country', value: 'Country' },
  { key: 'usm_top_ten_holdings_currency', value: 'Currency' },
  { key: 'usm_top_ten_holdings_download', value: 'Download' },
  { key: 'usm_top_ten_holdings_fund_percent', value: 'Fund %' },
  { key: 'usm_top_ten_holdings_holding', value: 'Holding' },
  { key: 'usm_top_ten_holdings_isin', value: 'ISIN' },
  { key: 'usm_top_ten_holdings_market_value', value: 'Market Value' },
  { key: 'usm_top_ten_holdings_search_placeholder', value: 'Search' },
  { key: 'usm_top_ten_holdings_sedol', value: 'SEDOL' },
  { key: 'usm_top_ten_holdings_show_all', value: 'All' },
  { key: 'usm_top_ten_holdings_show_top_ten', value: 'Top 10' },
  { key: 'usm_top_ten_holdings_total', value: 'Total' },
  {
    key: 'video_popup_error_message',
    value: 'Unfortunately we were unable to load the video. Please try again later.',
  },
  { key: 'video_popup_related_films', value: 'related films' },
  { key: 'video_popup_view_transcript', value: 'View Transcript' },
];

export default defaultDictionary;
