import { BasePage } from '@/shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './page-info-button.vue';
import BaseInfoButton from '../base-info-button/BaseInfoButton';

@Component({
  mixins: [template],
  components: {
    BaseInfoButton,
  },
})
export default class PageInfoButton extends Vue {
  @Prop()
  page: BasePage;
}
