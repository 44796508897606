import { Component, Prop, Mixins } from 'vue-property-decorator';
import { IPanelGroup } from '@/shared/MwsViewModels';
import template from './fund-aside.vue';
import GenericTextPanel from '../../panels/generic-text-panel/GenericTextPanel';
import OeicKeyInformationPanel from '../../panels/oeic-key-information/OeicKeyInformationPanel';
import OeicPeoplePanel from '../../panels/oeic-people/OeicPeoplePanel';
import OeicFundFactsPanel from '../../panels/oeic-fund-facts/OeicFundFactsPanel';
import UcitFundFactsPanel from '../../panels/ucit-fund-facts/UcitFundFactsPanel';
import ItKeyInformationPanel from '../../panels/it-key-information/it-key-information-panel/ItKeyInformationPanel';
import FundSidePeoplePanel from '../../panels/fund-side-people/FundSidePeoplePanel';
import FundSideContactPanel from '../../panels/fund-side-contact/FundSideContactPanel';
import FundSideKeyDocumentsPanel from '../../panels/fund-side-key-documents/FundSideKeyDocumentsPanel';
import FundSideRiskWarningPanel from '../../panels/fund-side-risk-warning/FundSideRiskWarningPanel';
import PanelIdentifierMixin from '../../../shared/panel-identification/PanelIdentificationMixin';
import PanelInfoButton from '../../../debug/panel-info-button/PanelInfoButton';
import FundSideCallToActionPanel from '../../panels/fund-side-call-to-action/FundSideCallToActionPanel';
import FundSideRatingsPanel from '../../panels/fund-side-ratings/FundSideRatingsPanel';
import ItSharePriceAndChargesPanel from '../../panels/it-share-price-and-charges/ItSharePriceAndChargesPanel';
// eslint-disable-next-line max-len
import OeicSharePriceAndChargesPanel from '../../panels/oeic-share-price-and-charges-panel/OeicSharePriceAndChargesPanel';
// eslint-disable-next-line max-len
import UcitChoiceAndChargesPanel from '../../panels/ucit-choice-and-charges/UcitChoiceAndChargesPanel';
import OeicInvestmentChoicesAndChargesPanel from '../../panels/oeic-investment-choices-and-charges-panel/OeicInvestmentChoicesAndChargesPanel';
import UsmSharePricePanel from '../../panels/usm-share-price/UsmSharePricePanel';
import UsmChargesPanel from '../../panels/usm-charges-panel/UsmChargesPanel';
import UsmPortfolioCharacteristicsPanel from '../../panels/usm-portfolio-characteristics/UsmPortfolioCharacteristicsPanel';
import UsmFundFactsPanel from '../../panels/usm-fund-facts/UsmFundFactsPanel';
import FixedAnchor from '../../anchors/fixed-anchor/FixedAnchor';
import FundTextWithBackgroundPanel from '../../panels/fund-text-with-background/FundTextWithBackgroundPanel';
import FundOverviewPanel from '../../panels/fund-overview/FundOverviewPanel';
import ExceptionInPanel from '../../../shared/exception-in-panel/ExceptionInPanel';
import CanadaSharePricePanel from '../../panels/canada-share-price/CanadaSharePricePanel';
import CanadaChargesPanel from '../../panels/canada-charges/CanadaChargesPanel';
import CanadaFundFactsPanel from '../../panels/canada-fund-facts/CanadaFundFactsPanel';
import CanadaPortfolioCharacteristicsPanel from '../../panels/canada-portfolio-characteristics/CanadaPortfolioCharacteristicsPanel';

@Component({
  mixins: [template],
  components: {
    FixedAnchor,
    PanelInfoButton,
    FundOverviewPanel,
    GenericTextPanel,
    OeicKeyInformationPanel,
    OeicPeoplePanel,
    OeicFundFactsPanel,
    UcitFundFactsPanel,
    UsmSharePricePanel,
    UsmChargesPanel,
    UsmPortfolioCharacteristicsPanel,
    ItKeyInformationPanel,
    FundSidePeoplePanel,
    FundSideContactPanel,
    FundSideRatingsPanel,
    FundSideRiskWarningPanel,
    FundSideKeyDocumentsPanel,
    ItSharePriceAndChargesPanel,
    OeicSharePriceAndChargesPanel,
    UcitChoiceAndChargesPanel,
    OeicInvestmentChoicesAndChargesPanel,
    CallToActionPanel: FundSideCallToActionPanel,
    UsmFundFactsPanel,
    FundTextWithBackgroundPanel,
    ExceptionInPanel,
    CanadaSharePricePanel,
    CanadaChargesPanel,
    CanadaFundFactsPanel,
    CanadaPortfolioCharacteristicsPanel
  },
})
export default class FundAside extends Mixins(PanelIdentifierMixin) {
  @Prop()
  groups: IPanelGroup[];

  @Prop({ default: false })
  debug: boolean;
}
