import { Mixins, Component, Prop } from 'vue-property-decorator';
import { CoreFunction } from '@/shared/MwsViewModels';
import template from './core-function-block.vue';
import DynamicTextFit from '../../../../shared/helpers/DynamicTextFit';
// eslint-disable-next-line max-len
import PositionedResponsiveImage from '../../../../shared/components/positioned-responsive-image/PositionedResponsiveImage';

@Component({
  mixins: [template],
  components: {
    PositionedResponsiveImage,
  },
})
export default class CoreFunctionBlock extends Mixins(DynamicTextFit) {
  @Prop()
  item: CoreFunction;

  mounted() {
    this.registerDynamicTextFitEvent(
      this.$refs.parentElement as Element,
      this.$refs.bottomElement as Element,
      this.$refs.textElement as Element,
      this.item.rolloverText,
      50,
    );
  }
}
