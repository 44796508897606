import * as mws from '@/shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './ic-search-block.vue';

@Component({
  mixins: [template],
})
export default class IcSearchBlock extends Vue {
  @Prop()
  data: mws.InsightsSearch;

  @Prop({default: true})
  forDesktop: boolean;

  query = '';

  search(): void {
    this.$emit('select', <mws.IntellectualCapitalDto>{ searchTerm: this.query });
  }

  clear(): void {
    this.query = '';
    this.search();
  }

  get formClass(): string {
    return this.forDesktop ? 
      "bginsights-search-form bginsights-search-form--desktop" :
      "bginsights-search-form";
  }

  get labelId(): string {
    return this.forDesktop ? 
      "search-insights-desktop" :
      "search-insights-mobile";
  }
}
