import { Component, Vue } from 'vue-property-decorator';
import template from './history-subtitles.vue';
import ChapterSubtitles from './chapters-subtitles/subtitle-models/ChapterSubtitles';
import { ChapterOneSubtitles } from './chapters-subtitles/static-chapters-subtitles/ChapterOne';
import { ChapterTwoSubtitles } from './chapters-subtitles/static-chapters-subtitles/ChapterTwo';
import { ChapterThreeSubtitles } from './chapters-subtitles/static-chapters-subtitles/ChapterThree';
import { ChapterFourSubtitles } from './chapters-subtitles/static-chapters-subtitles/ChapterFour';

@Component({
  mixins: [template],
})
export default class HistorySubtitles extends Vue {
  chaptersSubtitles: ChapterSubtitles[] = [
    ChapterOneSubtitles,
    ChapterTwoSubtitles,
    ChapterThreeSubtitles,
    ChapterFourSubtitles,
  ];

  subtitleTextIdPrefix = 'athenaeum-reworked__history-chapter-subtitles__subtitle__text__';

  subtitleLineIdPrefix = 'athenaeum-reworked__history-chapter-subtitles__subtitle__lines__line__';
}
