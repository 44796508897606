import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './notification-panel.vue';
import { ReactInVue } from 'vuera';
import NotificationPanelReact from '../../../react/rebrand-lite/panels/NotificationPanel';

@Component({
  mixins: [template],
  components: {
    NotificationPanelReact: ReactInVue(NotificationPanelReact),
  },
})
export default class NotificationPanel extends Vue {
  @Prop()
  data: mws.INotificationPanel;
}
