import { IInsightPageLink } from '@/shared/MwsViewModels';
import { gsap } from 'gsap/dist/gsap';
import { Component, Prop, Vue } from 'vue-property-decorator';
import InsightsLoadingIndicator from '../../../../shared/components/insights-loading-indicator/InsightsLoadingIndicator';
import IntellectualCapitalItem from '../intellectual-capital-item/IntellectualCapitalItem';
import template from './ic-item-container.vue';

const ItemBatchSize = 15;

@Component({
  mixins: [template],
  components: {
    IntellectualCapitalItem,
    InsightsLoadingIndicator
  },
})
export default class IcItemContainer extends Vue {
  @Prop()
  items: IInsightPageLink[];

  @Prop()
  isLoading: boolean;

  public numberOfItemsToDisplay = ItemBatchSize;
  public jsEnabled = false;

  mounted() {
    this.jsEnabled = true;
  }

  get itemsToRender(): IInsightPageLink[] {
    return this.items
      .slice(0, this.numberOfItemsToDisplay + ItemBatchSize);
  }

  get allItems(): IInsightPageLink[] {
    return this.items;
  }

  get itemProgressPercentage(): number {
    const itemCount = this.items.length;
    const percentage = this.currentlyViewedItemCount < itemCount
      ? this.currentlyViewedItemCount / itemCount
      : 1;
    return Math.round(100 * percentage);
  }

  get currentlyViewedItemCount(): number {
    if (this.jsEnabled) {
      return Math.min(this.numberOfItemsToDisplay, this.items.length);
    }
    return this.items.length;
  }

  isVisible(itemIndex: number): boolean {
    return itemIndex < this.numberOfItemsToDisplay;
  }

  loadMoreInsights(): void {
    const oldNumberOfItemsToDisplay = this.numberOfItemsToDisplay;
    const newNumberOfItemsToDisplay = Math.min(this.numberOfItemsToDisplay + ItemBatchSize, this.items.length);

    this.numberOfItemsToDisplay = newNumberOfItemsToDisplay;

    Vue.nextTick(() => {
      const icItems = this.$refs.itemsRef as IntellectualCapitalItem[];
      for (let i = oldNumberOfItemsToDisplay; i < newNumberOfItemsToDisplay; i++) {
        this.showIcItem(icItems[i]);
      }
    });
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private showIcItem(icItem: IntellectualCapitalItem): void {
    gsap.fromTo(
      icItem.$el,
      { opacity: 0 },
      { opacity: 1, duration: 1, onComplete: () => icItem.fitDescriptionToBlock(), }
    );
  }
}
