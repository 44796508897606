import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '../../../../shared/MwsViewModels';
import template from './call-to-action-list-panel.vue';

@Component({
  mixins: [template],
  components: {
  },
})
export default class CallToActionListPanel extends Vue {
  @Prop()
  data: mws.CallToActionListPanel;

  get linkItems() {
    return this.data.ctaLinks.filter(x => !!x && !!x.link);
  }
}
