import { Mixins, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './base-top-holdings.vue';
import HoldingsTable from '../holdings-table/HoldingsTable';
import HoldingsTableUsm from '../holdings-table-usm/HoldingsTableUsm'
import HoldingsTableCanada from '../holdings-table-canada/HoldingsTableCanada'
import FadeTransitionMixin from '../../../../shared/fade-transition/FadeTransitionMixin';
import axios from 'axios';

@Component({
  mixins: [template],
  components: {
    HoldingsTable,
    HoldingsTableUsm,
    HoldingsTableCanada
  },
})
export default class BaseTopHoldings extends Mixins(FadeTransitionMixin) {
  static readonly topTenHoldingsTableType = "topTenHoldings";
  static readonly allHoldingsTableType = "allHoldings";

  @Prop()
  data: mws.OeicFundTopHoldingsPanel | mws.ItFundTopHoldingsPanel | mws.UsmFundTopHoldingsPanel | mws.CanadaFundTopHoldingsPanel;

  private saintsFundName: string = "Scottish American Investment Company";
  private fullPortfolioButtonText: string = "Full Portfolio ";

  tables: mws.Table[] = [];
  tableTypes: string[] = [];
  currentTableIndex = 0;

  created() {
    if (this.data.topTenHoldings) {
      this.tables.push(this.data.topTenHoldings);
      this.tableTypes.push(BaseTopHoldings.topTenHoldingsTableType);
    }
    if (this.data.allHoldings) {
      this.tables.push(this.data.allHoldings);
      this.tableTypes.push(BaseTopHoldings.allHoldingsTableType);
    }
    this.currentTableIndex = 0;
    this.fullPortfolioButtonText = this.fullPortfolioButtonText + (this.data.fullPortfolioValuation?.name == this.saintsFundName ? "Including Property" : "Valuation");
  }

  async onTabClick(index: number): Promise<void> {
    if (index === this.currentTableIndex) {
      return;
    }

    const tableList = this.$refs.tableList as HTMLElement;
    const currentlySelectedTable = tableList.children[this.currentTableIndex] as HTMLElement;
    const newSelectedTable = tableList.children[index] as HTMLElement;

    await this.fadeHide(currentlySelectedTable);
    this.currentTableIndex = index;
    await this.fadeShow(newSelectedTable);
  }

  get isUsMutualFund(): boolean {
    return this.data.type === 'UsMutualFund';
  }

  get isCanadaFund(): boolean {
    return this.data.type === 'CanadaFund';
  }

  getDownloadUrl(fundId: number) {
    let date = new Date();
    const fileName: string = `Holdings-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const url: string = `/getTopHoldings?fundId=${fundId}&fileName=${fileName}`;
    return url;
  }

  async onDownloadClick(fundId: number): Promise<void> {
    if (!Boolean(fundId)) {
      return;
    }

    let date = new Date();
    const fileName: string = `Holdings-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

    const url: string = `/getTopHoldings?fundId=${fundId}&fileName=${fileName}`;
    await axios.get(url, { responseType: 'arraybuffer' })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
        window.URL.revokeObjectURL(url) // Release blob object
      })
      .catch((err) => console.log(`Failed to download document ${err}`));
  }
}
