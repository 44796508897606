import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './two-indicators.vue';
import CellData from '../../CellData';

@Component({
    mixins: [template],
})
export default class TwoIndicators extends Vue {
    @Prop()
    data!: CellData;

    @Prop()
    iconUrl: string;

    @Prop()
    counterText!: string;

    @Prop()
    secondCounterText!: string;

    get cellData(): CellData {
        return this.data;
    }

    get topTextFirstLineWithCounter() {
        let lineBreakes = this.data.text.split('<br/>');

        let classTxt = 'topTextCounter';

        if (this.counterText.length > 5) {
            classTxt += " small";
        }

        if (lineBreakes.length > 0) {
            let firstLineWithCounter = `<span>${lineBreakes[0]}</span> <span class="${classTxt}">${this.counterText}</span>`;
            let textAfterFirstLineBreak = lineBreakes.slice(1).join('<br/>');

            return `<span>${firstLineWithCounter}</span> <br/>
                    <span>${textAfterFirstLineBreak}</span>`;
        } else {
            return `<span>${this.data.text}</span> <span class="topTextCounter">${this.counterText}</span>`
        }
    }
}