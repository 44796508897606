import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import ActualInvestor1BTile from './actual-investor-1b-tile/ActualInvestor1BTile';
import ManifestoVideo from './manifesto-video/ManifestoVideo';
import ManifestoBody from './manifesto-body/ManifestoBody';
import template from './actual-investor-1b-panel.vue';

const gradientSrc = '/assets/images/gradients/gradient.jpg';

@Component({
  components: {
    ManifestoBody,
    ManifestoVideo,
    ActualInvestor1BTile,
  },
  mixins: [template],
})
export default class ActualInvestor1BPanel extends Vue {
  gradientSrc = gradientSrc;

  @Prop()
  data!: mws.ActualInvestor1BPanel;

  mounted() {
    this.matchingUtmItem = this.getMatchingUtmItem();
  }

  matchingUtmItem: mws.UtmAdItem | null = null;

  isAnyInfoElementVisible(): boolean {
    return this.data.info && this.data.info.length > 0;
  }

  isVisibleManifestoVideoContent(): boolean {
    return !this.data.hideManifestoVideoContent;
  }

  isVisibleMastheadContent(): boolean {
    return !this.data.hideMastheadContent;
  }

  isInfoElementsCountEqualsOne(): boolean {
    return this.data.info.length === 1;
  }

  get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  get mastheadImage(): mws.Image {
    const utmItem = this.matchingUtmItem;
    return utmItem == null ? this.data.mastheadImage : utmItem.mastheadImage;
  }

  get mobileTopText(): string {
    const utmItem = this.matchingUtmItem;
    return utmItem == null ? this.data.mobileTextTop : utmItem.mobileTextTop;
  }

  get mobileBottomText(): string {
    const utmItem = this.matchingUtmItem;
    return utmItem == null ? this.data.mobileTextBottom : utmItem.mobileTextBottom;
  }

  private getMatchingUtmItem(): mws.UtmAdItem | null {
    const matches = this.data.utmAdItems
      .filter((item) => this.isUtmItemValid(item.variable));
    return matches.length === 0 ? null : matches[0];
  }

  private isUtmItemValid(variable: string) {
    return this.$route.query.utm_content === variable;
  }
}
