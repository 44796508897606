import { Component, Vue, Watch } from 'vue-property-decorator';
import template from './athenaeum-popup.vue';
import * as mws from '../../../shared/MwsViewModels';
// eslint-disable-next-line max-len
import AthenaeumCulturePopupPanel from '../../../panel/components/athenaeum/athenaeum-culture/athenaeum-culture-popup-panel/AthenaeumCulturePopupPanel';
import AthenaeumHistoryPopupPanel from '../../../panel/components/athenaeum/athenaeum-history/athenaeum-history-popup-panel/AthenaeumHistoryPopupPanel';
// eslint-disable-next-line max-len
import AthenaeumPopupEvents from '../../../shared/AthenaeumPopupEvents';
import EventBus from '../../../shared/EventBus';
import { Route } from 'vue-router';

@Component({
  mixins: [template],
  components: {
    AthenaeumCulturePopupPanel,
    AthenaeumHistoryPopupPanel
  },
})
export default class AthenaeumPopup extends Vue {
  @Watch('$route')
  onUrlChange(newVal: Route, oldVal: Route) {
    if (oldVal.query.content && !newVal.query.content) {
      this.closePopup();
    }
  }

  showFullHeight = false;

  public get isOpen(): boolean {
    return this.$store.state.popups.athenaeumPopup.isOpen;
  }

  closePopup(): void {
    this.$store.dispatch('closeAthenaeumPopup');
    EventBus.$emit(AthenaeumPopupEvents.closeAthenaeum);
    (document.getElementsByTagName('body')[0] as HTMLElement).classList.remove('noscroll');
    this.$router.push({'query': null}).catch(()=>{});

    const closeHistoryButton = document.querySelector('#athenaeum-popup-container .banner button.js-athenaeum-reworked__history-popup-close-button') as HTMLElement;
      if (closeHistoryButton) {
        closeHistoryButton.classList.remove('js-athenaeum-reworked__history-popup-close-button')
      }
  }

  public get iframeSrc(): string {
    if (this.athenaeumPanelName && this.athenaeumDatum.length > 0) {
        var athenaeumUrl = this.athenaeumDatum.find(x => x.urlIdentifier == this.athenaeumPanelName);
        return athenaeumUrl ? athenaeumUrl.url : '';
    }
    return '';
  }

  public get athenaeumPanelName(): string {
    return this.$store.state.popups.athenaeumPopup.athenaeumContentData.athenaeumPanelName;
  }

  public get athenaeumDatum(): mws.AthenaeumDatum[] {
    return this.$store.state.popups.athenaeumPopup.athenaeumContentData.athenaeumDatum;
  }

  public get athenaeumData(): mws.AthenaeumDatum[] {
    return this.$store.state.popups.athenaeumPopup.athenaeumContentData;
  }
}
