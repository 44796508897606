import { Prop, Component, Vue } from 'vue-property-decorator';
import template from './periodic-performance.vue';
import * as mws from '../../../../shared/MwsViewModels';
import PerformanceTableRow from '../performance-table-row';
import DisclaimerWrapper from '../disclaimer-wrapper/DisclaimerWrapper';
import getPerformanceRows from '../get-performance-rows';

@Component({
  mixins: [template],
  components: {
    DisclaimerWrapper,
  },
})
export default class PeriodicPerformance extends Vue {
  @Prop()
  periodicPerformanceTable: mws.Table;

  getPeriodicPerformanceRows(): PerformanceTableRow[] {
    return getPerformanceRows(this.periodicPerformanceTable);
  }
}
