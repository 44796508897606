import { Component, Prop, Vue } from 'vue-property-decorator';
import * as mws from '../../shared/MwsViewModels';
import template from './side-share-box.vue';

const shareSrc = '/assets/images/svg/sprite/share-link-white.svg';
const tickSrc = '/assets/images/svg/sprite/tick-white.svg';
const emailSrc = '/assets/images/svg/sprite/email-white.svg';
const facebookSrc = '/assets/images/svg/sprite/facebook-white.svg';
const twitterSrc = '/assets/images/svg/sprite/twitter-white.svg';
const linkedinSrc = '/assets/images/svg/sprite/linkedin-white.svg';

@Component({
    mixins: [template]
})
export default class SideShareBox extends Vue {
    shareSrc = shareSrc;
    tickSrc = tickSrc;
    emailSrc = emailSrc;
    facebookSrc = facebookSrc;
    twitterSrc = twitterSrc;
    linkedinSrc = linkedinSrc;

    @Prop()
    pageNavigation!: mws.PageNavigationPanel;

    @Prop()
    shareOptions!: mws.IcSharePanel;

    @Prop()
    icTitle: string;

    get showShareIcons(): boolean {
        return this.shareOptions ? this.shareOptions.showShareIcons : false;
    }

    copyLink() {
        let shareButtonElement = document.getElementById('share_shareButton');
        
        if (shareButtonElement) {
            shareButtonElement.querySelector('span.label').innerHTML = 'Copied';
            shareButtonElement.querySelector('img').setAttribute('src', tickSrc);
    
            var url = window.location.href;
            window.navigator.clipboard.writeText(url);
    
            setTimeout(() => {
                shareButtonElement.querySelector('span.label').innerHTML = 'Share&nbsp;&nbsp;';
                shareButtonElement.querySelector('img').setAttribute('src', shareSrc);
            }, 1200);
        }
    }

    emailLink() {
        let emailButtonElement = document.getElementById('share_emailButton');
        
        if (emailButtonElement) {
            var url = window.location.href;
    
            emailButtonElement.setAttribute('href', `mailto:?subject=${this.icTitle}&body=${url}`);
        }
    }
}
