import { Mixins, Component, Prop } from 'vue-property-decorator';
import { Glossary, Table } from '@/shared/MwsViewModels';
import template from './glossary-panel.vue';
import IdGenerationMixin from '../../../shared/id-generation/IdGenerationMixin';
import FadeTransitionMixin from '../../../shared/fade-transition/FadeTransitionMixin';



@Component({
    mixins: [template],
  })
  export default class GlossaryPanel extends Mixins(IdGenerationMixin, FadeTransitionMixin) {
    @Prop()
    data: Glossary;

    show = false;
    panelId: string;
  
    created() {
      this.panelId = this.$ids.generate();
    }
  
    async showHide(): Promise<void> {
        var glossaryBody = this.$refs.glossaryBody as HTMLElement;
        if(this.show){
            this.show = false;
            await this.fadeHide(glossaryBody);
        }
        else{
            this.show = true;
            glossaryBody.style.display = 'inherit';
            await this.fadeShow(glossaryBody);
        }
    }
  }
