import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-overview-panel.vue';

@Component({
  mixins: [template],
})
export default class FundOverviewPanel extends Vue {
  @Prop()
  data: mws.FundOverviewPanel;
}
