import { Component, Prop, Vue } from 'vue-property-decorator';
import * as mws from '../../../shared/MwsViewModels';
import template from './article-series-navigation-panel.vue';
import GenericLink from '../../../shared/components/link/GenericLink';

@Component({
    mixins: [template],
    components: {
        GenericLink,
      },
})
export default class ArticleSeriesNavigationPanel extends Vue {
    @Prop()
    data: mws.ArticleSeriesNavigationPanel;

    get prev(): mws.ArticleSeriesNavigationItem {
        return this.data.prev;
    }

    get next(): mws.ArticleSeriesNavigationItem {
        return this.data.next;
    }
}
