import CellData from './CellData';

const cellsExapleData: CellData[] = [
  new CellData(
    'Helped farmers <br />engage in better <br />farming practices on',
    'hectares <br />of land',
    'green',
    0.000071,
    'farm',
  ),
  new CellData(
    'Saved a total of',
    'litres of <br />water',
    'green',
    1.690004,
    'drops',
    'NumberAbove',
  ),
  new CellData(
    'Avoided <br/> creation of',
    'metric tonnes <br/> of waste',
    'green',
    0.000000256336,
    'waste',
  ),
  new CellData(
    'Avoided <br/> metric tonnes of <br/> CO&#8322;e, equivalent of',
    'passenger <br/> cars off the <br/> street*',
    'green',
    0.000033,
    'car2',
    'TwoIndicators',
    false,
    0.000007,
  ),
  new CellData(
    'Provided access to <br />financial services <br />to at least',
    'people',
    'turquoise',
    0.000486,
    'money',
  ),
  new CellData(
    'Provided <br /> loans <br /> to',
    'borrowers',
    'turquoise',
    0.000081,
    'coins',
  ),
  new CellData(
    'Provided treatment and disease management solutions to',
    'patients',
    'pink',
    0.000002,
    'disease',
  ),
  new CellData(
    'Enabled',
    'people to send, save and spend money on healthcare services',
    'pink',
    0.000003,
    'medical',
    'ImageAbove',
  ),
  new CellData(
    'Contributed to <br />healthier lifestyles <br />for',
    'people',
    'pink',
    0.000167,
    'lifestyle',
  ),
  new CellData(
    'Provided',
    'instruments for scientific research',
    'pink',
    0.000000040821,
    'microscope',
    'ImageAbove',
  ),
  new CellData(
    'Provided access <br/> to education <br/> and/or training to',
    'registered <br/> learners',
    'blue',
    0.001727,
    'education',
  ),
  new CellData(
    'Provided access to online marketplaces for over',
    'merchants',
    'blue',
    0.000009,
    'merchants',
  ),
];

export default cellsExapleData;
