import { Mixins, Component, Prop } from 'vue-property-decorator';
import { Table } from '@/shared/MwsViewModels';
import template from './tab-container.vue';
import IdGenerationMixin from '../../shared/id-generation/IdGenerationMixin';
import FadeTransitionMixin from '../../shared/fade-transition/FadeTransitionMixin';
import EventBus from '../../shared/EventBus';

export interface TabViewModel {
  heading: string;
  tableData: Table;
}

@Component({
  mixins: [template],
})
export default class TabContainer extends Mixins(IdGenerationMixin, FadeTransitionMixin) {
  @Prop()
  tabs: TabViewModel[];

  @Prop()
  rowClass: string;

  private percentageChangeString = '% Change';

  private positiveNumberCssClass = 'positive-number';

  private negativeNumberCssClass = 'negative-number';

  currentTabIndex = 0;
  panelId: string;

  created() {
    this.panelId = this.$ids.generate();
  }

  getTabId(index: number): string {
    return `panel-${this.panelId}-button-${index}`;
  }

  isLastTab(index: number): boolean {
    const length = this.tabs?.length;
    return length && index === length - 1;
  }

  isCurrentTab(index: number): boolean {
    return index === this.currentTabIndex;
  }

  getRowsFor(index: number): { key: string; value: string; valueClass: string }[] {
    return this.tabs[index].tableData.rows.map((row) => ({
      key: row.columns[0].value,
      value: row.columns[1].value,
      valueClass:
        row.columns[0].value === this.percentageChangeString
          ? this.getValueClass(row.columns[1].value)
          : null,
    }));
  }

  getValueClass(value: string): string {
    return value.includes('-') ? this.negativeNumberCssClass : this.positiveNumberCssClass;
  }

  async onTabClick(index: number): Promise<void> {
    if (index === this.currentTabIndex) {
      return;
    }

    const tabList = this.$refs.tabList as HTMLElement;
    const currentlySelectedTab = tabList.children[this.currentTabIndex] as HTMLElement;
    const newSelectedTab = tabList.children[index] as HTMLElement;

    if (
      this.tabs[index].tableData.rows[0].columns[0].value === 'XD' ||
      this.tabs[index].tableData.rows[0].columns[0].value === 'Share Class'
    ) {
      // OEIC
      const newOEICshareClass = this.tabs[index].heading;
      EventBus.$emit('new-oeic-share-class', newOEICshareClass);
    } else if (this.tabs[index].tableData.rows[0].columns[0].value === 'Ticker') {
      // USM
      const newUSMShareClass = this.tabs[index].tableData.rows[0].columns[1].value;
      EventBus.$emit('new-share-class', newUSMShareClass);
    } else if (this.tabs[index].tableData.rows[0].columns[0].value === 'NAV') {
      //Canada
      EventBus.$emit('new-canada-share-class', this.tabs[index].heading);
    }

    await this.fadeHide(currentlySelectedTab);
    this.currentTabIndex = index;
    await this.fadeShow(newSelectedTab);
  }
}
