import { Component, Vue, Prop } from 'vue-property-decorator';
import { SearchResultSummary } from '@/shared/MwsViewModels';
import template from './search-items.vue';
import SearchItem from '../search-item/SearchItem';

const resultsPerScroll = 10;
const loadingGifSrc = '/assets/images/common/loading2.gif';

@Component({
  mixins: [template],
  components: {
    SearchItem,
  },
})
export default class SearchItems extends Vue {
  @Prop()
  bus!: Vue

  loadingGifSrc: string = loadingGifSrc;

  isLoading = false;

  mounted() {
    this.prepareInfiniteScroll();
    this.bus.$on('searchStarted', this.loadingResults);
    this.bus.$on('searchFinished', this.loadResults);
  }

  private prepareInfiniteScroll() {
    const searchContent = document.querySelector('#search-content');
    if (searchContent) {
      searchContent.addEventListener('scroll', () => {
        if (searchContent.scrollTop + searchContent.clientHeight >= searchContent.scrollHeight) {
          this.loadResults();
        }
      });
    }
  }

  private loadResults() {
    this.$store.dispatch('loadResults', resultsPerScroll);
    this.isLoading = false;
  }

  private loadingResults(): void {
    this.isLoading = true;
  }

  public get results(): SearchResultSummary[] {
    return this.$store.getters.searchResults;
  }
}
