import { Vue, Component, Prop } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import template from './carousel.vue';

@Component({
  mixins: [template],
  components: {
    VueSlickCarousel,
  },
})
export default class Carousel extends Vue {
  @Prop()
  settings: any;

  @Prop({ default: true })
  buttons: boolean;

  moveNext(): void {
    this.carousel.next();
  }

  movePrevious(): void {
    this.carousel.prev();
  }

  get carousel(): VueSlickCarousel {
    return this.$refs.carousel;
  }
}
