var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isNewsPanel())?_c('div',[(_vm.data.featureFirstItem)?_c('section',{staticClass:"content-panel magazine-grid feature-first-item",class:{
            divided: _vm.data.dividerStyle !== 'NONE',
        }},[_c('div',{staticClass:"featured-wrapper"},_vm._l((_vm.featuredInsight),function(card,index){return _c('MagazineSupercard',{key:index,staticClass:"featured",class:{
                    'is-new': _vm.isLinkNew(card.link),
                },attrs:{"index":index,"card":card,"isNew":_vm.isLinkNew(card.link)}})}),1),_vm._v(" "),_c('div',{staticClass:"featured-siblings-wrapper"},_vm._l((_vm.otherInsights),function(card,index){return _c('MagazineSupercard',{key:index,staticClass:"featured-sibling",class:{
                    'is-new': _vm.isLinkNew(card.link),
                },attrs:{"index":index,"card":card,"isNew":_vm.isLinkNew(card.link)}})}),1)]):(_vm.hasAsideItems())?_c('section',{staticClass:"content-panel magazine-grid",class:{
            divided: _vm.data.dividerStyle !== 'NONE',
            'has-aside-items': _vm.hasAsideItems(),
        }},[_vm._l((_vm.magazineGridItems),function(card,index){return _c('MagazineSupercard',{key:index,staticClass:"regular-card",class:{
                'is-new': _vm.isLinkNew(card.link),
                last: index === _vm.magazineGridItems.length - 1,
            },attrs:{"index":index,"card":card,"isNew":_vm.isLinkNew(card.link)}})}),_vm._v(" "),_c('MagazineAsideItems',{attrs:{"asideItems":_vm.magazineGridAsideItems}})],2):_vm._l((_vm.rows),function(row,index){return _c('section',{key:index,staticClass:"content-panel magazine-grid",class:{
            'row-divided': index < _vm.rows.length - 1,
            divided: index === _vm.rows.length - 1 && _vm.data.dividerStyle !== 'NONE',
            'last-row': index === _vm.rows.length - 1,
        },attrs:{"index":index}},_vm._l((row.rowItems),function(card,index){return _c('MagazineSupercard',{key:index,staticClass:"regular-card",class:{
                'is-new': _vm.isLinkNew(card.link),
                last: index === row.rowItems.length - 1,
            },attrs:{"index":index,"card":card,"isNew":_vm.isLinkNew(card.link)}})}),1)})],2):_c('div',[_c('MagazineGridWithNewsItems',{attrs:{"insights":_vm.magazineGridItems,"asideItems":_vm.magazineGridAsideItems,"heading":_vm.data.asideItemsHeading,"displayDivider":_vm.data.dividerStyle !== 'NONE'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }