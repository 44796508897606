import { Vue, Component } from 'vue-property-decorator';
import axios from 'axios';
import { IPanel, PageRequestModel } from '../MwsViewModels';

export type PageResponse = any;

@Component
export default class PostbackMixin extends Vue {
  public createRequestModel(panel: IPanel, postbackData: unknown): PageRequestModel {
    const postback = {
      data: postbackData,
      panelId: panel.id,
      panelName: panel.panelName,
    };
    return {
      postback: [postback],
      url: this.$store.getters.pageUrl,
      preview: this.$store.getters.config.preview,
    };
  }

  public async fetchPostback(data: PageRequestModel): Promise<PageResponse> {
    const apiUrl = '/api';
    const request = await axios.post(apiUrl, data);
    return request.data;
  }

  public async mergePostback(pageData: PageResponse): Promise<void> {
    return this.$store.dispatch('mergePanels', pageData);
  }
}
