import * as mws from '@/shared/MwsViewModels';
import BackgroundInstance from './BackgroundInstance';

export default class BackgroundFactory {
  public create(backgroundViewModel: mws.IBackground): BackgroundInstance {
    const { type } = backgroundViewModel;
    if (type === 'ImageBackground') {
      return this.createImage(backgroundViewModel as mws.ImageBackground);
    }
    if (type === 'VideoBackground') {
      return this.createVideo(backgroundViewModel as mws.VideoBackground);
    }
    console.warn(`Background type "${type}" not implemented.`);
    return this.createEmpty();
  }

  private createImage(background: mws.ImageBackground): BackgroundInstance {
    const url = background?.image?.url;
    return new BackgroundInstance(
      {
        'background-image': (url ? `url("${url}")` : undefined),
      },
      {
        'image-tint': background.showImageTint,
      }
    );
  }

  private createVideo(background: mws.VideoBackground): BackgroundInstance {
    const url = background?.image?.url;
    return new BackgroundInstance(
      {
        'background-image': (url ? `url("${url}")` : undefined),
      },
      {
        'image-tint': background.showImageTint,
      },
      background.url1,
      background.url2,
    );
  }

  private createEmpty(): BackgroundInstance {
    return new BackgroundInstance({}, {});
  }
}
