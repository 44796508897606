import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './magazine-footer-panel.vue';

@Component({
    mixins: [template],
})

export default class MagazineFooterPanel extends Vue {
    @Prop()
    data: mws.FooterPanel;

    hasLink(): boolean {
        return this.data.leftSideFooterLinks.length > 0;
    }

    getLinkUrl(): string {
        return this.data.leftSideFooterLinks[0].url;
    }

    getLinkText(): string {
        return this.data.leftSideFooterLinks[0].linkTitle;
    }

    getTarget(): string{
        var link = this.data.leftSideFooterLinks[0] as mws.TextLink;
        return link.openInNewWindow ? "_blank" : "_self";
    }

    scrollToTop(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}