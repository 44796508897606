import { Vue, Component, Prop } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import GenericLink from '../../shared/components/link/GenericLink';
import template from './insights-carousel.vue';
import * as mws from '../../shared/MwsViewModels';
import carouselSettings from './carousel-settings';
import IntellectualCapitalItem from '../../panel/components/intellectual-capital/intellectual-capital-item/IntellectualCapitalItem';
import IcViewMoreTile from '../../panel/components/intellectual-capital/ic-view-more-tile/IcViewMoreTile';

@Component({
  mixins: [template],
  components: {
    VueSlickCarousel,
    GenericLink,
    IntellectualCapitalItem,
    IcViewMoreTile
  },
})
export default class InsightsCarousel extends Vue {

  @Prop()
  items: mws.IInsightPageLink[];

  @Prop()
  link: mws.ILink;

  @Prop()
  includeViewMoreTile: boolean;

  @Prop()
  colourTheme: string;

  settings = carouselSettings;

  renderCarouselItems = false;

  moveNext(): void {
    this.carousel.next();
  }

  movePrevious(): void {
    this.carousel.prev();
  }

  get carousel(): VueSlickCarousel {
    return this.$refs.carousel;
  }

  get scrollbarWidth(): string {
    const n = carouselSettings.slidesToShow;
    return this.items.length > n ? '33.33%' : '100%';
  }

  xScrollbar = '0';

  onItemChange(oldItemIndex: number, itemIndex: number): boolean {
    const itemCount = this.items.length;
    const n = carouselSettings.slidesToShow;
    const percentage = itemCount > n ? (200 / (itemCount - n) * itemIndex) : 0;
    this.xScrollbar = percentage + '%';
    return false;
  }
}
