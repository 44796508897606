import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-side-call-to-action-panel.vue';
import GenericLink from '../../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class FundSideCallToActionPanel extends Vue {
  @Prop()
  data: mws.CallToActionPanel;

  get ctaElements(): mws.CtaTextLinkElement[] {
    return this.data.callToActions as mws.CtaTextLinkElement[];
  }
}
