import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import * as mws from '../../shared/MwsViewModels';
import template from './podcast-panel.vue';
import PodcastPlayerMixin from '../../shared/podcast-player/PodcastPlayerMixin';

@Component({
  mixins: [template],
})
export default class PodcastPanel extends Mixins(PodcastPlayerMixin) {
    @Prop()
    data: mws.IcItemPodcast;

    @Prop()
    shareIcons: mws.IcSharePanel;

    @Prop({default: false})
    isArchived: boolean;

    get showShareIcons(): boolean {
      return this.shareIcons ? this.shareIcons.showShareIcons : false;
    }

    mounted() {
      const podcastContainer = this.$refs.podcastContainer as HTMLElement;
      const config = {
        autostart: false,
        height: 'auto',
        aspectratio: '16:9',
        url: this.data.audioFile,
        image: this.data.image?.url
      };
      this.initialisePodcastPlayer(podcastContainer, config);
    }
}
