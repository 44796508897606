import canUseDOM from './canUseDOM';

export function safeArray<Type>(items: Type[]): Type[] {
  return Array.isArray(items) ? items : [];
}

export function getCookieValue(name: string) {
  if (canUseDOM()) {
    const foundCookie = document.cookie
      .split(';')
      .map((cookie) => cookie?.trim())
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1];

    return foundCookie;
  }

  return undefined;
}

export function setCookie(key: string, value: string, path: string, duration: string) {
  const d = new Date();
  d.setDate(new Date().getDate() + 90);
  d.setHours(0, 0, 0, 0);

  document.cookie = `${key}=${value};expires=${duration};path=${path}`;
}

export function newDateFromToday(days: number) {
  const d = new Date();
  d.setDate(new Date().getDate() + days);
  d.setHours(0, 0, 0, 0);

  return d;
}

export function newDateFromTodayString(offsetDays = 0) {
  const d = new Date();
  d.setDate(new Date().getDate() + offsetDays);
  d.setHours(0, 0, 0, 0);

  return d.toUTCString();
}

export function setAudienceSelectorCookie(key: string, value: string, path: string) {
  const d = new Date();
  d.setDate(new Date().getDate() + 90);
  d.setHours(0, 0, 0, 0);
  const ninetyDaysFromToday = newDateFromTodayString(90);

  if (key === 'cookieSelectedChannel' && value === '投資運用業') {
    setCookie('cookieChannelShorthand', 'JPIMB', path, ninetyDaysFromToday);
  } else if (key === 'cookieSelectedChannel' && value !== '投資運用業') {
    setCookie('cookieChannelShorthand', '', path, ninetyDaysFromToday);
  }

  setCookie(key, value, path, ninetyDaysFromToday);
}

export function setSelfAttestationCookie(key: string, value: string, path: string) {
  const d = new Date();
  d.setDate(new Date().getDate() + 90);
  d.setHours(0, 0, 0, 0);

  const ninetyDaysFromToday = newDateFromTodayString(90);
  setCookie(key, value, path, ninetyDaysFromToday);
}

export function setFundCookie(key: string, value: string, path: string) {
  const d = new Date();
  d.setDate(new Date().getDate() + 90);
  d.setHours(0, 0, 0, 0);
  const ninetyDaysFromToday = newDateFromTodayString(90);

  setCookie(key, value, path, ninetyDaysFromToday);
}
