import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './actual-attributes-panel.vue';
import Attribute from './attribute/Attribute';
import SecondaryAttribute from './attribute/SecondaryAttribute';
import * as mws from '../../../shared/MwsViewModels';
import { ReactInVue } from 'vuera'
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    Attribute,
    SecondaryAttribute,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class ActualAttributesPanel extends Vue {
  @Prop()
  data!: mws.ActualAttributesPanel;

  private mainNumberOfBlocks = 4;

  get blocksToDisplay(): mws.Block[] {
    return this.visibleBlocks
      .slice(0, this.mainNumberOfBlocks)
  }

  get secondaryBlocksToDisplay(): mws.Block[] {
    return this.visibleBlocks
      .slice(this.mainNumberOfBlocks, this.visibleBlocks.length)
  }

  get panelClasses(): { [key: string]: boolean } {
    return {
      [this.multiBlockClass()]: true
    };
  }

  get secondaryPanelClasses(): { [key: string]: boolean } {
    return {
      [this.secondaryMultiBlockClass()]: true
    };
  }

  get blockClasses(): { [key: string]: boolean } {
    return {
      [this.multiBlockClass()]: true,
    };
  }

  get secondaryBlockClasses(): { [key: string]: boolean } {
    return {
      [this.secondaryMultiBlockClass()]: true,
    };
  }

  get numberOfBlocksToDisplay(): number {
    return this.mainNumberOfBlocks;
  }

  get secondaryNumberOfBlocksToDisplay(): number {
    return this.visibleBlocks.length < this.mainNumberOfBlocks ? 0 : this.visibleBlocks.length - this.mainNumberOfBlocks;
  }

  get multiClassValue(): number {
    return this.mainNumberOfBlocks;
  }

  get secondaryMultiClassValue(): number {
    return 3;
  }

  get blockHeadingClass(): { [a: string]: boolean } {
    return { 'small-block-heading': true };
  }

  private multiBlockClass(): string {
    var multiBlockValue = '';

    if (this.data.blocks.length === 3)
      multiBlockValue = 'multi-blocks-3';
    else if (this.data.blocks.length === 2)
      multiBlockValue = 'multi-blocks-2';
    
    return `multi-block-${this.multiClassValue} ${multiBlockValue}`;
  }

  private secondaryMultiBlockClass(): string {
    return `multi-block-${this.secondaryMultiClassValue}`;
  }

  private shouldDisplayBlock(block: mws.Block): boolean {
    return block != null && block.link != null;
  }

  private get visibleBlocks(): mws.Block[] {
    return this.data.blocks
      .filter(this.shouldDisplayBlock);
  }
}
