import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './client-login-popup.vue';
import * as mws from '../../shared/MwsViewModels';
import ClientLoginPanel from '../../panel/components/client-login/ClientLoginPanel';

@Component({
  mixins: [template],
  components: {
    ClientLoginPanel,
  }
})

export default class ClientLoginPopup extends Vue {
  @Prop()
  page!: mws.HomePage;

  get link(): string {
    return this.$store.state.popups ? this.$store.state.popups.clientLoginPopup.linkUrl : '';
  }

  get isOpen(): boolean {
    return this.$store.state.popups.clientLoginPopup.isOpen;
  }

  get clientLoginPopupLink(): mws.ClientLoginPopupLink {
    if (this.page.panelGroups && this.page.panelGroups.length && this.page.panelGroups[0].panels.length) {
      let backgroundPanel = this.page.panelGroups[0].panels.find(x => x.panelName === "BackgroundPanel");
      if (backgroundPanel) {
        const clientPopUpLink = (backgroundPanel as mws.BackgroundPanel).secondary.find(x => x.type === 'ClientLoginPopupLink');
        return Boolean(clientPopUpLink) ? clientPopUpLink as mws.ClientLoginPopupLink : null;
      }
    }

    return null;
  }

  closePopup(): void {
    this.$store.dispatch('closeClientLoginPopup');
    if (this.$refs.clientLoginPanel) {
      const childComponent = this.$refs.clientLoginPanel as ClientLoginPanel;
      childComponent.resetDialogState();
    }
  }
}
