import React from 'react'
import classnames from 'classnames/bind'
import * as styles from './RadioSelector.module.scss'

const cx = classnames.bind(styles)

export interface RadioSelectorProps {
  key?: string,
  name: string
  uniqueID: string
  children?: React.ReactNode
  activeFilter: string
  onChangeHandler: (filterID: string) => void
  className?: string
  classNameActive?: string
}

const RadioSelector = ({
  name,
  children,
  uniqueID,
  activeFilter,
  onChangeHandler,
  className = '',
  classNameActive = '',
}: RadioSelectorProps) => {
  function handleFilterChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = ev.target.value

    onChangeHandler(value)
  }

  return (
    <label
      className={cx({
        ['radioSelector']: true,
        [className]: true,
        [classNameActive]: uniqueID === activeFilter,
      })}
    >
      <input
        type="radio"
        name={name}
        className={cx('input')}
        value={uniqueID}
        checked={uniqueID === activeFilter}
        onChange={handleFilterChange}
      />
      <span className={cx('radioLabel')}>
        <span className={cx('radioLabelIcon')} />
        {children}
      </span>
    </label>
  )
}

export default RadioSelector
