import ChapterSubtitles from '../subtitle-models/ChapterSubtitles';

export const ChapterThreeSubtitles: ChapterSubtitles = {
  textChunks: [
    {
      paragraphs: [
        'The finance act of \'65. The Arab Israeli war in \'73, leading to the quadrupling of oil prices. '
        + 'Rampant inflation. Sky high interest rates.',
      ],
    },
    {
      paragraphs: [
        'Problems for the markets mounted. And this generation was caught unprepared.',
        'The problem was that the senior partner saw new business as a distraction, and so declined to seek any out.',
      ],
    },
    {
      paragraphs: [
        'But allowing the firm to become so dependent on Trusts was a strategic error.',
        'Then in \'78 the firm lost its second biggest client.',
      ],
    },
    {
      paragraphs:
      [
        'Mergers were considered. The sale of the business was discussed. '
        + 'But the partners were determined to remain independent.',
        'And so they set the firm on the path that would lead to new business opportunities.',
      ],
    },
  ],
  subtitleLines: 4,
};

export default ChapterThreeSubtitles;
