import { Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './literature-library-filter.vue';
import Translatable from '../../../../translation/Translatable';
import _ from 'underscore';

@Component({
  mixins: [template],
})
export default class LiteratureLibraryFilter extends Translatable {
  @Prop()
  data: mws.LiteratureLibraryOverallPanelFilter;

  keyword = '';

  selectedType = '';

  selectedFund = '';

  async created() {
    await this.loadDictionary(['literature_library_search']);
  }

  onSearch(): void {
    const dto: mws.LiteratureDto = {
      fund: parseInt(this.selectedFund, 10) || undefined,
      keyword: this.keyword === '' ? undefined : this.keyword,
      category: this.selectedType === '' ? undefined : this.selectedType,
    };
    this.$emit('search', dto);

    this.$router.push({
      query: {
        mwKeyword: dto.keyword,
        mwLiteratureType: dto.category,
        mwFundId: dto.fund ? dto.fund.toString() : undefined
      }
    }).catch(() => { });
  }

  mounted() {
    let literatureTypeFromQuerystring = this.$router.currentRoute.query.mwLiteratureType;
    let keywordFromQuerystring = this.$router.currentRoute.query.mwKeyword;
    let fundFromQuerystring = this.$router.currentRoute.query.mwFundId;

    if (literatureTypeFromQuerystring || keywordFromQuerystring || fundFromQuerystring) {
      this.keyword = keywordFromQuerystring as string;
      this.selectedType = literatureTypeFromQuerystring as string ?? '';
      this.selectedFund = fundFromQuerystring as string ?? '';
      this.onSearch();
    }
  }

}
