import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './background-video.vue';
import * as mws from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
})
export default class BackgroundVideo extends Vue {
  @Prop()
  data!: mws.LoopVideo;

  private preferredResolutionOrder = ['720p', '1080p', '480p'];

  public get useVideo(): boolean {
    return this.data && !!this.orderedVideoUrls;
  }

  public get orderedVideoUrls(): string[] {
    const allResolutions = Object.keys(this.data.urls);

    const preferredResolutions = this.preferredResolutionOrder.filter((x) => allResolutions.includes(x));
    const otherResolutions = allResolutions.filter((x) => !preferredResolutions.includes(x));

    return preferredResolutions.concat(otherResolutions).map((key) => this.data.urls[key]);
  }
}
