import React from 'react'
import classnames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import Swiper, { A11y, Scrollbar } from 'swiper'
import SwiperCore from 'swiper';
import { InsightArticlePageLink } from '@/shared/MwsViewModels'
import canUseDOM from '../../utils/canUseDOM'
import { safeArray } from '../../utils/helpers'
import CardSmall from '../CardSmall'
import Icon from '../Icon'
import RadioSelector from '../RadioSelector'
import StyledButton from '../StyledButton'
import * as styles from './ArticleCarousel.module.scss'
SwiperCore.use([A11y, Scrollbar]);

const cx = classnames.bind(styles)

export interface ArticleCarouselProps {
  /**
   * Description of prop. This will display in Storybook
   */
  items: InsightArticlePageLink[]
  categories?: string[]
  labels?: Record<string, string>
  showCategories: boolean
}

type SwiperStatusType = {
  isEnd: boolean
  isBeginning: boolean
  isLocked: boolean
}

const allCategoriesKey = 'allCategories'
const categoriesRadioName = 'carouselFilters'

// TODO: Label for Select Category
const ArticleCarousel = ({
  items,
  categories = [],
  labels,
  showCategories
}: ArticleCarouselProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string>(allCategoriesKey)
  const [swiperStatus, setSwiperStatus] = useState<SwiperStatusType>({
    isEnd: false,
    isBeginning: false,
    isLocked: false,
  })
  const swiperRef = useRef<Swiper | null>(null)
  const swiperComponentRef = useRef<HTMLDivElement>(null)
  const swiperScrollbarRef = useRef<HTMLDivElement>(null)

  const safeItemsArray = safeArray(items)
  const safeCategoriesArray = safeArray(categories)
  const hasCategories = safeCategoriesArray.length > 0
  const slidesToDisplay =
    selectedFilter === allCategoriesKey
      ? safeItemsArray
      : safeItemsArray.filter((articleSlide) => {
          const themes = safeArray(articleSlide?.themes)
          return themes.includes(selectedFilter)
        })

  function handleFilterUpdate(filter: string) {
    setSelectedFilter(filter)
  }

  type direction = 'left' | 'right'
  function handleArrowClick(
    event: React.MouseEvent<HTMLButtonElement>,
    arrowDirection: direction
  ) {
    event.preventDefault()

    switch (arrowDirection) {
      case 'left':
        swiperRef?.current?.slidePrev()
        break
      case 'right':
        swiperRef?.current?.slideNext()
        break
      default:
        console.warn('provide direction')
    }
  }

  useEffect(() => {
    if (canUseDOM() && swiperRef && swiperComponentRef.current) {
      swiperRef.current = new Swiper(swiperComponentRef.current, {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        initialSlide: 0,
        scrollbar: {
          el: swiperScrollbarRef?.current,
          draggable: true,
          snapOnRelease: true,
        },
      })

      if (swiperRef && swiperRef.current) {
        swiperRef.current.on('slideChange', (swiper) => {
          setSwiperStatus({
            isEnd: swiper.isEnd,
            isBeginning: swiper.isBeginning,
            isLocked: swiper.isLocked,
          })
        })

        queueMicrotask(() => {
          setSwiperStatus({
            isEnd: swiperRef!.current!.isEnd,
            isBeginning: swiperRef!.current!.isBeginning,
            isLocked: swiperRef!.current!.isEnd,
          })
        })
      }
    }
    return () => {
      if (swiperRef?.current?.destroy) {
        swiperRef?.current.destroy()
        swiperRef.current = null
      }
    }
  }, [selectedFilter])

  const messageToDisplay = `Viewing ${slidesToDisplay.length} of ${safeItemsArray.length} insights`

  return (
    <div className={cx('articleCarousel')}>
      {hasCategories && showCategories && (
        <fieldset className={cx('categories')}>
          <h3 className="type__h5">Select a category</h3>
          <div className={cx('categoriesList')}>
            <RadioSelector
              name={categoriesRadioName}
              uniqueID={allCategoriesKey}
              activeFilter={selectedFilter}
              onChangeHandler={handleFilterUpdate}
              className={cx('category')}
            >
              {labels?.AllCategories || 'All Categories'}
            </RadioSelector>

            {categories.map((category) => {
              return (
                <RadioSelector
                  key={category}
                  name={categoriesRadioName}
                  uniqueID={category}
                  activeFilter={selectedFilter}
                  className={cx('category')}
                  onChangeHandler={handleFilterUpdate}
                >
                  {category}
                </RadioSelector>
              )
            })}
          </div>
        </fieldset>
      )}
      {hasCategories && (
        <div className={cx('statusMessage', 'spacing__sm')}>
          {messageToDisplay}
        </div>
      )}
      <div className={cx('carousel')}>
        <div
          ref={swiperComponentRef}
          className={cx('carouselComponent')}
          aria-live={hasCategories ? 'polite' : undefined}
        >
          <div className="swiper-wrapper">
            {slidesToDisplay.map((insightLink) => {
              return (
                <div
                  key={insightLink?.url}
                  className={cx('swiperSlide', 'swiper-slide')}
                >
                  <CardSmall
                    heading={insightLink?.tileHeading}
                    abstract={insightLink?.description}
                    url={insightLink?.url}
                    type={insightLink?.itemType}
                    date={insightLink?.date}
                    contentLength={insightLink?.duration}
                    image={insightLink.image}
                    openInNewWindow={insightLink.openInNewWindow}
                  />
                </div>
              )
            })}
          </div>
          <div
            className={cx({
              carouselControls: true,
              carouselControlsHidden: swiperStatus.isLocked,
            })}
          >
            <StyledButton
              type="pagination"
              className={cx('carouselArrow')}
              isDisabled={swiperStatus.isBeginning}
              callback={(event: React.MouseEvent<HTMLButtonElement>) =>
                handleArrowClick(event, 'left')
              }
            >
              <Icon
                className={cx('carouselArrowIcon')}
                type="arrowLeft"
              />
            </StyledButton>
            <div className={cx('scroller')}>
              <div
                ref={swiperScrollbarRef}
                className={cx(
                  'carouselScrollBar',
                  'swiper-scrollbar',
                  'swiper-scrollbar-horizontal'
                )}
              ></div>
            </div>
            <StyledButton
              type="pagination"
              className={cx('carouselArrow')}
              isDisabled={swiperStatus.isEnd}
              callback={(event: React.MouseEvent<HTMLButtonElement>) =>
                handleArrowClick(event, 'right')
              }
            >
              <Icon
                className={cx('carouselArrowIcon')}
                type="arrow"
              />
            </StyledButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleCarousel
