import { Component, Mixins } from 'vue-property-decorator';
import { RiskWarning } from '@/shared/MwsViewModels';
import template from './risk-warning-popup.vue';
import RiskWarningMixin from '../RiskWarningMixin';
import Translatable from '../../../translation/Translatable';

@Component({
  mixins: [template],
})
export default class RiskWarningPopup extends Mixins(RiskWarningMixin, Translatable) {
  public remember = false;
  public selectedWarningItem = 0;

  public get riskWarnings(): RiskWarning[] {
    return this.$store.state.popups.riskWarningPopup.riskWarnings || [];
  }

  public get multiRiskWarningHeading(): string {
    return this.$store.state.popups.riskWarningPopup.multiRiskWarningHeading || '';
  }

  public get multiRiskWarningIntroText(): string {
    return this.$store.state.popups.riskWarningPopup.multiRiskWarningIntroText || '';
  }

  public get isOpen(): boolean {
    return this.$store.state.popups.riskWarningPopup.isOpen;
  }

  async created() {
    await this.loadDictionary(['page_risk_warning_remember_choice']);
  }

  mounted() {
    this.openPageRiskWarningOnStart();
  }

  public accept(riskWarning: RiskWarning): void {
    if (riskWarning && riskWarning.id) {
      this.acceptRisk(riskWarning.id.toString(), this.remember);
    }
    if (this.acceptUrl) {
      this.$router.push({ path: this.acceptUrl });
    }
    this.$router.go(0);
  }

  public acceptMulti(riskWarning: RiskWarning): void {
      this.$store.dispatch('closeRiskWarningPopup');
      if(riskWarning.acceptCallToAction && riskWarning.acceptCallToAction.url)
      {
        this.acceptRisk(riskWarning.id.toString(), this.remember, riskWarning.acceptCallToAction.url);
        if (riskWarning.shouldRedirectAfterAcceptance) {
          this.$router.push({ path: riskWarning.acceptCallToAction.url });
          this.$router.go(0);
        }
      }
      else{
        this.accept(riskWarning);
      }
  }

  public decline(): void {
    this.$store.dispatch('closeRiskWarningPopup');
    this.$store.dispatch('openSelectLocationPopup', '/');
  }

  public declineMulti(riskWarning: RiskWarning): void {
    this.$store.dispatch('closeRiskWarningPopup');
    if(riskWarning.declineCallToAction && riskWarning.declineCallToAction.url)
    {
      this.$router.push({ path: riskWarning.declineCallToAction.url });
      this.$router.go(0);
    }
    else{
      this.decline();
    }
  }

  public setRiskWarningLanguage(riskwarning: RiskWarning): string {
    if (riskwarning != null && riskwarning.encodedLanguage != null)
      return riskwarning.encodedLanguage;

    return 'en';
  }

  private openRiskWarning(riskWarnings: RiskWarning[]): void {
    this.$store.dispatch('openRiskWarningPopup', {
      riskWarnings,
      acceptUrl: null,
      multiRiskWarningIntroText: this.multiRiskWarningIntroText,
      multiRiskWarningHeading: this.multiRiskWarningHeading
    });
  }

  private openPageRiskWarningOnStart(): void {
    if(!(this.isIndependentPage || this.isMicrositeRegion)) {
        return;
    } 

    const riskWarnings = this.pageRiskWarnings;
    if(riskWarnings && riskWarnings.length > 0 && this.riskAcceptedUrl(riskWarnings) == 'NA')
    {
      this.openRiskWarning(riskWarnings);
    }
  }

  public updateWarningItem(event) {
    Array.prototype.forEach.call(
      document.getElementsByClassName('page-risk-warning-item'),
      function (el) {
        el.style.display = 'none';
      }
    );

    var radioButtonId = 'riskwarning_item_container' + event.target.value;

    const displayingItem = document.querySelector('#' + radioButtonId) as HTMLElement;
    if (displayingItem) {
      displayingItem.style.display = 'block';
      displayingItem.parentElement.style.display = 'block';
    }
  }

  private get acceptUrl(): string {
    return this.$store.state.popups.riskWarningPopup.acceptUrl;
  }

  public riskAcceptedUrl(riskWarnings: RiskWarning[]): string
  {
    var response = 'NA';

    if(riskWarnings && riskWarnings.length > 0)
    {
      riskWarnings.forEach((riskWarning) => {
        var multiRiskSessionId =
          riskWarning.id.toString() + '$$' + (riskWarning.acceptCallToAction ? riskWarning.acceptCallToAction.url : '');

        if (
          riskWarning &&
          (this.hasAcceptedRisk(riskWarning.id.toString()) ||
            this.hasAcceptedRisk(multiRiskSessionId))
        ) {
            response = riskWarning.acceptCallToAction ? riskWarning.acceptCallToAction.url : '';
        }
      });
    }

    return response;
  }

  private get isIndependentPage(): boolean
  {
    return window.location.pathname.split('/').filter(part => part !== '').length < 3;
  }

  private get isMicrositeRegion(): boolean
  {
    var pageRegion = this.$store?.state?.pageData?.data?.page?.requestMetadata?.region;
    const micrositeRegions = ["LTGG", "LGPS", "Latest Insights", "Positive Change", "Health Innovation", "LatAm", "Media Hub", "Global Stewardship"];
    return Boolean(pageRegion) && micrositeRegions.some(region => pageRegion.includes(region));
  }

  private get pageRiskWarnings(): RiskWarning[] | null {
    return this.$store.getters.pageData.riskWarnings;
  }
}
