import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './magazine-grid-panel.vue';
import MagazineSupercard from './components/magazine-supercard/MagazineSupercard';
import MagazineAsideItems from './components/magazine-aside-items/MagazineAsideItems';
import MagazineGridWithNewsItems from './components/magazine-news-items/MagazineGridWithNewsItems';
import RowViewModel from './RowViewModel';

@Component({
  mixins: [template],
  components: {
    MagazineSupercard,
    MagazineAsideItems,
    MagazineGridWithNewsItems,
  },
})
export default class MagazineGridPanel extends Vue {
  @Prop()
  data: mws.GridPanel;

  get rows(): Array<RowViewModel> {
    return this.getRowsForLayout();
  }

  get magazineGridItems() {
    return this.data.gridItems.filter(gridItem => !gridItem.hasOwnProperty('link') || !!gridItem['link'])
  }

  get magazineGridAsideItems() {
    return this.data.gridAsideItems.filter(gridAsideItem => !!gridAsideItem.link);
  }

  getRowsForLayout() {
    let result: Array<RowViewModel> = new Array<RowViewModel>();
    let maxItemsPerRow: number = 4;
    let itemCount = this.magazineGridItems.length;
    let rowsRequired = Math.ceil(itemCount / maxItemsPerRow);
    let iteration: number = 1;
    for (let i = 0; i < rowsRequired; i++) {
      let cardsForRow = this.magazineGridItems.slice(
        i * maxItemsPerRow,
        iteration * maxItemsPerRow
      );
      let rowViewModel = new RowViewModel(cardsForRow);
      result.push(rowViewModel);
      iteration++;
    }
    return result;
  }

  // Only used if the feature first item flag is set:
  featuredInsight: mws.ISupercard[] = null;
  otherInsights: mws.ISupercard[] = null;

  isLinkNew(link: mws.ImageLink): boolean {
    return link.isLinkLessThanOneMonthOld;
  }

  hasAsideItems(): boolean {
    return this.magazineGridAsideItems.length > 0;
  }

  isNewsPanel(): boolean {
    //With this method we have to recognize that this is a "News" panel. This is temporary solution, we have to find something more clear.
    return this.hasAsideItems() && this.data.stackAsideItems;
  }

  mounted() {
    this.featuredInsight = this.magazineGridItems.slice(0, 1);
    this.otherInsights = this.magazineGridItems.slice(1, 3);
  }
}
