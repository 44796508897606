import { DailyPriceTableRow } from '../../../panel/components/daily-prices/daily-prices-table/DailyPriceTableRow';
import EventBus from '../../../shared/EventBus';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class FundDailyPricesShortlistMixin extends Vue {
  readonly shortlistNavHeadingPart = 'shortlist';
  readonly dailyPricesNavHeadingPart = 'prices';
  private readonly bgShortlistCookieName = 'bg_daily_prices_shortlist';

  public getShortlistItemsFromCookie(): {[key: string]: {[key:string]: string[] }} {
    return this.$cookies.get(this.bgShortlistCookieName) as {[key: string]: {[key:string]: string[] }} ?? {};
  }

  public toggleShortlistItemInCookie(row: DailyPriceTableRow, fundType: string): {[key: string]: {[key:string]: string[] }} {
    let shortlistItemsFromCookie = this.getShortlistItemsFromCookie();
    let currentUrlIdentifier = this.getRegionAndChannelIdentifier();

    let shortlistForRegionAndChannel = shortlistItemsFromCookie[currentUrlIdentifier];

    if (shortlistForRegionAndChannel) {
      let shortlistForFundType = shortlistForRegionAndChannel[fundType];
      
      if (shortlistForFundType) {
        if (!shortlistForFundType.includes(row.rowIdentifier)) {
          shortlistForFundType.push(row.rowIdentifier)
          EventBus.$emit('dp-shortlist-updated', 1);
        } else {
          const index = shortlistForFundType.indexOf(row.rowIdentifier);
          shortlistForFundType.splice(index, 1);
          EventBus.$emit('dp-shortlist-updated', -1);
        }

        shortlistItemsFromCookie[currentUrlIdentifier][fundType] = shortlistForFundType;
      }
      else {
        shortlistItemsFromCookie[currentUrlIdentifier][fundType] = [row.rowIdentifier];
        EventBus.$emit('dp-shortlist-updated', 1);
      }
    } else {
      shortlistItemsFromCookie[currentUrlIdentifier] = {};
      shortlistItemsFromCookie[currentUrlIdentifier][fundType] = [row.rowIdentifier];
      EventBus.$emit('dp-shortlist-updated', 1);
    }

    const options = { expires: 2147483647 }; //max-age expiration
    this.$cookies.set(this.bgShortlistCookieName, shortlistItemsFromCookie, options);

    return shortlistItemsFromCookie;
  }

  public getRegionAndChannelIdentifier(): string {
    return window.location.pathname.trim().split('/').filter(Boolean).slice(0, -1).join('/')?.toLowerCase();
  }

  public getCurrentRegionAndChannelShortlistCount() {
    let shortlistItemsFromCookie = this.getShortlistItemsFromCookie();
    let currentUrlIdentifier = this.getRegionAndChannelIdentifier();
    let shortlistItemsForRegionAndChannel = shortlistItemsFromCookie[currentUrlIdentifier] as {[key:string]: string[] };

    return shortlistItemsForRegionAndChannel ? Object.entries(shortlistItemsForRegionAndChannel).flatMap(([k, v]) => [...Object.values(v)]).length : 0;
  }
}
