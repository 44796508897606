import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './html-script-tag-panel.vue';
import { HtmlScriptTagPanel as model } from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
})
export default class HtmlScriptTagPanel extends Vue {
  @Prop()
  data!: model;
}
