import { Component, Vue, Prop } from 'vue-property-decorator';
import { throttle } from 'underscore';
import template from './landing-page-header-panel.vue';
import BackgroundVideo from '../background-video/BackgroundVideo';
import * as mws from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
  components: {
    BackgroundVideo,
  },
})
export default class LandingPageHeaderPanel extends Vue {
  @Prop()
  data!: mws.LandingPageHeaderPanel;

  overlayOffset = '0';

  public get useVideo(): boolean {
    return this.data.useLoopVideo && !!this.data.backgroundVideo;
  }

  mounted() {
    this.updateVideoOverlay();
    window.addEventListener('scroll', throttle(this.updateVideoOverlay, 10));
    window.addEventListener('resize', throttle(this.updateVideoOverlay, 10));
  }

  scrollDown(): void {
    const header = document.querySelector('.header-menu-navigation') as HTMLElement;
    const target = document.querySelector('#landing-page-header-panel ~ li') as HTMLElement;

    const scrollTo = target.offsetTop - (header ? header.clientHeight : 0);

    window.scrollTo({ top: scrollTo, behavior: 'smooth' });
  }

  private updateVideoOverlay(): void {
    const panelRef = this.$refs.panel as HTMLElement;
    const panelOffset = panelRef.getBoundingClientRect().y;
    this.overlayOffset = `${Math.max(0, window.innerHeight + panelOffset)}px`;
  }
}
