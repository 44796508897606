import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './secondary-attribute.vue';
// eslint-disable-next-line max-len
import PositionedResponsiveImage from '../../../../shared/components/positioned-responsive-image/PositionedResponsiveImage';
import GenericLink from '../../../../shared/components/link/GenericLink';
import DynamicTextFit from '../../../../shared/helpers/DynamicTextFit';
const icItemSvg = '/assets/images/svg/sprite/sprite-black.svg';

@Component({
  mixins: [template],
  components: {
    PositionedResponsiveImage,
    GenericLink,
  },
})
export default class SecondaryAttribute extends Mixins(DynamicTextFit) {
  @Prop()
  data!: mws.Block;

  @Prop({ default: false })
  tint!: boolean;

  @Prop()
  blockHeadingClass: string;

  @Prop({ default: true })
  small: boolean;

  private bottomDescriptionMargin = 5;

  mounted() {
    this.registerDynamicBlockText();
  }
 
  updated() {
    this.registerDynamicBlockText();
  }

  get itemSvg(): string {
    return this.dwellText ? `${icItemSvg}#article-read` : "";
  }

  get dwellText(): string {
    return this.data.readTime ? this.data.readTime : ""; 
  }

  private registerDynamicBlockText() {
    this.registerDynamicTextFitEvent(
      this.$refs.parentElement as Element,
      this.$refs.bottomElement as Element,
      this.$refs.textElement as Element,
      this.data.rolloverText,
      this.bottomDescriptionMargin,
      Number(process.env.VUE_APP_ACTUAL_SECONDARY_ATTRIBUTE_DESC_WORDCOUNT),
    );
  }
}