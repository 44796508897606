import {
  FooterWithImagesAndLinksPanel,
  FundPanelPage,
  IPage,
  IPanel,
  PageNavigationPanel,
  StrategyPanelPage,
} from '@/shared/MwsViewModels';

// pages
export const isFundPage = (page: IPage): page is FundPanelPage => page.pageType === 'FundPanelPage';

export const isStrategyPage = (page: IPage): page is StrategyPanelPage =>
  page.pageType === 'StrategyPanelPage';

// panels
export const isNavigationPanel = (panel: IPanel): panel is PageNavigationPanel =>
  panel.panelName === 'PageNavigationPanel';

export const isFooterPanel = (panel: IPanel): panel is FooterWithImagesAndLinksPanel =>
  panel.panelName === 'FooterWithImagesAndLinksPanel';
