import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './ucit-fund-facts-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import GlossaryPanel from '../glossary/GlossaryPanel'

@Component({
  mixins: [template],  
  components: {
    GlossaryPanel
  },
})
export default class UcitFundFactsPanel extends Vue {
  @Prop()
  data: mws.UcitFundFactsPanel;
  selectedFundSize='';

getFundSizes(){
  return this.data.fundSizes.map(function(row) {
    return { value: row.size, label: row.currency };
  })
}

  mounted(){
    this.selectedFundSize = this.data.fundSizes[0].size;
  }
}
