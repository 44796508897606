import classnames from 'classnames/bind'
import { useState } from 'react'
import { ICurrentChannel } from '../../types/AudienceSelector'
import {
  GlobalNaviagtion,
  GlobalNavigationSelectorPopupLink,
  Region,
} from '@/shared/MwsViewModels'
import { safeArray, setAudienceSelectorCookie } from '../../utils/helpers'
import RichText from '../RichText'
import StyledButton from '../StyledButton'
import * as styles from './AudienceSelector.module.scss'
import InvestmentTypeSelector from './InvestmentTypeSelector'
import RegionSelector from './RegionSelector'
import React from 'react'
import * as CookieLabels from '../../constants/COOKIES'

const cx = classnames.bind(styles)

export interface AudienceSelectorProps
  extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  data: GlobalNavigationSelectorPopupLink
  defaultChannelJurisdiction: Region | undefined
  labels: { [key: string]: string }
  currentChannel: ICurrentChannel
  hasForcedSelection: boolean
  audienceMenuVisible: boolean
  channelCookiesMatch: boolean
  globalRegionJurisdiction: Region | undefined
  globalRegionGroupNames: { [key: string]: string }
  handleAudienceMenuVisibility: (value?: boolean) => void
  id: {}
}

type RegionChoice = Region | undefined

const AudienceSelector = ({
  data,
  className,
  labels = {},
  currentChannel,
  hasForcedSelection = false,
  audienceMenuVisible = false,
  channelCookiesMatch,
  globalRegionJurisdiction,
  globalRegionGroupNames,
  handleAudienceMenuVisibility,
  defaultChannelJurisdiction,
  ...restOfAttributes
}: AudienceSelectorProps) => {
  const regionsMapping = new Map<string, Region[]>()
  const regionNames = safeArray(data?.globalNaviagtion?.regionGroupNames)
  const mainRegions = safeArray(data?.globalNaviagtion?.main)

  const [selectedRegion, setSelectedRegion] = useState<Region | undefined>(
    defaultChannelJurisdiction
  )

  // User Defined State to Change Region Selection
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [regionUserSelection, setRegionUserSelection] = useState<string>(
    defaultChannelJurisdiction?.regionGroupName || ''
  )

  const disableInvestorChannels =
    regionUserSelection !== selectedRegion?.regionGroupName

  const allLabels = {
    ...labels,
    viewGlobalSiteCta: data?.viewGlobalSiteCta,
  }

  regionNames.forEach((regionName) => {
    const regionList = mainRegions
      .filter((regionChoice) => regionChoice?.regionGroupName === regionName)
      .sort((a, b) => {
        const nameA = a?.name?.toLowerCase()
        const nameB = b?.name?.toLowerCase()

        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })

    regionsMapping.set(regionName, regionList)
  })

  function handleRegionSelection(region: RegionChoice) {
    setSelectedRegion(region || undefined)
    setIsDrawerOpen(false)
  }

  function handleDrawerToggleSetting(customValue?: boolean) {
    if (typeof customValue === 'boolean') {
      setIsDrawerOpen(customValue)
    }
    setIsDrawerOpen(!isDrawerOpen)
  }

  function handleAudienceClose(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    handleAudienceMenuVisibility(false)

    queueMicrotask(() => {
      setSelectedRegion(defaultChannelJurisdiction)
    })
  }

  function handleGlobalLinkClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    const globalRegionCookieName = 'Global'
    const globalLinkChannel = getGlobalLinkUrl(data?.globalNaviagtion)

    setAudienceSelectorCookie(
      CookieLabels.BG_REGION_COOKIE_NAME,
      globalRegionCookieName,
      '/'
    )
    // Reset Channel
    setAudienceSelectorCookie(CookieLabels.BG_CHANNEL_COOKIE_NAME, '', '/')

    if (globalLinkChannel) {
      window.location.replace(globalLinkChannel);
    } else {
      handleAudienceMenuVisibility(false)
    }

    queueMicrotask(() => {
      resetSelectionJurisdiction(globalRegionJurisdiction)
    })
  }

  queueMicrotask(() => {
    if (audienceMenuVisible === false) {
      resetSelectionJurisdiction()
    }
  })

  function resetSelectionJurisdiction(customJurisdiction?: Region) {
    const jurisdiction = customJurisdiction || defaultChannelJurisdiction

    setIsDrawerOpen(false)
    setSelectedRegion(jurisdiction)
    setRegionUserSelection(jurisdiction?.regionGroupName || '')
  }

  const cookieMessage = labels?.DetailsWillBeSaved

  const showWarning =
    defaultChannelJurisdiction?.regionGroupName !== regionUserSelection
    || currentChannel.regionName !== selectedRegion?.name
    ||
    (defaultChannelJurisdiction?.regionGroupName === regionUserSelection &&
      !channelCookiesMatch)


  const riskWarningCopy =
    currentChannel.regionName === selectedRegion?.name &&
    defaultChannelJurisdiction?.regionGroupName === regionUserSelection
    ? currentChannel?.riskWarnings?.[0]?.bodyText || ''
    : selectedRegion?.channels[0]?.riskWarnings?.[0]?.bodyText
      
  return (
    <div
      className={cx('audienceSelector', className)}
      {...restOfAttributes}
    >
     <div className={cx('audienceContainer', 'introHeader')}>
          <div className={cx('introHeaderMessages')}>
            {showWarning && riskWarningCopy && (
              <RichText
                copy={riskWarningCopy}
                className={cx('message', 'util__narrow-container')}
              />
            )}
            {cookieMessage && (
              <RichText
                copy={cookieMessage}
                className={cx('message', 'util__narrow-container')}
              />
            )}
          </div>
        {!hasForcedSelection && (
          <StyledButton
            type="text"
            customIcon="close"
            className={cx('closeAudienceButton')}
            classNameForIcon={cx('closeAudienceIcon')}
            callback={handleAudienceClose}
          />
        )}
      </div>

      <RegionSelector
        labels={allLabels}
        regionGroups={data?.globalNaviagtion?.regionGroupNames}
        selectedChannelRegion={selectedRegion}
        allRegions={regionsMapping}
        defaultChannelRegion={defaultChannelJurisdiction}
        updateRegionSelection={handleRegionSelection}
        selectedRegion={regionUserSelection}
        setSelectedRegion={setRegionUserSelection}
        globalLink={getGlobalLinkUrl(data?.globalNaviagtion)}
        handleDrawerToggle={handleDrawerToggleSetting}
        handleGlobalLink={handleGlobalLinkClick}
        isDrawerOpenState={isDrawerOpen}
      />
      <InvestmentTypeSelector
        selectedChannelRegion={selectedRegion}
        currentChannel={currentChannel}
        globalLink={getGlobalLinkUrl(data?.globalNaviagtion)}
        globalRegionGroupNames={globalRegionGroupNames}
        labels={allLabels}
        handleGlobalLink={handleGlobalLinkClick}
        hasForcedSelection={hasForcedSelection}
        isDisabledInvestorChannels={disableInvestorChannels}
        handleAudienceMenuClose={handleAudienceMenuVisibility}
      />
    </div>
  )
}

function getGlobalLinkUrl(regions: GlobalNaviagtion) {
  const globalRegions: Region[] = regions?.additional

  const globalRegion = globalRegions?.find((regionItem) => {
    return regionItem?.name === 'Global'
  })

  const globalRegionChannel = globalRegion?.channels?.[0]

  return globalRegionChannel?.url || ''
}

export default AudienceSelector
