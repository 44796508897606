import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './actual-investor-inner-page-bottom-panel.vue';
import * as mws from '../../../../shared/MwsViewModels';
import GenericLink from '../../../../shared/components/link/GenericLink';


@Component ({
    mixins: [template],
    components: {
      GenericLink
    }
})

export default class ActualInvestorInnerPageBottomPanel extends Vue {
    @Prop()
    data!: mws.ActualInvestorInnerPageBottomPanel

    isCurrent(url: string): boolean {
      const resource = this.$route.fullPath;
      return url.endsWith(resource);
    }
}
