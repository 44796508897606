import { Component, Mixins, Prop } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';
import template from './contact-form.vue';
import FormSubmissionHistoryMixin from '../../../../shared/forms/FormSubmissionHistoryMixin';
import * as mws from '@/shared/MwsViewModels';

@Component({
  mixins: [template],
  components: {
    VueRecaptcha,
  },
})
export default class ContactForm extends Mixins(FormSubmissionHistoryMixin) {
  @Prop()
  data: mws.ContactFormPanel;

  name = '';
  phoneNumber = '';
  emailAddress = '';
  message = '';

  recaptchaResponse: string | null = null;

  useCaptcha = false;

  get canSubmit(): boolean {
    return !(this.useCaptcha && this.recaptchaResponse == null);
  }

  get sitekey(): string {
    return this.$store.getters.config.recaptchaSitekey;
  }

  shouldUseCaptcha(): boolean {
    return this.getRecentSubmissionsCount() > 1;
  }

  onSubmit(): void {
    if (this.recaptchaResponse == null && this.shouldUseCaptcha()) {
      this.useCaptcha = true;
      return;
    }

    const dto: mws.ContactFormDto = {
      name: this.name === '' ? undefined : this.name,
      phoneNumber: this.phoneNumber === '' ? undefined : this.phoneNumber,
      emailAddress: this.emailAddress === '' ? undefined : this.emailAddress,
      message: this.message === '' ? undefined : this.message
    };

    this.$emit('submit', dto);
  }

  onRecaptchaSuccess(response: string): void {
    this.recaptchaResponse = response;
  }

  onRecaptchaFailure(): void {
    this.recaptchaResponse = null;
  }
}
