import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './insights-page-header.vue';
import * as mws from '../../shared/MwsViewModels';
import IcFilteringMixin from '../../shared/intellectual-capital/IcFilteringMixin';

@Component({
  mixins: [template],
})
export default class InsightsPageHeader extends Mixins(IcFilteringMixin) {
    @Prop()
    icNavigation: mws.IcNavigationBar;
    @Prop()
    isActual: boolean;

    mounted() {
      this.icNavigation.backLinkUrl += this.getIcHomeQuerystringFromCookie();
    }

    getNavClasses(): string{
      return this.isActual ? 'nav-row with-top-border' : 'nav-row';
    }
}
