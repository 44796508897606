import StyledLink from "../../../lib/StyledLink"
import { Capability } from "@/shared/MwsViewModels"
import React from 'react'
import * as styles from './NavigationMegaSubMenuGroup.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

interface NavigationMegaSubMenuGroupProps {
    group: Capability[]
  }
  
  interface SubGroup {
    title: string
    items: Capability[]
  }

export const NavigationMegaSubMenuGroup = ({
    group,
  }: NavigationMegaSubMenuGroupProps) => {
    const hasSubGroups = group.every((item) => item.capabilitySubGroup !== '')
  
    const subGroups: SubGroup[] = hasSubGroups
      ? group.reduce((acc, item) => {
          const currentSubGroup = acc.find(
            (subGroup) => subGroup.title === item.capabilitySubGroup
          )
          if (currentSubGroup) {
            currentSubGroup.items.push(item)
            return acc
          } else {
            return [...acc, { title: item.capabilitySubGroup, items: [item] }]
          }
        }, [] as SubGroup[])
      : []
  
    return (
      <ul className={cx('mega-menu-group', 'util__reset-list')}>
        {hasSubGroups ? (
          subGroups.map((subGroup) => {
            return (
              <li key={subGroup.title}>
                <div className={cx('sub-group-title')}>{subGroup.title}</div>
                <ul className={cx('sub-group-collection', 'util__reset-list')}>
                  {subGroup.items.map((item) => {
                    return (
                      <li
                        className={cx('sub-group-item')}
                        key={item.capabilityName}
                      >
                        <StyledLink
                          type={'link'}
                          data={item.link}
                        >
                          {item.capabilityName}
                        </StyledLink>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          })
        ) : (
          <div className={cx('group-collection')}>
            {group.map((item) => {
              return (
                <li
                  key={item.capabilityName}
                  className={cx('group-item')}
                >
                  <StyledLink
                    type={'link'}
                    data={item.link}
                    className={cx('item-title')}
                  >
                    {item.link.linkTitle}
                  </StyledLink>
                  <div className={cx('item-description')}>
                    {item.link.rolloverText}
                  </div>
                </li>
              )
            })}
          </div>
        )}
      </ul>
    )
  }