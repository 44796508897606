import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './base-info-button.vue';
import highlightSyntax from '../highlightSyntax';
import axios from 'axios';

@Component({
  mixins: [template],
})
export default class BaseInfoButton extends Vue {
  @Prop()
  identifier: number;

  @Prop({ default: null })
  viewmodel: any;

  @Prop()
  extraClasses: string[];

  tooltipText = '';

  tooltipOpen = false;

  private editorUrlInternal: string = null;
  get editorUrl(): string {
    return this.editorUrlInternal;
  }

  async created(): Promise<void> {
    const sourceCode = JSON.stringify(this.viewmodel, null, 2);
    this.tooltipText = highlightSyntax(sourceCode);
    const response = await axios.get(`/cms?identifier=${this.identifier}`);
    this.editorUrlInternal = response.status == 200 ? response.data : '#';
  }

  onViewmodelClick(): void {
    this.tooltipOpen = !this.tooltipOpen;
  }
}
