import classnames from 'classnames/bind'
import { IBaseLink } from '../../LinkButtonStyles'
import StyledButton from '../../StyledButton'
import * as styles from './../AudienceSelector.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

interface IGlobalLinkButton {
  promptLabel: string
  globalSiteLink: IBaseLink
  buttonClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export default function GlobalLinkButton({
  promptLabel,
  globalSiteLink,
  buttonClick,
}: IGlobalLinkButton) {
  return (
    <div>
      <span className={cx('globalSiteLinkLabel')}>{promptLabel}</span>
      <StyledButton
        type="link"
        data={globalSiteLink}
        callback={buttonClick}
      />
    </div>
  )
}
