import { Component, Vue, Prop } from 'vue-property-decorator';
import { AddressElement, ContactMapPanel as Model } from '@/shared/MwsViewModels';
import template from './contact-map-panel.vue';
import OfficeAddress from './components/OfficeAddress';
import * as gm from './GmapMarker';
import defaultGmapOptions, { GmapOptions } from './GmapOptions';
import OfficeAddressData from './OfficeAddressData';
import { AddressCssClasses } from './AddressCssClasses';
import GmapMarker from '../../../../node_modules/vue2-google-maps/src/components/marker';
import GmapMap from '../../../../node_modules/vue2-google-maps/src/components/map.vue';

@Component({
  mixins: [template],
  components: {
    OfficeAddress,
    GmapMap,
    GmapMarker,
  },
})
export default class ContactMapPanel extends Vue {
  @Prop()
  data: Model;

  gmapOptions: GmapOptions = defaultGmapOptions;

  marker: gm.GmapMarker = {
    latitude: this.firstAddress ? +this.firstAddress.latitude : 0,
    longitude: this.firstAddress ? +this.firstAddress.longitude : 0,
    zoom: this.firstAddress ? +this.firstAddress.zoomLevel : 0,
  };

  get showEnquiries(): boolean {
    return this.data.showEnquiries && this.data.enquiryInformation.length > 0;
  }

  get anyContentOverAdresses(): boolean {
    return this.showEnquiries || this.data.showContactInformation;
  }

  get addressCssClasses(): AddressCssClasses {
    const border = this.data.showContactInformation || this.showEnquiries ? 'border-top' : '';
    const css = {
      columnClass: 'column-50',
      rteWidth: 'rich-text-editor-50',
      border,
    };
    switch (this.data.address.length) {
      case 1: {
        css.columnClass = 'column-100';
        css.rteWidth = 'rich-text-editor-100';
        return css;
      }
      case 2: {
        return css;
      }
      case 3: {
        css.columnClass = 'column-33';
        css.rteWidth = 'rich-text-editor-33';
        return css;
      }
      default: {
        return css;
      }
    }
  }

  get firstAddress(): AddressElement {
    return this.data.address[0];
  }

  get getGmapMarkerPoint(): gm.GmapMarkerPoint {
    return {
      lat: this.marker.latitude,
      lng: this.marker.longitude,
    };
  }

  get getGmapZoom(): number {
    return this.marker.zoom;
  }

  getOfficeAddress(address: AddressElement): OfficeAddressData {
    return new OfficeAddressData(address, this.addressCssClasses, this.data.address.length);
  }

  setGmapMarkerPoint(address: AddressElement): void {
    this.marker = {
      latitude: +address.latitude,
      longitude: +address.longitude,
      zoom: +address.zoomLevel,
    };
    this.scrollToElementId('#contact-map-panel-gmap');
  }

  scrollToElementId(elementId: string): void {
    const container = this.$el.querySelector(elementId);
    container.scrollIntoView({ behavior: 'smooth' });
  }
}
