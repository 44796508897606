import { Component, Vue, Prop } from 'vue-property-decorator';
import { SelectedChannel } from '@/shared/global-navigation/SelectedChannel';
import { ILink, GlobalNavigationSelectorPopupLink } from '@/shared/MwsViewModels';
import template from './global-navigation-header.vue';
import GenericLink from '../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  name: 'GlobalNavigationHeader',
  components: {
    GenericLink,
  },
})
export default class GlobalNavigationHeader extends Vue {
  @Prop({ default: () => [] })
  secondary!: ILink[];

  @Prop({ default: () => null })
  selectedChannel?: SelectedChannel;

  @Prop({ default: () => null })
  globalSelector?: GlobalNavigationSelectorPopupLink;
}
