import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './person.vue';
import AccordionMixin from '../../../../shared/accordion/accordionMixin';

@Component({
  mixins: [
    template,
    AccordionMixin,
  ],
})
export default class Person extends Vue {
  @Prop()
  person: mws.Person;

  getName(person: mws.Person): string {
    return person.title ? `${person.name} - ${person.title}` : person.name;
  }

  personClicked(): void {
    this.$emit('onPersonClick');
  }
}
