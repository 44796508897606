import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './single-video-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import VideoPlayerMixin from '../../../shared/video-player/VideoPlayerMixin';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
    mixins: [template],
    components: {
      'SectionContainer': ReactInVue(SectionContainer)
    }
  })
  export default class SingleVideoPanel extends Mixins(VideoPlayerMixin) {
    @Prop()
    data: mws.SingleVideoPanel;

    @Prop({default: false})
    isArchived: boolean;

    mounted() {
      const videoContainer = this.$refs.singleVideoContainer as HTMLElement;
      const config = {
        playlist: this.data.videoUrl,
        autostart: false,
        height: 'auto',
        aspectratio: '16:9',
      };
      this.initialiseVideoPlayer(videoContainer, config);
    }
  }