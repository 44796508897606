import Vue from 'vue';
import { IPageWithPanels } from '../MwsViewModels';

export default function mergePostbackPanels(original: IPageWithPanels, updated: IPageWithPanels): void {
  for (let i = 0; i < updated.panelGroups.length; ++i) {
    const group = updated.panelGroups[i];
    for (let j = 0; j < group.panels.length; ++j) {
      const updatedPanel = group.panels[j];
      const correspondingIndex = original.panelGroups[i].panels
        .findIndex((panel) => panel.id === updatedPanel.id);
      if (correspondingIndex > -1) {
        Vue.set(original.panelGroups[i].panels, correspondingIndex, updatedPanel);
      }
    }
  }
}
