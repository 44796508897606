import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-side-ratings-panel.vue';

@Component({
  mixins: [template],
})
export default class FundSideRatingsPanel extends Vue {
  @Prop()
  data: mws.FundSideRatingsPanel;

  getLiteratureEntriesFor(rating: mws.FundRating): mws.Literature[] {
    return rating.fundsAndDocuments
      .map((item) => item.literature)
      .filter((literature) => literature != null);
  }
}
