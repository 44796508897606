import EventBus from '../../../shared/EventBus';
import * as mws from '../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './daily-prices-search-block.vue';
import { debounce } from 'underscore';
import DailyPricesSearchColumn from './DailyPricesSearchColumn';

const loadSpinnerImgSrc = '/assets/images/common/load_spinner.svg';

@Component({
  mixins: [template],
  components: {
    DailyPricesSearchColumn
  }
})
export default class DailyPricesSearchBlock extends Vue {
  @Prop()
  table!: mws.Table;

  @Prop()
  filter: mws.DailyPricesFilter;

  @Prop()
  tabIndex: number;

  mobileFiltersOpened: boolean = false;
  searchDebounced: Function;
  loadSpinnerImgSrc = loadSpinnerImgSrc;
  isLoading: boolean = false;
  query: string = '';

  created() {
    this.searchDebounced = debounce(()=> {
      EventBus.$emit('dp-table-filter-by-text', this.tabIndex, this.query);
    }, 200);

    EventBus.$on('fund-daily-price-search-is-loading-change', (isLoading: boolean) => {
      this.isLoading = isLoading;
    });

    EventBus.$on('fund-daily-price-selected-date-change', (tabIndex) => {
      if (tabIndex === this.tabIndex) {
        this.query = '';
      }
    });
  }

  handleClearAllFilters() {
    this.query = '';
    EventBus.$emit('dp-table-all-filters-cleared', this.tabIndex);
  }

  clear(): void {
    this.query = '';
    EventBus.$emit('dp-table-filter-by-text', this.tabIndex, this.query);
  }
}
