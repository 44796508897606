import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './magazine-call-to-action-panel.vue';
import { CtaTextLinkElement} from '@/shared/MwsViewModels';

@Component({
  mixins: [template]
})

export default class MagazineCallToActionPanel extends Vue {
  @Prop()
  data: mws.CallToActionPanel;

  get getTextColour(): string {
    return this.data.textButtonColour != null && this.data.textButtonColour !== '' ? this.data.textButtonColour : 'white';
  }

  get getBackgroundColour(): string {
    return this.data.backgroundColour != null && this.data.backgroundColour !== '' ? this.data.backgroundColour : 'white';
  }

  get getCallToActionHeading() : string {
    return (this.data.callToActions[0] as CtaTextLinkElement)?.heading;
  }

  public handleCtaButtonClick() {
    window.open((this.data.callToActions[0] as CtaTextLinkElement)?.links[0]?.url);
  }
}