import { Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import NavigationBar from '../navigation-bar/NavigationBar';
import template from './background-panel.vue';
import Translatable from '../../../translation/Translatable';
import GenericLink from '../../../shared/components/link/GenericLink';
import BackgroundFactory from './background/BackgroundFactory';
import BackgroundInstance from './background/BackgroundInstance';

const logoImageSrc = '/assets/images/common/BG_logo_WHITE.svg';

@Component({
  mixins: [template],
  components: {
    NavigationBar,
    GenericLink,
  },
})
export default class BackgroundPanel extends Translatable {
    @Prop()
    data!: mws.BackgroundPanel;

    logoImageSrc = '';

    navMenuVisible = false;

    background: BackgroundInstance = null;

    async created() {
      this.logoImageSrc = logoImageSrc;
      this.background = new BackgroundFactory().create(this.data.background);
      return this.loadDictionary(['homepage_menu']);
    }

    get statementLines(): string[] {
      return (this.data.statementText || '')
        .split('\n')
        .filter((line) => line !== '');
    }

    get primeVideoUrl(): string {
      return this.background?.primeVideoUrl;
    }

    get altVideoUrl(): string {
      return this.background?.altVideoUrl;
    }

    get enableBackgroundVideo(): boolean {
      return Boolean(this.primeVideoUrl) && Boolean(this.altVideoUrl);
    }

    get panelStyles(): {[key: string]: string} {
      return this.background?.panelStyles || {};
    }

    get panelClasses(): {[key: string]: boolean} {
      const backgroundClasses = this.background?.panelClasses;
      return {
        ...(backgroundClasses || {}),
        ...{ 'nav-menu-visible': this.navMenuVisible },
      };
    }

    openNavMenu(): void {
      this.navMenuVisible = true;
    }

    closeNavMenu(): void {
      this.navMenuVisible = false;
    }
}
