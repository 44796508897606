import { Module } from 'vuex';
import { SearchCriteria, SearchResult, SearchResultSummary } from '@/shared/MwsViewModels';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    searchResults: {} as SearchResult,
    loadedSearchResults: {} as SearchResultSummary[],
    searchCriteria: {} as SearchCriteria,
    searchPopup: {
      isOpen: false,
    },
    currentUrl: '',
  },
  actions: {
    updateSearchResults({ commit }, data: { results: any, searchCriteria: SearchCriteria }) {
      commit('resetLoadedResults', {});
      commit('setSearchResults', data.results);
      commit('setSearchCriteria', data.searchCriteria);
    },
    loadResults({ commit }, perPageAmount: number) {
      commit('loadResults', perPageAmount);
    },
    openSearchPopup({ commit }, currentUrl: string) {
      commit('toggleSearchPopup', { isOpen: true, currentUrl });
    },
    closeSearchPopup({ commit }) {
      commit('toggleSearchPopup', { isOpen: false });
    },
  },
  getters: {
    searchResults: (state): SearchResultSummary => state.loadedSearchResults,
    totalSearchResults: (state): number => state.searchResults.totalResults,
    searchCriteria: (state): SearchCriteria => state.searchCriteria,
  },
  mutations: {
    setSearchResults(state, response: SearchResult) {
      state.searchResults = response;
    },
    setSearchCriteria(state, data: SearchCriteria) {
      state.searchCriteria = data;
    },
    loadResults(state, perPageAmount: number) {
      const totalNumberToLoad = state.loadedSearchResults.length + perPageAmount;
      const { searchResults } = state;

      state.loadedSearchResults = totalNumberToLoad <= searchResults.totalResults
        ? searchResults.results.slice(0, totalNumberToLoad)
        : searchResults.results;
    },
    resetLoadedResults(state) {
      state.loadedSearchResults = [];
    },
    toggleSearchPopup(state, data) {
      if (!data.isOpen) {
        state.searchCriteria = '';
        state.loadedSearchResults = [];
      } else state.currentUrl = data.currentUrl;
      state.searchPopup.isOpen = data.isOpen;
    },
  },
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
