import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './usm-distributions-panel.vue'
import * as mws from '../../../shared/MwsViewModels';
import UsmDistributionTable from './usm-distribution-table/UsmDistributionTable'
import { Table } from '../../../shared/MwsViewModels';
import FadeTransitionMixin from '../../../shared/fade-transition/FadeTransitionMixin';

@Component({
  mixins: [template],
  components: {
    UsmDistributionTable
  }
})
export default class UsmDistributionsPanel extends Mixins(FadeTransitionMixin) {
  @Prop()
  data: mws.UsmDistributionsPanel;

  currentTableIndex = 0;

  async onTabClick(index: number): Promise<void> {
    if (index === this.currentTableIndex) {
      return;
    }

    const tableList = this.$refs.tableList as HTMLElement;
    const currentlySelectedTable = tableList.children[this.currentTableIndex] as HTMLElement;
    const newSelectedTable = tableList.children[index] as HTMLElement;

    await this.fadeHide(currentlySelectedTable);
    this.currentTableIndex = index;
    await this.fadeShow(newSelectedTable);
  }
}
