import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './actual-investor-landing-panel.vue';
import * as mws from '../../../../shared/MwsViewModels';
import GenericLink from '../../../../shared/components/link/GenericLink';


@Component ({
    mixins: [template],
    components: {
      GenericLink
    }
})

export default class ActualInvestorLandingPanel extends Vue {
    @Prop()
    data!: mws.ActualInvestorLandingPanel

    backgroundStyle = '';

    mounted() {
        this.backgroundStyle = `background-image: url('${this.data.backgroundImage.url}')`;
      }
    }
