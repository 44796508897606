import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './actual-investor-1b-tile.vue';
import GenericLink from '../../../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class ActualInvestor1BTile extends Vue {
  @Prop()
  data!: mws.InfoLinkElement;

  get tileImage(): mws.Image | undefined {
    return (this.data.link as mws.ImageLink)?.image;
  }
}
