import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './literature-library-carousel.vue';
import Carousel from '../../../../shared/carousel/Carousel';
import carouselSettings from '../../carousel/carousel-settings';

@Component({
  mixins: [template],
  components: {
    Carousel,
  },
})
export default class LiteratureLibraryCarousel extends Vue {
  @Prop()
  data: mws.LiteratureLibraryOverallPanelCarousel;

  settings = carouselSettings;
}
