import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './actual-investor-header-menu.vue';
import * as mws from '../../shared/MwsViewModels';
import GenericLink from '../../shared/components/link/GenericLink';

const logoImageSrcWhite = '/assets/images/common/BG_logo_WHITE.svg';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class ActualInvestorHeaderMenu extends Vue {
  @Prop()
  links: mws.ILink[];

  @Prop({ default: null })
  logoUrl: string | null;

  @Prop()
  icNavigation: mws.IcNavigationBar;

  logoImageSrcWhite = '';

  async created() {
    this.logoImageSrcWhite = logoImageSrcWhite;
  }

  isCurrent(url: string): boolean {
    const resource = this.$route.fullPath;
    return url.endsWith(resource);
  }
}
