import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './carousel-panel.vue';
import CarouselExternalLink from './carousel-items/CarouselExternalLink';
import Carousel from '../../../shared/carousel/Carousel';
import carouselSettings from './carousel-settings';

import 'vue-slick-carousel/src/slick-theme.css';

@Component({
  mixins: [template],
  components: {
    Carousel,
    CarouselExternalLink,
  },
})
export default class CarouselPanel extends Vue {
  @Prop()
  data: mws.CarouselPanel;

  private width = 0;

  private mobileView = false;

  mounted() {
    this.width = window.innerWidth;
    if(this.width < 800) {
      this.mobileView = true;
    }
  }

  settings = carouselSettings;
}
