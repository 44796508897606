const riskSeparator = '|';

function getSessionAcceptedRisks(cookie) {
  return (cookie || '')
    .split(riskSeparator)
    .filter((riskId) => riskId !== '');
}

function getPersistentAcceptedRisks(cookie) {
  return cookie ? [cookie] : [];
}

function isRiskAccepted(riskId, sessionCookie, persistentCookie) {
  return [
    ...getSessionAcceptedRisks(sessionCookie),
    ...getPersistentAcceptedRisks(persistentCookie),
  ].indexOf(riskId) >= 0;
}

module.exports = {
  riskSeparator,
  sessionCookieName: 'bg_risk_warnings',
  persistentCookieName: 'bg_chosen_risk_warning',
  getSessionAcceptedRisks,
  getPersistentAcceptedRisks,
  isRiskAccepted,
};
