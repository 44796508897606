import { Component, Prop, Vue } from 'vue-property-decorator';
import gsap from 'gsap';
import template from './calculator-panel.vue';
import CellData from './CellData';
import Cell from './components/Cell';
import Currency from './Currency';
import cellsExapleData from './cellsDataSource';
import * as mws from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
  components: {
    Cell,
  },
})
export default class CalculatorPanel extends Vue {
  @Prop()
  data!: mws.CalculatorPanel;

  cells: CellData[] = cellsExapleData;

  currencies: Currency[] = [
    { id: 1, value: 1.274799633, text: 'GBP' },
    { id: 5, value: 0.682349794, text: 'AUD' },
    { id: 2, value: 0.758380100, text: 'CAD' },
    { id: 8, value: 0.148184371, text: 'DKK' },
    { id: 3, value: 1.104650135, text: 'EUR' },
    { id: 4, value: 0.007093205, text: 'JPY' },
    { id: 9, value: 0.098466871, text: 'NOK' },
    { id: 7, value: 0.099227514, text: 'SEK' },
    { id: 10, value: 0.029297590, text: 'THB' },
    { id: 6, value: 1, text: 'USD' },
    { id: 11, value: 0.140437604, text: 'CNH' },
    { id: 12, value: 0.032583373, text: 'TWD' },
  ];

  selectedCurrency = this.currencies[0];

  submitBtnEnabled = false;

  investmentInputValue = '';

  get displayedCells(): CellData[] {
    return this.cells.filter((c) => c.show);
  }

  investmentKeyDown = (event: KeyboardEvent): void => {
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57)
      && (event.keyCode < 96 || event.keyCode > 105)
      && event.keyCode !== 8
      && event.keyCode !== 13
      && event.keyCode !== 37
      && event.keyCode !== 39
    ) {
      event.preventDefault();
    }
  };

  investmentKeyUp(): void {
    this.submitBtnEnabled = this.investmentInputValue !== '';
    this.investmentInputValue = this.investmentInputValue
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    this.cells.forEach((c) => this.hideCell(c));

    if (this.investmentInputValue) {
      const value = this.selectedCurrency.value * parseFloat(this.investmentInputValue.replace(/,/g, ''));

      this.cells.forEach((c) => {
        c.additionalClass = '';
        c.calculatedValue = 0.0;
        c.secondCalculatedValue = 0.0;

        const calc = Math.round(value * c.source);
        const calc2 = Math.round(value * c.secondSource);
        if (Math.round(calc) !== 0) {
          gsap.to(c, { duration: 2.0, calculatedValue: calc });
          gsap.to(c, { duration: 2.0, secondCalculatedValue: calc2 });
          c.show = true;
        } else {
          c.show = false;
        }

        if (calc.toString().length > 8) {
          c.additionalClass = 'smaller-text';
        } else if (calc.toString().length > 6) {
          c.additionalClass = 'small-text';
        }
      });
    }
  }

  private hideCell(c: CellData): void {
    c.show = false;
  }
}
