import Vue from 'vue';
import { Module } from 'vuex';
import { BrowserInfo } from '@/shared/browser/browser-info';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    browserInfo: {} as BrowserInfo,
    navbarOverflow: false,
  },
  actions: {
    setBrowserInfo({ commit }, info) {
      commit('setBrowserInfo', info);
    },
    setNavbarOverflow({ commit }, overflow) {
      commit('setNavbarOverflow', overflow);
    },
  },
  getters: {
    isMobile: (state): boolean => state.browserInfo.isMobile,
  },
  mutations: {
    setBrowserInfo(state, info) {
      Vue.set(state, 'browserInfo', info);
    },
    setNavbarOverflow(state, overflow) {
      Vue.set(state, 'navbarOverflow', overflow);
    },
  },
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
