import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './one-column-text-image-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import { ReactInVue  } from 'vuera'
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    'SectionContainer': ReactInVue(SectionContainer)
  }
})
export default class OneColumnTextImagePanel extends Vue {
  @Prop()
  data!: mws.OneColumnTextImagePanel;

  public get isImagePresent(): boolean {
    return this.data.image != null;
  }

  public get isSecondParagraphPresent(): boolean {
    return !!this.data.secondParagraph;
  }
}
