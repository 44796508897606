import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './client-login-panel.vue';
import * as mws from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
})
export default class ClientLoginPanel extends Vue {
  @Prop()
  data!: mws.ClientLoginPopupLink;
  localData: mws.ClientLoginPopupLink = JSON.parse(JSON.stringify(this.data));

  get links(): mws.ILink[] {
    return this.localData.links;
  }

  handleLinkClick(event: Event, link: mws.ClientLoginLink) {
    const childLink: mws.ILink = link.link;
    if (childLink.type === 'ClientLoginPopupLink') {
      this.localData = childLink as mws.ClientLoginPopupLink;
      event.preventDefault();
    }
  }

  getContentCssClasses(): string[] {
    return this.links && this.links.length > 0 ? [`links-${this.links.length}`] : [];
  }

  getUrl(link: mws.ClientLoginLink): string {
    return link.link.url;
  }

  getTarget(link: mws.ClientLoginLink): string {
    const childLink: mws.ILink = link.link;

    if (childLink.type !== 'TextLink') {
      return '';
    }

    const textLink: mws.TextLink = childLink as mws.TextLink;
    return textLink.openInNewWindow ? 'target=\'_blank\'' : '';
  }

  resetDialogState(): void {
    this.localData = JSON.parse(JSON.stringify(this.data));
  }
}
