import Vue from 'vue';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  toggleWarningPopup(state, data) {
    Vue.set(state, 'warningPopup', data);
  },
  toggleRiskWarningPopup(state, data) {
    Vue.set(state, 'riskWarningPopup', data);
  },
  populateSelectLocationPopup(state, data) {
    state.selectLocationPopup.globalNavigation = data;
  },
  toggleSelectLocationPopup(state, data) {
    state.selectLocationPopup.isOpen = data.isOpen;
    state.selectLocationPopup.onCloseRedirectUrl = data.onCloseRedirectUrl
  },
  setLocationByIP(state, data) {
    Vue.set(state, 'userLocationByIP', data);
  },
  setPendingGeoIp2Request(state, data) {
    Vue.set(state, 'pendingGeoIp2Request', data);
  },

  populateRedirectSelectorPopUp(state, data) {
    state.redirectSelectorPopUp.globalNavigation = data;
  },
  toggleRedirectSelectorPopUp(state, data) {
    state.redirectSelectorPopUp.isOpen = data.isOpen;
  },

  toggleMenuPopup(state, data) {
    state.menuPopup.isOpen = data.isOpen;
  },
  updateMenuPopupData(state, data) {
    state.menuPopup.secondaryLinks = data.secondaryLinks;
    state.menuPopup.additionalLinks = data.additionalLinks;
    state.menuPopup.globalSelector = data.globalSelector;
  },
  toggleAthenaeumPopup(state, data) {
    Vue.set(state, 'athenaeumPopup', data);
  },
  toggleClientLoginPopup(state, data) {
    Vue.set(state, 'clientLoginPopup', data);
  },
  toggleVideoPopup(state, data) {
    Vue.set(state, 'videoPopup', data);
  },
  toggleLiteratureOrderPopup(state, data) {
    Vue.set(state, 'literatureOrderPopup', data);
  },
  toggleFundSubscriptionPopup(state, data) {
    state.fundSubscriptionPopup.isOpen = data.isOpen;
  },
  setFundSubscriptionPopupLink(state, data) {
    state.fundSubscriptionPopup.link = data;
  },
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
