import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '../../shared/MwsViewModels';
import template from './insights-video-page.vue';
import PageNavigationPanel from '../../panel/components/page-navigation/PageNavigationPanel';
import RelatedFunds from '../related-funds/RelatedFunds';
import RelatedInsights from '../related-insights/RelatedInsights';
import VideoPanel from './VideoPanel';
import IcSignUpBlock from '../../panel/components/intellectual-capital/ic-sign-up-block/IcSignUpBlock';
import IcFilteringMixin from '../../shared/intellectual-capital/IcFilteringMixin';
import SideShareBox from '../side-share-box/SideShareBox';

@Component({
  mixins: [template],
  components: {
    PageNavigationPanel,
    RelatedFunds,
    RelatedInsights,
    VideoPanel,
    IcSignUpBlock,
    SideShareBox
  },
})
export default class InsightsVideoPage extends Mixins(IcFilteringMixin) {
  @Prop()
  page!: mws.InsightsVideoPage;

  @Prop({ default: false })
  debug: boolean;

  mounted() {
    document.body.classList.add('bginsights-dark-background');
    this.page.icNavigation.backLinkUrl += this.getIcHomeQuerystringFromCookie();
  }

  destroyed() {
    document.body.classList.remove('bginsights-dark-background');
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
