import { throttle } from 'underscore';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './positioned-responsive-image.vue';
import { Image, FocalPoint } from '../../MwsViewModels';

@Component({
  mixins: [template],
})
export default class PositionedResponsiveImage extends Vue {
    @Prop()
    image!: Image;

    @Prop({ default: false })
    tint!: boolean;

    @Prop({ default: false })
    square!: boolean;

    @Prop({ default: false })
    greyscale!: boolean;

    @Prop({ default: () => [] })
    extraClasses!: string[];

   _eventHandler = null;

    mounted(){
      this.setFocalPoint();
      this._eventHandler = throttle(this.setFocalPoint, 100);
      window.addEventListener("resize", this._eventHandler);
    }

    beforeDestroy() {
      window.removeEventListener('resize', this._eventHandler);
      this._eventHandler = null;
    }

    setFocalPoint(){
      if(!this.image || !this.image.width || !this.image.height){
        return;
      }

      var focalPoint = this.image.focalPoint;
      if(focalPoint == null || focalPoint.left == null || focalPoint.top == null){
        return;
      }

      if(this.isSvg()){
        return;
      }

      let containerHeight = this.$el.clientHeight;
      let containerWidth = this.$el.clientWidth;
      let img = this.$refs.imgElement as HTMLElement;

      let imageAspect = this.image.width / this.image.height;
      let containerAspect = containerWidth / containerHeight;

      if(!imageAspect || !containerAspect){
        return;
      }

      let width, height, left, top;

      if (imageAspect > containerAspect) {
        var widthNumeric = containerHeight * imageAspect;
        var focalPointX = focalPoint.left * widthNumeric;
        var distanceX = focalPointX - containerWidth / 2;
        var deltaWidth = widthNumeric - containerWidth;
        width = widthNumeric + "px";
        height = "100%";
        left = -Math.max(0, Math.min(deltaWidth, distanceX)) + "px";
        top = 0;
      } else {
          // Width should be 100% of container, size height accordingly
          var heightNumeric = containerWidth / imageAspect;
          var focalPointY = focalPoint.top * heightNumeric;
          var distanceY = focalPointY - containerHeight / 2;
          var deltaHeight = heightNumeric - containerHeight;
          height = heightNumeric + "px";
          width = "100%";
          top = -Math.max(0, Math.min(deltaHeight, distanceY)) + "px";
          left = 0;
      }

      img.style.height = height;
      img.style.width = width;
      img.style.left = left;
      img.style.top = top;
    }

    public get topClasses(): string[] {
      const result = ['positioned-image'];
      if (this.tint) {
        result.push('image-tint-after');
      }
      return result.concat(this.extraClasses);
    }

    public get imageSrc(): string {
      let additionalParameters = '';
      if (!this.isSvg()) {
        if (this.square) additionalParameters += '&heightratio=1';
        if (this.greyscale) additionalParameters += '&filter=GreyScale';
      }
      return this.image.url + additionalParameters;
    }

    public isSvg(): boolean {
      return this.image.mimeType === 'image/svg+xml';
    }
}
