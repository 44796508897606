import * as mws from '../../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './canada-performance-and-portfolio-chart.vue';

@Component({
  mixins: [template]
})
export default class CanadaPerformanceAndPortfolioChart extends Vue {
    @Prop()
    chartData: mws.CanadaPerformanceAndPortfolioChart;

    @Prop()
    tabIndex: number;

    @Prop() 
    itemIndex: number;
}