import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './intellectual-capital-item.vue';
import DynamicTextFit from '../../../../shared/helpers/DynamicTextFit';
import IcFilteringMixin from '../../../../shared/intellectual-capital/IcFilteringMixin';

const roseSrc = '/assets/images/fund/blue-rose.jpeg';
const icItemSvg = '/assets/images/svg/sprite/sprite.svg';

@Component({
  mixins: [template],
})
export default class IntellectualCapitalItem extends Mixins(DynamicTextFit, IcFilteringMixin) {
  @Prop()
  item: mws.IInsightPageLink;

  @Prop()
  colourTheme: string;

  backgroundUrl = null;
  dataLayer = null;

  private bottomBlockMargin = 40;

  mounted() {
    this.backgroundUrl = `url("${roseSrc}")`;
    this.registerDynamicTextFitEvent(
      this.$refs.blockElement as Element,
      this.$refs.bottomElement as Element,
      this.$refs.descriptionElement as Element,
      this.item.description,
      this.bottomBlockMargin,
      Number(process.env.VUE_APP_INSIGHT_DESC_WORDCOUNT),
    );
    this.dataLayer = window.dataLayer || [];
  }

  updated() {
    this.registerDynamicTextFitEvent(
      this.$refs.blockElement as Element,
      this.$refs.bottomElement as Element,
      this.$refs.descriptionElement as Element,
      this.item.description,
      this.bottomBlockMargin,
    );
  }

  pushToDataLayer(action: number) : void {
    this.dataLayer.push({
      "event": "Insights Asset",
      "eventCategory": this.item.itemType,
      "eventAction": action,
      "eventLabel": this.item.title
    });
  }

  get itemSvg(): string {
    switch (this.item.itemType) {
      case 'Video':
        return `${icItemSvg}#article-watch`;
      case 'Podcast':
        return `${icItemSvg}#article-listen`;
      default:
        return this.item.dwellText ? `${icItemSvg}#article-read` : '';
    }
  }

  get dwellText(): string {
    let dwellTime: string = this.item.dwellTime ? `${this.item.dwellTime} MIN` : "";
    return dwellTime ? `${dwellTime} ${this.item.dwellText}` : this.item.dwellText; 
  }

  get imageStyle(): {[key: string]: string} {
    return {
      backgroundImage: this.item.image?.url ? `url("${this.item.image.url}")` : null,
    };
  }

  get windowTarget(): string{
    return this.item.itemType === 'ExternalLink' || this.item.itemType === 'Document' ? "_blank" : "";
  }

  get elementClass(): string {    
    let classes = this.colourTheme != null && this.colourTheme === "dark" ? 
      "bgfunds-carousel-item bginsights-insight bginsights-insight" :
      "bgfunds-carousel-item bginsights-insight bginsights-insight--light";

      classes += this.item.isAnchored ? " bginsights-insight--featured" : "";
      
      return this.item.isArchived ? classes.concat(" bginsights-insight-archived") : classes;
  }

  handleInsightItemClick() {
    if (this.item.itemType !== 'ExternalLink' && this.item.itemType !== 'Document') {
      this.setIcHomeQuerystringCookie(JSON.stringify(this.$router.currentRoute.query));
    }
  }
}
