import { Component, Vue, Prop } from 'vue-property-decorator';
import * as mws from '../../shared/MwsViewModels';
import template from './insights-podcast-page.vue';
import PageNavigationPanel from '../../panel/components/page-navigation/PageNavigationPanel';
import RelatedFunds from '../related-funds/RelatedFunds';
import RelatedInsights from '../related-insights/RelatedInsights';
import PodcastPanel from './PodcastPanel';
import IcSignUpBlock from '../../panel/components/intellectual-capital/ic-sign-up-block/IcSignUpBlock';
import SideShareBox from '../side-share-box/SideShareBox';

@Component({
  mixins: [template],
  components: {
    PageNavigationPanel,
    RelatedFunds,
    RelatedInsights,
    PodcastPanel,
    IcSignUpBlock,
    SideShareBox
  },
})
export default class InsightsPodcastPage extends Vue {
  @Prop()
  page!: mws.InsightsPodcastPage;
  @Prop({ default: false })
  debug: boolean;
  mounted() {
    document.body.classList.add('bginsights-dark-background');
  }
  destroyed() {
    document.body.classList.remove('bginsights-dark-background');
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}