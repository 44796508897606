import { IPanel } from '@/shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './panel-info-button.vue';
import BaseInfoButton from '../base-info-button/BaseInfoButton';

const anchorSrc = '/assets/images/common/anchor.png';

@Component({
  mixins: [template],
  components: {
    BaseInfoButton,
  },
})
export default class PanelInfoButton extends Vue {
  @Prop()
  panel: IPanel;

  @Prop()
  anchor: string;

  get anchorStyles(): {[key: string]: string} {
    return { 'background-image': `url("${anchorSrc}")` };
  }

  get anchorLink(): string {
    return this.anchor ? `#${this.anchor}` : null;
  }
}
