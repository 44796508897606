import { Component, Prop, Mixins } from 'vue-property-decorator';
import { FundPanelGroup } from '@/shared/MwsViewModels';
import template from './fund-main.vue';
import FundRelatedContentPanel from '../../panels/fund-related-content/FundRelatedContentPanel';
import FundOverviewPanel from '../../panels/fund-overview/FundOverviewPanel';
import FundDonutChartPanel from '../../panels/fund-donut-chart/FundDonutChartPanel';
import FundPromoPanel from '../../panels/fund-promo/FundPromoPanel';
import FundDiscreteAndPeriodicPerformanceTablePanel
  from '../../panels/fund-discrete-and-periodic-performance-table/FundDiscreteAndPeriodicPerformanceTablePanel';
import FundPerformanceChartPanel from '../../panels/fund-performance-chart/FundPerformanceChart';
import FundFullWidthQuotePanel from '../../panels/fund-full-width-quote/FundFullWidthQuotePanel';
import FundPeoplePanel from '../../panels/fund-people/FundPeoplePanel';
import FundPlatformPanel from '../../panels/fund-platform/FundPlatformPanel';
import FundLiteratureLibraryPanel from '../../panels/fund-literature-library/FundLiteratureLibraryPanel';
import FundRiskWarningPanel from '../../panels/fund-risk-warning/FundRiskWarningPanel';
import FundTextWithBackgroundPanel from '../../panels/fund-text-with-background/FundTextWithBackgroundPanel';
import FundTopHoldingsPanel from '../../panels/fund-top-holdings/FundTopHoldingsPanel';
import CarouselPanel from '../../../panel/components/carousel/CarouselPanel';
import FixedAnchor from '../../anchors/fixed-anchor/FixedAnchor';
import PanelIdentifierMixin from '../../../shared/panel-identification/PanelIdentificationMixin';
import PanelInfoButton from '../../../debug/panel-info-button/PanelInfoButton';
import PanelGroupHeading from '../../../shared/panel-group-heading/PanelGroupHeading';
import FundIntellectualCapitalPanel from '../../panels/fund-intellectual-capital/FundIntellectualCapitalPanel';
import UsmDistributionsPanel from '../../panels/usm-distributions/UsmDistributionsPanel';
import UsmPerformanceAndPortfolioPanel from '../../panels/usm-performance-and-portfolio/UsmPerformanceAndPortfolioPanel';
import HowToInvestPanel from '../../panels/how-to-invest/how-to-invest';
import UcitPerformancePanel from '../../panels/ucit-performance-panel/UcitPerformancePanel'
import FundDailyPricesPanel from '../../../panel/components/daily-prices/FundDailyPricesPanel';
import FundDailyPricesShortlistPanel from '../../../panel/components/daily-prices/shortlist/FundDailyPricesShortlistPanel';
import PeoplePanel from '../../panels/people-panel/PeoplePanel';
import ExceptionInPanel from '../../../shared/exception-in-panel/ExceptionInPanel';
import AccordionListPanel from '../../../panel/components/accordion/AccordionListPanel';
import CanadaDistributionsPanel from '../../panels/canada-distributions/CanadaDistributionsPanel';
import CanadaPerformanceAndPortfolioPanel from '../../../fund/panels/canada-performance-and-portfolio/CanadaPerformanceAndPortfolioPanel';
import UsmFundStockLevelAttributionPanel from '../../panels/usm-fund-stock-level-attribution/UsmFundStockLevelAttributionPanel';
import CanadaFundStockLevelAttributionPanel from '../../panels/canada-fund-stock-level-attribution/CanadaFundStockLevelAttributionPanel';

@Component({
  mixins: [template],
  components: {
    FixedAnchor,
    PanelInfoButton,
    FundRelatedContentPanel,
    FundOverviewPanel,
    FundDonutChartPanel,
    FundPromoPanel,
    FundPerformanceChartPanel,
    FundDiscreteAndPeriodicPerformanceTablePanel,
    FundFullWidthQuotePanel,
    FundPeoplePanel,
    FundPlatformPanel,
    FundLiteratureLibraryPanel,
    FundRiskWarningPanel,
    FundTextWithBackgroundPanel,
    FundDailyPricesPanel,
    FundDailyPricesShortlistPanel,
    FundTopHoldingsPanel,
    FundIntellectualCapitalPanel,
    FundCarouselPanel: HowToInvestPanel,
    PanelGroupHeading,
    UsmDistributionsPanel,
    UsmPerformanceAndPortfolioPanel,
    CanadaPerformanceAndPortfolioPanel,
    UcitPerformancePanel,
    PeoplePanel,
    ExceptionInPanel,
    AccordionListPanel,
    CanadaDistributionsPanel,
    UsmFundStockLevelAttributionPanel,
    CanadaFundStockLevelAttributionPanel
  },
})
export default class FundMain extends Mixins(PanelIdentifierMixin) {
  @Prop()
  groups: FundPanelGroup[];

  @Prop({ default: false })
  debug: boolean;
}
