import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './cell.vue';
import CellData from '../CellData';
import AllBelow from './components/AllBelow';
import ImageAbove from './components/ImageAbove';
import NumberAbove from './components/NumberAbove';
import TextWithNumber from './components/TextWithNumber';
import TwoIndicators from './components/TwoIndicators';

const car = '/assets/images/calculator/car.svg';
const drops = '/assets/images/calculator/drops.svg';
const prevent = '/assets/images/calculator/prevent.svg';
const money = '/assets/images/calculator/money.svg';
const mobile = '/assets/images/calculator/mobile.svg';
const waste = '/assets/images/calculator/waste.svg';
const disese = '/assets/images/calculator/disese.svg';
const papers = '/assets/images/calculator/papers.svg';
const farm = '/assets/images/calculator/farm.svg';
const coins = '/assets/images/calculator/coins.svg';
const medical = '/assets/images/calculator/medical.svg';
const cups = '/assets/images/calculator/cups.svg';
const cars = '/assets/images/calculator/cars.svg';
const trash = '/assets/images/calculator/trash.svg';
const car2 = '/assets/images/calculator/car2.svg';
const merchants = '/assets/images/calculator/merchants.svg';
const lifestyle = '/assets/images/calculator/lifestyle.svg';
const education = '/assets/images/calculator/education.svg';
const microscope = '/assets/images/calculator/microscope.svg';

@Component({
  mixins: [template],
  components: {
    AllBelow,
    ImageAbove,
    NumberAbove,
    TextWithNumber,
    TwoIndicators
  },
})
export default class Cell extends Vue {
  @Prop()
  data!: CellData;

  @Prop()
  index: number;

  icons = {
    "car": car,
    "drops": drops,
    "money": money,
    "mobile": mobile,
    "waste": waste,
    "disese": disese,
    "papers": papers,
    "farm": farm,
    "coins": coins,
    "medical": medical,
    "cups": cups,
    "cars": cars,
    "trash": trash,
    "car2": car2,
    "merchants": merchants,
    "lifestyle": lifestyle,
    "education": education,
    "microscope": microscope
  };

  get cellData(): CellData {
    return this.data;
  }

  get counterText(): string {
    return Math.floor(this.cellData.calculatedValue)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get secondCounterText(): string {
    return Math.floor(this.cellData.secondCalculatedValue)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
