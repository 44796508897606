import { ContentPageHeaderPanel as IContentPageHeaderPanel } from '@/shared/MwsViewModels'
import HeaderGeneral from '../../lib/HeaderGeneral'
import HeaderSideBySide from '../../lib/HeaderSideBySide'
import React from 'react'

export interface ContentPageHeaderPanelProps {
  data: IContentPageHeaderPanel
}

const ContentPageHeaderPanel = ({ data }: ContentPageHeaderPanelProps) => {
  const LayoutType = data?.layoutType

  switch (LayoutType) {
    case 'side-by-side': {
      return (
        <HeaderSideBySide
          heading={data?.heading}
          description={data?.description}
          image={data?.backgroundImage}
          tabs={data?.links}
        />
      )
    }
    default: {
      return (
        <HeaderGeneral
          heading={data?.heading}
          description={data?.description}
          image={data?.backgroundImage}
          links={data?.links}
        />
      )
    }
  }
}

export default ContentPageHeaderPanel
