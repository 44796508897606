import { Vue, Component, Prop } from 'vue-property-decorator';
import { FundPanelGroup } from '@/shared/MwsViewModels';
import template from './fund-header.vue';
import PageNavigationPanel from '../../panel/components/page-navigation/PageNavigationPanel';
import FundLandingPageHeaderPanel from '../panels/fund-landing-page-header/FundLandingPageHeaderPanel';
import ExceptionInPanel from '../../shared/exception-in-panel/ExceptionInPanel';

@Component({
  mixins: [template],
  components: {
    PageNavigationPanel,
    FundLandingPageHeaderPanel,
    ExceptionInPanel,
  },
})
export default class FundHeader extends Vue {
  @Prop()
  groups: FundPanelGroup[];
}
