import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './generic-text-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    'SectionContainer': ReactInVue(SectionContainer),
  }
})
export default class GenericTextPanel extends Vue {
  @Prop()
  data!: mws.GenericTextPanel;
}
