import { Component, Vue } from 'vue-property-decorator';
import { Metadata } from './shared/MwsViewModels';

@Component
export default class MetaPage extends Vue {
  // hook for metainfo takes this method and use to render meta tags
  public metaInfo(): {[key: string]: any} {
    const metadata = {
      title: this.metadata?.title + ' | Baillie Gifford',
      meta: [],
      link: []
    };

    const swiftype: string = 'swiftype';
    const stringDataType: string = 'string';
    const dateDataType: string = 'date';
    const enumDataType: string = 'enum';

    const meta: any[] = [];
    const link: any[] = [];

    if(this.metadata == null){
      return meta;
    }

    if (this.metadata.description) {
      meta.push({ name: 'description', content: this.metadata.description, class: swiftype, 'data-type': stringDataType });
    }

    if (this.metadata.keywords) {
      meta.push({ name: 'keywords', content: this.metadata.keywords });
    }

    if (this.metadata.hideFromRobots) {
      meta.push({ name: 'robots', content: 'none' });
    }

    if (this.metadata.channelLevelPath) {
      meta.push({ name: 'channel_level_path', content: this.metadata.channelLevelPath, class: swiftype, 'data-type': stringDataType });
    }

    if (this.metadata.date) {
      meta.push({ name: 'date', content: this.metadata.date, class: swiftype, 'data-type': dateDataType });
    }

    if (this.metadata.duration) {
      meta.push({ name: 'duration', content: this.metadata.duration, class: swiftype, 'data-type': stringDataType });
    }

    if (this.metadata.type) {
      meta.push({ name: 'type', content: this.metadata.type, class: swiftype, 'data-type': enumDataType });
    }

    if (this.metadata.marketoMunchkinId) {
      meta.push({ name: 'marketoMunchkinId', content: this.metadata.marketoMunchkinId, 'data-type': stringDataType });
    }

    if (this.metadata.eyebrow) {
      meta.push({ name: 'eyebrow', content: this.metadata.eyebrow, class: swiftype, 'data-type': stringDataType });
    }
    if (this.metadata.pageType) {
      meta.push({ name: 'pageType', content: this.metadata.pageType, class: swiftype, 'data-type': enumDataType });
    }

    this.addSocialMediaTags(meta);

    if (this.metadata.canonicalUrl) {
      link.push({ rel: 'canonical', href: this.metadata.canonicalUrl });
    }

    metadata.meta = meta;
    metadata.link = link;
    return metadata;
  }

  private addSocialMediaTags(meta: any[]) {

    if(!this.metadata.sharingTitle ||
      !this.metadata.sharingDescription ||
      !this.metadata.sharingImage) {
        return;
      }

      let type = "website";

      switch(this.metadata.sharingType.toLowerCase()) {
        case "article": type = "article";
          break;
        case "video": type = "video";
          break;
      }

      meta.push({ property: 'og:type', content: this.metadata.pageType });
      meta.push({ property: 'og:title', content: this.metadata.sharingTitle });
      meta.push({ property: 'og:description', content: this.metadata.sharingDescription });
      meta.push({ property: 'og:image', content: this.metadata.sharingImage.url });
      meta.push({ property: 'og:url', content: this.metadata.canonicalUrl});
      meta.push({ property: 'og:site_name', content: "Baillie Gifford"});

      if (this.metadata.sharingImage.altText) {
        meta.push({ property: 'og:image:alt', content: this.metadata.sharingImage.altText });
      }

      meta.push({ name: 'twitter:card', content: "summary_large_image"});
      meta.push({ name: 'twitter:creator', content: "@BaillieGifford"});
      meta.push({ name: 'twitter:site', content: "@BaillieGifford"});
      meta.push({ name: 'twitter:domain', content: "www.bailliegifford.com"});
      meta.push({ name: 'twitter:title', content: this.metadata.sharingTitle});
      meta.push({ name: 'twitter:description', content: this.metadata.sharingDescription});
      meta.push({ name: 'twitter:image:src', content: this.metadata.sharingImage.url});

      if (this.metadata.sharingImage.altText) {
        meta.push({ name: 'twitter:image:alt', content: this.metadata.sharingImage.altText});
      }
  }

  private get metadata(): Metadata {
    return this.$store.getters.pageMetainfo;
  }
}
