import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './insight-card-panel.vue';
import { ReactInVue } from 'vuera';
import InsightCardPanelReact from '../../../react/rebrand-lite/panels/InsightCardPanel';

@Component({
  mixins: [template],
  components: {
    InsightCardPanelReact: ReactInVue(InsightCardPanelReact),
  },
})
export default class InsightCardPanel extends Vue {
  @Prop()
  data: mws.InsightCardPanel;
}
