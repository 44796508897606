
import React from 'react'
import { IPanel } from '@/shared/MwsViewModels'
import { isFooterPanel, isNavigationPanel } from '../../types/typeguards'
import FooterWithImagesAndLinksPanel from '../../panels/FooterWithImagesAndLinksPanel'
import GlobalHeader from '../../panels/GlobalHeader'
import PanelComponent from '../PanelComponent'

export interface PanelGroupProps {
  /**
   * Description of prop. This will display in Storybook
   */
  panels: IPanel[]
}

const PanelGroup = ({ panels }: PanelGroupProps) => {
  const navigationPanel = panels.find((panel) => isNavigationPanel(panel))
  const footerPanel = panels.find((panel) => isFooterPanel(panel))
  const restOfPanels = panels.filter(
    (panel) => !isNavigationPanel(panel) && !isFooterPanel(panel)
  )

  return (
    <>
      {navigationPanel && isNavigationPanel(navigationPanel) && (
        <GlobalHeader data={navigationPanel} />
      )}
      <main>
        {restOfPanels.map((panel) => (
          <PanelComponent
            data={panel}
            key={panel.id}
          />
        ))}
      </main>
      {footerPanel && isFooterPanel(footerPanel) && (
        <FooterWithImagesAndLinksPanel data={footerPanel} />
      )}
    </>
  )
}

export default PanelGroup
