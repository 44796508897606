import { Component, Prop, Vue } from 'vue-property-decorator';
import { ILink } from '@/shared/MwsViewModels';
import template from './navigation-bar.vue';
import GenericLink from '../../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  name: 'NavigationBar',
  components: {
    GenericLink,
  },
})
export default class NavigationBar extends Vue {
  @Prop()
  primary: ILink[];

  @Prop()
  secondary: ILink[];
}
