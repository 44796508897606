import classnames from 'classnames/bind'
import { IInterruptor } from '@/shared/MwsViewModels'
import RichText from '../../lib/RichText'
import SectionContainer from '../../lib/SectionContainer'
import * as styles from './Interruptor.module.scss'
import React from 'react'
import Heading from '../Heading'
import StyledLink from '../StyledLink'
import ImageComponent from '../ImageComponent'

const cx = classnames.bind(styles)

export interface InterruptorProps {
  data: IInterruptor
}

const Interruptor = ({ data }: InterruptorProps) => {
  const ctaLink = data?.link
  const hasImage = data?.image?.url
  const hasDescription = data?.bodyText
  const headingClass = !hasDescription ? 'largeHeading' : ''
  const imageDirectionRight =
    data?.imageDirection === 'Right' ? 'imageRight' : ''
  const contentLeftMargin =
    data?.imageDirection === 'Right' ? 'noMarginLeft' : ''
  const fullWidthText = hasImage ? "" : 'noImage'

  return (
    <SectionContainer
      className={cx('interruptorContainer')}
      customPaddingWithBackground={true}
      coloursOverride={data?.colours}
    >
      <>
        <div className={cx('interruptor')}>
          {hasImage && (
            <div className={cx('interruptorImage', imageDirectionRight)}>
              <ImageComponent
                className={cx('imageGrow')}
                ratio={'1:1'}
                data={data.image}
              />
            </div>
          )}
          <div className={cx('contentContainer', contentLeftMargin, fullWidthText)}>
            {data?.eyebrow && <div className={cx('eyebrow')}>{data.eyebrow}</div>}
            {data?.subheading && (
              <Heading
                className={cx('heading', headingClass)}
                tag={'H2'}
                text={data.subheading}
              />
            )}
            {hasDescription && (
              <RichText
                className={cx('description')}
                copy={data?.bodyText}
              />
            )}

            {ctaLink?.url && (
              <div className={cx('cta')}>
                <StyledLink
                  type="link"
                  data={ctaLink}
                />
              </div>
            )}
          </div>
        </div>
      </>
    </SectionContainer>
  )
}

export default Interruptor
