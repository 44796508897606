import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import template from './insights-article-page.vue';
import * as mws from '../../shared/MwsViewModels';
import ScriptLoadingMixin from '../../shared/script-loading/ScriptLoadingMixin';
import RelatedInsights from '../related-insights/RelatedInsights';
import RelatedFunds from '../related-funds/RelatedFunds';
import InsightsSignUp from '../insights-sign-up/InsightsSignUp';
import SideRelatedInsights from '../side-related-insights/SideRelatedInsights';
import PageNavigationPanel from '../../panel/components/page-navigation/PageNavigationPanel';
import MenuPopup from '../../popups/menu/MenuPopup';
import IcFilteringMixin from '../../shared/intellectual-capital/IcFilteringMixin';
import axios from 'axios';
import MagazineContentPageHeaderPanel from '../../panel/components/content-page-header/magazine-content-page-header/MagazineContentPageHeaderPanel';

export interface VideoItemEntry {
  Title: string;
  PlaylistUrl: string;
}

@Component({
  mixins: [template],
  components: {
    RelatedInsights,
    RelatedFunds,
    InsightsSignUp,
    SideRelatedInsights,
    PageNavigationPanel,
    MagazineContentPageHeaderPanel,
    MenuPopup,
  },
})
export default class InsightsArticlePage extends Mixins(ScriptLoadingMixin) {
  @Prop()
  page: mws.InsightsArticlePage;
  innerBodyCode = '';

  mounted() {
    const parser = new DOMParser();
    const articleRoot = parser.parseFromString(this.page.article.content, 'text/html');
    this.importArticleStyles(articleRoot);
    this.importArticleScripts(articleRoot);
    this.initialiseArticleHtml(articleRoot);
    this.cloneBodyClasses(articleRoot);
    Vue.nextTick(this.injectComponentsIntoArticle);
    Vue.nextTick(this.setBackToInsightsUrl);
    Vue.nextTick(this.removeNoJsBody);
  }

  updated() {
    Vue.nextTick(this.jwStatistics);
  }

  private importArticleStyles(articleRoot: Document): void {
    const articleHead = articleRoot.head;
    const styles = Array.from(articleHead.getElementsByTagName('link'));
    document.head.append(...styles);
  }

  private importArticleScripts(articleRoot: Document): void {
    const articleBody = articleRoot.body;
    Array.from(articleBody.getElementsByTagName('script')).forEach((script) => {
      if (script.src) ScriptLoadingMixin.loadScript(script.src);
      else ScriptLoadingMixin.loadScriptCode(script.innerHTML);
    });
  }

  private initialiseArticleHtml(articleRoot: Document): void {
    const originalInnerBodyCode = articleRoot.body.innerHTML;
    this.innerBodyCode = this.replaceCommentsWithAnchors(originalInnerBodyCode);
  }

  private cloneBodyClasses(articleRoot: Document): void {
    const articleBodyClasses = Array.from(articleRoot.body.classList);
    document.body.classList.add(...articleBodyClasses);
  }

  public async jwStatistics() {
    if (!this.page.hasJwStatistics) {
      return;
    }

    var jwPlaceholders = document.querySelectorAll('.video-container');
    if (jwPlaceholders && jwPlaceholders.length > 0  && typeof jwplayer != 'undefined') {
      jwPlaceholders.forEach((player) => {
        var jwHtmlElement = player as HTMLElement;
        var playerInstance = jwplayer(jwHtmlElement);
        var articleUrl = player.getAttribute('data-video-url');
        var playlistUrl = '';
        var myDataLayer = window.dataLayer || [];

        axios.get<VideoItemEntry>(articleUrl).then((response) => {
          playlistUrl = response.data.PlaylistUrl;

          playerInstance.setup({
            fullscreen: 'true',
            playlist: playlistUrl,
            autostart: false,
            height: '400px',
            width: '100%',
            aspectratio: '16:9',
            playbackRateControls: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]
          });

          let passedTenPercentBreakpoint: Boolean = false;
          let passedTwentyFivePercentBreakpoint: Boolean = false;
          let passedFiftyPercentBreakpoint: Boolean = false;
          let passedSeventyFivePercentBreakpoint: Boolean = false;
          let passedNinetyPercentBreakpoint: Boolean = false;

          playerInstance.on('error', () => {
            pushToDataLayer('Video error', playerInstance, myDataLayer);
          });
          playerInstance.on('play',() => {
            this.hideOvelayText(jwHtmlElement);
            pushToDataLayer('Played video', playerInstance, myDataLayer);
          });
          playerInstance.on('pause',() => {
            pushToDataLayer('Pause video', playerInstance, myDataLayer);
          });
          playerInstance.on('complete',() => {
            pushToDataLayer('100%', playerInstance, myDataLayer);
          });
          playerInstance.on('time',() => {
            const time = Math.floor(
              (100 * playerInstance.getPosition()) / playerInstance.getDuration()
            );
            if (time === 10 && passedTenPercentBreakpoint === false) {
              passedTenPercentBreakpoint = true;
              pushToDataLayer(`${time} %`, playerInstance, myDataLayer);
            } else if (time === 25 && passedTwentyFivePercentBreakpoint === false) {
              passedTwentyFivePercentBreakpoint = true;
              pushToDataLayer(`${time} %`, playerInstance, myDataLayer);
            } else if (time === 50 && passedFiftyPercentBreakpoint === false) {
              passedFiftyPercentBreakpoint = true;
              pushToDataLayer(`${time} %`, playerInstance, myDataLayer);
            } else if (time === 75 && passedSeventyFivePercentBreakpoint === false) {
              passedSeventyFivePercentBreakpoint = true;
              pushToDataLayer(`${time} %`, playerInstance, myDataLayer);
            } else if (time === 90 && passedNinetyPercentBreakpoint === false) {
              passedNinetyPercentBreakpoint = true;
              pushToDataLayer(`${time} %`, playerInstance, myDataLayer);
            }
          });
        });
      });
    }
  }

  private injectComponentsIntoArticle(): void {
    this.injectElement('SheqBodyBegin', this.$refs.sheqBodyBegin as Vue | Element);
    this.injectElement('SheqTrustHeader', this.$refs.trustHeader as Vue | Element);
    this.injectElement('SignUpPanel-side', this.$refs.signUpPanelSide as Vue | Element);
    this.injectElement(
      'RelatedInsightsPanel-side',
      this.$refs.relatedInsightsSide as Vue | Element
    );
    this.injectElement('RelatedFundsPanel-side', this.$refs.relatedFundsSide as Vue | Element);
    this.injectElement(
      'RelatedInsightsPanel-main',
      this.$refs.relatedInsightsMain as Vue | Element
    );
  }

  private injectElement(identifier: string, elementToInject: Vue | Element): void {
    const element = (elementToInject as Vue)?.$el || (elementToInject as Element);
    if (!element) return;

    const placeholder = this.articleNode.querySelector(`#${identifier}`);
    if (!placeholder) return;

    placeholder.replaceWith(element);
  }

  private replaceCommentsWithAnchors(htmlCode: string): string {
    return htmlCode.replace(/<!--([\w\d-]+)-->/g, '<span id="$1"></span>');
  }

  private get articleNode(): HTMLElement {
    return this.$refs.articleNode as HTMLElement;
  }

  private setBackToInsightsUrl(): void {
    let link = document.getElementsByClassName(
      'bginsights-back-to-link bginsights-back-to-link--insights'
    )[0];

    if (link) {
      let backToInsightsUrl = this.page.icNavigation.backLinkUrl;

      if (!this.page.trustHeader) {
        let icFilteringMixin = new IcFilteringMixin();
        backToInsightsUrl += icFilteringMixin.getIcHomeQuerystringFromCookie();
      }

      link.setAttribute('href', (backToInsightsUrl));
    }
  }

  private hideOvelayText(playerInstance: HTMLElement) : void{
    var playerHtml = document.getElementById(playerInstance.id);
    var placeholders = playerHtml.parentElement.querySelectorAll('.overlay-contents');
    if (placeholders?.length > 0) {
      placeholders.forEach((placeholder: HTMLElement) => {
        placeholder.style.display = 'none';
      })
    }
  }

  private removeNoJsBody(): void {
    let noJsArticleBody = document.getElementsByClassName(
      'article-nojs-body'
    ) as HTMLCollectionOf<HTMLElement>;
    if (noJsArticleBody && noJsArticleBody.length > 0) {
      noJsArticleBody[0].remove();
    }
  }

  get injectableClass(): string {
    return this.page.icNavigation.isNewDesign
      ? 'injectable-article-items'
      : 'injectable-article-items old-design';
  }
}
function pushToDataLayer(action: string, playerInstance: jwplayer.JWPlayer, dataLayer) {

  var playerListItem = playerInstance.getPlaylistItem(0);
  const title = playerListItem.title ? playerListItem.title : playerListItem.file;
  dataLayer.push({
    event: 'Video',
    eventCategory: 'JW Player',
    eventAction: action,
    eventLabel: title,
  });
}
