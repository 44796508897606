/* eslint-disable global-require */
import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '../../../../../shared/MwsViewModels';
import template from './athenaeum-culture-popup-panel.vue';
import VideoPlayerMixin from '../../../../../shared/video-player/VideoPlayerMixin';

const cultureVideoOverlayImageSrc = '/assets/images/athenaeum/squares-line--squares-only.svg';
const markThumbImageSrc = '/assets/images/athenaeum/mark-thumb.jpg';
const spencerThumbImageSrc = '/assets/images/athenaeum/spencer-thumb.jpg';
const tomThumbImageSrc = '/assets/images/athenaeum/tom-thumb.jpg';

@Component({
  mixins: [template],
})
export default class AthenaeumCulturePopupPanel extends Mixins(VideoPlayerMixin) {
  @Prop()
  data!: mws.AthenaeumCulturePanel;

  cultureVideoOverlayImageSrc = cultureVideoOverlayImageSrc;
  markThumbImageSrc = markThumbImageSrc;
  spencerThumbImageSrc = spencerThumbImageSrc;
  tomThumbImageSrc = tomThumbImageSrc;
  cultureBackgroundImage = '';
  cultureRiskWarning = '';
  videoVisible = false;

  mounted() {
    this.cultureBackgroundImage = this.data.cultureBackgroundImage.url;
    this.cultureRiskWarning = this.data.cultureRiskWarning;
  }

  openVideo(url: string): void {
    if (url) {
      this.videoVisible = true;
      const videoContainer = this.$refs.videoContener as HTMLElement;
      const config = {
        url,
        onComplete: () => {
          this.closeVideo();
        },
        autostart: true,
      };
      this.initialiseVideoPlayer(videoContainer, config);
    }
  }

  closeVideo(): void {
    this.videoVisible = false;
    this.destroyVideoPlayer();
  }
}
