import { Channel, GlobalNavigationSelectorPopupLink, Region } from '@/shared/MwsViewModels';
import { safeArray } from '../utils/helpers';

export interface AudienceSelectorHelpersProps {
  globalSelector: GlobalNavigationSelectorPopupLink;
  currentChannelUrl: string;
  currentChannelName: string;
}

interface findByChannelUrlProps {
  locations: Region[];
  channelUrlToMatch: string;
}

function findRegionByChannelUrl({ locations, channelUrlToMatch }: findByChannelUrlProps) {
  return locations?.find((regionChoice) => {
    const channels = safeArray(regionChoice?.channels);
    const currentInvestor = channels.find(
      (channelOption) => channelOption?.url === channelUrlToMatch
    );
    return currentInvestor;
  });
}

export default function useAudienceSelectorHelpers({
  globalSelector,
  currentChannelUrl,
  currentChannelName
}: AudienceSelectorHelpersProps) {
  const mainRegions = safeArray(globalSelector?.globalNaviagtion?.main);
  const globalRegions = safeArray(globalSelector?.globalNaviagtion?.additional);

  let activeChannelJurisdiction: Region | undefined = undefined;

  const foundRegionSelection = findRegionByChannelUrl({
    locations: mainRegions,
    channelUrlToMatch: currentChannelUrl,
  });

  const foundGlobalSelection = findRegionByChannelUrl({
    locations: globalRegions,
    channelUrlToMatch: currentChannelUrl,
  });

  const globalRegionChannels: Channel[] = [];

  globalRegions.forEach((regionChoice) => {
    const channels = regionChoice?.channels;

    for (const element of channels) {
      globalRegionChannels.push(element);
    }
  });

  const globalRegion = Object.assign({}, globalRegions?.[0]);
  globalRegion.channels = globalRegionChannels;

  if (foundRegionSelection) {
    activeChannelJurisdiction = foundRegionSelection;
  }

  if (foundGlobalSelection) {
    activeChannelJurisdiction = Object.assign({}, foundGlobalSelection);

    activeChannelJurisdiction.channels = globalRegionChannels;
  }

  let activeChannelName = activeChannelJurisdiction?.channels?.find(
    (channelOption) => channelOption?.url === currentChannelUrl
  );

  if (!activeChannelJurisdiction && !activeChannelName) {
    activeChannelJurisdiction = globalRegion;
    activeChannelName = globalRegion?.channels?.find(x => x.name == currentChannelName);
  }

  const globalRegionNames = globalRegions.reduce(
    (accumulator, globalRegionItem) => {
      const globalChannels = safeArray(globalRegionItem?.channels)

      globalChannels.forEach((channel) => {
        accumulator[channel?.url] = globalRegionItem?.name
      })

      return accumulator
    },
    Object.assign({})
  )

  return {
    activeChannelJurisdiction,
    activeChannelName,
    globalRegion,
    isGlobalRegion: Boolean(foundGlobalSelection),
    globalRegionNames
  };
}
