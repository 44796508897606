import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';
import template from './ucit-performance-panel.vue'
import * as mws from '../../../shared/MwsViewModels';
import FadeTransitionMixin from '../../../shared/fade-transition/FadeTransitionMixin';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import UcitPerformanceOverTime from './ucit-performance-over-time/UcitPerformanceOverTime';
import UcitBarChartPerformance from './ucit-bar-chart-performance/UcitBarChartPerformance';
import LoadingIndicator from '../../../shared/components/loading-indicator/LoadingIndicator'; 
import EventBus from '../../../shared/EventBus';

@Component({
  mixins: [template],
  components: {
    UcitPerformanceOverTime,
    UcitBarChartPerformance,
    LoadingIndicator
  }
})
export default class UcitPerformancePanel extends Mixins(PostbackMixin, FadeTransitionMixin) {
  @Prop()
  data: mws.UcitPerformancePanel;

  private oldSelectedCurrency: string = null;
  selectedCurrency: string = null;
  availableCurrencies: { value: string, label: string }[];// = ["USD", "EUR", "GBP", "JPY", "SEK", "CAD", "CHF"].map((x) => ({ value: x, label: x }));
  dataAvailable: boolean = false;
  dataAvailableTxt: string = '';
  isLoading = false;
  showingLongestRunning: boolean = false;

  mounted() {
    let initialShareClassSelected = this.data.shareClasses.length > 0 ? 
        this.data.shareClasses.find(s => s.mappingType === 'L') ?
           this.data.shareClasses.find(s => s.mappingType === 'L') : this.data.shareClasses[0] 
        : null;

    this.dataAvailable = initialShareClassSelected.mappingType !== 'X';

    this.availableCurrencies = this.data.performanceOverTime ? 
      this.data.performanceOverTime.availableCurrencies.map((x) => ({ value: x, label: x })) : [];

    this.showingLongestRunning = initialShareClassSelected.mappingType === 'L';

    this.selectedCurrency = this.availableCurrencies.length > 0 ? initialShareClassSelected.mappingType === 'L' ? 
        this.availableCurrencies.find(x => x.value === initialShareClassSelected.currency).value 
      : this.availableCurrencies[0].value 
    : '';

    this.oldSelectedCurrency = this.selectedCurrency;
    if (this.selectedCurrency) {
      this.$store.dispatch('setUcitPerformancePanelOverridingCurrency', this.selectedCurrency);
    }

    EventBus.$on('share-class-change', () => {
        this.checkDataAvailability();
        this.selectedCurrency = this.showingLongestRunning ? this.$store.getters.ucitShareClass.currency : this.availableCurrencies[0].value;
        EventBus.$emit('ucitPerformanceChanged', this.dataAvailable);
    });
  }

  async onCurrencyChange(): Promise<void> {
    const requestedCurrency = this.selectedCurrency;
    if (this.oldSelectedCurrency === requestedCurrency) {
      return;
    }

    this.oldSelectedCurrency = requestedCurrency;
    
    const postbackDto: mws.UcitPostbackShareClassDto = {
      shareClass: this.$store.getters.ucitShareClass.shareClass,
      currency: this.$store.getters.ucitShareClass.currency,
      incAccStr: this.$store.getters.ucitShareClass.incAcc,
      mappingType: this.$store.getters.ucitShareClass.mappingType,
      hedgedFund: this.$store.getters.ucitShareClass.hedgedFund,
      overrideCurrency: requestedCurrency
    };
    const model = this.createRequestModel(this.data, postbackDto);
    try {
      this.isLoading = true;
      const updatedPage = await this.fetchPostback(model);

      if (this.selectedCurrency === requestedCurrency) { // check if user changed their mind
        await this.mergePostback(updatedPage);

        this.checkDataAvailability();
        EventBus.$emit('ucitPerformanceChanged', this.dataAvailable);

        this.isLoading = false;
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  private checkDataAvailability(): void {
    this.dataAvailable = this.$store.getters.ucitShareClass.mappingType !== 'X';
    this.showingLongestRunning = this.$store.getters.ucitShareClass.mappingType === 'L';
  }
}