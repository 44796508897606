import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '../../../shared/MwsViewModels';
import template from './contact-form-panel.vue';
import ContactForm from './contact-form/ContactForm';
import Translatable from '../../../translation/Translatable';
import FormSubmissionHistoryMixin from '../../../shared/forms/FormSubmissionHistoryMixin';
import PostbackMixin from '../../../shared/postback/PostbackMixin';

@Component({
  mixins: [template],
  components: {
    ContactForm,
  },
})
export default class ContactFormPanel extends Mixins(
  Translatable,
  FormSubmissionHistoryMixin,
  PostbackMixin,
) {
  @Prop()
  data!: mws.ContactFormPanel;

  formSuccess: boolean | null = null;

  async created() {
    await this.loadDictionary(['contact_form_submission_success_message']);
  }

  async onSubmit(dto: mws.TrustMagazineFormDto): Promise<void> {
    try {
      const model: mws.PageRequestModel = this.createRequestModel(this.data, dto);
      const response: Promise<any> = await this.fetchPostback(model);
      await this.mergePostback(response);

      this.formSuccess = true;
      this.reportFormSubmission();
    }
    catch (error) {
      this.formSuccess = false;
    }
  }
}
