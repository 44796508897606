import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './actual-investor-related-content-panel.vue';
import * as mws from '../../../../shared/MwsViewModels';
import GenericLink from '../../../../shared/components/link/GenericLink';

@Component ({
    mixins: [template],
    components: {
        GenericLink
      }
})

export default class ActualInvestorRelatedContentPanel extends Vue {
    @Prop()
    data!: mws.ActualInvestorRelatedContentPanel
}