import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './expandable-content-panel.vue';
import ExpandableContentItem from './expandable-content-item/ExpandableContentItem';

@Component({
  mixins: [template],
  components: {
    ExpandableContentItem,
  },
})
export default class ExpandableContentPanel extends Vue {
  @Prop()
  data: mws.ExpandableContentPanel;
}
