import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-centre-panel.vue';
import Translatable from '../../../translation/Translatable';
import FundSelectorContainer from './components/FundSelectorContainer';
import FundBlock from './components/FundBlock';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import FundListItem from './components/FundListItem';
import Multiselect from 'vue-multiselect';
import FundSearchDropdownMultiselect from './components/components/FundSearchDropdownMultiselect';
import EventBus from '../../../shared/EventBus';

@Component({
  mixins: [template],
  components: {
    FundSelectorContainer,
    FundBlock,
    FundListItem,
    Multiselect,
    FundSearchDropdownMultiselect,
  },
})
export default class FundCentrePanel extends Mixins(Translatable, PostbackMixin) {
  @Prop()
  data: mws.FundCentrePanel;

  filteredFundsList = [];
  originalFundsList = [];

  selectedRegions: string[] = [];
  selectedFundTypes: string[] = [];
  selectedAssetClasses: string[] = [];
  selectedInvestmentStyles: string[] = [];
  searchtext: string = '';

  investmentStyleOptions: string[] = [];
  regionOptions: string[] = [];
  fundTypesOptions: string[] = [];
  assetClassOptions: string[] = [];

  disabledInvestmentStyles: string[] = [];
  disabledRegions: string[] = [];
  disabledFundTypes: string[] = [];
  disabledAssetClasses: string[] = [];

  isSelectorOpen: boolean = false;

  mobileFiltersOpened: boolean = false;

  searchDto: mws.FundCentrePanelDto = null;

  async mounted() {
    this.filteredFundsList = this.data.funds;

    EventBus.$on('dropdown_filter_confirm', async (propName: string, selections: string[]) => {
      if (propName == 'selectedInvestmentStyles') {
        this.selectedInvestmentStyles = selections;
      } else if (propName == 'selectedAssetClasses') {
        this.selectedAssetClasses = selections;
      } else if (propName == 'selectedFundTypes') {
        this.selectedFundTypes = selections;
      } else if (propName == 'selectedRegions') {
        this.selectedRegions = selections;
      }

      this.filterSelected();
    });
  }

  async created() {
    await this.loadDictionary([
      'fund_selector_open',
      'fund_selector_close',
      'fund_selector_header',
      'fund_selector_warning',
      'fund_selector_submit',
    ]);
    this.originalFundsList = [...this.data.funds];
  }

  get header(): string {
    return this.getTranslationOrDefault('fund_selector_header');
  }

  get submitButtonText(): string {
    return this.getTranslationOrDefault('fund_selector_submit');
  }

  get selectorToggleText(): string {
    return this.isSelectorOpen
      ? this.getTranslationOrDefault('fund_selector_close')
      : this.getTranslationOrDefault('fund_selector_open');
  }

  showFundSelector(): boolean {
    return this.data.filter && this.data.filter.showFilter;
  }

  async onSubmit(): Promise<void> {
    if (this.searchDto == null) {
      return;
    }
    const model = this.createRequestModel(this.data, this.searchDto);
    try {
      const response = await this.fetchPostback(model);
      await this.mergePostback(response);
      this.isSelectorOpen = false;
    } catch (error) {
      console.error(`Failed to load data: ${error}`);
    }
  }

  selectorToggleOnClick(): void {
    this.isSelectorOpen = !this.isSelectorOpen;
  }

  selectorCss(): string[] {
    return this.isSelectorOpen ? ['open-selector'] : ['close-selector'];
  }

  selectFilter(searchDto: mws.FundCentrePanelDto): void {
    this.searchDto = searchDto;
  }

  //List items methods///
  get getRegionOptions(): { value: string; label: string }[] {
    var response = this.data.filter.geographicRegions
      ? Object.entries(this.data.filter.geographicRegions)
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map((x) => ({ value: x[0], label: x[1] }))
          .filter((data) => data.label != 'All')
      : [];

    this.regionOptions = response.map((data) => data.value);

    return response;
  }

  get getFundTypes(): { value: string; label: string }[] {
    var response = this.data.filter.fundTypes
      ? Object.entries(this.data.filter.fundTypes)
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map((x) => ({ value: x[0], label: x[1] }))
          .filter((data) => data.label != 'All')
      : [];

    this.fundTypesOptions = response.map((data) => data.value);

    return response;
  }

  get getAssetClasses(): { value: string; label: string }[] {
    var response = this.data.filter.assetClasses
      ? Object.entries(this.data.filter.assetClasses)
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map((x) => ({ value: x[0], label: x[1] }))
          .filter((data) => data.label != 'All')
      : [];

    this.assetClassOptions = response.map((data) => data.value);

    return response;
  }

  get getInvestmentStyles(): { value: string; label: string }[] {
    var response = this.data.filter.investmentStyles
      ? Object.entries(this.data.filter.investmentStyles)
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map((x) => ({ value: x[0], label: x[1] }))
          .filter((data) => data.label != 'All')
      : [];

    this.investmentStyleOptions = response.map((data) => data.value);

    return response;
  }

  handleClearAllFilters() {
    this.selectedRegions = [];
    this.selectedFundTypes = [];
    this.selectedAssetClasses = [];
    this.selectedInvestmentStyles = [];
    this.searchtext = '';

    EventBus.$emit('fund-dropdown-multiselect-cleared');

    this.filterSelected();
  }

  sortChange(event) {
    if (event.target.value === 'AZ') {
      this.filteredFundsList.sort((a, b) => {
        let fa = a.fundName.toLowerCase(),
          fb = b.fundName.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    } else if (event.target.value === 'ZA') {
      this.filteredFundsList.sort((a, b) => {
        let fa = a.fundName.toLowerCase(),
          fb = b.fundName.toLowerCase();
        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
        return 0;
      });
    } else if (event.target.value === 'Default') {
      this.filteredFundsList.sort(
        (a, b) => this.originalFundsList.indexOf(a) - this.originalFundsList.indexOf(b)
      );
    }
  }

  searchFunds() {
    if (this.searchtext.length > 2 || this.searchtext.length == 0) {
      this.filterSelected();
    }
  }

  filterSelected() {
    this.filteredFundsList = this.data.funds;

    if (this.selectedAssetClasses && this.selectedAssetClasses.length > 0) {
      var selectedAssetClassesValues = this.selectedAssetClasses.map((a) => a.toLowerCase());
      this.filteredFundsList = this.filteredFundsList.filter((fund) =>
        selectedAssetClassesValues.includes(fund.assetClass.toLowerCase())
      );
    }

    if (this.selectedFundTypes && this.selectedFundTypes.length > 0) {
      var selectedFundTypesValues = this.selectedFundTypes.map((a) => a.toLowerCase());
      this.filteredFundsList = this.filteredFundsList.filter((fund) =>
        selectedFundTypesValues.includes(fund.fundType.toLowerCase())
      );
    }

    if (this.selectedInvestmentStyles && this.selectedInvestmentStyles.length > 0) {
      var selectedInvestmentStylesValues = this.selectedInvestmentStyles.map((a) =>
        a.toLowerCase()
      );
      this.filteredFundsList = this.filteredFundsList.filter((fund) =>
        selectedInvestmentStylesValues.includes(fund.investmentStyle.toLowerCase())
      );
    }

    if (this.selectedRegions && this.selectedRegions.length > 0) {
      var selectedregionsValues = this.selectedRegions.map((a) => a.toLowerCase());
      this.filteredFundsList = this.filteredFundsList.filter((fund) =>
        selectedregionsValues.includes(fund.geographicRegion.toLowerCase())
      );
    }

    if (this.searchtext.length > 0) {
      this.filteredFundsList = this.filteredFundsList.filter(
        (fund) =>
          fund.fundName.toLowerCase().includes(this.searchtext.toLowerCase()) ||
          fund.rolloverText.toLowerCase().includes(this.searchtext.toLowerCase())
      );
    }

    this.ApplyDisabledDropdownItems();
  }

  ApplyDisabledDropdownItems() {
    this.disabledInvestmentStyles = [...this.investmentStyleOptions];
    this.disabledRegions = [...this.regionOptions];
    this.disabledFundTypes = [...this.fundTypesOptions];
    this.disabledAssetClasses = [...this.assetClassOptions];

    this.investmentStyleOptions.forEach(isp => {
      var investmentFilteredItems = this.filteredFundsList.filter(
        (fund) => fund.investmentStyle.toLowerCase() == isp.toLowerCase()
      );
      if (investmentFilteredItems && investmentFilteredItems.length > 0) {
        this.disabledInvestmentStyles = this.disabledInvestmentStyles.filter(
          (item) => item.toLowerCase() != isp.toLowerCase()
        );
      }
    });

    this.regionOptions.forEach(reg => {
      var regionFilteredItems = this.filteredFundsList.filter(
        (fund) => fund.geographicRegion.toLowerCase() == reg.toLowerCase()
      );
      if (regionFilteredItems && regionFilteredItems.length > 0) {
        this.disabledRegions = this.disabledRegions.filter(
          (item) => item.toLowerCase() != reg.toLowerCase()
        );
      }
    });

    this.fundTypesOptions.forEach(ft => {
      var fundTypeItems = this.filteredFundsList.filter(
        (fund) => fund.fundType.toLowerCase() == ft.toLowerCase()
      );
      if (fundTypeItems && fundTypeItems.length > 0) {
        this.disabledFundTypes = this.disabledFundTypes.filter(
          (item) => item.toLowerCase() != ft.toLowerCase()
        );
      }
    });

    this.assetClassOptions.forEach(ac => {
      var assetClassItems = this.filteredFundsList.filter(
        (fund) => fund.assetClass.toLowerCase() == ac.toLowerCase()
      );
      if (assetClassItems && assetClassItems.length > 0) {
        this.disabledAssetClasses = this.disabledAssetClasses.filter(
          (item) => item.toLowerCase() != ac.toLowerCase()
        );
      }
    });
  }
}
