import { Vue, Component, Prop } from 'vue-property-decorator';
import { FundPanelGroup } from '@/shared/MwsViewModels';
import template from './fund-content.vue';
import FundMain from './fund-main/FundMain';
import FundAside from './fund-aside/FundAside';
import FundOverviewPanel from '../panels/fund-overview/FundOverviewPanel';

@Component({
  mixins: [template],
  components: {
    FundMain,
    FundAside,
  },
})
export default class FundContent extends Vue {
  @Prop()
  groups: FundPanelGroup[];

  @Prop({ default: false })
  debug: boolean;

  get asidePanelGroups(): FundPanelGroup[] {
    this.copyOverviewPanelToSidePanels();
    return this.groups.filter((group) => group.isSidePanel);
  }

  get mainPanelGroups(): FundPanelGroup[] {
    return this.groups.filter((group) => !group.isSidePanel);
  }

  
  copyOverviewPanelToSidePanels() {
    let sidePanelGroups = this.groups.filter((group) => group.isSidePanel);

    if (sidePanelGroups.length > 0) {

      //if there is no overview panel in side panel group yet...
      if (!sidePanelGroups[0].panels.some(p => p.panelName === 'FundOverviewPanel')) {

        let mainGroupsWithOverviewPanel = this.groups.filter((group) => !group.isSidePanel && group.panels.some(p => p.panelName === 'FundOverviewPanel'));
        if (mainGroupsWithOverviewPanel.length > 0) {
          // getting first Overview Panel from first matching main group 
          let fundOverviewPanels = mainGroupsWithOverviewPanel[0].panels.filter(p => p.panelName === 'FundOverviewPanel');
     
          if (fundOverviewPanels.length > 0) {
            let fundOverviewPanel = fundOverviewPanels[0];
  
            if (fundOverviewPanels[0] as unknown as FundOverviewPanel) {
              var indexToAdd = sidePanelGroups[0].panels.length > 0 && sidePanelGroups[0].panels[0].panelName === 'FundSideRiskWarningPanel' ? 1 : 0; 
  
              let firstSidePanelGroupIndex  = this.groups.indexOf(sidePanelGroups[0]);
              
              this.groups[firstSidePanelGroupIndex].panels.splice(indexToAdd, 0, fundOverviewPanel);
            }
          }
        } 
      }
    }
  }
}