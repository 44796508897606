import React from 'react'
import classnames from 'classnames/bind'
import { useEffect, useState } from 'react'
import * as CookieLabels from '../../constants/COOKIES'
import { Image as IImage } from '@/shared/MwsViewModels'
import canUseDOM from '../../utils/canUseDOM'
import { setAudienceSelectorCookie } from '../../utils/helpers'
import AudienceNudger from '../AudienceNudger'
import ImageComponent from '../ImageComponent'
import StyledButton from '../StyledButton'
import * as styles from './LocationSelector.module.scss'
import Icon from '../Icon'

const cx = classnames.bind(styles)
const informationRequestsPath = '/en/global/information-requests/';

export interface LocationSelectorProps {
  channelFlag: IImage
  channelInvestorName: string
  channelRegionName: string
  channelDisplayLabel: string
  labels: { [key: string]: string }
  handleAudienceMenuVisibility: (value?: boolean) => void
  audienceMenuVisible: boolean
  ariaControlsLabel: string
  closeNudgerOnScroll: boolean
  forcedSelectionFlag: boolean
  channelCookiesMatch: boolean
}

const LocationSelector = ({
  channelFlag,
  channelInvestorName,
  channelRegionName,
  channelDisplayLabel,
  labels,
  handleAudienceMenuVisibility,
  audienceMenuVisible,
  ariaControlsLabel,
  forcedSelectionFlag,
  closeNudgerOnScroll,
  channelCookiesMatch,
}: LocationSelectorProps) => {
  const [showAudienceNudger, setShowAudienceNudger] = useState<boolean>(false)

  function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    handleAudienceMenuVisibility()
  }

  function handleConfirmNudgerSelection(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault()

    setAudienceSelectorCookie(
      CookieLabels.BG_CHANNEL_COOKIE_NAME,
      channelInvestorName,
      '/'
    )

    setAudienceSelectorCookie(
      CookieLabels.BG_REGION_COOKIE_NAME,
      channelRegionName,
      '/'
    )

    setShowAudienceNudger(false)
  }

  function handleChangeNudgerSelection(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault()

    handleAudienceMenuVisibility(true)

    queueMicrotask(() => {
      setShowAudienceNudger(false)
    })
  }
  function handleCloseNudger(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    setShowAudienceNudger(false)
  }

  useEffect(() => {
    if (canUseDOM()) {
      const pathname = window.location.pathname
      const is404Page = pathname?.startsWith('/404')
      const isGlobalInformationRequestsPage = pathname?.startsWith(informationRequestsPath);
      setShowAudienceNudger(!is404Page && !isGlobalInformationRequestsPage)
    }
  }, [window.location.pathname])

  return (
    <div className={cx('locationSelector')}>
      <div className={cx('channel')}>
        {channelFlag?.url && (
          <span className={cx('channelFlag')}>
            <span className={cx('channelLocationLabel', 'type__h6')}>
              {channelRegionName}
            </span>
            {channelFlag && (
              <ImageComponent
                data={channelFlag}
                className={cx('channelImage')}
                imageClassName={cx('flag')}
                priority={true}
              />
            )}
          </span>
        )}
        <span className={cx('channelInvestor')}>{channelDisplayLabel}</span>
      </div>
      <div className={cx('actionsAndNudger')}>
        {labels?.Change && (
          <StyledButton
            id="audienceMenuToggleBtn"
            type={'linkSmall'}
            customIcon={'chevronRight'}
            className={cx('audienceToggle')}
            classNameForIcon={cx('audienceToggleIcon')}
            aria-controls={ariaControlsLabel}
            aria-expanded={audienceMenuVisible}
            callback={handleButtonClick}
          >
            {labels?.Change}
            <Icon
              aria-hidden="true"
              className={cx('audienceToggleIcon', 'audienceToggleIconDesktop')}
              type={audienceMenuVisible ? 'chevronUp' : 'chevronDown'}
            />
          </StyledButton>
        )}
        {showAudienceNudger &&
          !closeNudgerOnScroll &&
          !channelCookiesMatch &&
          !audienceMenuVisible &&
          !forcedSelectionFlag &&
          !window.location.pathname?.startsWith(informationRequestsPath)  &&
          (
            <AudienceNudger
              channelInvestorName={channelInvestorName}
              channelRegionName={channelRegionName}
              labels={labels}
              handleCloseButton={handleCloseNudger}
              handleChange={handleChangeNudgerSelection}
              handleConfirm={handleConfirmNudgerSelection}
            />
          )}
      </div>
    </div>
  )
}

export default LocationSelector
