import { Vue, Component } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';

@Component
export default class LocationSelectorMixin extends Vue {
  public userCountryHasBeenSet: boolean = false;
  private readonly bgUserLocationCookieName = 'bg_user_location';

  public fetchUserLocationByIp(currentRegions: mws.Region[]): void {
    return;
  }

  public getLocationByIdFromCookie() {
    let userLocationFromCookie = this.$cookies.get(this.bgUserLocationCookieName)?.toString();

    if (userLocationFromCookie) {
      this.$store.dispatch('setCountry', userLocationFromCookie);
    }

    return userLocationFromCookie;
  }

  public setUserLocationFirstInList(regions: mws.Region[], country: string): void {
    if (!regions.some(region => region.name === country)) {
      return;
    }

    var userCountryObject = regions.find(x => x && x.name === country);
    regions.splice(regions.indexOf(userCountryObject), 1);
    regions.unshift(userCountryObject);
  }

  private setLocationByIdCookie(userLocation) {
    if (this.getLocationByIdFromCookie()) {
      return
    }

    let expiryDate = this.getCookieExpiryDate(1 / 8);
    const options = { expires: expiryDate };
    this.$cookies.set(this.bgUserLocationCookieName, userLocation, options);
  }

  private getCookieExpiryDate(hourToDay: number): string {
    var date = new Date();
    date.setTime(date.getTime() + (hourToDay * 24 * 60 * 60 * 1000));
    return date.toUTCString();
  }
}
