import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './it-share-price-and-charges-panel.vue';
import { Helpers } from '../../../assets/scripts/utilities/formatHelpers';
import loadCldrPromise from '../../../assets/scripts/utilities/intialise';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import LoadingIndicator from '../../../shared/components/loading-indicator/LoadingIndicator';

@Component({
  mixins: [template],
  components: {
    LoadingIndicator,
  },
})
export default class ItSharePriceAndChargesPanel extends Mixins(PostbackMixin) {
  @Prop()
  data: mws.ItSharePriceAndChargesPanel;

  private oldSelectedDate: string = null;

  selectedDate: string = null;

  availableDates: {value: string, label: string}[] = [];

  isLoading = false;

  created() {
    this.selectedDate = undefined;
  }

  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      loadCldrPromise().done(() => {
        this.initialiseAvailableDates();
        this.selectedDate = this.availableDates[0].value;
        this.oldSelectedDate = this.selectedDate;
      });
    });
  }

  get rows(): {key: string, value: string}[] {
    return this.data.tableData.rows
      .map((row) => ({ key: row.columns[0].value, value: row.columns[1].value }));
  }

  async onDateSelected(): Promise<void> {
    const requestedDate = this.selectedDate;
    if (this.oldSelectedDate === requestedDate) {
      return;
    }

    this.oldSelectedDate = requestedDate;
    const postbackData: mws.ItSharePriceAndChargesPanelDto = { selectedDate: requestedDate };
    const model = this.createRequestModel(this.data, postbackData);
    try {
      this.isLoading = true;
      const updatedPage = await this.fetchPostback(model);

      if (this.selectedDate === requestedDate) { // check if user changed their mind
        await this.mergePostback(updatedPage);
        this.initialiseAvailableDates();
        this.isLoading = false;
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  private initialiseAvailableDates(): void {
    this.availableDates = this.data.availableDates
      .map((date: string) => ({ value: date, label: Helpers.formatDateLong(date, false, true) }));
  }
}
