import { render, staticRenderFns } from "./oeic-people-panel.vue?vue&type=template&id=1b435594&"
var script = {}
import style0 from "../../../assets/styles/panels/_StaffInfoPanel.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports