import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './insights-sign-up.vue';
import * as mws from '../../shared/MwsViewModels';
import GenericLink from '../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  }
})
export default class InsightsSignUp extends Vue {
  @Prop()
  data: mws.InsightsSignUp;
}
