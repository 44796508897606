import classnames from 'classnames/bind'
import * as styles from './Heading.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

const ALL_HEADINGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'] as const
type HEADINGTAG = (typeof ALL_HEADINGS)[number]

function isHeadingTag(maybeHeadingTag: unknown) {
  return (
    typeof maybeHeadingTag === 'string' &&
    ALL_HEADINGS.includes(maybeHeadingTag as HEADINGTAG)
  )
}

export function parseHeadingTag(
  givenHeading: string | null
): HEADINGTAG | null {
  if (isHeadingTag(givenHeading)) {
    return givenHeading as HEADINGTAG
  } else {
    return null
  }
}

export interface HeadingProps {
  sampleTextProp?: string
  text: string
  tag: string | null
  className?: string
  fontStyleClassName?: string
  children?: React.ReactNode
}

const HeadingMapping: { [key in HEADINGTAG]: React.ElementType } = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
};

const Heading: React.FC<HeadingProps> = ({tag = null, text = '',  className,  fontStyleClassName = ''}: HeadingProps) => {
  const HeadingTagType = parseHeadingTag(tag)
  const HeadingTag: React.ElementType = HeadingTagType
    ? (HeadingMapping[HeadingTagType] as keyof JSX.IntrinsicElements)
    : ('h1' as keyof JSX.IntrinsicElements)
  
    const HeadingDefaultClass = HeadingTagType
    ? HeadingClassMapping[HeadingTagType]
    : '';

  return (   
    <HeadingTag
      className={cx(
        'heading',
        className,
        fontStyleClassName || HeadingDefaultClass
      )}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

const HeadingClassMapping = {
  H1: 'type__h1',
  H2: 'type__h2',
  H3: 'type__h3',
  H4: 'type__h4',
  H5: 'type__h5',
  H6: 'type__h6',
}

export default Heading
