import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './landing-page-header.vue';
import * as mws from '../../shared/MwsViewModels';

const logoImageSrc = '/assets/images/common/BG_logo_BLACK.svg';

@Component({
  mixins: [template],
})
export default class LandingPageHeader extends Vue {
    @Prop()
    links!: mws.ILink[];

    logoImageSrc = logoImageSrc;
}
