import { Component, Mixins, Vue, Prop } from 'vue-property-decorator';
import template from './actual-investor-inner-page-video-panel.vue';
import VideoPlayerMixin from '../../../../shared/video-player/VideoPlayerMixin';
import * as mws from '../../../../shared/MwsViewModels';
import GenericLink from '../../../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  components: {
    GenericLink
  }
})

export default class ActualInvestorInnerPageVideoPanel extends Mixins(VideoPlayerMixin) {
  @Prop()
  data!: mws.ActualInvestorInnerPageVideoPanel;

  backgroundStyle = '';

  showMore(): void {
    const rolloverArea = this.$refs.rolloverArea as HTMLElement;
    rolloverArea.classList.remove('collapsed');

    this.resizeRolloverArea();
  }

  resizeRolloverArea() {
    const rolloverArea = this.$refs.rolloverArea as HTMLElement;
    const videoOverlay = this.$refs.videoOverlay as HTMLElement;
    const overlayBtn = this.$refs.overlayButton as HTMLElement;

    if (!rolloverArea.classList.contains('collapsed') && videoOverlay && overlayBtn) {
      const textBelowVideoOverlayHeight = videoOverlay.offsetHeight;
      const txtWithBtnContainerHeight = overlayBtn.offsetHeight;

      //rolloverArea.animate([{ 'height': textBelowVideoOverlayHeight + txtWithBtnContainerHeight + 'px' }], {duration:500});
      rolloverArea.style.setProperty('height', textBelowVideoOverlayHeight + txtWithBtnContainerHeight + 'px');
    }
  }

  mounted() {
    this.backgroundStyle = `background-image: url('${this.data.backgroundImage.url}')`;

    const videoContainer = this.$refs.videoContainer as HTMLElement;
    const config = {
      url: this.data.videoUrl,
      imageUrl: this.data.previewImage.url,
      autostart: false,
      height: 'auto',
      aspectratio: '16:9'
    };
    this.initialiseVideoPlayer(videoContainer, config);
  }
}
