import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './magazine-supercard.vue';

@Component({
    mixins: [template]
})

export default class MagazineSupercard extends Vue {
    @Prop()
    card: mws.ISupercard;

    @Prop()
    isNew: boolean;

    @Prop()
    index: number;

    @Prop()
    isFeaturedSibling: boolean;
}