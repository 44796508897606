import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import PopupsModule from './modules/Popups.module';
import GlobalSelectorModule from './modules/GlobalSelector.module';
import TranslationModule from './modules/Translation.module';
import PageDataModule from './modules/PageData.module';
import ConfigModule from './modules/Config.module';
import WindowModule from './modules/Window.module';
import SearchModule from './modules/Search.module';
import UcitShareClassModule from './modules/UcitShareClass.module';
import DailyPriceModule from './modules/DailyPrices.module';

Vue.use(Vuex);

export default function createStore(): Store<any> {
  return new Store({
    modules: {
      pageData: PageDataModule,
      popups: PopupsModule,
      globalSelector: GlobalSelectorModule,
      translation: TranslationModule,
      config: ConfigModule,
      window: WindowModule,
      search: SearchModule,
      ucitShareClass: UcitShareClassModule,
      dailyPrices: DailyPriceModule
    },
  });
}
