import React from 'react'
import classnames from 'classnames/bind'
import Image from 'next/future/image'
import { Image as Iimage } from '@/shared/MwsViewModels'
import * as styles from './ImageComponent.module.scss'

const cx = classnames.bind(styles)

type ImageRatios = '1:1' | '3:1' | '3:2' | '3:4' | '4:3' | '16:10' | '16:9'

const ratioMapping = {
  '1:1': 'oneByOne',
  '3:1': 'threeByOne',
  '3:2': 'threeByTwo',
  '3:4': 'threeByFour',
  '4:3': 'fourByThree',
  '16:10': 'sixteenByTen',
  '16:9': 'sixteenByNine',
} as const

export interface ImageComponentProps {
  data: Iimage
  ratio?: ImageRatios
  className?: string
  imageClassName?: string
  priority?: boolean
}

const ImageComponent = ({
  data = {} as Iimage,
  ratio,
  className,
  imageClassName = '',
  priority = false,
}: ImageComponentProps) => {
  const srcUrl = data?.url

  const hasRatio = ratio && ratioMapping[ratio]

  return (
    <span
      className={cx(
        'parentContextContainer',
        { parentContext: hasRatio },
        hasRatio && ratioMapping[ratio],
        className
      )}
    >
      <Image
        alt={data?.altText || ''}
        className={cx({
          [imageClassName]: true,
          absoluteImage: hasRatio,
        })}
        src={srcUrl}
        placeholder="empty"
        priority={priority}
        width={data?.width}
        height={data?.height}
        sizes="100vw"
        unoptimized={true}
      />
    </span>
  )
}

export default ImageComponent
