import * as mws from '@/shared/MwsViewModels';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import LoadingIndicator from '../../../../shared/components/loading-indicator/LoadingIndicator';
import template from './ic-filter-block.vue';
import Multiselect from 'vue-multiselect';
import { data } from 'jquery';

@Component({
  mixins: [template],
  components: {
    LoadingIndicator,
    Multiselect
  }
})
export default class IcFilterBlock extends Vue {
  @Prop()
  data: mws.InsightsFilter;
  @Prop()
  isLoading: boolean;

  // watch for handling browser navigation
  @Watch('$route')
  onUrlChange(newVal: Route) {
    let urlFundId = newVal.query.funds;
    if (!urlFundId) {
      this.selectedFunds = undefined;
      this.emitPayload();
    }
    else if (this.selectedFunds.includes(urlFundId)) {
      this.selectedFunds.push(Number(urlFundId))
      this.emitPayload();
    }
  }

  selectedType = null;
  selectedOrder = null;
  selectedFunds = [];
  selectedStrategies = [];
  selectedContentType = null;
  contentTypeFilterPlaceHolder = null;
  hideContentTypeFilter = false;

  created() {
    this.setInitialValues();
  }

  clearAll(): void {
    this.setInitialValues();
    this.onSelect();
  }

  onSelect(): void {
    this.emitPayload();
    this.generateQueryStringOnFundSelectionChange();
  }

  hideFundsDropdown(): boolean {
    const fundsData = this.data?.filterFunds ? Object.entries(this.data.filterFunds).sort((a, b) => a[1].localeCompare(b[1])).map((x) => ({ value: (x[0]), label: x[1] })) : [];
    return this.data.hideFilterFundDropDown || fundsData.length == 0;
  }

  showStrategiesDropdown(): boolean {
    return Boolean(this.strategyOptions.length);
  }

  private emitPayload() {

    var fundsIds = [];
    const strategyIds = [];

    if (this.selectedFunds !== undefined)
      this.selectedFunds.forEach(element => {
        fundsIds.push(element.value);
      });

    if (this.selectedStrategies !== undefined) {
      this.selectedStrategies.forEach(element => {
        strategyIds.push(element.value);
      });
    }

    const payload = <mws.IntellectualCapitalDto>{
      insightTypes: this.selectedType ? [this.selectedType] : [],
      filterFunds: fundsIds,
      filterStrategies: strategyIds,
      order: this.selectedOrder,
      themes: this.$router.currentRoute.query.themes ? this.$router.currentRoute.query.themes.toString().split(',') : [],
      insightContentTypes: this.selectedContentType ? [this.selectedContentType] : []
    };
    this.$emit('select', payload);
  }

  private generateQueryStringOnFundSelectionChange() {
    var fundsIds = [];
    const strategyIds = [];

    if (this.selectedFunds !== undefined)
      this.selectedFunds.forEach(element => {
        fundsIds.push(element.value);
      });

    if (this.selectedStrategies !== undefined) {
      this.selectedStrategies.forEach(element => {
        strategyIds.push(element.value);
      });
    }

    this.$router.push({
      query: {
        themes: this.$router.currentRoute.query.themes,
        funds: fundsIds.length > 0 ? fundsIds.join(',') : undefined,
        strategies: strategyIds.length > 0 ? strategyIds.join(',') : undefined,
        order: this.selectedOrder !== this.orderOptions[0].value ? this.selectedOrder : undefined,
        format: this.selectedType === "All" ? undefined : this.selectedType === "ExternalLink" ? "Magazine" : this.selectedType,
        contentType: this.selectedContentType === "All" ? undefined : this.selectedContentType
      }
    }).catch(() => { });
  }

  get typeOptions(): { value: string, label: string }[] {
    return this.data
      ? Object.entries(this.data.insightTypes).map((x) => ({ value: x[0], label: x[1] }))
      : [];
  }

  get orderOptions(): { value: string, label: string }[] {
    return this.data
      ? Object.entries(this.data.order).map((x) => ({ value: x[0], label: x[1] }))
      : [];
  }

  get fundOptions(): { value: string, label: string }[] {
    return this.data?.filterFunds
      ? Object.entries(this.data.filterFunds).sort((a, b) => a[1].localeCompare(b[1])).map((x) => ({ value: (x[0]), label: x[1] }))
      : [];
  }

  get strategyOptions() {
    return this.data?.filterStrategies
      ? Object.entries(this.data.filterStrategies).sort((a, b) => a[1].localeCompare(b[1])).map((x) => ({ value: (x[0]), label: x[1] }))
      : [];
  }

  get contentTypeOptions(): { value: string, label: string }[] {
    return this.data?.insightContentTypes
      ? Object.entries(this.data.insightContentTypes).map((x) => ({ value: x[0], label: x[1] }))
      : [];
  }

  private setInitialValues(): void {
    this.selectedType = undefined;
    this.selectedOrder = this.orderOptions[0].value;
    this.selectedFunds = [];
    this.selectedStrategies = [];
    this.selectedContentType = undefined;
  }

  mounted() {
    this.contentTypeFilterPlaceHolder = this.data.contentTypePlaceHolderText;
    this.hideContentTypeFilter = this.data.hideContentTypeFilter;
    this.setFundsFilter();
    this.setStrategiesFilter();
    this.setOrderFilter();
    this.setFormatFilter();
    this.setContentTypeFilter();
  }

  private setFundsFilter(): void {
    var fundIdsFromQuerystring = this.$router.currentRoute.query.funds?.toString().split(',');
    var fundsParameter = "&funds=";
    if (fundIdsFromQuerystring) {
      var fundOptionsFromQuerystring = this.fundOptions.filter(x => fundIdsFromQuerystring.indexOf(x.value) !== -1);
      if (fundOptionsFromQuerystring.length < 6) {
        this.selectedFunds = [];
        fundOptionsFromQuerystring.forEach(x => {
          this.selectedFunds.push(x);
        })
      }
    } else if (this.$router.currentRoute.fullPath.includes(fundsParameter)) {
      this.setFundsFilterAfterRedirectToFilteredInsights(fundsParameter);
    }
  }

  private setStrategiesFilter(): void {
    const strategyIdsFromQuerystring = this.$router.currentRoute.query.strategies?.toString().split(',');
    const strategiesParameter = "&strategies=";
    if (strategyIdsFromQuerystring) {
      const strategyOptionsFromQuerystring = this.strategyOptions.filter(x => strategyIdsFromQuerystring.indexOf(x.value) !== -1);
      if (strategyOptionsFromQuerystring.length < 6) {
        this.selectedStrategies = [];
        strategyOptionsFromQuerystring.forEach(x => {
          this.selectedStrategies.push(x);
        })
      }
    } else if (this.$router.currentRoute.fullPath.includes(strategiesParameter)) {
      this.setStrategiesFilterAfterRedirectToFilteredInsights(strategiesParameter);
    }
  }

  private setOrderFilter(): void {
    var orderParameter = "&order=";
    var orderFromQuerystring = this.$router.currentRoute.query.order;
    if (orderFromQuerystring) {
      var qOrder = this.orderOptions.filter(x => x.value.toLowerCase() === this.$router.currentRoute.query.order.toString().toLowerCase())[0];
      if (qOrder) {
        this.selectedOrder = qOrder.value;
      }
    } else if (this.$router.currentRoute.fullPath.includes(orderParameter)) {
      this.setOrderFilterAfterRedirectToFilteredInsights(orderParameter);
    }
  }

  private setFormatFilter(): void {
    var typeFromQuerystring = this.$router.currentRoute.query.format;
    var formatParameter = "&format=";
    if (typeFromQuerystring) {
      var qType = this.typeOptions.filter(x => x.value.toLowerCase() === this.$router.currentRoute.query.format.toString().toLowerCase())[0];
      if (qType) {
        this.selectedType = qType.value;
      }
    } else if (this.$router.currentRoute.fullPath.includes(formatParameter)) {
      this.setFormatFilterAfterRedirectToFilteredInsights(formatParameter);
    }
  }

  private setContentTypeFilter(): void {
    var typeFromQuerystring = this.$router.currentRoute.query.contentType;
    var contentTypeParameter = "&contentType=";
    if (typeFromQuerystring) {
      var qType = this.contentTypeOptions.filter(x => x.value.toLowerCase() === this.$router.currentRoute.query.contentType.toString().toLowerCase())[0];
      if (qType) {
        this.selectedContentType = qType.value;
      }
    } else if (this.$router.currentRoute.fullPath.includes(contentTypeParameter)) {
      this.setContentTypeFilterAfterRedirectToFilteredInsights(contentTypeParameter);
    }
  }

  private setFundsFilterAfterRedirectToFilteredInsights(fundsParameter: string): void {
    var fundsValue = this.$router.currentRoute.fullPath.slice(this.$router.currentRoute.fullPath.indexOf(fundsParameter) + fundsParameter.length);
    fundsValue = fundsValue.substring(0, fundsValue.indexOf('&'));
    var fundsIds = fundsValue.split(',');
    var fundOptions = this.fundOptions.filter(x => fundsIds.indexOf(x.value) !== -1);
    fundOptions.forEach(fundId => {
      this.selectedFunds.push(fundId);
    });
  }

  private setStrategiesFilterAfterRedirectToFilteredInsights(strategiesParameter: string): void {
    let strategiesValue = this.$router.currentRoute.fullPath.slice(this.$router.currentRoute.fullPath.indexOf(strategiesParameter) + strategiesParameter.length);
    strategiesValue = strategiesValue.substring(0, strategiesValue.indexOf('&'));
    const strategiesIds = strategiesValue.split(',');
    const strategyOptions = this.strategyOptions.filter(x => strategiesIds.indexOf(x.value) !== -1);
    strategyOptions.forEach(strategyId => {
      this.selectedStrategies.push(strategyId);
    });
  }

  private setOrderFilterAfterRedirectToFilteredInsights(orderParameter: string): void {
    var orderValue = this.$router.currentRoute.fullPath.slice(this.$router.currentRoute.fullPath.indexOf(orderParameter) + orderParameter.length);
    orderValue = orderValue.substring(0, orderValue.indexOf('&'));
    this.selectedOrder = orderValue;
  }

  private setFormatFilterAfterRedirectToFilteredInsights(formatParameter: string): void {
    var formatValue = this.$router.currentRoute.fullPath.slice(this.$router.currentRoute.fullPath.indexOf(formatParameter) + formatParameter.length);
    this.selectedType = formatValue === "ExternalLink" ? "Magazine" : formatValue;
  }

  private setContentTypeFilterAfterRedirectToFilteredInsights(contentTypeParameter: string): void {
    var contentTypeValue = this.$router.currentRoute.fullPath.slice(this.$router.currentRoute.fullPath.indexOf(contentTypeParameter) + contentTypeParameter.length);
    this.selectedContentType = contentTypeValue;
  }
}
