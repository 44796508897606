import axios, { AxiosError } from 'axios';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import LiteratureOrderMixin from '../../shared/literature-order/LiteratureOrderMixin';
import { OrderableItem, PersonalDetailsDto, GetFormInfoDto, GetOrderDto, DataProtectionStatement } from './ViewModels';
import template from './literature-order-popup.vue';
import { Config } from '../../config';
import LiteratureOrderForm from './literature-order-form/LiteratureOrderForm';
import * as mws from '../../shared/MwsViewModels';

@Component({
  mixins: [template],
  components: {
    LiteratureOrderForm,
  },
})
export default class LiteratureOrderPopup extends Mixins(LiteratureOrderMixin) {
  literatureItems: OrderableItem[] = [];

  formDisabled = false;
  showThankYou = false;
  showRequestError = false;

  dataProtectionStatement: DataProtectionStatement = null;

  get isOpen(): boolean {
    return this.$store.state.popups.literatureOrderPopup.isOpen;
  }

  @Watch('isOpen')
  onPopupOpen(): void {
    if (this.isOpen) {
      this.updateLiterature();
    }
  }

  close(): void {
    this.$store.dispatch('closeLiteratureOrderPopup');
  }

  submitFormBegin(personalDetails: PersonalDetailsDto): void {
    this.formDisabled = true;
    this.showRequestError = false;

    personalDetails.dataProtectionStatement = this.dataProtectionStatement;
    const payload = {
      documents: this.literatureItems,
      personalDetailsForm: personalDetails,
      url: this.$store.getters.pageUrl,
    } as GetOrderDto;
    const orderUrl = `/OrderLiterature`;
    axios.post(orderUrl, payload)
      .then((response) => { 
        this.onSubmitSuccess(payload, response.data);
      })
      .catch((error) => {
        this.onSubmitFailure();
      });
  }

  private onSubmitSuccess(orderDto: GetOrderDto, responseData: any): void {
    if (responseData.isLiveInstance) {

      if(this.sendLiteratureOrderFormDataToMarketo(orderDto, responseData.logErrorUrl))
      {
        this.showThankYou = true;
      }
    }
    else{
      this.showThankYou = true;
    }

    this.removeAllLiteratureIds();
  }

  private onSubmitFailure(): void {
    this.formDisabled = false;
    this.showRequestError = true;
  }

  async removeItem(id: number): Promise<void> {
    this.removeLiteratureId(id);
    const indexToRemove = this.literatureItems.findIndex((item) => item.id === id);
    if (indexToRemove !== -1) {
      this.literatureItems.splice(indexToRemove, 1);
    }
    if (this.literatureItems.length === 0) {
      this.close();
    }
  }

  private async updateLiterature(): Promise<void> {
    const ids = this.getLiteratureIds();
    await this.fetchLiteratureItems(ids);
  }

  private async fetchLiteratureItems(ids: number[]): Promise<void> {
    const payload = {
      url: this.$store.getters.pageUrl,
      documents: ids.map((id) => ({ id: id })),
    } as GetFormInfoDto;
    const response = await axios.post<GetOrderDto>('/GetFormInfo', payload);

    const data = response.data;
    this.literatureItems = data.documents;
    this.dataProtectionStatement = data.personalDetailsForm.dataProtectionStatement;
  }

  private get config(): Config {
    return this.$store.getters.config;
  }
  
  private sendLiteratureOrderFormDataToMarketo(orderDto: GetOrderDto, logErrorUrl: string) : boolean{

      let documentNames = orderDto.documents.map(x => x.name).join(", ");

      let dpDisclaimerCode = (<HTMLInputElement>document.getElementById("marketoDpDescLiteratureRequest")).value;
      let dpDescription = dpDisclaimerCode + " - Literature Request Form";
      let postResponse = true;
      
      let addr = (orderDto.personalDetailsForm.address1 ? orderDto.personalDetailsForm.address1 + ', ' : '') + 
                  (orderDto.personalDetailsForm.address2 ? orderDto.personalDetailsForm.address2 + ', ' : '') + 
                  (orderDto.personalDetailsForm.town ? orderDto.personalDetailsForm.town + ', ' : '') + 
                  (orderDto.personalDetailsForm.county ? orderDto.personalDetailsForm.county + ', ' : '') + 
                  (orderDto.personalDetailsForm.postcode ? orderDto.personalDetailsForm.postcode : '' );

      let formData = {
        email : orderDto.personalDetailsForm.email ? orderDto.personalDetailsForm.email : '',
        latestWebLiteratureRequested : documentNames,
        salutation: orderDto.personalDetailsForm.title,
        firstName : orderDto.personalDetailsForm.forename, 
        lastName: orderDto.personalDetailsForm.surname,
        postalCode: orderDto.personalDetailsForm.postcode,
        Address_1__c: orderDto.personalDetailsForm.address1,
        Address_2__c: orderDto.personalDetailsForm.address2 ? orderDto.personalDetailsForm.address2 : '',
        address: addr,
        city: orderDto.personalDetailsForm.town,
        state: orderDto.personalDetailsForm.county ? orderDto.personalDetailsForm.county : '',
        Opt_In_Disclaimer_Code__c: dpDisclaimerCode,
        Opt_in_Date__c: new Date().toISOString(),

        DP_Flag_Email__c: (!!orderDto.personalDetailsForm.email).toString(),
        DP_Description_Email__c: !!orderDto.personalDetailsForm.email ? dpDescription : "",
        DP_Date_Email__c: !!orderDto.personalDetailsForm.email ? new Date().toISOString() : "",

        DP_Flag_Trust__c: orderDto.personalDetailsForm.trustFlag.toString(),
        DP_Description_Trust__c: orderDto.personalDetailsForm.trustFlag ? dpDescription : "NULL",
        DP_Date_Trust__c: orderDto.personalDetailsForm.trustFlag ? new Date().toISOString() : "",
      }

      let postObject ={
        input: [{
          leadFormFields : formData
        }],
        formId : (<HTMLInputElement>document.getElementById("marketoFormId")).value
      }

      axios({
        method: "post",
        url: '/submitToMarketo',
        data: postObject,
        headers: { "Content-Type": "application/json" }
      }).then(function (response) {
        if(response && response.data){
          postResponse = true;
        }
        else{
          postResponse = false;
        }
      })
      .catch(async function (error: AxiosError<any>) {
        if (error.response) {
          try {
            const errorDto: mws.SendingDataToMarketoErrorDto =  {
              errorMessage: `/submitToMarketo - ${error.response.data.message}`,
              errorHttpCode: error.response.data.errorCode,
              urlReferer: window.location.href
            }
            axios.post(logErrorUrl, errorDto)
            .catch((error) => {
              console.error(error);
            });
          }
          catch (error) {
            console.error(error);
          }
        }
  
        postResponse = false;
      });

      return postResponse;
  }
}