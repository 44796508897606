import classnames from 'classnames/bind'
import * as styles from  './RichText.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

const StyleSizeMapping = {
  default: '',
  big: 'bigIntro',
  prominent: 'prominent',
  card: 'card',
}

type Sizes = keyof typeof StyleSizeMapping

export interface RichTextProps {
  copy: string
  className?: string
  size?: Sizes
}

const RichText = ({
  copy = 'Default Text',
  className,
  size = 'default',
}: RichTextProps) => {
  return copy ? (
    <div
      className={cx('rich-text', className, StyleSizeMapping[size])}
      dangerouslySetInnerHTML={{ __html: copy }}
    />
  ) : null
}

export default RichText
