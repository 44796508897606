import classnames from 'classnames/bind'
import {
  ColourPickerElement,
  SupercardDesign2,
} from '@/shared/MwsViewModels'
import SectionContainer from '../SectionContainer'
import Callout from './Callout'
import * as styles from './CalloutsCTA.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface CalloutsCTAProps {
  coloursOverride: ColourPickerElement
  items: SupercardDesign2[]
}

const CalloutsCTA = ({ coloursOverride, items }: CalloutsCTAProps) => {
  const calloutsItems = (Array.isArray(items) ? items : []).slice(0, 3)
  const itemWidth = getCalloutWidthClass(calloutsItems.length)

  const calloutInlineStyles = {
    borderTopColor: coloursOverride?.textColour,
  } as React.CSSProperties

  return (
    <SectionContainer coloursOverride={coloursOverride}>
      <div className={cx('callouts-container')}>
        {calloutsItems.map((card, index) => {
          return (
            <div
              key={`Callout-CTA-${index}`}
              className={cx('callout-container', itemWidth)}
              style={calloutInlineStyles}
            >
              <Callout card={card}></Callout>
            </div>
          )
        })}
      </div>
    </SectionContainer>
  )
}

function getCalloutWidthClass(itemsCount: number) {
  switch (itemsCount) {
    case 2:
      return 'two-items'
    case 3:
      return 'three-items'
    default:
      return ''
  }
}

export default CalloutsCTA
