import { React, useEffect, useRef, useState } from 'react'
import canUseDOM from '../utils/canUseDOM'

let passiveSupported = false

function testCallback() {}
function setupPassiveSupport() {
  try {
    const options = {
      get passive() {
        // This function will be called when the browser
        //   attempts to access the passive property.
        passiveSupported = true
        return false
      },
    }

    window.addEventListener('scroll', testCallback, options)
    window.removeEventListener('scroll', testCallback, false)
  } catch (err) {
    passiveSupported = false
  }
}

interface ScrollStateType {
  isSticky: boolean
  isPinned: boolean
}

const tolerance = {
  down: 10,
  up: 10,
} as const

const STICKY_OFFSET = 100

function isOutOfBounds(currentScrollY: number) {
  return currentScrollY <= 0
}

function shouldUnpin(
  currentScrollY: number,
  toleranceExceeded: boolean,
  lastKnownScrollY: number
) {
  const scrollingDown = currentScrollY > lastKnownScrollY
  const pastOffset = currentScrollY >= STICKY_OFFSET

  return scrollingDown && toleranceExceeded && pastOffset
}

function shouldPin(
  currentScrollY: number,
  toleranceExceeded: boolean,
  lastKnownScrollY: number
) {
  const scrollingUp = currentScrollY < lastKnownScrollY
  const pastOffset = currentScrollY >= STICKY_OFFSET

  return scrollingUp && toleranceExceeded && pastOffset
}

function toleranceExceeded(
  currentScrollY: number,
  direction: 'down' | 'up',
  lastKnownScrollY: number
) {
  return Math.abs(currentScrollY - lastKnownScrollY) >= tolerance[direction]
}

function checkToleranceAndDirection(
  currentScrollY: number,
  lastKnownScrollY: number
) {
  const scrollDirection = currentScrollY > lastKnownScrollY ? 'down' : 'up'
  const hasToleranceExceeded = toleranceExceeded(
    currentScrollY,
    scrollDirection,
    lastKnownScrollY
  )
  let action = ''

  if (shouldUnpin(currentScrollY, hasToleranceExceeded, lastKnownScrollY)) {
    action = 'unpinned'
  } else if (
    shouldPin(currentScrollY, hasToleranceExceeded, lastKnownScrollY)
  ) {
    action = 'pinned'
  }

  return action
}

let isSticky = false
let isPinned = false

function trackStickyHeader(
  lastScrollPosition: React.MutableRefObject<number>,
  setScrollState: React.Dispatch<React.SetStateAction<ScrollStateType>>
) {
  return () => {
    const scrollYPosition = window.scrollY

    if (isOutOfBounds(scrollYPosition)) {
      return
    }

    const scrollAction = checkToleranceAndDirection(
      scrollYPosition,
      lastScrollPosition.current
    )

    isSticky = scrollYPosition > STICKY_OFFSET

    if (scrollAction === 'pinned') {
      isPinned = true
    } else if (scrollAction === 'unpinned' || !isSticky) {
      isPinned = false
    }

    lastScrollPosition.current = scrollYPosition

    setScrollState({
      isSticky: isSticky,
      isPinned: isPinned,
    })
  }
}

function useOnScroll() {
  const lastScrollPositionRef = useRef(0)
  const [scrollState, setScrollState] = useState<ScrollStateType>({
    isSticky: false,
    isPinned: false,
  })

  const pinnedHeaderLogic = trackStickyHeader(
    lastScrollPositionRef,
    setScrollState
  )

  useEffect(() => {
    let rAFTicker = false

    function handleOnScroll() {
      if (!rAFTicker) {
        window.requestAnimationFrame(() => {
          pinnedHeaderLogic()
          rAFTicker = false
        })
        rAFTicker = true
      }
    }
    if (canUseDOM()) {
      setupPassiveSupport()
      window.addEventListener(
        'scroll',
        handleOnScroll,
        passiveSupported ? { passive: true } : false
      )
    }

    return () => {
      window.removeEventListener('scroll', handleOnScroll, false)
    }
  }, [pinnedHeaderLogic])

  return scrollState
}

export default useOnScroll
