import classnames from 'classnames/bind'
import { INotificationPanel } from '@/shared/MwsViewModels'
import RichText from '../../lib/RichText'
import SectionContainer from '../../lib/SectionContainer'
import * as styles from './NotificationPanel.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface NotificationPanelProps {
  data: INotificationPanel
}

const NotificationPanel = ({ data }: NotificationPanelProps) => {
  return (
    <SectionContainer
      coloursOverride={data?.colours}
      className={cx('componentPadding', 'rebrand-lite-notification')}
    >
      {data?.notificationText && (
        <div>
          <RichText copy={data.notificationText} />
        </div>
      )}
    </SectionContainer>
  )
}

export default NotificationPanel
