import Vue from 'vue';
import VueRouter from 'vue-router';
import Page from '../page/Page';
import qs from 'qs/dist/qs';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'Page',
    component: Page,
  },
];

export default function createRouter(): VueRouter {
  return new VueRouter({
    mode: 'history',
    routes,
    parseQuery: (query: any): object => { return qs.parse(query); },
    stringifyQuery(query: any): string {
      let result = qs.stringify(query, { encode: false });
      return result ? ('?' + result) : '';
    }
  });
}
