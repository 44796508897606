import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './portfolio-cards-panel.vue';
// eslint-disable-next-line max-len

const cardsBatchSize = 16;

@Component({
  mixins: [template]
})
export default class ContentPageHeaderImagePanel extends Vue {
  @Prop()
  data?: mws.PortfolioCardsPanel;
  showingCardsCount: number = cardsBatchSize;

  public loadMoreCards() {
    this.showingCardsCount += cardsBatchSize;
  }

  mounted() {
    this.data.portfolioCards.forEach(card => 
      {
        if(!card.itemUrl.toLowerCase().startsWith('http')) 
        {
          if(card.itemUrl.toLowerCase().startsWith('//www.'))
          {
            card.itemUrl = "https://" + card.itemUrl.replace('//www.', 'www.');
          }
          //Check if it's an internal or external URL
          else if(!card.itemUrl.toLowerCase().startsWith('/')){
            card.itemUrl = "https://" + card.itemUrl;
          }
        }   
      }
    );
  }
}