import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './oeic-people-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import OeicPerson from './oeic-person/OeicPerson';

@Component({
  mixins: [template],
  components: {
    OeicPerson,
  },
})
export default class OeicPeoplePanel extends Vue {
  @Prop()
  data: mws.OeicPeoplePanel;
}
