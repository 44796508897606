import { InsightsSignUp } from '@/shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './ic-sign-up-block.vue';
import GenericLink from '../../../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class IcSignUpBlock extends Vue {
  @Prop()
  data: InsightsSignUp;
}
