import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-related-content-panel.vue';

const roseSrc = '/assets/images/fund/blue-rose.jpeg';

@Component({
  mixins: [template],
})
export default class FundRelatedContentPanel extends Vue {
  @Prop()
  data: mws.FundRelatedContentPanel;

  get blockBackground(): string {
    return `url('${roseSrc}') center center no-repeat, linear-gradient(180deg, #000000 0%, rgba(0,0,0,0) 100%)`;
  }
}
