import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './oeic-key-information.vue';
import * as mws from '../../../shared/MwsViewModels';

@Component({
  mixins: [template],
})
export default class OeicKeyInformationPanel extends Vue {
  @Prop()
  data: mws.OeicKeyInformationPanel;
}
