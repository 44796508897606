/* eslint-disable max-len */
function createErrorPageData(pageTitle: string, titleLines: string[], message: string): any {
  return {
    isPreview: true,
    page: {
      pageType: 'ErrorPage',
      metadata: {
        title: pageTitle,
        description: 'Baillie Gifford was founded in 1908 and is an independent investment manager, managing pension funds, investment trusts, unit trusts and ISAs.',
        keywords: 'Baillie Gifford, Asset Management, Investment Trusts, Unit trusts, ISAs',
        hideFromRobots: false,
        canonicalUrl: 'https://www.bailliegifford.com',
      },
      requestMetadata: {
        channel: 'None',
        language: '',
        region: '',
        culture: '',
      },
      errorMessage: {
        titleLines,
        message,
      },
    },
  };
}

export function getNotFoundData(): any {
  return createErrorPageData(
    '404 Error',
    ['Sorry', 'this page', 'cannot be found.'],
    'The page you are looking for might have been removed, had its name changed or is temporarily unavailable.',
  );
}

export function getBadRequestData(): any {
  return createErrorPageData(
    'Bad Request',
    ['Bad Request'],
    'Your browser sent a request that this server could not understand.',
  );
}

export function getInternalServerErrorData(): any {
  return createErrorPageData(
    'Internal Server Error',
    ['Sorry,', 'we\'re experiencing', 'an internal server problem.'],
    'We are working to resolve it as quickly as possible. In the meantime, you can return to our home page or contact us by using the links below.',
  );
}

export function getNotFoundErrorPageData(receivedData: any): any {
  return {
    page: receivedData
  };
}
/* eslint-enable max-len */
