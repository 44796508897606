import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './content-page-video-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import GradientPanel from '../gradient/GradientPanel';
import VideoPopupConfig from './VideoPopupConfig';
import PositionedResponsiveImage from
  '../../../shared/components/positioned-responsive-image/PositionedResponsiveImage';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    GradientPanel,
    PositionedResponsiveImage,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class ContentPageVideoPanel extends Vue {
  @Prop()
  data!: mws.ContentPageVideoPanel;

  videoConfig: VideoPopupConfig = {
    closeOnComplete: false,
    imageUrl: this.image?.url,
  };

  get image(): mws.Image {
    return this.data?.holdingImage;
  }

  get holdingImageUrl(): string {
    const { image } = this;
    let url = `${image.url}?anchor=center&amp;mode=crop&amp;slimmage=true&amp;`;

    url = image.width ? `${url}width=${image.width}&amp;` : url;
    url = image.height ? `${url}height=${image.height}&amp;` : url;

    return url;
  }
}
