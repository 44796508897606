import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './event-listing-panel.vue';
import EventListingItem from './event-listing-item/EventListingItem';
import EventListingDetails from './event-listing-details/EventListingDetails';

@Component({
  mixins: [template],
  components: {
    EventListingItem,
    EventListingDetails,
  },
})
export default class EventListingPanel extends Vue {
  @Prop()
  data: mws.EventListingPanel;

  selectedEventIndex: number | null = null;

  get selectedEvent(): mws.EventElement | null {
    const index = this.selectedEventIndex;
    return index == null ? null : this.data.events[index] as mws.EventElement;
  }

  onEventClick(index: number): void {
    this.selectedEventIndex = (this.selectedEventIndex === index) ? null : index;
  }
}
