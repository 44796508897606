import { Vue, Component } from 'vue-property-decorator';
import * as riskWarning from '../../server-dependencies/RiskWarningHelper';

@Component
export default class RiskWarningMixin extends Vue {
  private readonly path = '/';

  private getSessionCookie(): string {
    return this.$cookies.get(riskWarning.sessionCookieName)?.toString();
  }

  private getPersistentCookie(): string {
    return this.$cookies.get(riskWarning.persistentCookieName)?.toString();
  }

  private setSessionCookie(riskIds: string[]): void {
    const options = { path: this.path };
    this.$cookies.set(
      riskWarning.sessionCookieName,
      riskIds.join(riskWarning.riskSeparator),
      options
    );
  }

  private setPersistentCookie(riskKey: string): void {
    const options = {
      path: this.path,
      expires: "30d"
    };
    this.$cookies.set(riskWarning.persistentCookieName, riskKey, options);
  }

  public hasAcceptedRisk(riskKey: string): boolean {
    return riskWarning.isRiskAccepted(riskKey, this.getSessionCookie(), this.getPersistentCookie());
  }

  public acceptRisk(riskId: string, rememberChoice: boolean , acceptRiskUrl: string = ""): void {
    const acceptedRisks = riskWarning.getSessionAcceptedRisks(this.getSessionCookie());
    const persistentRisks = riskWarning.getPersistentAcceptedRisks(this.getPersistentCookie());
    if (rememberChoice) {
      if (persistentRisks.length > 0) {
        this.setSessionCookie([...acceptedRisks, ...persistentRisks]);
      }
      this.setPersistentCookie(riskId + '$$' + acceptRiskUrl);
    } else {
      this.setSessionCookie([...acceptedRisks, riskId]);
    }
  }
}
