import { Component, Mixins, Vue, Prop } from 'vue-property-decorator';
import template from './daily-prices-table.vue';
import * as mws from '../../../../shared/MwsViewModels';
import Translatable from '../../../../translation/Translatable';
import FundTypeTableCss from './FundTypeTableCss';
import TableCellContent from '../../../../shared/table/table-cell-content/TableCellContent';
import EventBus from '../../../../shared/EventBus';
import FundDailyPricesShortlistMixin from '../../../../shared/components/fund-daily-prices/FundDailyPricesShortlistMixin';
import { DailyPriceTableRow } from './DailyPriceTableRow';

const starIconSrc = '/assets/images/common/shortlist_star.svg';
const starSelectedIconSrc = '/assets/images/common/shortlist_star_selected.svg';
const binIconSrc = '/assets/images/common/bin_gray.svg';
const deletedIconSrc = '/assets/images/common/confirmation_success.svg';

@Component({
  mixins: [template],
  components: {
    TableCellContent,
  },
})
export default class DailyPricesTable extends Mixins(Translatable, FundDailyPricesShortlistMixin) {
  @Prop()
  table!: mws.Table;

  @Prop()
  fundName!: string;

  @Prop()
  emptyText: string;

  @Prop()
  tab: mws.DailyPriceTab;

  @Prop() 
  isFromShortlistPage!: boolean;

  private positiveNumberCssClass = 'positive-number';
  private negativeNumberCssClass = 'negative-number';
  private percentageChangeString = '% Change';
  private firstSectionHeaderValue: string = null;
  private headerText = 'inlineHeader';
  shortlistItemsFromCookie: {[key: string]: {[key:string]: string[] }} = {};
  rerenderShortlistColumn: boolean = true;
  starIconSrc = starIconSrc;
  starSelectedIconSrc = starSelectedIconSrc;
  binIconSrc = binIconSrc;
  deletedIconSrc = deletedIconSrc;
  currentUrlIdentifier: string = '';
  isShortlistTabAvailable: boolean = false;

  mounted() {
    this.firstSectionHeaderValue = this.rows[0]?.columns[0]?.value;
    this.shortlistItemsFromCookie = this.getShortlistItemsFromCookie();
    this.currentUrlIdentifier = this.getRegionAndChannelIdentifier();

    EventBus.$on('dp-shortlist-navitem-exist', () => {
      this.isShortlistTabAvailable = true;
    });

    Vue.nextTick(() => this.calulateShortlistOverlayHeights());
  }

  isTableUrlItem(column: mws.TableColumn): boolean {
    return column.originalType === TableCellContent.TableUrlItem;
  }

  getClassForNumber(column: mws.TableColumn): string {
    if (column.value && typeof(column.value) === 'string' && column.value.includes('%')) {
      const numericValue: number = +column.value.replace('%','');
      return numericValue > 0 ? this.positiveNumberCssClass : this.negativeNumberCssClass;
    }
    return '';
  }

  getClassForNotNumber(column: mws.TableColumn): string {
    return column.originalType === TableCellContent.TableUrlItem ? 'cell-with-arrow' : '';
  }

  getClassForHeader(headerValue: string): string {
    // first header should not be padded
    if(this.firstSectionHeaderValue == headerValue) {
      return 'category'
    }
    return 'category padded';
  }

  isHeaderRow(row: mws.TableRow): boolean {
    if(row.columns) {
      if(row.columns[0].originalType !== TableCellContent.TableUrlItem && row.columns[0].originalType !== TableCellContent.TableTaggedItem) {
        return row.columns[0]?.value.includes(this.headerText);
      }
    }
    return false;
  }

  getCellValue(column: mws.TableColumn, replaceWithDash: boolean = true): mws.TableColumn {
    if(column.value === '') {
      let replacedValue = '';
      if(replaceWithDash) {
        replacedValue = '-';
      }
      return {value: replacedValue, originalType: ''};
    }
    return column;
  }

  get tableClass(): string {
    return FundTypeTableCss[this.fundName] ? FundTypeTableCss[this.fundName] : '';
  }

  get tableHeader(): mws.TableColumn[] {
    return this.table && this.table.header ? this.table.header.columns : ([] as mws.TableColumn[]);
  }

  get rows(): DailyPriceTableRow[] {
    return this.table.rows ? 
      this.table.rows.map(x => { return { columns: x.columns, rowIdentifier: x.rowIdentifier, isInShortlist: this.isInShortlist(x)}; }) as DailyPriceTableRow[] :
      ([] as DailyPriceTableRow[]);
  }

  public isInShortlist(row: mws.TableRow): boolean {
    let shortlistForRegionAndChannel = this.shortlistItemsFromCookie[this.currentUrlIdentifier];

    if (shortlistForRegionAndChannel) {
      let shortlistForFundType = shortlistForRegionAndChannel[this.tab.fundType];
    
      if (shortlistForFundType) {
        return shortlistForFundType.includes(row.rowIdentifier);
      }
    }

    return false;
  }

  public toggleShortlistItem(row: DailyPriceTableRow, el: any = null) {
    this.shortlistItemsFromCookie = this.toggleShortlistItemInCookie(row, this.tab.fundType);
    if (this.isFromShortlistPage && el) {
      let rowElement = (el.target as HTMLElement)?.closest('tr');
      if (rowElement) {
        rowElement.classList.toggle('deleted');
      }
    }
    this.forceRerender();
  }

  calulateShortlistOverlayHeights() {
    let overlays = document.getElementsByClassName(
      'shortlist-row-overlay'
    ) as HTMLCollectionOf<HTMLElement>;
      
    for (var i = 0; i < overlays.length; i++) {
      let rowElement = (overlays[i] as HTMLElement).closest('tr');

      if (rowElement) {
        overlays[i].style.height = rowElement.clientHeight + 1 + 'px';
        rowElement.style.height = rowElement.clientHeight + 1.4 + 'px';
      }
    }
  }

  private forceRerender() {
    this.rerenderShortlistColumn = false;
    this.$nextTick(() => {
        this.rerenderShortlistColumn = true;
    });
  }
}