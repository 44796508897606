import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-promo-panel.vue';

@Component({
  mixins: [template],
})

export default class FundPromoPanel extends Vue {
  @Prop()
  data: mws.FundPromoPanel;

  getLinkClass(): string {
    return "bgfunds-promo-panel " + (this.data.blocks.length == 2 ? "bgfunds-promo-panel-double" : this.data.blocks.length == 1 ? "bgfunds-promo-panel-single" : "");
  }

  getLinkStyle(block: mws.Block): string {
    const link = block.link as mws.ImageLink;

    var responseStyle = "";
    if (link.image) {
      responseStyle += `background: url(\'${link.image?.url}\') center center/cover no-repeat, `;
    }
    responseStyle += 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)';

    return responseStyle;
  }

}
