import { Vue, Component } from 'vue-property-decorator';
import IdGenerationService from './IdGenerationService';
import BasicIdGenerationService from './implementations/BasicIdGenerationService';

@Component
export default class IdGenerationMixin extends Vue {
  $ids!: IdGenerationService;

  created() {
    this.$ids = new BasicIdGenerationService();
  }
}
