import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '../../../shared/MwsViewModels';
import template from './marketo-form-panel.vue';

@Component({
  mixins: [template],
})
export default class MarketoFormPanel extends Vue {
  @Prop()
  data: mws.MarketoFormPanel;
}
