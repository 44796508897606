import * as mws from '@/shared/MwsViewModels';
import Multiselect from 'vue-multiselect';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './fund-search-dropdown-multiselect.vue';
import EventBus from '../../../../../shared/EventBus';

interface MultiselectExt extends Multiselect {
  deactivate();
}

@Component({
  mixins: [template],
  components: {
    Multiselect,
  },
})
export default class FundSearchDropdownMultiselect extends Vue {
  @Prop()
  header: string;

  @Prop()
  propName: string;

  @Prop()
  values: mws.FundSearchFilterSelections[];

  @Prop()
  disabledValues: string[];

  @Prop()
  name: string;

  @Prop()
  tabIndex: number;

  selections: { label: string; value: string; $isDisabled: boolean }[];
  prevSelections: { label: string; value: string; $isDisabled: boolean }[];

  created(): void {
    this.selections = [];
    this.prevSelections = [];

    EventBus.$on('fund-dropdown-multiselect-cleared', () => {
      this.selections = [];
      this.prevSelections = this.selections;
    });
  }

  onClose(): void {
    this.handleClose();
  }

  clearSelections() {
    if (this.prevSelections.length > 0) {
      EventBus.$emit('dropdown_filter_confirm', this.propName, []);
    }
    this.selections = this.prevSelections = [];
    (this.$refs.multiselect as MultiselectExt).deactivate();
  }

  confirmAndClose() {
    this.handleClose();
    (this.$refs.multiselect as MultiselectExt).deactivate();
  }

  data() {
    return { selections: this.selections };
  }

  get options(): { value: string; label: string; $isDisabled: boolean }[] {
    return this.values?.map((x) => {
      return { label: x.label, value: x.value, $isDisabled: this.disabledValues.includes(x.value) };
    });
  }

  private handleClose() {
    //emit change only if it actually changed
    if (
      this.selections.length !== this.prevSelections.length ||
      this.selections.filter((item) => this.prevSelections.indexOf(item) < 0).length > 0
    ) {
      EventBus.$emit(
        'dropdown_filter_confirm',
        this.propName,
        this.selections.map((x) => x.value)
      );
      this.prevSelections = this.selections;
    }
  }
}
