import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-platform-panel.vue';
import GenericLink from '../../../shared/components/link/GenericLink';

interface PlatformSelectViewModel {
  value: string;
  label: string;
}

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class FundPlatformPanel extends Vue {
  @Prop()
  data: mws.FundPlatformPanel;

  private selectText = "Select platform from list";

  private selectValue = this.selectText;

  get platforms(): PlatformSelectViewModel[] {
    return this.data.links
      .filter((link) => link.link != null)
      .map((link) => ({ value: link.link.url, label: link.title }));
  }

  onPlatformSelected(url: string): void {
    if (url) {
      const selectedPlatformLabel = this.platforms.find(platform => platform.value === url)?.label;
      if(selectedPlatformLabel) {
        this.selectText = selectedPlatformLabel;
      }
      this.selectValue = url;
      window.open(url, '_blank');
    }
  }
}
