import classnames from 'classnames/bind'
import * as CookieLabels from '../../../constants/COOKIES'
import { ICurrentChannel } from '../../../types/AudienceSelector'
import { Channel, Region } from '@/shared/MwsViewModels'
import { setAudienceSelectorCookie } from '../../../utils/helpers'
import Icon from '../../Icon'
import { IBaseLink } from '../../LinkButtonStyles'
import StyledButton from '../../StyledButton'
import GlobalLinkButton from '../GlobalLinkButton'
import * as styles from './../AudienceSelector.module.scss'
import * as componentStyles from './InvestmentTypeSelector.module.scss'
import React from 'react'

const cx = classnames.bind(Object.assign({}, styles, componentStyles))

type RegionChoice = Region | undefined

interface IInvestmentTypeSelector {
  labels: { [key: string]: string }
  globalLink: string | undefined
  globalRegionGroupNames: { [key: string]: string }
  selectedChannelRegion: RegionChoice
  currentChannel: ICurrentChannel
  handleGlobalLink: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleAudienceMenuClose: (value?: boolean) => void
  hasForcedSelection: boolean
  isDisabledInvestorChannels: boolean
}

export default function InvestmentTypeSelector({
  labels,
  globalLink,
  globalRegionGroupNames,
  selectedChannelRegion,
  currentChannel,
  handleGlobalLink,
  handleAudienceMenuClose,
  hasForcedSelection,
  isDisabledInvestorChannels,
}: IInvestmentTypeSelector) {
  const globalSiteLink: IBaseLink = {
    url: globalLink || '',
    linkText: labels?.viewGlobalSiteCta,
    linkTitle: '',
    openInNewWindow: false,
  }

  const selectedRegionNameAndNonGlobal = selectedChannelRegion?.regionGroupName
  const channels =
    selectedChannelRegion && Array.isArray(selectedChannelRegion?.channels)
      ? selectedChannelRegion.channels
      : ([] as Channel[])

  function individualChannelIsActive(url: string) {
    return url === currentChannel?.url && !hasForcedSelection
  }

  function getCookieRegion(url: string) {
    if (url && globalRegionGroupNames[url]) {
      return globalRegionGroupNames[url]
    } else {
      return selectedChannelRegion?.name
    }
  }

  const currentRegionMatchesChannels = currentChannel?.regionName === selectedChannelRegion?.name

  return (
    <div className={cx('selectorSection', 'selectorSection--region')}>
      <div className={cx('audienceContainer')}>
        <h2 className={cx('type__h5', 'selectorSectionHeading')}>
          {'02. ' + labels?.YourUserType}
        </h2>
      </div>
      <ul
        className={cx({
          investorsList: true,
          'util__reset-list': true,
          investorsListDisabled: isDisabledInvestorChannels,
        })}
      >
        {channels.map((investor) => {
          return (
            <li
              className={cx('investorsListItem')}
              key={investor?.url}
            >
              <InvestmentUserTypeButton
                isActive={individualChannelIsActive(investor?.url)}
                label={investor.name}
                description={investor.channelDescription}
                currentRegionMatchesChannels={currentRegionMatchesChannels}
                isDisabled={
                  isDisabledInvestorChannels ||
                  individualChannelIsActive(investor?.url)
                }
                cookieChannel={investor?.name}
                cookieRegion={getCookieRegion(investor?.url)}
                currentChannel={currentChannel}
                channelUrl={investor?.url}
                handlerAudienceMenu={handleAudienceMenuClose}
              />
            </li>
          )
        })}
      </ul>
      {globalSiteLink?.url && selectedRegionNameAndNonGlobal && (
        <div className={cx('audienceContainer', 'additionalInfo')}>
          <GlobalLinkButton
            globalSiteLink={globalSiteLink}
            promptLabel={labels?.UserTypeNotListed}
            buttonClick={handleGlobalLink}
          />
        </div>
      )}
    </div>
  )
}

interface IInvestmentUserTypeButton {
  label: string
  description?: string
  isActive: boolean
  isDisabled: boolean
  currentChannel: ICurrentChannel
  cookieChannel: string
  cookieRegion: string | undefined
  channelUrl: string
  currentRegionMatchesChannels: boolean
  handlerAudienceMenu: (value?: boolean) => void
}

function InvestmentUserTypeButton({
  label,
  description,
  isActive,
  isDisabled,
  currentChannel,
  cookieChannel,
  cookieRegion,
  channelUrl,
  currentRegionMatchesChannels,
  handlerAudienceMenu
}: IInvestmentUserTypeButton) {

  function getQueryParams(url: string) {
    const justParams = url.indexOf('?') > 0 ? url.slice(url.indexOf('?') + 1) : ''
    return new URLSearchParams(justParams)
  }

  function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    if (isActive || isDisabled) {
      return
    }

    setAudienceSelectorCookie(
      CookieLabels.BG_CHANNEL_COOKIE_NAME,
      cookieChannel,
      '/'
    )
    if (cookieRegion) {
      setAudienceSelectorCookie(
        CookieLabels.BG_REGION_COOKIE_NAME,
        cookieRegion,
        '/'
      )
    }

    if (channelUrl && channelUrl !== currentChannel?.url) {
      const currentUrlPath = window?.location?.pathname

      // The path is a different channel (most likely invalid) to the current channel object, all.bets.are.off
      if (!currentUrlPath.includes(currentChannel?.url)) {
        window.location.replace(channelUrl)
        return
      }

      const globalInformationRequestHomePath = '/en/global/information-requests/'
      const isGlobalInformationRequest =
        currentChannel?.name === '' &&
        currentChannel?.url === '' &&
        currentUrlPath.includes(globalInformationRequestHomePath)

      const interiorPagePath = isGlobalInformationRequest
        ? currentUrlPath.slice(globalInformationRequestHomePath.length)
        : currentUrlPath.slice(currentChannel?.url.length)

      const params = getQueryParams(window.location.href).toString()

      const pageToRedirectToIfExists = `${channelUrl}${interiorPagePath}${
        params !== '' ? '?' : ''
      }${params}`

      window.location.replace(pageToRedirectToIfExists)
    } else {
      handlerAudienceMenu(false)
    }
  }

  return (
    <StyledButton
      type="text"
      className={cx({
        userTypeButton: true,
        userTypeButtonActive: isActive,
        userTypeButtonDisabled: isDisabled,
      })}
      callback={handleButtonClick}
      isDisabled={isDisabled}
    >
      <span className={cx('userTypeButtonLabel')}>
        {label}
        <Icon
          className={cx('userTypeButtonIcon')}
          type="arrow"
        />
      </span>
      {description && (
        <div className={cx('userTypeButtonDescription', 'type__caption')}>
          {description}
        </div>
      )}
    </StyledButton>
  )
}
