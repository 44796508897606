import * as mws from '../../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './usm-performance-and-portfolio-items.vue';
import UsmPerformanceAndPortfolioTables from '../usm-performance-and-portfolio-tables/UsmPerformanceAndPortfolioTables';
import UsmPerformanceAndPortfolioBarChart from '../usm-performance-and-portfolio-bar-chart/UsmPerformanceAndPortfolioBarChart';
import UsmPerformanceAndPortfolioChart from '../usm-performance-and-portfolio-chart/UsmPerformanceAndPortfolioChart'

@Component({
  mixins: [template],
  components: {
      UsmPerformanceAndPortfolioTables,
      UsmPerformanceAndPortfolioBarChart,
      UsmPerformanceAndPortfolioChart
  }
})
export default class UsmPerformanceAndPortfolioItems extends Vue {
  @Prop()
  items: mws.IUsmPerformanceAndPortfolioItem[];

  @Prop()
  tabIndex: number;
}