import classnames from 'classnames/bind'
import { ILink, Image } from '@/shared/MwsViewModels'
import { safeArray } from '../../utils/helpers'
import Heading from '../Heading'
import ImageComponent from '../ImageComponent'
import RichText from '../RichText'
import SectionContainer from '../SectionContainer'
import TabLinks, { TabLinksType } from '../TabLinks'
import * as styles from './HeaderSideBySide.module.scss'
import React from 'react'



const cx = classnames.bind(styles)

export interface HeaderSideBySideProps {
  image: Image
  description: string
  heading: string
  tabs: ILink[]
}

export interface FooterComponentProps {
  tabLinks: TabLinksType
}

function FooterComponent({ tabLinks }: FooterComponentProps) {
  return (
    <TabLinks
      className={cx('tabs')}
      links={tabLinks}
    />
  )
}

const HeaderSideBySide = ({
  image,
  heading,
  description,
  tabs,
}: HeaderSideBySideProps) => {
  const tag = 'H1'
  const tabLinks = safeArray(tabs)

  const hasImage = image?.url

  return (
    <SectionContainer
      className={cx('headerSideBySide', 'headerSideBySideContainer')}
      customPaddingWithBackground={true}
      FooterChildren={<FooterComponent tabLinks={tabLinks} />}
    >
      <div
        data-testid="side-by-side"
        className={cx('columns')}
      >
        {heading && (
          <div className={cx('columnContent')}>
            <Heading
              tag={tag}
              text={heading}
              className={cx('heading')}
              headingClassName="type__h1"
            />
            {description && (
              <RichText
                className={'spacing__md-top'}
                copy={description}
              />
            )}
          </div>
        )}
        <div className={cx('columnImage')}>
          {hasImage && (
            <div className={cx('customImageWrapper')}>
              <ImageComponent
                className={cx('imageGrow')}
                ratio={'4:3'}
                data={image}
              />
            </div>
          )}
        </div>
      </div>
    </SectionContainer>
  )
}

export default HeaderSideBySide
