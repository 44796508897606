import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '../../../../../shared/MwsViewModels';
import template from './athenaeum-history-popup-panel.vue';
import AthenaeumHistoryCarousel from '../athenaeum-history-carusel/AthenaeumHistoryCarousel';
import HistorySubtitles from '../athenaeum-history-panel/subtitles/HistorySubtitles';
import SideNavigation from '../athenaeum-history-panel/side-navigation/SideNavigation';
import ScriptLoadingMixin from '../../../../../shared/script-loading/ScriptLoadingMixin';

const headphonesImageSrc = '/assets/images/athenaeum/headphones--white.svg';

@Component({
  mixins: [template],
  components: {
    AthenaeumHistoryCarousel,
    HistorySubtitles,
    SideNavigation,
  },
})
export default class AthenaeumHistoryPopupPanel extends Mixins(ScriptLoadingMixin) {
  @Prop()
  data!: mws.AthenaeumHistoryPanel;
  headphonesImageSrc: string = headphonesImageSrc;
  
  mounted() {
    ScriptLoadingMixin.loadScript('/public/static/webgl.js');
    ScriptLoadingMixin.loadScript('/public/static/site.js');
  }

  get getChapterData() {
    return [
      {
        chapterCarousel: this.data.historyCarouselOne,
        chapterTitle: this.data.historyChapterOne,
        chapterPeriod: '2000-2017',
      },
      {
        chapterCarousel: this.data.historyCarouselTwo,
        chapterTitle: this.data.historyChapterTwo,
        chapterPeriod: '1979-1999',
      },
      {
        chapterCarousel: this.data.historyCarouselThree,
        chapterTitle: this.data.historyChapterThree,
        chapterPeriod: '1947-1978',
      },
      {
        chapterCarousel: this.data.historyCarouselFour,
        chapterTitle: this.data.historyChapterFour,
        chapterPeriod: '1908-1947',
      },
    ];
  }
}