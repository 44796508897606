import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ScriptLoadingMixin extends Vue {
  public static loadScript(source: string): void {
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', source);
    document.body.appendChild(scriptElement);
  }

  public static loadScriptCode(code: string): void {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = code;
    document.body.appendChild(scriptElement);
  }
}
