var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"bginsights-article"},[(_vm.isArchived)?_c('span',{staticClass:"bginsights-insight-archived-label"},[_vm._v("Archived")]):_vm._e(),_vm._v(" "),_c('time',[_vm._v(_vm._s(_vm.data.date))]),_vm._v(" "),_c('div',{staticClass:"rte rte--dark"},[_c('h1',[_vm._v("\n            "+_vm._s(_vm.data.title)+"\n        ")]),_vm._v(" "),(_vm.data.standfirst)?_c('span',{staticClass:"standfirst podcast",domProps:{"innerHTML":_vm._s(_vm.data.standfirst)}}):_c('span',{staticClass:"description"},[_vm._v("\n            "+_vm._s(_vm.data.description)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"suppress-animation",attrs:{"id":"podcast-popup-container"}},[_c('div',{staticClass:"podcast-popup"},[_c('div',{staticClass:"podcast-content"},[_c('div',{staticClass:"podcast-text-with-player-container"},[(_vm.isPlayerAvailable)?_c('div',{staticClass:"podcast-popup-player-container"},[_c('div',{staticClass:"podcast-player-overlay-box",class:_vm.data.textColour == 'Black'
                                    ? 'overlay-color-black'
                                    : _vm.data.textColour == 'Cream'
                                    ? 'overlay-color-cream'
                                    : 'overlay-color-white',on:{"mouseover":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false}}},[_c('div',{ref:"podcastContainer",staticClass:"podcast-player",attrs:{"id":"podcast-popup-player"}}),_vm._v(" "),_c('div',{staticClass:"overlay-contents",class:[
                                    _vm.data.textColour == 'Black'
                                        ? 'jw-overlay-contents-black'
                                        : _vm.data.textColour == 'Cream'
                                        ? 'jw-overlay-contents-cream'
                                        : 'jw-overlay-contents-white' ],on:{"click":function($event){return _vm.hoverClicked()}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPlayed),expression:"!isPlayed"}],class:[
                                        _vm.data.textColour == 'Black'
                                            ? 'jw-podcast-play-black'
                                            : _vm.data.textColour == 'Cream'
                                            ? 'jw-podcast-play-cream'
                                            : 'jw-podcast-play-white' ]}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlayed && _vm.isHover),expression:"isPlayed && isHover"}],class:[
                                        _vm.data.textColour == 'Black'
                                            ? 'jw-podcast-pause-black'
                                            : _vm.data.textColour == 'Cream'
                                            ? 'jw-podcast-pause-cream'
                                            : 'jw-podcast-pause-white' ]}),_vm._v(" "),_c('p'),_vm._v(" "),(!_vm.isPlayed)?_c('p',[_vm._v(_vm._s(_vm.data.callToAction))]):_vm._e(),_vm._v(" "),(!_vm.isPlayed)?_c('p',[_vm._v(_vm._s(_vm.data.duration))]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"transcript-swatch"})]):_vm._e(),_vm._v(" "),(!_vm.isPlayerAvailable)?_c('div',{staticClass:"simple-html-audio-container"},[_c('div',[_c('img',{attrs:{"src":_vm.data.image.url}})]),_vm._v(" "),_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.data.audioFile,"type":"audio/mp4"}}),_vm._v(" "),_c('span',[_vm._v("Sorry, your browser doesn't support HTML5 audio, but you can download "),_c('a',{attrs:{"href":_vm.data.audioFile,"target":"_blank"}},[_vm._v("this podcast")]),_vm._v(".")])])]):_vm._e()])]),_vm._v(" "),(!_vm.data.hidePlatformLinks)?_c('div',{staticClass:"podcast-platforms"},_vm._l((_vm.data.platforms),function(platform,index){return _c('div',{key:index,staticClass:"podcast-card"},[_c('a',{attrs:{"href":platform.platformUrl,"target":"_blank"}},[_c('img',{attrs:{"src":platform.platformIcon,"border":"0","alt":"","width":"100%","height":"100%"}})])])}),0):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"nested-content"},[_c('div',{staticClass:"bgside-share-and-print bginsights-share-and-view-transcript bgfunds-main-module bginsights-main-module"},[(_vm.data.transcript)?_c('a',{staticClass:"bginsights-link--view-transcript bginsights-link bginsights-link--white bginsights-link--bold",attrs:{"target":"_blank","href":_vm.data.transcript}},[_vm._v("\n                "+_vm._s(_vm.data.transcriptCta)+"\n            ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }