import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-side-risk-warning-panel.vue';

@Component({
  mixins: [template],
})
export default class FundSideRiskWarningPanel extends Vue {
  @Prop()
  data: mws.FundSideRiskWarningPanel;
}
