import { useEffect, useState } from 'react'
import { BG_SELF_ATTESTATION_COOKIE_NAME } from '../../constants/COOKIES'
import canUseDOM from '../../utils/canUseDOM'
import { getCookieValue } from '../../utils/helpers'
import ModalSelfAttestation, {
  SelfAttestationData,
} from '../ModalSelfAttestation'
import React from 'react';

interface IModalOrchestrationProps {
  selfAttestationData: SelfAttestationData
}

export const ModalOrchestration = ({
  selfAttestationData,
}: IModalOrchestrationProps) => {
  const [activeSelfAttestation, setActiveSelfAttestation] =
    useState<boolean>(false)

  useEffect(() => {
    if (canUseDOM()) {
      const COOKIE_ATTESTATION =
        getCookieValue(BG_SELF_ATTESTATION_COOKIE_NAME) || ''

      const confirmedAttestationPages = COOKIE_ATTESTATION?.split(',')
        .map((element) => parseInt(element))
        .filter(Boolean)
      if (
        !confirmedAttestationPages.includes(selfAttestationData?.pageId) &&
        selfAttestationData?.showSelfAttestation
      ) {
        setActiveSelfAttestation(true)
      }
    }
  }, [selfAttestationData])

  function resetModalAttestation() {
    setActiveSelfAttestation(false)
  }

  return (
    <>
      {
        activeSelfAttestation &&
         (
          <ModalSelfAttestation
            selfAttestationContent={selfAttestationData}
            closeModal={resetModalAttestation}
          />
        )}
    </>
  )
}
