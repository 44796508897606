import { Component, Vue, Prop } from 'vue-property-decorator';
import { SearchResultSummary } from '@/shared/MwsViewModels';
import template from './search-item.vue';

@Component({
  mixins: [template],
})
export default class SearchItem extends Vue {
  @Prop()
  item!: SearchResultSummary;
}
