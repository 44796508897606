import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './bottom-link-panel.vue';
import BottomLinkItem from './bottom-link-item/BottomLinkItem';
import { ReactInVue } from 'vuera'
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    BottomLinkItem,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class BottomLinkPanel extends Vue {
  @Prop()
  data!: mws.BottomLinkPanel;

  public get linkedItems(): mws.RelatedLinksElement[] {
    return this.data.relatedLinks.slice(0, 2);
  }

  highlightedLinkIndex: number | null = null;

  mounted() {
    this.highlightedLinkIndex = 0;
  }

  mouseOver(linkIndex: number): void {
    this.highlightedLinkIndex = linkIndex;
  }

  mouseLeave(): void {
    this.highlightedLinkIndex = 0;
  }
}
