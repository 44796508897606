import { Component, Vue } from 'vue-property-decorator';
import template from './side-navigation.vue';

const subtitlesImageSrc = '/assets/images/athenaeum/subtitles.svg';

@Component({
  mixins: [template],
})
export default class SideNavigation extends Vue {
  subtitlesImageSrc: string = subtitlesImageSrc;
}
