import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import { SelectedChannel } from '@/shared/global-navigation/SelectedChannel';
import template from './page-navigation-panel.vue';
import GlobalNavigationHeader from '../../../top-navigation-bar/global-navigation-header/GlobalNavigationHeader';
import TopLevelNavigationHeader from '../../../top-navigation-bar/top-level-navigation-header/TopLevelNavigationHeader';
import LandingPageHeader from '../../../top-navigation-bar/landing-page-header/LandingPageHeader';
import InsightsPageHeader from '../../../top-navigation-bar/insights-page-header/InsightsPageHeader';
import ActualInvestorHeaderMenu from '../../../top-navigation-bar/actual-investor-header-menu/ActualInvestorHeaderMenu';
import { ReactInVue  } from 'vuera'
import GlobalHeader from '../../../react/rebrand-lite/panels/GlobalHeader';

@Component({
  mixins: [template],
  components: {
    GlobalNavigationHeader,
    TopLevelNavigationHeader,
    LandingPageHeader,
    InsightsPageHeader,
    ActualInvestorHeaderMenu,
    'GlobalHeader': ReactInVue(GlobalHeader)
  },
})
export default class PageNavigationPanel extends Vue {
  @Prop()
  data: mws.PageNavigationPanel;

  @Prop()
  icNavigation: mws.IcNavigationBar;

  @Prop({ default: false })
  useDarkTheme: boolean;

  navbarOverflows = false;

  extraLinks: mws.ILink[] = [];

  created() {
    this.updateStore();
  }

  get selectedChannel(): SelectedChannel {
    return {
      channelId: this.getChannelId(),
      flag: this.data.channelFlag,
      channelName: this.data.channelName,
      channelUrl: this.data.channelUrl,
    };
  }

  useExtraNavigation(): boolean {
    return this.extraLinks != null && this.extraLinks.length > 0;
  }

  getClasses(): string{
    return this.icNavigation != null ? 'page-navigation-panel standalone-header-menu-navigation' : 'page-navigation-panel';
  }

  updateOverflow(newValue: boolean): void {
    this.navbarOverflows = newValue;
    this.$store.dispatch('setNavbarOverflow', newValue);
  }

  private updateStore(): void {
    this.$store.dispatch('updateMenuPopupData', {
      secondaryLinks: this.data.secondary,
      additionalLinks: this.data.links,
      globalSelector: this.data.globalSelector
    });
    this.$store.dispatch('updateSelectedChannel', this.selectedChannel);
  }

  private getChannelId(): number {
    return this.data.channelId;
  }
}
