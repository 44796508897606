import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-side-key-documents-panel.vue';
import Translatable from '../../../translation/Translatable';
import LiteratureOrderMixin from '../../../shared/literature-order/LiteratureOrderMixin';

@Component({
  mixins: [template],
})
export default class FundSideKeyDocumentsPanel extends Mixins(Translatable, LiteratureOrderMixin) {
  @Prop()
  data: mws.FundSideKeyDocumentsPanel;

  async created() {
    return this.loadDictionary([
      'literature_library_results_download',
      'literature_library_results_order',
    ]);
  }

  order(document: mws.Literature): void {
    this.addLiteratureId(document.id);
  }
}
