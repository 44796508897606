import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './athenaeum-panel.vue';
import * as mws from '../../../shared/MwsViewModels';

@Component({
  mixins: [template]
})
export default class AthenaeumPanel extends Vue {
  @Prop()
  data!: mws.AthenaeumPanel;

  backgroundStyle = '';

  mounted() {
    this.backgroundStyle = `background-image: url('${this.data.backgroundImage}')`;
  }

  openAthenaeum() : void {
    this.$store.dispatch('openAthenaeumPopup', this.data);
  }
}
