import { Component, Vue, Watch } from 'vue-property-decorator';
import template from './fund-subscription-popup.vue';

@Component({
  mixins: [template]
})

export default class FundSubscriptionPopup extends Vue {

  get link(): string {
    return this.$store.state.popups && this.$store.state.popups.fundSubscriptionPopup ? this.$store.state.popups.fundSubscriptionPopup.link : '';
  }

  get isOpen(): boolean {
    return this.$store.state.popups.fundSubscriptionPopup.isOpen;
  }

  closePopup(): void {
    this.$store.dispatch('setFundSubscriptionPopupLink', '');
    this.$store.dispatch('closeFundSubscriptionPopup');
  }
}
