import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '../../../shared/MwsViewModels';
import template from './trust-magazine-form-panel.vue';
import TrustMagazineForm from './trust-magazine-form/TrustMagazineForm';
import Translatable from '../../../translation/Translatable';
import FormSubmissionHistoryMixin from '../../../shared/forms/FormSubmissionHistoryMixin';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import axios, { AxiosError } from 'axios';

@Component({
  mixins: [template],
  components: {
    TrustMagazineForm,
  },
})
export default class TrustMagazineFormPanel extends Mixins(
  Translatable,
  FormSubmissionHistoryMixin,
  PostbackMixin,
) {
  @Prop()
  data!: mws.TrustMagazineFormPanel;

  formDisabled = false;
  showThankYou = false;
  showRequestError = false;

  formSuccess: boolean | null = null;

  onSubmit(dto: mws.TrustMagazineFormDto) {
    try {
      if (this.data.isLiveInstance) 
      {
        if(this.sendTrustMagazineFormDataToMarketo(dto))
        {
          this.formSuccess = true;
          this.reportFormSubmission();
        }
        else{
          this.formSuccess = false;
        }
      }
      else{
        this.formSuccess = true;
        this.reportFormSubmission();
      }
    }
    catch (error) {
      this.formSuccess = false;
    }
  }

  private sendTrustMagazineFormDataToMarketo(dto: mws.TrustMagazineFormDto): boolean {
    let dpDisclaimerCode = (<HTMLInputElement>document.getElementById("marketoDpDescTrustMagazine")).value;
    let dpDescription = dpDisclaimerCode + " - Trust Magazine Form";
    let addr = (dto.addressLine1 ? dto.addressLine1 + ', ' : '') + (dto.addressLine2 ? dto.addressLine2 + ', ' : '') + 
      (dto.town ? dto.town + ', ' : '') + (dto.county ? dto.county + ', ' : '') + (dto.postcode ? dto.postcode : '' );

    let submissionResponse = true;

    let formData = {
      //required fields
      email : dto.emailAddress ? dto.emailAddress : '',
      latestWebLiteratureRequested: "Trust Magazine",
      //optional fields
      salutation: dto.title,
      firstName : dto.forename, 
      lastName: dto.surname,
      postalCode: dto.postcode,
      Address_1__c: dto.addressLine1,
      Address_2__c: dto.addressLine2 ? dto.addressLine2 : '',
      city: dto.town,
      state: dto.county ? dto.county : '',
      address: addr,
      //DP fields
      Opt_In_Disclaimer_Code__c : dpDisclaimerCode,
      Opt_in_Date__c : new Date().toISOString(),
      DP_Flag_Email__c : (!!dto.emailAddress).toString(),
      DP_Description_Email__c : !!dto.emailAddress ? dpDescription : "",
      DP_Date_Email__c : !!dto.emailAddress ? new Date().toISOString() : "",
      DP_Flag_Trust__c : "true",
      DP_Description_Trust__c : dpDescription,
      DP_Date_Trust__c : new Date().toISOString(),
      DP_Flag_Events__c : "false",
      DP_Flag_Other__c : "false",
      DP_Description_Events__c : "",
      DP_Date_Events__c : ""
    };

     let postObject ={
            input: [{
              leadFormFields : formData
            }],
            formId : (<HTMLInputElement>document.getElementById("marketoFormId")).value
        }

    axios({
      method: 'post',
      url: '/submitToMarketo',
      data: postObject,
      headers: { 'Content-Type': 'application/json' }
    }).then(function (response) {
      if(response && response.data){
        axios({
          method: 'post',
          url: '/submitTrustMagazineOrder',
          data: postObject,
          headers: { 'Content-Type': 'application/json' }
        }).then(function(orderResponse)
        {
          if(!orderResponse || !orderResponse.data)
          {
            submissionResponse = false;
          }
         })
        .catch((orderError) => {
          console.error(orderError);
          submissionResponse = false;
        })
      }
      else{
        submissionResponse = false;
      }
    })
    .catch((error) => {
      if (error.response) {
        try {
          const errorDto: mws.SendingDataToMarketoErrorDto =  {
            errorMessage: `submitToMarketo - ${error.response.data.message}`,
            errorHttpCode: error.response.data.errorCode,
            urlReferer: window.location.href
          }
          const model: mws.PageRequestModel = this.createRequestModel(this.data, errorDto);
          this.fetchPostback(model);
        }
        catch (error) {
          console.error(error);
        }
      }

      submissionResponse = false;
    });

    return submissionResponse;
  }
}