import { Module } from 'vuex';
import { SelectedChannel } from '@/shared/global-navigation/SelectedChannel';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    selectedChannel: {} as SelectedChannel,
  },
  actions: {
    updateSelectedChannel({ commit }, channel: SelectedChannel) {
      commit('updateSelectedChannel', channel);
    },
  },
  mutations: {
    updateSelectedChannel(state, channel: SelectedChannel) {
      state.selectedChannel = channel;
    },
  },
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
