import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ErrorHandlerMixin extends Vue {
  public capturedError: Error | null = null;

  public errorCaptured(err: Error): boolean {
    console.error(`${err.name} caught: ${err.message}\n\n${err?.stack}`);
    this.capturedError = err;
    return false;
  }
}
