export interface GmapOptions {
  zoomControl: boolean;
  mapTypeControl: boolean;
  scaleControl: boolean;
  streetViewControl: boolean;
  rotateControl: boolean;
  fullscreenControl: boolean;
  disableDefaultUi: boolean;
  draggable: boolean;
}

const defaultGmapOptions = {
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: false,
  streetViewControl: true,
  rotateControl: false,
  fullscreenControl: true,
  disableDefaultUi: false,
  draggable: false,
};

export default defaultGmapOptions;
