import { Helpers } from "../../assets/scripts/utilities/formatHelpers";
import { BarChart } from "@/shared/MwsViewModels";

namespace UmbracoLocalisation {
    export class Translations {
        public static getInstance(): Translations {
            return new Translations();
        }

        public getDictionaryValue(key: string, alternative: string) {
            return alternative;
        }
    }
}
const translations = UmbracoLocalisation.Translations.getInstance();

export default function createFundBarChartOptions(data: BarChart): any {

    return {
        chart: {
            type: "column",
            height: 500,
            pinchType: "",
            marginRight: 0
        },
        title: {
            text: ''
        },
        navigator: {
            enabled: false
        },
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: data.header,
            labels: {
                style: {
                    color: '#000000',
                    fontSize: '12px',
                    fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif'
                },
                y: 20,
                useHTML: true
            },
            title: {
                text: null
            }
        },
        yAxis: {
            type: "linear",
            title: {
                text: translations.getDictionaryValue('bar_chart_y_axis_title', '% p.a.'),
            },
            labels: {
                style: {
                    color: '#000000',
                    fontSize: '12px',
                    fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif'
                }
            }
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: {
                        color: '#000000',
                        fontSize: '10px',
                        fontWeight: 'light',
                        fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif'
                    },
                    formatter(): string {
                        return `${Helpers.formatNumberFixed(this.y, 2)}%`;
                    },
                    allowOverlap: true,
                },
            },
            series: {
                clip: false
            }
        },
        legend: {
            align: "left",
            style: {
                color: '#000000',
                fontSize: '12px',
                borderRadius: 10,
                fontFamily: 'HelveticaNowTextRegular, Arial, Helvetica, sans-serif'
            }
        },
        series: data.series.map(x => ({
            name: x.name,
            color: x.color,
            data: x.values
        }))
    };
}