import Globalize, { DateFormatterOptions, NumberFormatterOptions } from 'globalize';

export namespace Helpers {
  export function formatDateLong(seriesDate: string, useLongMonthNames: boolean, useOnlyNumbersFormat = false): string {
    const date = new Date(seriesDate);
    let formatOptions: DateFormatterOptions;
    if (useOnlyNumbersFormat) {
      formatOptions = { skeleton: 'yMd'}
    }
    else if (useLongMonthNames) {
      formatOptions = { date: 'long' };
    } else {
      formatOptions = { skeleton: 'yMMMd' };
    }
    return Globalize.formatDate(date, formatOptions);
  }

  export function formatNumberFixed(value: number, decimalPoints: number): string {
    const decimalOptions: NumberFormatterOptions = {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints,
    };
    return Globalize.formatNumber(value, decimalOptions);
  }

  export function format(originalString: string, ...val:string[]): string {
    for (let i = 0; i < val.length; i++) {
        originalString = originalString.replace(`{${i}}`, val[i]);
    }
    return originalString;
  }
}
