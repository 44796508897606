import classnames from 'classnames/bind'
import RichText from '../RichText'
import StyledButton from '../StyledButton'
import * as styles from './AudienceNudger.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface AudienceNudgerProps {
  channelInvestorName: string
  channelRegionName: string
  className?: string
  labels?: { [key: string]: string }
  handleCloseButton: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleChange: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const AudienceNudger = ({
  channelInvestorName,
  channelRegionName,
  labels,
  className,
  handleCloseButton,
  handleConfirm,
  handleChange,
}: AudienceNudgerProps) => {
  const messageTemplate = labels?.NudgerMessage || ''

  const message = messageTemplate
    .replace('{{ChannelName}}', channelInvestorName)
    .replace('{{CountryName}}', channelRegionName)

  return (
    <div
      role="alert"
      className={cx('audienceNudger', className)}
    >
      <div className={cx('copy')}>
        <RichText
          className={cx('message')}
          copy={message}
        />
        <div className={cx('buttonRow')}>
          {labels?.Change && (
            <StyledButton
              className={cx('callToAction')}
              type="pill"
              callback={handleChange}
            >
              {labels?.Change}
            </StyledButton>
          )}
          {labels?.Confirm && (
            <StyledButton
              className={cx('callToAction')}
              type="pillReverse"
              callback={handleConfirm}
            >
              {labels?.Confirm}
            </StyledButton>
          )}
        </div>
      </div>
      <StyledButton
        type="text"
        customIcon="close"
        className={cx('closeButton')}
        classNameForIcon={cx('closeButtonIcon')}
        callback={handleCloseButton}
      />
    </div>
  )
}

export default AudienceNudger
