const carouselSettings = {
  infinite: false,
  dots: false,
  arrows: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default carouselSettings;
