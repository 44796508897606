import ChapterSubtitles from '../subtitle-models/ChapterSubtitles';

export const ChapterFourSubtitles: ChapterSubtitles = {
  textChunks: [
    {
      paragraphs: [
        'Colonel Augustus Baillie and the young Carlyle Gifford\'s first move into the '
        + 'investment business came in 1908 with the idea for a Trust to invest in the rubber '
        + 'industry, as the launch of the Model T Ford took America by storm.',
      ],
    },
    {
      paragraphs: [
        'The roaring twenties fed the pair\'s appetite for growth. Despite even the Wall Street '
        + 'Crash in 1929, the firm continued to expand.',
      ],
    },
    {
      paragraphs: [
        'Then in World War Two came Gifford\'s finest hour. The Governor of the Bank of England '
        + 'chose him to sell the country\'s assets on the New York Stock Exchange in order to '
        + 'build the nation\'s war chest. The way he went about it made him a celebrity in the '
        + 'US.',
      ],
    },
    {
      paragraphs: [
        'Time magazine calling him, "No nervous Nellie as he oversees the biggest selling '
        + 'order in history."',
        'It was a fitting final success story for the first generation of Baillie Gifford.',
      ],
    },
  ],
  subtitleLines: 4,
};

export default ChapterFourSubtitles;
