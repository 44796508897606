import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '../../../shared/MwsViewModels';
import template from './usm-fund-stock-level-attribution-panel.vue';

@Component({
  mixins: [template],
  components: {
  },
})
export default class UsmFundStockLevelAttributionPanel extends Vue {
  @Prop()
  data: mws.UsmFundStockLevelAttributionPanel;
}
