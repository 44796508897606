import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './block.vue';
// eslint-disable-next-line max-len
import PositionedResponsiveImage from '../../../../shared/components/positioned-responsive-image/PositionedResponsiveImage';
import GenericLink from '../../../../shared/components/link/GenericLink';
import DynamicTextFit from '../../../../shared/helpers/DynamicTextFit';

@Component({
  mixins: [template],
  components: {
    PositionedResponsiveImage,
    GenericLink,
  },
})
export default class Block extends Mixins(DynamicTextFit) {
  @Prop()
  data!: mws.Block;

  @Prop({ default: false })
  tint!: boolean;

  @Prop()
  blockHeadingClass: string;

  @Prop({ default: true })
  small: boolean;

  private bottomDescriptionMargin = 50;

  mounted() {
    this.registerDynamicBlockText();
  }
 
  updated() {
    this.registerDynamicBlockText();
  }

  private registerDynamicBlockText() {
    this.registerDynamicTextFitEvent(
      this.$refs.parentElement as Element,
      this.$refs.bottomElement as Element,
      this.$refs.textElement as Element,
      this.data.rolloverText,
      this.bottomDescriptionMargin,
    );
  }
}