import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './actual-investor-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import GenericLink from '../../../shared/components/link/GenericLink';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    GenericLink,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class ActualInvestorPanel extends Vue {
  @Prop()
  data!: mws.ActualInvestorPanel;

  topTextFirstWord = '';

  topTextRemainder = '';

  created() {
    const { topText } = this.data;
    const firstWordEnd = topText.indexOf(' ');
    this.topTextFirstWord = topText.substr(0, firstWordEnd);
    this.topTextRemainder = topText.substr(firstWordEnd + 1);
  }
}
