import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './trust-magazine-cta-panel.vue';
import { TrustMagazineCtaPanel as viewModel } from '../../../shared/MwsViewModels';
import GradientPanel from '../gradient/GradientPanel';
import GenericLink from '../../../shared/components/link/GenericLink';
import { ReactInVue } from 'vuera';
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    GradientPanel,
    GenericLink,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class TrustMagazineCtaPanel extends Vue {
  @Prop()
  data!: viewModel;
}
