
import React from 'react'
import classnames from 'classnames/bind'
import Icon from '../Icon'
import * as styles from './Hint.module.scss'

const cx = classnames.bind(styles)

export interface HintProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Description of prop. This will display in Storybook
   */
  message: string
  className?: string
  children?: React.ReactNode
  handleCloseButton: (ev: React.MouseEvent<HTMLButtonElement>) => void
}

const Hint = ({
  message,
  className = '',
  handleCloseButton,
  ...restOfAttributeProps
}: HintProps) => {
  return (
    <div
      {...restOfAttributeProps}
      className={cx('hint', className)}
    >
      <div className={cx('container')}>
        <div className={cx('message')}>{message}</div>
        <button
          onClick={handleCloseButton}
          className={cx('close')}
        >
          <Icon type="close" />
        </button>
      </div>
    </div>
  )
}

export default Hint
