
export namespace FieldValidation {

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  export function validateNotEmpty(text: string): boolean {
    return text != null && text !== '';
  }

  export function validateEmail(email: string): boolean {
    return emailRegex.test(email);
  }

}
