import { Mixins, Component } from 'vue-property-decorator';
import template from './literature-order-tab.vue';
import LiteratureOrderMixin from '../LiteratureOrderMixin'
import EventBus from '../../../shared/EventBus';
import LiteratureOrderEvents from '../LiteratureOrderEvents';

@Component({
  mixins: [template],
})
export default class LiteratureOrderTab extends Mixins(LiteratureOrderMixin) {

  itemCount: number = null;

  mounted() {
    this.updateItemCount();
    EventBus.$on(LiteratureOrderEvents.selectItem, this.updateItemCount);
  }

  onClick(): void {
    this.$store.dispatch('openLiteratureOrderPopup');
  }

  private updateItemCount(): void {
    this.itemCount = this.getLiteratureIds().length;
  }

}
