import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './gradient-panel.vue';

@Component({
  mixins: [template],
})
export default class GradientPanel extends Vue {
  @Prop()
  data!: mws.GradientPanel;
}
