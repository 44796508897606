import { Vue, Component } from 'vue-property-decorator';
import template from './loading-indicator.vue';

const loadingGifSrc = '/assets/images/common/loading.gif';

@Component({
  mixins: [template],
})
export default class LoadingIndicator extends Vue {
  loadingGifSrc = loadingGifSrc;
}
