import classnames from 'classnames/bind'
import { IInterruptor, TextWithBackgroundPanel as ITextWithBackgroundPanel } from '@/shared/MwsViewModels'
import RichText from '../../lib/RichText'
import SectionContainer from '../../lib/SectionContainer'
import React from 'react'
import Heading, { HeadingProps } from '../../lib/Heading'
import * as styles from './TextWithBackgroundPanel.module.scss'
import Interruptor from '../../lib/Interruptor'

const cx = classnames.bind(styles)
  
  export interface TextWithBackgroundPanelProps {
    data: ITextWithBackgroundPanel
  }
  
  const TextWithBackgroundPanel = ({ data }: TextWithBackgroundPanelProps) => {
    const headingText = data?.subheading
    const headingTag = data?.subheadingTagType || null
    const headingInfo: HeadingProps = {
      text: headingText || '',
      tag: headingTag,
    }
    const LayoutType = data?.layoutType
  
    switch (LayoutType) {
      case 'INTERRUPTOR': {
         return <Interruptor data={data as IInterruptor}></Interruptor>
       }
      default: {
        return (
          <SectionContainer coloursOverride={data?.colours}>
            <div className="util__narrow-container">
              {headingText && (
                <Heading
                  {...headingInfo}
                  className={cx('spacing__heading')}
                />
              )}
              {data?.bodyText && (
                <div data-testid="TextWithBackgroundPanel-richtext">
                  <RichText copy={data.bodyText} />
                </div>
              )}
            </div>
          </SectionContainer>
        )
      }
    }
  }
  
  export default TextWithBackgroundPanel
  