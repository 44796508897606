var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"bginsights-article"},[(_vm.isArchived)?_c('span',{staticClass:"bginsights-insight-archived-label"},[_vm._v("Archived")]):_vm._e(),_vm._v(" "),_c('time',[_vm._v(_vm._s(_vm.data.date))]),_vm._v(" "),_c('div',{staticClass:"rte rte--dark"},[_c('h1',[_vm._v("\n            "+_vm._s(_vm.data.title)+"\n        ")]),_vm._v(" "),(_vm.data.standfirst)?_c('span',{staticClass:"standfirst",domProps:{"innerHTML":_vm._s(_vm.data.standfirst)}}):_c('span',{staticClass:"description"},[_vm._v("\n            "+_vm._s(_vm.data.description)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"nested-content"},[(_vm.isPlayerAvailable)?_c('div',{staticClass:"bginsights-video-player-container bgfunds-main-module no-print"},[_c('div',{staticClass:"video-player-overlay-box",class:_vm.data.textColour == 'Black'
                        ? 'overlay-color-black'
                        : _vm.data.textColour == 'Cream'
                        ? 'overlay-color-cream'
                        : 'overlay-color-white'},[_c('div',{ref:"videoContainer",attrs:{"id":"js-bginsights-video-player","aria-labelledby":"bginsights-video-player-controls"}}),_vm._v(" "),_c('p',{staticClass:"u-visually-hidden",attrs:{"id":"bginsights-video-player-controls","aria-hidden":"true"}},[_vm._v("\n                    The following keyboard controls control the video player. - - Space -\n                    Pause/Play Video - - m - Mute/Unmute - - Up and Down arrows - adjust volume\n                    - - 0-9 - seek to percentage - - f - fullscreen - - c - toggle captions\n                ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPlayed),expression:"!isPlayed"}],staticClass:"overlay-contents",class:[
                        _vm.data.textColour == 'Black'
                            ? 'jw-overlay-contents-black'
                            : _vm.data.textColour == 'Cream'
                            ? 'jw-overlay-contents-cream'
                            : 'jw-overlay-contents-white' ]},[_c('p',[_vm._v(_vm._s(_vm.data.callToAction))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.duration))])]),_vm._v(" "),_c('div',{staticClass:"overlay-contents-mobile",on:{"click":function($event){return _vm.hoverClicked()}}})])]):_vm._e(),_vm._v(" "),(!_vm.isPlayerAvailable)?_c('div',[_c('video',{staticClass:"bg-insights-native-video-player",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.data.videoFile,"type":"video/mp4"}}),_vm._v(" "),_c('span',[_vm._v("Sorry, your browser doesn't support HTML5 video, but you can download "),_c('a',{attrs:{"href":_vm.data.videoFile,"target":"_blank"}},[_vm._v("this video")]),_vm._v(".")])])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"nested-content"},[_c('div',{staticClass:"bgside-share-and-print bginsights-share-and-view-transcript bgfunds-main-module bginsights-main-module"},[(_vm.data.transcriptUrl)?_c('a',{staticClass:"bginsights-link--view-transcript bginsights-link bginsights-link--white bginsights-link--bold",attrs:{"target":"_blank","href":_vm.data.transcriptUrl}},[_vm._v("\n                "+_vm._s(_vm.data.transcriptCta)+"\n            ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }