import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './fund-daily-prices-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import Translatable from '../../../translation/Translatable';
import DailyPricesTable from './daily-prices-table/DailyPricesTable';
import 'regenerator-runtime/runtime';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import GenericLink from '../../../shared/components/link/GenericLink';
import loadCldrPromise from '../../../assets/scripts/utilities/intialise';
import { Helpers } from '../../../assets/scripts/utilities/formatHelpers';
import axios from 'axios';
import DailyPricesSearchBlock from './DailyPricesSearchBlock';
import EventBus from '../../../shared/EventBus';

const loadingGifSrc = '/assets/images/common/loading.gif';

@Component({
  mixins: [template],
  components: {
    DailyPricesSearchBlock,
    DailyPricesTable,
    GenericLink,
  },
})
export default class FundDailyPricesPanel extends Mixins(Translatable, PostbackMixin) {
  @Prop()
  data!: mws.FundDailyPricesPanel;

  dates: {value: string; label: string}[] = [];

  selectedIndex = 0;

  loadingGifSource: string = loadingGifSrc;

  formatDates = false;

  mounted() {
    this.selectedIndex = 0;

    document.addEventListener('DOMContentLoaded', () => {
      loadCldrPromise()
        .done(() => { this.formatDates = true; })
        .fail(() => { this.formatDates = false; })
        .always(this.updateDates)
    });

    EventBus.$on('dp-table-filter-by-text', async (tabIndex: number, query: string) => {
      if (tabIndex === this.selectedIndex) {
        this.data.tabs[tabIndex].filter.searchText = query;
        await this.fetchData();
      }
    });

    EventBus.$on('dp-table-filter-by-dropdown', async (tabIndex: number, propName: string, selections: string[]) => {
      if (tabIndex === this.selectedIndex) {
        let tabFilter = this.data.tabs[tabIndex].filter;
        tabFilter[propName] = selections;
        await this.fetchData();
      }
    });

    EventBus.$on('dp-table-all-filters-cleared', async (tabIndex: number) => {
      if (tabIndex === this.selectedIndex) {
        this.clearAllFilterDataForTab(tabIndex);
        await this.fetchData();
      }
    })
  }

  updateDates(): void {
    const selectedTab = this.priceModels[this.selectedIndex];
    const dates = selectedTab.availableDates || [];
    var fundType = selectedTab.filter.fundType;
    var isUSMutualFund = fundType === 'UsMutualFund';
    
    this.dates = dates.map((date: string) => ({
      value: date,
      label: this.formatDates ? this.getDateFormat(date, isUSMutualFund) : date,
    }));
  }

  selectFundType(tabIndex: number): void {
    this.selectedIndex = tabIndex;
    this.updateDates();
  }

  handleSelectedDateChange(tabIndex) {
    if (tabIndex === this.selectedIndex) {
      EventBus.$emit('fund-daily-price-selected-date-change', tabIndex);
      this.clearAllFilterDataForTab(tabIndex);
      this.fetchData();
    }
  }

  public get table(): mws.Table {
    if (this.priceModels[this.selectedIndex]) return this.priceModels[this.selectedIndex].table;
    return {} as mws.Table;
  }

  getExportUrl() {
    return this.excelLink.url;
  }

  async fetchData(): Promise<void> {
    EventBus.$emit('fund-daily-price-search-is-loading-change', true);
    const payload: mws.FundDailyPricesPanelDto = {
      queries: { },
      selectedDates: { },
      selectedAssetClasses: { },
      selectedDistributionTypes: { },
      selectedGeographicRegions: { },
      selectedInvestmentStyles: { },
      selectedSfdrCategories: { }
    };

    this.data.tabs.forEach((tab) => {
      payload.queries[tab.filter.fundType] = tab.filter.searchText.trim();
      payload.selectedDates[tab.filter.fundType] = tab.selectedDate;
      
      if (tab.filter.showFilter) {
        payload.selectedAssetClasses[tab.filter.fundType] = tab.filter.selectedAssetClasses;
        payload.selectedDistributionTypes[tab.filter.fundType] = tab.filter.selectedDistributionTypes;
        payload.selectedGeographicRegions[tab.filter.fundType] = tab.filter.selectedGeographicRegions;
        payload.selectedInvestmentStyles[tab.filter.fundType] = tab.filter.selectedInvestmentStyles;
        payload.selectedSfdrCategories[tab.filter.fundType] = tab.filter.selectedSfdrCategories;
      }
    });

    const model = this.createRequestModel(this.data, payload);
    try {
      const updatedPage = await this.fetchPostback(model);
      await this.mergePostback(updatedPage);
      EventBus.$emit('fund-daily-price-search-is-loading-change', false);
    } catch (exc) {
      console.error(`Failed to update: ${exc}`);
      EventBus.$emit('fund-daily-price-search-is-loading-change', false);
    }
  }

  get excelLink(): mws.ILink {
    let link = this.priceModels[this.selectedIndex].excelLink;
    link.url = link.url.startsWith("/") ? link.url : `/${link.url}`;
    return link;
  }

  get priceModels(): mws.DailyPriceTab[] {
    return this.data.tabs;
  }

  async onDownloadClick(): Promise<void> {
    const url: string = this.excelLink.url;
    const currentTab = this.priceModels[this.selectedIndex];
    const fundType: string = currentTab.filter.fundType;
    const fileName: string = `${fundType}-prices-${currentTab.selectedDate.slice(0, 10)}`;

    await axios.get(url, { responseType: 'arraybuffer' })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
        window.URL.revokeObjectURL(url) // Release blob object
      })
      .catch((err) => console.log(`Failed to download document ${err}`));
  }
  
  isLastTab(index: number): boolean {
    return index === this.data.tabs.length - 1;
  }

  isCurrentTab(index: number): boolean {
    return index === this.selectedIndex;
  }

  private getDateFormat(date: string, isUSMutualFund: boolean): string {
    if (isUSMutualFund) {
      var dateValue = new Date(date);
      // For some reason Globalize() doesn't handle it.
      var dateFormat = `${('0' + (dateValue.getMonth() + 1)).slice(-2)}/${('0' + dateValue.getDate()).slice(-2)}/${dateValue.getFullYear()}`
      return dateFormat;
    }
    return Helpers.formatDateLong(date, false, true);
  }

  private clearAllFilterDataForTab(tabIndex) {
    this.data.tabs[tabIndex].filter.searchText = '';
    this.data.tabs[tabIndex].filter.selectedAssetClasses =
      this.data.tabs[tabIndex].filter.selectedDistributionTypes =
      this.data.tabs[tabIndex].filter.selectedGeographicRegions =
      this.data.tabs[tabIndex].filter.selectedInvestmentStyles =
      this.data.tabs[tabIndex].filter.selectedSfdrCategories = [];
  }
}