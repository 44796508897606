import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './it-key-information-panel.vue';
import * as mws from '../../../../shared/MwsViewModels';
import PostbackMixin from '../../../../shared/postback/PostbackMixin';
import loadCldrPromise from '../../../../assets/scripts/utilities/intialise';
import { Helpers } from '../../../../assets/scripts/utilities/formatHelpers';
import LoadingIndicator from '../../../../shared/components/loading-indicator/LoadingIndicator';

@Component({
  mixins: [template],
  components: {
    LoadingIndicator
  },
})
export default class ItKeyInformationPanel extends Mixins(PostbackMixin) {
  @Prop()
  data: mws.ItKeyInformationPanel;

  private oldSelectedDate: string = null;

  selectedDate: string = null;

  availableDates: {value: string, label: string}[] = [];

  isLoading = false;
  public hasFetchFailed = false;

  get totalAssetsValue(): string {
    const { columns } = this.data.totalAssets;
    return columns.length > 1 ? (columns[1].value || '') : '';
  }

  get shareholdersFundsValue(): string {
    const { columns } = this.data.shareholdersFunds;
    return columns.length > 1 ? (columns[1].value || '') : '';
  }

  created() {
    this.selectedDate = undefined;
  }

  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      loadCldrPromise().done(() => {
        this.initialiseAvailableDates();
        this.selectedDate = this.availableDates[0].value;
        this.oldSelectedDate = this.selectedDate;
      });
    });
  }

  public async fetchData(): Promise<void> {
    const requestedDate = this.selectedDate;
    if (this.oldSelectedDate === requestedDate) {
      return;
    }
    const postbackDto: mws.ItKeyInformationDto = {
      selectedDate: requestedDate,
    };
    const model = this.createRequestModel(this.data, postbackDto);

    this.hasFetchFailed = false;
    try {
      this.isLoading = true;
      const updatedPage = await this.fetchPostback(model);
      await this.mergePostback(updatedPage);
      this.initialiseAvailableDates();
    } catch (_error) {
      this.hasFetchFailed = true;
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  private initialiseAvailableDates(): void {
    this.availableDates = this.data.availableDates
      .map((date: string) => ({ value: date, label: Helpers.formatDateLong(date, false, true) }));
  }
}
