import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';
import template from './ucit-bar-chart-performance-set.vue'
import * as mws from '../../../../../shared/MwsViewModels';
import FadeTransitionMixin from '../../../../../shared/fade-transition/FadeTransitionMixin';
import { Helpers } from "../../../../../assets/scripts/utilities/formatHelpers";

@Component({
    mixins: [template]
})
export default class UcitBarChartPerformanceSet extends Mixins(FadeTransitionMixin) {
    @Prop() 
    data: mws.UcitBarChartPerformancePeriodDataSet;

    @Prop()
    tabIndex: number;

    roundValue(colValue: number): string {
        if (colValue) {
            return Helpers.formatNumberFixed(colValue, 2);
        } else {
            return 'n/a';
        } 
    }

    mounted() {
        this.$emit('barChartPerformanceSetLoaded');
    }
}