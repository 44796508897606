import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './literature-order-form.vue';
import { OrderableItem, PersonalDetailsDto } from '../ViewModels';
import { FieldValidation } from '../../../shared/forms/FieldValidation';
import RequiredFieldLabel from '../required-field-label/RequiredFieldLabel';
import AddressSelectorMixin, { AddressEntry } from '../AddressSelectorMixin';

@Component({
  mixins: [template],
  components: {
    RequiredFieldLabel,
  },
})
export default class LiteratureOrderForm extends Mixins(AddressSelectorMixin) {
  @Prop()
  literatureItems: OrderableItem[];

  @Prop()
  formDisabled: boolean;

  @Prop()
  error: boolean;

  validateRequired = false;
  cannotFindPostcode = false;

  addressesToSelect: AddressEntry[] = [];
  selectedAddressId = 'default';

  formData: PersonalDetailsDto = {
    title: '',
    forename: null,
    surname: null,
    postcode: null,
    address1: null,
    address2: null,
    town: null,
    county: null,
    email: null,
    trustFlag: false,
    dataProtectionStatement: null,
  }

  isRequiredValid(text: string): boolean {
    return !this.validateRequired || FieldValidation.validateNotEmpty(text);
  }

  isTitleValid(): boolean { return this.isRequiredValid(this.formData.title); }
  isForenameValid(): boolean { return this.isRequiredValid(this.formData.forename); }
  isSurnameValid(): boolean { return this.isRequiredValid(this.formData.surname); }
  isPostcodeValid(): boolean { return this.isRequiredValid(this.formData.postcode); }
  isAddress1Valid(): boolean { return this.isRequiredValid(this.formData.address1); }
  isAddress2Valid(): boolean { return true; }
  isTownValid(): boolean { return this.isRequiredValid(this.formData.town); }
  isCountyValid(): boolean { return true; }
  isEmailValid(): boolean {
    const { email } = this.formData;
    return email == null || email === '' || FieldValidation.validateEmail(email);
  }

  async findAddress(): Promise<void> {
    this.addressesToSelect = await this.findAddressesByPostcode(this.formData.postcode);
    const addressCount = this.addressesToSelect.length;
    this.cannotFindPostcode = addressCount === 0;
    this.selectedAddressId = addressCount === 0 ? null : 'default';
  }

  async onSelectedAddressChange(): Promise<void> {
    this.addressesToSelect.length = 0;
    try {
      const details = await this.findAddressDetails(this.selectedAddressId);
      this.formData.address1 = details.address1;
      this.formData.address2 = details.address2;
      this.formData.county = details.county;
      this.formData.town = details.town;
      this.cannotFindPostcode = false;
    } catch (_exc) {
      this.cannotFindPostcode = true;
    }
  }

  removeLiterature(id: number): void {
    this.$emit('removeLiterature', id);
  }

  onSubmit(): void {
    this.validateRequired = true;
    if ([
      this.isTitleValid(),
      this.isForenameValid(),
      this.isSurnameValid(),
      this.isPostcodeValid(),
      this.isAddress1Valid(),
      this.isAddress2Valid(),
      this.isTownValid(),
      this.isCountyValid(),
      this.isEmailValid(),
    ].some((x) => !x)) {
      return;
    }

    this.$emit('submit', this.formData);
  }
}
