import React from 'react'
import classnames from 'classnames/bind'
import {
  ChildLink,
  EnhancedTextLink,
  ILink,
  ImageLink,
} from '@/shared/MwsViewModels'
import Icon, { IconOptions } from '../Icon'
import {
  IBaseLink,
  LinkButtonStyles,
  StylesMap,
  provideButtonIcon,
} from '../LinkButtonStyles'
import * as styles from './../LinkButtonStyles/LinkButtonStyles.module.scss'

const cx = classnames.bind(styles)

export interface StyledButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  type: LinkButtonStyles
  children?: React.ReactNode
  className?: string
  classNameForIcon?: string
  isActive?: boolean
  data?: ILink | ImageLink | EnhancedTextLink | ChildLink | IBaseLink
  callback?: (event: React.MouseEvent<HTMLButtonElement>) => void
  isExpanded?: boolean
  isDisabled?: boolean | undefined
  customIcon?: IconOptions | null
  hideIcon?: boolean
  id?: string;
}

const StyledButton = ({
  data,
  type,
  className,
  classNameForIcon = '',
  children,
  isActive = false,
  isExpanded = false,
  isDisabled = undefined,
  hideIcon = false,
  customIcon = null,
  id = null,
  callback,
  ...attributeProps
}: StyledButtonProps) => {
  const text = data?.linkText
  const linkType =
    data && 'elementType' in data ? data?.elementType : 'internalLink'
  const generatedIcon = provideButtonIcon(linkType, type)
  const isActiveClass = isActive && 'isActive'
  const isExpandedClass = isExpanded && 'isExpanded'
  const isDisabledClass = isDisabled && 'isDisabled'

  return (
    <button
      id={id}
      onClick={callback}
      className={cx(
        StylesMap[type],
        className,
        isActiveClass,
        isExpandedClass,
        isDisabledClass
      )}
      disabled={isDisabled}
      {...attributeProps}
    >
      {type === 'radioSelector' && <span className={cx('radioLabelIcon')} />}
      {children ? children : text}
      {(generatedIcon || customIcon) && !hideIcon && (
        <Icon
          className={cx({
            [classNameForIcon]: true,
            generatedIconRight:
              ['arrow', 'document'].includes(generatedIcon || '') &&
              !classNameForIcon,
            generatedIconRightNonArrow:
              generatedIcon === 'document' && !classNameForIcon,
          })}
          type={customIcon || generatedIcon}
        />
      )}
      {type === 'primaryNavigation' && (
        <Icon
          aria-hidden="true"
          className={cx(
            'primaryNavigationIcon',
            'primaryNavigationIconDesktop'
          )}
          type={isExpanded ? 'chevronUp' : 'chevronDown'}
        />
      )}
      {type === 'primaryNavigation' && (
        <Icon
          aria-hidden="true"
          className={cx('primaryNavigationIcon', 'primaryNavigationIconMobile')}
          type="chevronRight"
        />
      )}
    </button>
  )
}

export default StyledButton
