import { Component, Prop } from 'vue-property-decorator';
import { ILink } from '@/shared/MwsViewModels';
import template from './carousel-external-link.vue';
import GenericLink from '../../../../shared/components/link/GenericLink';
import Translatable from '../../../../translation/Translatable';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class CarouselExternalLink extends Translatable {
  @Prop()
  data: ILink;

  async created() {
    await this.loadDictionary(['literature_library_results_download']);
  }
}
