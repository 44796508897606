import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './analysis-table.vue';

@Component({
  mixins: [template],
})
export default class DonutChartDetailsTable extends Vue {
  @Prop()
  data: mws.DonutChartModel;

  readonly regionSecondaryValue: string = '#MERGE#';

  get tableData(): mws.SeriesDataDonutWithBenchmark[] {
    return this.data.chart.series.data as mws.SeriesDataDonutWithBenchmark[];
  }

  public isSecondaryNested(item: mws.SeriesDataDonutWithBenchmark) {
    return item.secondaryInfo && item.secondaryInfo !== this.regionSecondaryValue;
  } 
}
