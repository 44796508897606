import { Component, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './global-selector-popup.vue';
import Translatable from '../../../translation/Translatable';
import Region from './components/Region';
import LocationSelectorMixin from '../../../shared/global-navigation/LocationSelectorMixin';

@Component({
  mixins: [template],
  components: {
    Region,
  },
})
export default class GlobalSelectorPopup extends Mixins(Translatable, LocationSelectorMixin) {
  public readonly columnsCount: number = 3;
  private selectedRegionId: string = '';

  async created() {
    await this.loadDictionary(['global_navigation_secondary_regions_lead_text']);
  }

  beforeUpdate() {
    var location = this.$store.getters.getLocationByIP;
    if (!location) {
      location = this.getLocationByIdFromCookie();
    }
    if (!location && !this.userCountryHasBeenSet) {
        this.fetchUserLocationByIp(this.allMainRegions);
    } else {
      this.setUserLocationFirstInList(this.allMainRegions, location);
    }
  }

  onRegionClick(regionId: string): void {
    this.selectedRegionId = this.selectedRegionId != regionId ? regionId : '';
  }

  public closePopup(): void {
    const closeUrl = this.$store.state.popups.selectLocationPopup.onCloseRedirectUrl;
    this.$store.dispatch('closeSelectLocationPopup');
    if (closeUrl) {
      this.$router.push({ path: closeUrl });
      this.$router.go(0);
    }
  }

  public getRegions(column: number): mws.Region[] {
    const start = column + (column * this.columnSize);
    const end = start + this.columnSize + 1;
    return this.allMainRegions.slice(start, end);
  }

  public getAdditional(column: number): mws.Region[] {
    return column === this.columnsCount - 1 ? this.allAdditionalRegions : [];
  }

  public get isOpen(): boolean {
    return this.$store.state.popups.selectLocationPopup.isOpen;
  }

  private get allMainRegions(): mws.Region[] {
    const regions = this.globalNavigation.main;
    return regions == null ? [] : regions;
  }

  private get allAdditionalRegions(): mws.Region[] {
    const regions = this.globalNavigation.additional;
    return regions == null ? [] : regions;
  }

  private get globalNavigation(): mws.GlobalNaviagtion {
    const navigation = this.$store.state.popups.selectLocationPopup.globalNavigation;
    return navigation == null ? {} as mws.GlobalNaviagtion : navigation;
  }

  private get columnSize(): number {
    const regions = this.allMainRegions.length;
    const additional = this.allAdditionalRegions.length;

    return (regions + additional) / this.columnsCount;
  }

}
