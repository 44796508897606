import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './magazine-aside-items.vue';


@Component({
    mixins: [template],
})

export default class MagazineAsideItems extends Vue{
    @Prop()
    asideItems: mws.CallToActionListLink[];
}