import classnames from 'classnames/bind'
import {
  ColourPickerElement,
  SupercardDesign2,
} from '@/shared/MwsViewModels'
import Heading, { HeadingProps } from '../Heading'
import RichText from '../RichText'
import SectionContainer from '../SectionContainer'
import StyledLink from '../StyledLink'
import * as styles from './PageAnchor.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface PageAnchorProps {
  coloursOverride: ColourPickerElement
  items: SupercardDesign2[]
}

interface AnchorCardProps {
  card: SupercardDesign2
}

function AnchorCard({ card }: AnchorCardProps) {
  const ctaLink = card?.cta
  const headingText = card?.title

  const headingInfo: HeadingProps = {
    text: headingText || '',
    tag: 'H2',
  }

  return (
    <div>
      <Heading {...headingInfo} />
      {card?.description && (
        <RichText
          className={cx('spacing__sm-top')}
          copy={card?.description}
        />
      )}
      {ctaLink?.url && (
        <div className={cx('spacing__sm-top')}>
          <StyledLink
            type="link"
            data={ctaLink}
          />
        </div>
      )}
    </div>
  )
}

const PageAnchor = ({ coloursOverride, items }: PageAnchorProps) => {
  const anchorItems = (Array.isArray(items) ? items : []).slice(0, 2)

  return (
    <SectionContainer coloursOverride={coloursOverride}>
      <div
        data-testid="page-anchor-list"
        className={cx('grid')}
      >
        {anchorItems.map((card, index) => {
          return (
            <div
              className={cx('gridColumn')}
              key={`anchor-${index}`}
            >
              <AnchorCard card={card} />
            </div>
          )
        })}
      </div>
    </SectionContainer>
  )
}

export default PageAnchor
