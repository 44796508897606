import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './content-page-header-image-panel.vue';
// eslint-disable-next-line max-len
import PositionedResponsiveImage from '../../../shared/components/positioned-responsive-image/PositionedResponsiveImage';
import EventBus from '../../../shared/EventBus';
import FundDailyPricesShortlistMixin from '../../../shared/components/fund-daily-prices/FundDailyPricesShortlistMixin';

@Component({
  mixins: [template],
  components: {
    PositionedResponsiveImage,
  }
})
export default class ContentPageHeaderImagePanel extends Mixins(FundDailyPricesShortlistMixin) {
  private width = 0;
  private maxNavHeight = '';
  shortlistCounter: number = 0;

  @Prop()
  data?: mws.ContentPageHeaderImagePanel;

  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
    this.handleShortlistCounter();
    Vue.nextTick(() => {
      this.doesShortlistNavItemExist();
      this.sendDailyPricesPageUrl();
    });
  }

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  get navLinks(): mws.ILink[] {
    return (this.data.links || []).slice(0, 5);
  }

  isCurrent(url: string): boolean {
    const resource = this.$route.fullPath;
    return url.endsWith(resource);
  }

  private resizeHandler() {
    this.width = window.innerWidth;
    let navHeadings = this.$refs.navHeading as Element[];
    if(navHeadings) {
      let maxHeight = Math.max(...navHeadings.map(n => n.clientHeight));
      if(this.width < 800){
        this.maxNavHeight = `${maxHeight - 30}px`;
      } else {
        this.maxNavHeight = `${maxHeight}px`;
      }
    }
  }

  private handleShortlistCounter() {
    this.shortlistCounter = this.getCurrentRegionAndChannelShortlistCount();

    EventBus.$on('dp-shortlist-updated', async (toAdd: number) => {
      this.shortlistCounter += toAdd;
    });
  }

  private doesShortlistNavItemExist() {
    let exists = this.navLinks.filter(l => l.linkText.toLowerCase().includes(this.shortlistNavHeadingPart)).length > 0;
    if (exists) {
      EventBus.$emit('dp-shortlist-navitem-exist');
    }
  }

  private sendDailyPricesPageUrl() {
    let navLink = this.navLinks.filter(l => l.linkText.toLowerCase().includes(this.dailyPricesNavHeadingPart))[0];
    if (navLink) {
      EventBus.$emit('dp-dailyPrices-navitem-exist', `<a href=${navLink.url}>${navLink.linkText}</a>`);
    }
  }

  isItShortlistNavItem(navItem: mws.ILink) {
    return navItem.linkText.toLowerCase().includes(this.shortlistNavHeadingPart);
  }

  get getShortlistCounter() {
    return this.shortlistCounter;
  }
}