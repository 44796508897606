import { Module } from 'vuex';
import Vue from 'vue';
import { Metadata, Page } from '@/shared/MwsViewModels';
import mergePostbackPanels from '../../shared/postback/mergePostbackPanels';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    data: null,
    url: '',
  },
  actions: {
    setPage({ commit }, data) {
      commit('setPageData', data.data);
      commit('setPageUrl', data.url);
    },
    mergePanels({ commit }, data) {
      commit('mergePanels', data);
    },
  },

  getters: {
    pageMetainfo: (state): Metadata => state.data.page.metadata,
    pageData: (state): Page => state.data.page,
    pageUrl: (state): string => state.url,
  },

  mutations: {
    setPageData(state, data) {
      Vue.set(state, 'data', data);
    },
    setPageUrl(state, url) {
      Vue.set(state, 'url', url);
    },
    mergePanels(state, newPage) {
      mergePostbackPanels(state.data.page, newPage.page);
    },
  },
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
