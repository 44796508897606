import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './usm-portfolio-characteristics-panel.vue';
import TabContainer, { TabViewModel } from '../../tab-container/TabContainer';
import GlossaryPanel from '../glossary/GlossaryPanel'

@Component({
  mixins: [template],
  components: {
    TabContainer,
    GlossaryPanel
  },
})
export default class UsmPortfolioCharacteristicsPanel extends Vue {
  @Prop()
  data: mws.UsmPortfolioCharacteristicsPanel;

  get containerTabs(): TabViewModel[] {
    return this.data.tabs
      .map((tab) => ({ heading: tab.tableData.title, tableData: tab.tableData }));
  }
}
