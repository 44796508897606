import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './text-with-background-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import { ReactInVue } from 'vuera';
import TextWithBackgroundPanelReact from '../../../react/rebrand-lite/panels/TextWithBackgroundPanel';

@Component({
  mixins: [template],
  components: {
    TextWithBackgroundPanelReact: ReactInVue(TextWithBackgroundPanelReact),
  },
})
export default class TextWithBackgroundPanel extends Vue {
  @Prop()
  data: mws.TextWithBackgroundPanel;
}
