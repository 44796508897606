import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-text-with-background-panel.vue';

@Component({
  mixins: [template],
})
export default class FundTextWithBackgroundPanel extends Vue {
  @Prop()
  data: mws.FundTextWithBackgroundPanel;
}
