import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AccordionMixin extends Vue {
  isOpen = false;

  contentHeight = 'auto';

  private preferredHeight: number = null;

  mounted() {
    if (this.contentHeight === 'auto') {
      const element = this.$refs.content as Element;
      this.preferredHeight = element.scrollHeight;
    }
    this.contentHeight = '0';
  }

  onAccordionClick(): void {
    this.isOpen = !this.isOpen;
    this.contentHeight = this.isOpen ? `${this.preferredHeight}px` : '0';
  }
}
