import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './call-to-action-panel.vue';
import CallToActionElement from './call-to-action-element/CallToActionElement';
import { ReactInVue } from 'vuera'
import SectionContainer from '../../../react/rebrand-lite/lib/SectionContainer';

@Component({
  mixins: [template],
  components: {
    CallToActionElement,
    'SectionContainer': ReactInVue(SectionContainer),
  },
})
export default class CallToActionPanel extends Vue {
  @Prop()
  data: mws.CallToActionPanel;

  private maxImageHeight: number = 0;

  ctaImageHeightSet(height: number) {
    if(height > this.maxImageHeight) {
      this.maxImageHeight = height;
      const elements = this.$refs.ctaElement as Vue[];
      elements.forEach(el => {
        let img = el.$refs.ctaImage as HTMLImageElement;
        img.height = this.maxImageHeight;
      });
    }
  }

  get callsToAction(): mws.ICallToActionElement[] {
    return this.data.callToActions;
  }

  get hasBackground(): boolean {
    return this.data.backgroundColour != null && this.data.backgroundColour !== '';
  }
}
