import { Table } from '@/shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseTopHoldings from '../base-top-holdings/BaseTopHoldings'; //keep that line - usage in template!
import template from './holdings-table-canada.vue';

@Component({
  mixins: [template],
})
export default class HoldingsTableCanada extends Vue {
  @Prop()
  table: Table;

  @Prop()
  tableType: string;

  disclaimer: string;
  tableDisclaimer: string;

  get columns(): CanadaHoldingsTableColumn[] {
    if (this.tableType === BaseTopHoldings.allHoldingsTableType) {
      return [
        { label: '', style: "width: 3%;" }, //index column
        { label: 'SEDOL', style: "width: 10%;" },
        { label: 'ISIN', style: "width: 15%;" },
        { label: 'Stock Name', style: "width: 13%;" },
        { label: 'Country', style: "width: 9%;" },
        { label: 'Holding', style: "width: 9%;" },
        { label: 'Asset Price', style: "width: 9%;" },
        { label: 'Currency', style: "width: 9%;" },
        { label: 'Asset Type', style: "width: 6%;" },
        { label: 'Market Value (CAD)', style: "width: 9%;" },
        { label: 'Fund %', style: "width: 8%;" }
      ];
    } else if (this.tableType === BaseTopHoldings.topTenHoldingsTableType) {
      return [
        { label: '', style: "width: 5%;" }, //index column
        { label: 'Company Name', style: "width: 87%;" },
        { label: 'Fund %', style: "width: 8%;" }
      ];
    }

    return [];
  }
}

class CanadaHoldingsTableColumn {
  label: string;
  style: string;
}