import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';
import template from './ucit-performance-over-time.vue'
import * as mws from '../../../../shared/MwsViewModels';
import FadeTransitionMixin from '../../../../shared/fade-transition/FadeTransitionMixin';
import loadCldrPromise from '../../../..//assets/scripts/utilities/intialise';
import FundPerformanceChart from '../../fund-performance-chart/FundPerformanceChart';
import * as stockHighcharts from 'highcharts/highstock';
import { createLineChartOptions } from '../../../../assets/scripts/charting/options/lineChartOptions';

@Component({
    mixins: [template]
})
export default class UcitPerformanceOverTime extends Mixins(FadeTransitionMixin) {
    @Prop()
    data: mws.UcitPerformanceOverTime;

    mounted() {
        loadCldrPromise().done(() => {
            this.createPerformanceOverTimeChart(true);
        });
    }

    updated() {
        if (this.data) {
            this.createPerformanceOverTimeChart(false);
        }
    }

    private createPerformanceOverTimeChart(seriesAnimation: boolean) {
        const elementSelector = 'line-chart-container';
        const highchartsSeries = FundPerformanceChart.mapToHighstockDataSeries(this.data.series.series);
        const highchartsOptions = createLineChartOptions(highchartsSeries, "isWWFund");
        highchartsOptions.plotOptions.series.animation = seriesAnimation;

        let element = document.getElementById(elementSelector);

        if (element) {
            stockHighcharts.stockChart(elementSelector, highchartsOptions);
        }
    }
}