import { isMobile } from 'mobile-device-detect';

export interface BrowserInfo {
    isMobile: boolean;
}

export function getClientSideBrowserInfo(): BrowserInfo {
  return {
    isMobile,
  };
}
