import { Component, Prop } from 'vue-property-decorator';
import template from './actual-investor-insights-panel.vue';
import * as mws from '../../../../shared/MwsViewModels';
import PositionedResponsiveImage from '../../../../shared/components/positioned-responsive-image/PositionedResponsiveImage';
import GenericLink from '../../../../shared/components/link/GenericLink';
import Translatable from '../../../../translation/Translatable';


@Component({
    mixins: [template],
    components: {
        PositionedResponsiveImage,
        GenericLink
    },
})

export default class ActualInvestorInsightsPanel extends Translatable {
    private showAllInsights = false;

    @Prop()
    data!: mws.ActualInvestorInsightsPanel;
    get groupedInsights(): any[] {
        let grouped = [];
        let index = -1;
        for (let i = 0; i < this.data.insights.length; i++) {
            if (i % 3 == 0) {
                index++;
                grouped[index] = [];
            }

            if (index < 1 || this.showAllInsights) {
                grouped[index].push(this.data.insights[i]);
            }
        }
        return grouped;
    }

    loadMore(): void {
        this.showAllInsights = true;
    }

    async created() {
        return this.loadDictionary([
            'actual_investor_load_more_button'
        ]);
    }
}