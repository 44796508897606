import ChapterSubtitles from '../subtitle-models/ChapterSubtitles';

export const ChapterTwoSubtitles: ChapterSubtitles = {
  textChunks: [
    {
      paragraphs: [
        'It is the spring of 1979. And the UK market is about to go from strength to strength.',
        'The Conservatives take power introducing sweeping changes. This leads to new opportunities.'],
    },
    {
      paragraphs: [
        'The performance of the Baillie Gifford UK team over the next 20 years will prove invaluable '
        + 'to the firm’s clients.',
        'But the key decision that will lead to growth is the serious move into pension funds.',
      ],
    },
    {
      paragraphs: [
        'Fortunately Baillie Gifford’s approach to investing will attract a large number of '
        + 'pension fund clients to want to invest with them.',
        'And this will see assets under management grow from £3.5 billion to £16.2 billion pounds.',
        'In only 10 years.',
      ],
    },
    {
      paragraphs: [
        'Proof that this generation of partners took full advantage for their clients during '
        + 'the great bull market of 1982 to 2000.',
      ],
    },
  ],
  subtitleLines: 4,
};

export default ChapterTwoSubtitles;
