import classnames from 'classnames/bind'
import { ColourPickerElement } from '@/shared/MwsViewModels'
import * as styles from './SectionContainer.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface SectionContainerProps {
  className?: string
  headerSpacing?: boolean
  customPaddingWithBackground?: boolean
  coloursOverride?: ColourPickerElement
  children?: React.ReactNode
  FooterChildren?: React.ReactNode
}

const SectionContainer = ({
  headerSpacing = false,
  children,
  className,
  coloursOverride = undefined,
  customPaddingWithBackground = false,
  FooterChildren,
}: SectionContainerProps) => {
  const paddedClass =
    coloursOverride?.backgroundColour &&
    !customPaddingWithBackground &&
    'padded'
  const actsPaddedWithBackgroundClass =
    customPaddingWithBackground && 'actsPaddedWithBackground'
  const headerSpacingClass = headerSpacing ? 'sectionHeaderSpacing' : ''

  const inlineStyles = {
    '--heading-color': coloursOverride?.titleColour,
    '--text-color': coloursOverride?.textColour,
    backgroundColor: coloursOverride?.backgroundColour,
  } as React.CSSProperties

  return (
    <section
      className={cx(
        'section',
        className,
        paddedClass,
        headerSpacingClass,
        actsPaddedWithBackgroundClass
      )}
      style={inlineStyles}
    >
      <div className={cx('rebrandContainerRedesign')}>{children}</div>
      {FooterChildren}
    </section>
  )
}

export default SectionContainer
