import classnames from 'classnames/bind'
import { ILink, Image } from '@/shared/MwsViewModels'
import { safeArray } from '../../utils/helpers'
import Heading from '../Heading'
import ImageComponent from '../ImageComponent'
import RichText from '../RichText'
import SectionContainer from '../SectionContainer'
import StyledLink from '../StyledLink'
import * as styles from './HeaderGeneral.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface HeaderGeneralProps {
  image: Image
  description: string
  heading: string
  links: ILink[]
}

export interface FooterImageProps {
  imageContent: Image
}

function FooterImage({ imageContent }: FooterImageProps) {
  const hasImage = imageContent?.url
  return (
    <>
      {hasImage && (
        <ImageComponent
          className={cx('headerImage')}
          ratio={'3:1'}
          priority={true}
          data={imageContent}
        />
      )}
    </>
  )
}

const HeaderGeneral = ({
  heading,
  description,
  image,
  links,
}: HeaderGeneralProps) => {
  const tag = 'H1'
  const callToActionsLinks = safeArray(links)

  return (
    <SectionContainer
      FooterChildren={<FooterImage imageContent={image} />}
      customPaddingWithBackground={Boolean(image)}
      className={cx('componentPadding', 'header-general-container')}
    >
      <div
        data-testid="header-general"
        className={cx('columns')}
      >
        {heading && (
          <div className={cx('columnLeft')}>
            <Heading
              tag={tag}
              text={heading}
              className={cx('spacing__sm', 'heading')}
              headingClassName="type__h1-large"
            />
          </div>
        )}
        <div className={cx('columnRight', 'fittedContent')}>
          {description && (
            <RichText
              size="prominent"
              className={'spacing__sm'}
              copy={description}
            />
          )}
          {callToActionsLinks.length > 0 &&
            callToActionsLinks.map((linkData) => {
              return (
                <div
                  key={linkData.url}
                  className={cx('callToActionLink')}
                >
                  <StyledLink
                    data={linkData}
                    type="linkLarge"
                  />
                </div>
              )
            })}
        </div>
      </div>
    </SectionContainer>
  )
}

export default HeaderGeneral
