import { Mixins, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-landing-page-header-panel.vue';
import FundBanner from './fund-banner/FundBanner';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import { ILink, ShareClass } from '../../..//shared/MwsViewModels';
import EventBus from '../../../shared/EventBus';

@Component({
  mixins: [template],
  components: {
    FundBanner
  }
})
export default class FundLandingPageHeaderPanel extends Mixins(PostbackMixin) {
  @Prop()
  data: mws.FundLandingPageHeaderPanel;

  public async onShareClassChange(selectedShareClass: ShareClass) {
    await this.$store.dispatch('setUcitShareClass', selectedShareClass);

    const postbackDto: mws.UcitPostbackShareClassDto = {
      shareClass: selectedShareClass.shareClass,
      currency: selectedShareClass.currency,
      incAccStr: selectedShareClass.incAcc,
      mappingType: selectedShareClass.mappingType,
      hedgedFund: selectedShareClass.hedgedFund,
      overrideCurrency: this.$store.getters.ucitPerformancePanelOverridingCurrency,
      selectedDate: this.$store.getters.ucitChoiceAndChargesSelectedDate
    };
    const model = this.createRequestModel(this.data, postbackDto);

    try {
      const updatedPage = await this.fetchPostback(model);
      await this.mergePostback(updatedPage);
      EventBus.$emit('share-class-change');
    } catch (_error) {
    }
   }

  get fundSubscriptionLink(): ILink {
    return this.data.showFundSubscription ? this.data.fundSubscriptionLink : null;
  }
}