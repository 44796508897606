import { Vue, Component, Prop } from 'vue-property-decorator';
import { ICallToActionElement, ILink } from '@/shared/MwsViewModels';
import template from './call-to-action-element.vue';
import GenericLink from '../../../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class CallToActionElement extends Vue {
  @Prop()
  item: ICallToActionElement;

  mounted() {
    let img = this.$refs.ctaImage as HTMLImageElement;
    if(img) {
      img.onload = () => {
        this.$emit('ctaImageSet', img.height);
      }
    }
  }

  get links(): ILink[] {
    const anyLink = this.item as any;
    if (anyLink.link != null) {
      return [anyLink.link];
    } if (anyLink.links != null) {
      return anyLink.links;
    }
    return [];
  }
}