import React from 'react'
import classnames from 'classnames/bind'
import { Image } from '@/shared/MwsViewModels'
import Heading from '../Heading'
import Icon from '../Icon'
import ImageComponent from '../ImageComponent'
import { IBaseLink } from '../LinkButtonStyles'
import RichText from '../RichText'
import StyledLink from '../StyledLink'
import * as styles from './CardSmall.module.scss'

const cx = classnames.bind(styles)

export interface CardSmallProps {
  heading: string
  url: string
  image: Image | null
  abstract?: string
  date?: string | null
  contentLength?: string | null
  type?: string | null
  openInNewWindow?: boolean
}

const CardSmall = ({
  heading,
  abstract,
  type = null,
  contentLength = null,
  date = null,
  url,
  image,
  openInNewWindow = false,
}: CardSmallProps) => {
  const MetaBarProps = {
    type,
    contentLength,
    date,
  }

  const StyledLinkProps: IBaseLink = {
    url,
    linkText: heading,
    linkTitle: heading,
    openInNewWindow,
  }

  const hasImage = image?.url

  const iconForImage = getIconType(type)

  return (
    <StyledLink
      type="text"
      data={StyledLinkProps}
      className={cx('card')}
    >
      {hasImage && (
        <div className={cx('imageContext', 'spacing__sm')}>
          <div className={cx('imageFrame')}>
            <ImageComponent
              className={cx('image')}
              ratio={'16:10'}
              data={image}
            />
            {iconForImage && (
              <div className={cx('imageIconFrame')}>
                <Icon
                  type={iconForImage}
                  className={cx({
                    imageIcon: true,
                    imageIconPlay: iconForImage === 'play',
                    imageIconPodcast: iconForImage === 'podcast',
                  })}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={cx('spacing__sm')}>
        <Heading
          tag="H3"
          text={heading}
          className={cx('title', 'type__h4')}
        />
      </div>
      {abstract && (
        <RichText
          size="card"
          copy={abstract}
        />
      )}
      <MetaBar {...MetaBarProps} />
    </StyledLink>
  )
}

interface metaBarProps {
  date: string | null
  contentLength: string | null
  type: string | null
}

function MetaBar({ type, contentLength, date }: metaBarProps) {
  const metaPipedList = [
    type,
    contentLength && { duration: contentLength },
  ].filter(Boolean)

  const hasMetaInfo = metaPipedList.length > 0 && date

  if (!hasMetaInfo) return null

  return (
    <div className={cx('metabar', 'spacing__sm-top')}>
      {date && <span className={cx('metaItem')}>{date}</span>}

      <div className={cx('metaList')}>
        {metaPipedList.map((meta) => {
          if (typeof meta === 'string') {
            return (
              <span
                key={meta}
                className={cx('metaItem')}
              >
                {meta}
              </span>
            )
          }

          if (typeof meta === 'object' && meta?.duration) {
            return (
              <span
                key={'Content Length'}
                className={cx('metaItem')}
              >
                <Icon
                  type="stopwatch"
                  className={cx('metaIcon')}
                />
                {meta?.duration}
              </span>
            )
          }
        })}
      </div>
    </div>
  )
}

function getIconType(articleType: string | null) {
  switch (articleType) {
    case 'Video':
      return 'play'
    case 'Podcast':
      return 'podcast'
    default:
      return ''
  }
}

export default CardSmall
