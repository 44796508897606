import { Vue, Component, Prop } from 'vue-property-decorator';
import { RelatedLinksElement } from '@/shared/MwsViewModels';
import template from './bottom-link-item.vue';
import GenericLink from '../../../../shared/components/link/GenericLink';

@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class BottomLinkItem extends Vue {
  @Prop()
  item?: RelatedLinksElement;

  @Prop({ default: false })
  highlighted!: boolean;
}
