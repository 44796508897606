import { render, staticRenderFns } from "./panel-group-heading.vue?vue&type=template&id=79dbac01&scoped=true&"
var script = {}
import style0 from "./panel-group-heading.scss?vue&type=style&index=0&id=79dbac01&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79dbac01",
  null
  
)

export default component.exports