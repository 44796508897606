
export const fetchGlobalTypeahead = (
  keyword: string,
  channel: string,
  options: RequestInit | undefined
) => {
  return  fetch('/search', {
    method: 'post',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({
         "url": window.location.pathname,
         "searchTerm": keyword
    })
 });
}
