import { Vue, Component } from 'vue-property-decorator';

export interface IPodcastConfiguration {
  url?: string;
  onComplete?: () => void;
  autostart: boolean;
  image?: string;
  playlist?: string;
  width?: string;
  height?: string;
  aspectratio?: string;
}
@Component
export default class PodcastPlayerMixin extends Vue {
  jwPlayerInstance: jwplayer.JWPlayer = null;
  dataLayer = null;
  isPlayed = false;
  isHover = false;
  isPlayerAvailable = true;

  mounted() {
    this.dataLayer = window.dataLayer || [];
  }
  public initialisePodcastPlayer(element: HTMLElement, config: IPodcastConfiguration): void {
    // Check if Jwplayer cdn is available? It seems JWPlayer is blocked by China Great Wall!
    // So instead of throwing error we server the page, without the player/video
    
    if (typeof jwplayer == 'undefined') {
      this.isPlayerAvailable = false;
      return;
    }
    this.isPlayerAvailable = true;  
    this.jwPlayerInstance = jwplayer(element);
    this.jwPlayerInstance.setup({
      file: config.url,
      stretching: 'fill',
      height: '300px',
      width: '100%',
      image: config.image,
      enableFullscreen: false,
      playbackRateControls: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]
  });
   
    if (config.onComplete != null) {
      this.jwPlayerInstance.on('complete', (config.onComplete));
    }
    
    let passedTenPercentBreakpoint: Boolean = false;
    let passedTwentyFivePercentBreakpoint: Boolean = false;
    let passedFiftyPercentBreakpoint: Boolean = false;
    let passedSeventyFivePercentBreakpoint: Boolean = false;
    let passedNinetyPercentBreakpoint: Boolean = false;

    this.jwPlayerInstance.on('error', () => { this.pushToDataLayer('Podcast error') });
    this.jwPlayerInstance.on('play', () => {  this.isPlayed = true; this.pushToDataLayer('Played podcast'); });
    this.jwPlayerInstance.on('pause', () => { this.pushToDataLayer('Pause podcast') });
    this.jwPlayerInstance.on('complete', () => { this.pushToDataLayer('100%') });
    this.jwPlayerInstance.on('time', () => {
      const time = Math.floor(100 * this.jwPlayerInstance.getPosition() / this.jwPlayerInstance.getDuration());
        if (time === 10 && !passedTenPercentBreakpoint) {
            passedTenPercentBreakpoint = true;
            this.pushToDataLayer(`${time} %`);
        } else if (time === 25 && !passedTwentyFivePercentBreakpoint) {
          passedTwentyFivePercentBreakpoint = true;
            this.pushToDataLayer(`${time} %`);
        } else if (time === 50 && !passedFiftyPercentBreakpoint) {
          passedFiftyPercentBreakpoint = true;
          this.pushToDataLayer(`${time} %`);
        } else if (time === 75 && !passedSeventyFivePercentBreakpoint) {
          passedSeventyFivePercentBreakpoint = true;
          this.pushToDataLayer(`${time} %`);
        } else if (time === 90 && !passedNinetyPercentBreakpoint) {
          passedNinetyPercentBreakpoint = true;
          this.pushToDataLayer(`${time} %`);
        }
    });
  }

  public destroyPodcastPlayer(): void {
    if (this.jwPlayerInstance) {
      this.jwPlayerInstance.remove();
    }
    this.jwPlayerInstance = null;
  }

  private pushToDataLayer(action: string) {
    const title = this.jwPlayerInstance.getPlaylistItem(0).title ? this.jwPlayerInstance.getPlaylistItem(0).title : this.jwPlayerInstance.getPlaylistItem(0).file;
    this.dataLayer.push({
      "event": "Podcast",
      "eventCategory": "JW Player",
      "eventAction": action,
      "eventLabel": title
    });
  }

  public hoverClicked():void{
    if(this.isPlayed)
      this.jwPlayerInstance.pause();
    else
      this.jwPlayerInstance.play();
  }
}
declare global {
  interface Window { dataLayer: any[]; } 
}
