import { Component, Prop, Mixins } from 'vue-property-decorator';
import { EnumItem } from '@/shared/Enums';
import { FundCentreFilter } from '@/shared/MwsViewModels';
import template from './fund-selector-container.vue';
import Translatable from '../../../../translation/Translatable';
import FundFilterColumn from './components/FundFilterColumn';
import { SelectedFilters } from '../models/SelectedFilters';
import { FundFilterColumnModel } from '../models/FundFilterColumnModel';
import * as consts from '../models/Consts';
import Data from '../Data';

@Component({
  mixins: [template],
  components: {
    FundFilterColumn,
  },
})
export default class FundSelectorContainer extends Mixins(Translatable) {
  @Prop()
  filter: FundCentreFilter;

  @Prop()
  isMobile: boolean;

  private selected: SelectedFilters = {
    investmentStyle: null,
    assetClass: null,
    fundType: null,
    geographicRegion: null,
  };

  get assetClassData(): FundFilterColumnModel {
    return Data.assetClassData(this.isMobile, this.filter.assetClasses, this.selected.assetClass);
  }

  get fundTypeData(): FundFilterColumnModel {
    return Data.fundTypeData(this.isMobile, this.filter.fundTypes, this.selected.fundType);
  }

  get geographicRegionData(): FundFilterColumnModel {
    return Data.geographicRegionData(this.isMobile, this.filter.geographicRegions, this.selected.geographicRegion);
  }

  get investmentStyleData(): FundFilterColumnModel {
    return Data.investmentStyleData(this.isMobile, this.filter.investmentStyles, this.selected.investmentStyle);
  }

  selectFilter(element: EnumItem): void {
    if (element.enumId === consts.investmentStyleId) {
      this.selected.investmentStyle = element.key;
    } else if (element.enumId === consts.assetClassId) {
      this.selected.assetClass = element.key;
    } else if (element.enumId === consts.fundTypeId) {
      this.selected.fundType = element.key;
    } else if (element.enumId === consts.geographicRegionId) {
      this.selected.geographicRegion = element.key;
    }

    const dto: { [key: string]: string } = {};
    Object.keys(this.selected).forEach((key) => {
      const value = this.selected[key];
      if (value && value !== 'NotSet') {
        dto[key] = value;
      }
    });

    this.$emit('selectFilter', dto);
  }
}
