import { GridPanel as IGridPanel, SupercardDesign2 } from '@/shared/MwsViewModels'
import CalloutsCTA from '../../lib/CalloutsCTA/index'
import PageAnchor from '../../lib/PageAnchor'
import PromoterCTA from '../../lib/PromoterCTA'
import React from 'react'

export interface GridPanelProps {
  data: IGridPanel
}

const All_LayoutTypes = ['ANCHOR PAGE', 'CTA PROMOTER', 'CTA CALLOUTS'] as const
type LayoutTypes = (typeof All_LayoutTypes)[number]

function isLayoutType(maybeLayoutType: unknown) {
  return (
    typeof maybeLayoutType === 'string' &&
    All_LayoutTypes.includes(maybeLayoutType as LayoutTypes)
  )
}

function parseLayoutType(givenHeading: string | null): LayoutTypes | null {
  if (isLayoutType(givenHeading)) {
    return givenHeading as LayoutTypes
  } else {
    return null
  }
}

const GridPanel = ({ data }: GridPanelProps) => {
  const layoutName = data?.layoutType
  const LayoutTypeName = parseLayoutType(layoutName)
  let cardItems : SupercardDesign2[]
  cardItems = data?.gridItems as SupercardDesign2[];

  switch (LayoutTypeName) {
    case 'ANCHOR PAGE': {
      return (
        <PageAnchor
          coloursOverride={data?.colours}
          items={cardItems}
        />
      )
    }
    case 'CTA PROMOTER': {
      return (
        <PromoterCTA
          coloursOverride={data?.colours}
          items={cardItems}
        />
      )
    }
    case 'CTA CALLOUTS': {
      return (
        <CalloutsCTA
          coloursOverride={data?.colours}
          items={cardItems}
        />
      )
    }
    default: {
      return <div>NOT IMPLEMENTED</div>
    }
  }
}

export default GridPanel
