import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './full-width-image-panel.vue';
// eslint-disable-next-line max-len
import PositionedResponsiveImage from '../../../shared/components/positioned-responsive-image/PositionedResponsiveImage';

@Component({
  mixins: [template],
  components: {
    PositionedResponsiveImage,
  },
})
export default class FullWidthImagePanel extends Vue {
  @Prop()
  data: mws.FullWidthImagePanel;
}
