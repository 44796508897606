import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './grid-panel.vue';
import { ReactInVue } from 'vuera';
import GridPanelReact from '../../../react/rebrand-lite/panels/GridPanel';

@Component({
  mixins: [template],
  components: {
    GridPanelReact: ReactInVue(GridPanelReact),
  },
})
export default class GridPanel extends Vue {
  @Prop()
  data: mws.GridPanel;
}
