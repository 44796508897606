import * as mws from '../../../../shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './canada-performance-and-portfolio-distributions-table.vue';

@Component({
  mixins: [template]
})
export default class CanadaPerformanceAndPortfolioDistributionsTable extends Vue {
  @Prop()
  data: mws.CanadaPerformanceAndPortfolioDistributionsTable;
}