import React from 'react'
import classnames from 'classnames/bind'
import { useRef, useState, useEffect } from 'react'
import usePinnedHeader from '../../hooks/usePinnedHeader'
import {
  Capability,
  ILink,
  PageNavigationPanel as IPageNavigationPanel,
} from '@/shared/MwsViewModels'
import { getCookieValue, safeArray } from '../../utils/helpers'
import GlobalTypeahead, { TypeaheadHandlers } from '../../lib/GlobalTypeahead'
import Icon from '../../lib/Icon'
import StyledLink from '../../lib/StyledLink'
import * as styles from './GlobalHeader.module.scss'
import useScrollLock from '../../hooks/useScrollLock/useScrollLock'
import canUseDOM from '../../utils/canUseDOM'
import LocationSelector from '../../lib/LocationSelector'
import { LinkButtonStyles } from '../../lib/LinkButtonStyles'
import AudienceSelector from '../../lib/AudienceSelector'
import { BG_CHANNEL_COOKIE_NAME, BG_REGION_COOKIE_NAME} from '../../constants/COOKIES'
import useAudienceSelectorHelpers from '../../hooks/useAudienceSelectorHelpers'
import NavigationLinks from './NavigationLinks'


const cx = classnames.bind(styles)

export interface GlobalHeaderProps {
  data: IPageNavigationPanel
}

const TypeaheadActiveState = 'typeahead-expanded'
const MobileMenuActiveState = 'menu-expanded'
const AudienceSelectorState = 'audience-selector'

type FlyoutOptions =
  | typeof MobileMenuActiveState
  | typeof TypeaheadActiveState
  | typeof AudienceSelectorState

interface channelsMatchCookiesProps {
  currentChannelInvestor: string
  currentChannelRegion: string
}

function channelMatchesCookies({
  currentChannelInvestor,
  currentChannelRegion,
}: channelsMatchCookiesProps) {
  const cookieRegionSetting = getCookieValue(BG_REGION_COOKIE_NAME)
  const cookieChannelSetting = getCookieValue(BG_CHANNEL_COOKIE_NAME)

  const channelsMatch =
    cookieChannelSetting === currentChannelInvestor &&
    cookieRegionSetting === currentChannelRegion

    return channelsMatch
}

const GlobalHeader = ({ data }: GlobalHeaderProps) => {
  const scrollState = usePinnedHeader()

  const [expandedFlyout, setExpandedFlyout] = useState<FlyoutOptions | ''>('')
  const [expandedSubmenu, setExpandedSubmenu] = useState<string>('')
  const [channelMatchCookies, setChannelMatchCookies] = useState<boolean>(false)
  const [hasForcedSelection, setHasForcedSelection] = useState<boolean>(false)
  const [forceNudgerToClose, setForceNudgerToClose] = useState<boolean>(false)
  const primaryLinks = safeArray(data?.links)
  const secondaryLinks = safeArray(data?.secondary)
  const utilityLinks = safeArray(data?.utilityLinks)
  const [selectedSubMenuGroup, setSelectedSubMenuGroup] = useState<
  Capability[] | null
>(null)

  const showDropshadow =
    expandedSubmenu.startsWith('secondary-nav') ||
    expandedSubmenu.startsWith('primary-nav') ||
    expandedFlyout === TypeaheadActiveState ||
    expandedFlyout === AudienceSelectorState

  const typeaheadRef = useRef<TypeaheadHandlers>(null!)
  const labels = data?.labels

  // Computed State
  const showSearchTypeaheadFromUserData = !data?.hideSearch
  const mobileMenuExpanded = expandedFlyout === MobileMenuActiveState
  const typeaheadSearchExpanded = expandedFlyout === TypeaheadActiveState
  const audienceSelectorExpanded = expandedFlyout === AudienceSelectorState

  const logoImageSrc = '/assets/images/common/BG_logo_BLACK.svg';
  function handleNavigationMenu(
    event: React.MouseEvent<HTMLButtonElement>
  ): void {
    event.preventDefault()
    event.stopPropagation()
    if (mobileMenuExpanded) {
      closeAllMenus()
    } else {
      setExpandedFlyout(MobileMenuActiveState)
    }
  }

  function handleExpandedMenu(menukey: string) {
    setExpandedSubmenu(menukey === expandedSubmenu ? '' : menukey)
    setSelectedSubMenuGroup(null)

    if (typeaheadSearchExpanded) {
      setExpandedFlyout('')
      typeaheadRef.current.resetInput()
    }
  }

  function closeMegaMenus() {
    setExpandedSubmenu('')
    setSelectedSubMenuGroup(null)
  }

  function closeAllMenus() {
    setExpandedFlyout('')
    setExpandedSubmenu('')

    typeaheadRef.current.resetInput()
  }

  function handleTypeaheadToggle(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    event.stopPropagation()
    if (typeaheadSearchExpanded) {
      setExpandedFlyout('')
    } else {
      setExpandedFlyout(TypeaheadActiveState)
      closeMegaMenus()
      typeaheadRef.current.focus()
    }
  }

  function handleTypeaheadClose() {
    closeAllMenus()
  }

  if (scrollState.isSticky && !scrollState.isPinned) {
    if (expandedSubmenu !== '' || typeaheadSearchExpanded) {
      closeAllMenus()
    }
  }

  const { 
    activeChannelJurisdiction, 
    activeChannelName, 
    globalRegion,
    globalRegionNames, 
  } = useAudienceSelectorHelpers({
    globalSelector: data?.globalSelector,
    currentChannelUrl: data?.channelUrl,
    currentChannelName: data?.channelName
  })

  const { lockScrollWindow, unLockScrollWindow } = useScrollLock()

  function handleAudienceSelectorMenu(customValue?: boolean) {
    closeMegaMenus()

    const channelMatchCookies = channelMatchesCookies({
      currentChannelInvestor: activeChannelName?.name || '',
      currentChannelRegion: activeChannelJurisdiction?.name || '',
    })
    const derivedForcedSelection =
    data?.globalSelector?.forceSelectionFlag &&
    !channelMatchCookies &&
    activeChannelJurisdiction !== undefined

    setChannelMatchCookies(channelMatchCookies)
    setHasForcedSelection(derivedForcedSelection)

    if (derivedForcedSelection) {
      return
    }

    if (typeof customValue === 'boolean') {
      if (customValue) {
        setExpandedFlyout(AudienceSelectorState)
      } else {
        setExpandedFlyout('')
      }
      return
    }

    const flyoutStatus = audienceSelectorExpanded ? '' : AudienceSelectorState

    setExpandedFlyout(flyoutStatus)
  }


  useEffect(() => {
    if (canUseDOM()) {
      const channelMatchCookies = channelMatchesCookies({
        currentChannelInvestor: activeChannelName?.name || '',
        currentChannelRegion: activeChannelJurisdiction?.name || '',
      })
      const derivedForcedSelection =
        data?.globalSelector?.forceSelectionFlag && !channelMatchCookies
      setChannelMatchCookies(channelMatchCookies)
      setExpandedFlyout(derivedForcedSelection ? AudienceSelectorState : '')
      setHasForcedSelection(derivedForcedSelection)
    }
  }, [
    data?.globalSelector?.forceSelectionFlag,
    activeChannelName?.name,
    activeChannelJurisdiction?.name,
  ])

  useEffect(() => {
    if (canUseDOM()) {
      audienceSelectorExpanded && derivedShowAudienceSelector ? lockScrollWindow() : unLockScrollWindow()
    }
  }, [audienceSelectorExpanded, lockScrollWindow, unLockScrollWindow])

  if (
    !data?.globalSelector?.forceSelectionFlag &&
    scrollState?.isSticky &&
    !channelMatchCookies
  ) {
    if (forceNudgerToClose === false) {
      setForceNudgerToClose(true)
    }
  } else {
    if (forceNudgerToClose === true) {
      setForceNudgerToClose(false)
    }
  }

  const derivedShowAudienceSelector = !data.hideGlobalSelector && data.globalSelector; 

  return (
    <div className={cx('headerSpacer')}>
      <div
        className={cx({
          globalHeader: true,
          globalHeaderIsSticky: scrollState.isSticky,
          globalHeaderIsPinned: scrollState.isPinned,
        })}
      >
        <div
          className={cx({
            channelAndUtilityBar: true,
            channelAndUtilityBarExpandedMenu: audienceSelectorExpanded,
          })}
        >
          <div className={cx('barContainer', 'utilityBar')}>
          {derivedShowAudienceSelector && (
            <LocationSelector
              channelFlag={data?.channelFlag}
              channelInvestorName={activeChannelName?.name || ''}
              channelRegionName={activeChannelJurisdiction?.name || ''}
              channelDisplayLabel={data?.channelName}
              labels={labels}
              audienceMenuVisible={audienceSelectorExpanded}
              handleAudienceMenuVisibility={handleAudienceSelectorMenu}
              forcedSelectionFlag={data?.globalSelector?.forceSelectionFlag}
              channelCookiesMatch={channelMatchCookies}
              ariaControlsLabel={AudienceSelectorState}
              closeNudgerOnScroll={forceNudgerToClose}
            />
            )}
            {utilityLinks.length > 0 && (
              <UtilityNavigation
                key={'utility-header'}
                linkType="linkSmall"
                links={utilityLinks}
              />
            )}
          </div>
        </div>
        {derivedShowAudienceSelector && (
          <AudienceSelector
            data={data?.globalSelector}
            globalRegionJurisdiction={globalRegion}
            globalRegionGroupNames={globalRegionNames}
            currentChannel={{
              id: data?.channelId,
              name: activeChannelName?.name || '',
              url: activeChannelName?.url || '',
              regionName: activeChannelJurisdiction?.name,
              riskWarnings: activeChannelName?.riskWarnings || [],
            }}
            defaultChannelJurisdiction={activeChannelJurisdiction}
            labels={data?.labels}
            className={cx({
              channelAudienceSelector: true,
              channelAudienceSelectorExpanded: audienceSelectorExpanded,
            })}
            handleAudienceMenuVisibility={handleAudienceSelectorMenu}
            hasForcedSelection={hasForcedSelection}
            channelCookiesMatch={channelMatchCookies}
            audienceMenuVisible={audienceSelectorExpanded}
            id={AudienceSelectorState}
            aria-hidden={expandedFlyout !== AudienceSelectorState}
          />
        )}
        <header className={cx('header')}>
          <div
            className={cx({
              bar: true,
              showDropshadow: showDropshadow,
            },
            'menu-bar')}
          >
            <a href={data?.logoUrl}>
                <img 
                  alt="Baillie Gifford & Co"
                  src={logoImageSrc} 
                  className={cx('logo', 'nav-logo')}
                />
            </a>
            <div className={cx('rightColumn')}>
              <button
                aria-controls="global-navigation"
                onClick={handleNavigationMenu}
                aria-label={
                  mobileMenuExpanded ? 'Expand Navigation' : 'Close Navigation'
                }
                aria-expanded={mobileMenuExpanded}
                className={cx({
                  mobileToggle: true,
                  mobileToggleExpanded: mobileMenuExpanded,
                })}
              >
                <span className={cx('hamburger')}></span>
              </button>
              <div
                className={cx({
                  menu: true,
                  showMenu: mobileMenuExpanded,
                })}
                id="global-Navigation"
                aria-label="Global Navigation"
                aria-hidden={!mobileMenuExpanded}
              >
                {showSearchTypeaheadFromUserData && (
                  <GlobalTypeahead
                    ref={typeaheadRef}
                    data-testid="Global-Typeahead"
                    aria-hidden={
                      !(mobileMenuExpanded || typeaheadSearchExpanded)
                    }
                    heading={labels?.search || 'Search'}
                    seeAllResultsLabel={labels?.SeeAllResults}
                    placeholder={labels?.TypeYourSearchHere}
                    clearSearchLabel={labels?.ClearSearch}
                    handleClose={handleTypeaheadClose}
                    channelLevelPath={data?.channelUrl}
                    isVisible={typeaheadSearchExpanded || mobileMenuExpanded}
                    hintLabel={labels?.EnterAKeywordOrPhrase}
                    noMatchingResultsLabel={
                      labels?.NoResultsFoundMatchingYourSearchCriteria
                    }
                  />
                )}
                <nav className={cx('navigation')}>
                  {primaryLinks.length > 0 && (
                    <NavigationLinks
                      links={primaryLinks}
                      linkType="primary-nav"
                      expandedSubmenu={expandedSubmenu}
                      labels={labels}
                      handleExpandedMenu={handleExpandedMenu}
                      handleCloseBtnClick={closeAllMenus}
                      strategiesMegaMenu={data.megaNav}
                      selectedSubMenuGroup={selectedSubMenuGroup}
                      setSelectedSubMenuGroup={setSelectedSubMenuGroup}
                    />
                  )}
                  {secondaryLinks.length > 0 && (
                    <NavigationLinks
                      links={secondaryLinks}
                      linkType="secondary-nav"
                      expandedSubmenu={expandedSubmenu}
                      labels={labels}
                      handleExpandedMenu={handleExpandedMenu}
                      handleCloseBtnClick={closeAllMenus}
                    />
                  )}
                  {utilityLinks.length > 0 && (
                    <UtilityNavigation
                      key={'utility-mobile'}
                      className={'utilityNavigationMobile'}
                      linkType="primaryNavigation"
                      links={utilityLinks}
                    />
                  )}
                </nav>
                {showSearchTypeaheadFromUserData && (
                  <button
                    aria-controls="global-search"
                    aria-label={
                      typeaheadSearchExpanded
                        ? 'Close Site Search menu'
                        : 'Expand to Perform Site Search'
                    }
                    aria-expanded={typeaheadSearchExpanded}
                    onClick={handleTypeaheadToggle}
                    data-testid="Typeahead-Toggle"
                    className={cx({
                      searchToggle: true,
                      searchToggleExpanded: typeaheadSearchExpanded,
                    })}
                  >
                    <Icon type="search" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </header>
        <div
          role="presentation"
          onClick={(ev) => {
            ev.stopPropagation()
            handleAudienceSelectorMenu(false)
          }}
          className={cx({
            globalHeaderBlurredBackground: true,
            globalHeaderBlurredBackgroundActive: audienceSelectorExpanded && derivedShowAudienceSelector,
          })}
        />
      </div>
    </div>
  )
}

interface utilityNavigationProps {
  links: ILink[]
  className?: string
  linkType: LinkButtonStyles
  key: {}
}

function UtilityNavigation({
  links = [],
  className,
  linkType,
}: utilityNavigationProps) {
  return (
    <ul
      className={cx('utilityNavigation', 'util__reset-list', className)}
      data-testid="utility-nav"
    >
      {links.map((linkItem) => {
        return (
          <li
            key={linkItem?.url}
            className={cx('utilityNavigationItem')}
          >
            <StyledLink
              data={linkItem}
              type={linkType}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default GlobalHeader
