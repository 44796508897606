import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './fund-discrete-and-periodic-performance-table-panel.vue';
import * as mws from '../../../shared/MwsViewModels';
import PeriodicPerformance from './periodic-performance/PeriodicPerformance';
import DiscretePerformance from './discrete-performance/DiscretePerformance';

@Component({
  mixins: [template],
  components: {
    PeriodicPerformance,
    DiscretePerformance,
  },
})
export default class FundDiscreteAndPeriodicPerformanceTablePanel extends Vue {
  @Prop()
  data: mws.ItFundDiscreteAndPeriodicPerformanceTablePanel | mws.OeicFundDiscreteAndPeriodicPerformanceTablePanel;
  private panelTitle: string = "Performance & Portfolio";
}
