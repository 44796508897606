import Vue from 'vue';
import { Module } from 'vuex';
import { Config } from '@/config';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<any, any> = {
  state: {
    config: {} as Config,
  },
  actions: {
    setConfig({ commit }, config) {
      commit('setConfig', config);
    },
  },
  getters: {
    config: (state): Config => state.config,
  },
  mutations: {
    setConfig(state, config) {
      Vue.set(state, 'config', config);
    },
  },
};
export default module;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
