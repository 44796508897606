import { Mixins, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './ucit-choice-and-charges-panel.vue';
import loadCldrPromise from '../../../assets/scripts/utilities/intialise';
import { Helpers } from '../../../assets/scripts/utilities/formatHelpers';
import PostbackMixin from '../../../shared/postback/PostbackMixin';
import LoadingIndicator from '../../../shared/components/loading-indicator/LoadingIndicator';
import EventBus from '../../../shared/EventBus';

@Component({
  mixins: [template],
  components: {
    LoadingIndicator,
  },
})
export default class UcitChoiceAndChargesPanel extends Mixins(PostbackMixin) {
  @Prop()
  data: mws.UcitChoiceAndChargesPanel;

  private percentageChangeString = '% Change';

  private positiveNumberCssClass = 'positive-number';

  private negativeNumberCssClass = 'negative-number';

  private oldSelectedDate: string = null;

  selectedDate: string = null;
  priceAvailable: boolean = true;
  availableDates: {value: string, label: string}[] = [];

  isLoading = false;

  created() {
    this.selectedDate = undefined;
  }

  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      loadCldrPromise().done(() => {
        this.initialiseAvailableDates();
        this.selectedDate = this.availableDates[0].value;
        this.oldSelectedDate = this.selectedDate;
        this.checkPriceAvailability();
      });
    });

    EventBus.$on('share-class-change', () => {
      this.checkPriceAvailability();
  });
  }

  async onDateSelected(): Promise<void> {
    const requestedDate = this.selectedDate;
    if (this.oldSelectedDate === requestedDate) {
      return;
    }

    this.oldSelectedDate = requestedDate;

    await this.$store.dispatch('setUcitChoiceAndChargesSelectedDate', requestedDate);
    const selectedShareClass = this.$store.getters.ucitShareClass;

    const postbackDto: mws.UcitPostbackShareClassDto = {
      shareClass: selectedShareClass.shareClass,
      currency: selectedShareClass.currency,
      incAccStr: selectedShareClass.incAcc,
      mappingType: selectedShareClass.mappingType,
      hedgedFund: selectedShareClass.hedgedFund,
      overrideCurrency: this.$store.getters.ucitPerformancePanelOverridingCurrency,
      selectedDate: this.$store.getters.ucitChoiceAndChargesSelectedDate
    };

    const model = this.createRequestModel(this.data, postbackDto);

    //todo: Product Backlog Item 335756: TODO: update UcitChoicesAndChargesPanel onDateSelected method
    //hack to get round a change to postback model for UcitChoicesAndCharges panel having changed
    model.postback[0].panelId = 19079;
    model.postback[0].panelName = 'FundLandingPageHeaderPanel';

    try {
      this.isLoading = true;
      const updatedPage = await this.fetchPostback(model);
      if (this.selectedDate === requestedDate) { // check if user changed their mind
        await this.mergePostback(updatedPage);
        this.initialiseAvailableDates();
        this.isLoading = false;
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  private initialiseAvailableDates(): void {
    this.availableDates = this.data.availableDates
      .map((date: string) => ({ value: date, label: Helpers.formatDateLong(date, false, true) }));
  }

  private checkPriceAvailability() {
    this.priceAvailable = this.data && this.data.hasPrice;
  }

  getValueClass(value: string): string {
    return value.includes('-') ? this.negativeNumberCssClass : this.positiveNumberCssClass;
  }
}
