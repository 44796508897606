import { render, staticRenderFns } from "./magazine-content-page-header-panel.vue?vue&type=template&id=d4a38846&scoped=true&"
var script = {}
import style0 from "../../../../assets/styles/panels/_ContentPageHeaderPanel.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./magazine-content-page-header-panel.scss?vue&type=style&index=1&id=d4a38846&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4a38846",
  null
  
)

export default component.exports