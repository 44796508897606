import { Component, Vue, Prop } from 'vue-property-decorator';
import template from './number-above.vue';
import CellData from '../../CellData';

@Component({
  mixins: [template],
})
export default class NumberAbove extends Vue {
  @Prop()
  data!: CellData;

  @Prop()
  iconUrl: string;

  @Prop()
  counterText!: string;

  get cellData(): CellData {
    return this.data;
  }
}
