import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './magazine-content-page-header-panel.vue';

@Component({
  mixins: [template]
})
export default class MagazineContentPageHeaderPanel extends Vue {
  selectedValue = "SELECT SECTION";

  @Prop()
  data?: mws.ContentPageHeaderPanel;


  get navLinks(): mws.ILink[] {
    return (this.data.links || []).slice(0, 5);
  }

  isCurrent(url: string): boolean {
    const resource = this.$route.fullPath;
    return url.endsWith(resource);
  }

  get orderOptions(): {value: string, label: string}[] {
    return this.data.links ? Object.entries(this.data.links).map((x) => ({ value: x[1].url, label: x[1].linkText })) : [];
  }

  onSelect(event): void { 
    window.open(event);
  }
}