import { Vue, Component, Prop } from 'vue-property-decorator';
// eslint-disable-next-line max-len
import { ILink, GlobalNavigationSelectorPopupLink, GenericPopupLink, Image, ImageLink } from '@/shared/MwsViewModels';
import template from './generic-link.vue';

@Component({
  mixins: [template],
})
export default class GenericLink extends Vue {
  @Prop()
  link: ILink | null;

  get linkText(): string | undefined {
    return (this.link as any)?.linkText;
  }

  get linkUrl(): string | undefined {
    return (this.link as any)?.url;
  }

  get linkImage(): Image | undefined {
    return (this.link as ImageLink)?.image;
  }

  get opensInNewWindow(): boolean {
    const result = (this.link as any)?.openInNewWindow;
    return result != null ? result : false;
  }

  get extraClasses(): { [key: string]: boolean } {
    return {
      'select-country': this.link?.type === this.globalNavigationType,
    };
  }

  get overrideTileLinkBehaviour(): boolean {
    return (this.link as any)?.overrideTileLinkBehaviour;
  }

  get linkRolloverRichText(): string {
    return (this.link as any)?.linkRolloverRichText;
  }
  
  get linkSubtitle(): string {
    return (this.link as any)?.linkSubtitle;
  }

  created() {
    if (this.link?.type === this.globalNavigationType) {
      const link = this.link as GlobalNavigationSelectorPopupLink;
      this.$store.dispatch('populateSelectLocationPopup', link.globalNaviagtion);
    }
  }

  onClick($event: Event): void {
    const linkType = this.link?.type;
    if ([this.clientLoginType, this.globalNavigationType, this.genericPopupType].includes(linkType)) {
      $event.preventDefault();

      switch (linkType) {
        case this.clientLoginType: {
          this.$store.dispatch('openClientLoginPopup', { linkUrl: this.link.url });
          break;
        }
        case this.globalNavigationType: {
          this.$store.dispatch('openSelectLocationPopup', null);
          break;
        }
        case this.genericPopupType: {
          const link = this.link as GenericPopupLink;
          this.$store.dispatch('openWarningPopup', {
            title: link.popupHeading,
            message: link.bodyText,
          });
          break;
        }
        default: break;
      }
    }
  }

  private readonly clientLoginType = 'ClientLoginPopupLink';

  private readonly globalNavigationType = 'GlobalNavigationSelectorPopupLink';

  private readonly genericPopupType = 'GenericPopupLink';
}
