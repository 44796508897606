import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './section-header-panel.vue';
import { ReactInVue  } from 'vuera'
import SectionHeaderPanelReact from '../../../react/rebrand-lite/panels/SectionHeaderPanel';

@Component({
  mixins: [template],
  components: {
    'SectionHeaderPanelReact': ReactInVue(SectionHeaderPanelReact)
  },
})

export default class SectionHeaderPanel extends Vue {
  @Prop()
  data: mws.SectionHeaderPanel;
}