import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import Block from '../../multi-block/block/Block';
import template from './fund-block.vue';

@Component({
  mixins: [template],
  components: {
    Block,
  },
})
export default class FundBlock extends Vue {
  @Prop()
  fund: mws.Fund;

  @Prop()
  large: true;

  get blockViewModel(): mws.Block {
    const imageLink: mws.ImageLink = {
      image: this.fund.backgroundImage,
      url: this.fund.url,
      type: 'ImageLink',
      linkText: 'Read more',
      openInNewWindow: false,
      linkRolloverRichText: this.fund.linkRolloverRichText,
      linkSubtitle: this.fund.linkSubtitle,
      overrideTileLinkBehaviour: this.fund.overrideTileLinkBehaviour,
      linkTitle: this.fund.fundName,
      addCircle: false,
      icon: '',
      isLinkLessThanOneMonthOld: false,
      buttonStyle: null,
      elementType: null,
      iconPosition: null,
      rolloverText: null,
    };

    return {
      title: this.fund.fundName,
      link: imageLink,
      rolloverText: this.fund.rolloverText,
      category: '',
      readTime: '',
    };
  }
}
