import { createApp } from './App';

import './assets/styles/common/Bundled.scss';
import './assets/main.scss';
import './assets/styles/athenaeum/site.scss';

const { app, router, store } = createApp();

router.onReady(() => {
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
  }
  app.$mount('#app');
});
