import { EnumItem } from '@/shared/Enums';

export const pageCapacity = 5;

export const investmentStyleId = 'is';

export const assetClassId = 'ac';

export const fundTypeId = 'ft';

export const geographicRegionId = 'gr';

export function splitIntoColumns(items: EnumItem[]): EnumItem[][] {
  const columnCount = Math.max(1, Math.ceil(items.length / pageCapacity));
  const itemsPerColumn = Math.ceil(items.length / columnCount);
  const result: EnumItem[][] = [];
  for (let i = 0; i < columnCount; i += 1) {
    result.push(items.slice(itemsPerColumn * i, itemsPerColumn * (i + 1)));
  }
  return result;
}
