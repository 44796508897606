import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import * as mws from '../../../../../shared/MwsViewModels';
import template from './athenaeum-positive-panel.vue';

@Component({
  mixins: [template]
})
export default class AthenaeumPositivePanel extends Vue {
  @Prop()
  data!: mws.AthenaeumPositivePanel;

  @Watch('$route')
  onUrlChange(newVal: Route, oldVal: Route) {
    if (!oldVal.query.content && newVal.query.content)
    this.handleContentQuery();
  }

  mounted() {
    this.handleContentQuery(); 
  }

  openAthenaeum() : void {
    this.data.athenaeumPanelName = 'positive';
    this.$store.dispatch('openAthenaeumPopup', this.data);
    (document.getElementsByTagName("body")[0] as HTMLElement).classList.add('noscroll');
    this.$router.push({query : {content: this.data.panelUrl.split('/').slice(-2, -1)[0]} }).catch(()=>{});
  }

  private handleContentQuery() {
    const currentRouteQuery = this.$router.currentRoute.query.content;
    const panelNameFromUrl = this.data.panelUrl.split('/').slice(-2, -1)[0];

    if (currentRouteQuery && currentRouteQuery === panelNameFromUrl) {
      this.openAthenaeum();
    }
  }
}
