import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './landing-page-video-panel.vue';
// eslint-disable-next-line max-len
import PositionedResponsiveImage from '../../../shared/components/positioned-responsive-image/PositionedResponsiveImage';
import VideoPopupConfig from '../content-page-video/VideoPopupConfig';

const playArrow = '/assets/images/arrows/video-play-up.svg';

@Component({
  mixins: [template],
  components: {
    PositionedResponsiveImage,
  },
})
export default class LandingPageVideoPanel extends Vue {
  @Prop()
  data: mws.LandingPageVideoPanel;

  playIcon: string = playArrow;

}
