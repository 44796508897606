import { Component, Vue, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './top-level-navigation-header.vue';
import Translatable from '../../translation/Translatable';
import GenericLink from '../../shared/components/link/GenericLink';

const logoImageSrc = '/assets/images/common/BG_logo_BLACK.svg';
const logoImageSrcWhite = '/assets/images/common/BG_logo_WHITE.svg';


@Component({
  mixins: [template],
  components: {
    GenericLink,
  },
})
export default class TopLevelNavigationHeader extends Translatable {
  @Prop()
  links: mws.ILink[];

  @Prop({ default: true })
  search: boolean;

  @Prop({ default: null })
  logoUrl: string | null;

  @Prop({ default: false })
  useDarkTheme: boolean;

  logoImageSrc = '';

  hideNavbar = false;

  private linkContainer: Element | null = null;

  private searchButton: Element | null = null;

  async created() {
    this.logoImageSrc = this.useDarkTheme ? logoImageSrcWhite : logoImageSrc;

    await this.loadDictionary(['header_menu']);
  }

  mounted() {
    this.linkContainer = this.$refs.navItemContainer as Element;
    this.searchButton = this.$refs.searchButton as Element;
    this.checkIfNavbarOverflows();
    window.addEventListener('resize', this.checkIfNavbarOverflows);
  }

  openSearchPopup(): void {
    this.$store.dispatch('openSearchPopup', this.$router.currentRoute.path);
  }

  openMenuPopup(): void {
    this.$store.dispatch('openMenuPopup');
  }

  private get page(): mws.Page {
    return this.$store.getters.pageData;
  }

  private checkIfNavbarOverflows(): void {
    if (this.linkContainer == null || this.searchButton == null) return;

    const wasNavbarHidden = this.hideNavbar;
    // In order to detect an overflow, the navbar must be visible.
    this.hideNavbar = false;

    Vue.nextTick().then(() => {
      const leftBox = this.linkContainer.getBoundingClientRect();
      const rightBox = this.searchButton.getBoundingClientRect();
      const minimalAcceptableDistance = 5;

      const belowMinimumWidth = rightBox.x < 780;
      const objectsTooClose = leftBox.x + leftBox.width > rightBox.x - minimalAcceptableDistance;
      this.hideNavbar = objectsTooClose || belowMinimumWidth;

      if (wasNavbarHidden !== this.hideNavbar) {
        this.emitOverflowChange(this.hideNavbar);
      }
    });
  }

  private emitOverflowChange(value: boolean): void {
    this.$emit('overflow', value);
  }
}
