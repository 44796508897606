import { Component, Watch, Mixins } from 'vue-property-decorator';
import template from './video-popup.vue';
import VideoPlayerMixin from '../../shared/video-player/VideoPlayerMixin';

@Component({
  mixins: [template],
})
export default class VideoPopup extends Mixins(VideoPlayerMixin) {
  get isOpen(): boolean {
    return !!this.$store.state.popups.videoPopup?.isOpen;
  }

  get title(): string {
    const title = this.$store.state.popups.videoPopup?.data?.title;
    return title || '';
  }

  get placeholderImageUrl(): string {
    return this.$store.state.popups.videoPopup.config?.imageUrl;
  }

  get imageCss(): {[key: string]: string} {
    if (!this.placeholderImageUrl) return {};

    const parameters = '?anchor=center&mode=crop&quality=60';
    return {
      'background-image': `url('${this.placeholderImageUrl + parameters}')`,
      'background-position': 'center center',
      'background-size': 'cover',
    };
  }

  public isPlayerMode = false;

  close(): void {
    this.isPlayerMode = false;
    this.destroyVideoPlayer();
    this.$store.dispatch('closeVideoPopup');
  }

  @Watch('isOpen')
  onIsOpenChange(): void {
    this.adjustBodyStyles();
  }

  play(): void {
    this.isPlayerMode = true;
    this.createPlayer();
  }

  private createPlayer(): void {
    const { url } = this.$store.state.popups.videoPopup.data;
    const closeOnComplete = this.$store.state.popups.videoPopup?.config?.closeOnComplete;
    const config = {
      url,
      onComplete: () => {
        if (closeOnComplete) {
          this.close();
        }
      },
      autostart: true,
    };
    const videoContainer = this.$refs.videoPopupPlayer as HTMLElement;
    this.initialiseVideoPlayer(videoContainer, config);
  }

  private adjustBodyStyles(): void {
    document.body.style.overflow = this.isOpen ? 'hidden' : 'auto';
  }

  // hardcoded properties - no api equivalents
  get watchTheFilm(): string {
    return 'Watch the film';
  }

  get filmDuration(): string {
    return '1 minute';
  }

  get videoDescription(): string {
    return `The financial capital of Scotland makes this a great place to work. The biggest arts festival on the planet,
     World heritage architecture and Scotland’s great outdoors on your doorstep, makes it a great place to play.`;
  }
}
