import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as highcharts from 'highcharts/highstock';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-performance-chart.vue';
import loadCldrPromise from '../../../assets/scripts/utilities/intialise';
import { createLineChartOptions } from '../../../assets/scripts/charting/options/lineChartOptions';
import HighstockSeries from './highstock-series';
import FadeTransitionMixin from '../../../shared/fade-transition/FadeTransitionMixin';
import DonutChart from '../fund-donut-chart/donut-chart/DonutChart';

@Component({
  mixins: [template],
  components: {
    DonutChart
  }
})
export default class FundPerformanceChartPanel extends Mixins(FadeTransitionMixin) {
  @Prop()
  data!: mws.FundPerformanceChartPanel;

  private currentTabIndex = 0;
  private charts = [];

  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      loadCldrPromise().done(() => {
        this.data.charts.forEach((chart, index) => {
          const highchartsSeries = FundPerformanceChartPanel.mapToHighstockDataSeries(chart.series);
          const chartId = this.getChartId(index);

          var additional = '';
          const isPerformance = chart?.name?.toLowerCase()?.includes('performance');
          const isActiveShare = chart?.name?.toLowerCase()?.includes('active');
          const isSaintsDividend = chart?.name?.toLowerCase()?.includes('dividend growth vs inflation');

          if(isPerformance) {
            additional = 'isPerformanceChart';
          } else if(isActiveShare){
            additional = 'isActiveShareChart';
          } else if (isSaintsDividend) {
            additional = 'isDividendGrowthVsInflation';
          } else {
            additional = 'isDiscountChart';
          }
          
          const options = createLineChartOptions(highchartsSeries, additional);
          this.charts[index] = highcharts.stockChart(chartId, options);
        });
      });
    });
  }

  getChartId(index: number): string {
    return `container-${this.data.id}-${index}`;
  }

  isCurrentTab(index: number): boolean {
    return index === this.currentTabIndex;
  }

  isLastTab(index: number): boolean {
    return index === this.data.charts.length - 1;
  }

  public static mapToHighstockDataSeries(chartSeries: mws.SeriesDataPerformance[]): HighstockSeries[] {
    return chartSeries.map((serie) => ({
      ...serie,
      data: serie.data.map((
        point: mws.SeriesDataPerformanceValue,
      ) => [point.timeStamp, point.value]),
    }));
  }

  async onTabClick(tabIndex: number, chartIndex: number, isDonutChart: boolean = false): Promise<void> {
    if (tabIndex === this.currentTabIndex) {
      return;
    }

    const chartContainer = this.$refs.chartContainer as HTMLElement;
    const currentlyVisibleChart = chartContainer.children[this.currentTabIndex] as HTMLElement;
    const newVisibleChart = chartContainer.children[tabIndex] as HTMLElement;

    await this.fadeHide(currentlyVisibleChart);
    this.currentTabIndex = tabIndex;
    await this.fadeShow(newVisibleChart);

    if (!isDonutChart) {
      this.charts[chartIndex].reflow();
    }
  }
}

