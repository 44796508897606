import classnames from 'classnames/bind'
import { SupercardDesign2 } from '@/shared/MwsViewModels'
import RichText from '../../RichText'
import StyledLink from '../../StyledLink'
import * as styles from './Callout.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export interface CalloutProps {
  card: SupercardDesign2
}

const Callout = ({ card }: CalloutProps) => {
  const ctaLink = card?.cta

  return (
    <>
      {card?.eyebrow && (
        <div
          className={cx('eyebrow')}
          dangerouslySetInnerHTML={{ __html: card.eyebrow }}
        />
      )}
      {card?.title && (
        <h3
          className={cx('type__h3', 'title')}
          dangerouslySetInnerHTML={{ __html: card.title }}
        />
      )}
      {card?.description && (
        <RichText
          className={cx('description')}
          copy={card.description}
        />
      )}
      {ctaLink?.url && (
        <div className={cx('spacing__sm-top')}>
          <StyledLink
            type="link"
            data={ctaLink}
          />
        </div>
      )}
    </>
  )
}

export default Callout
