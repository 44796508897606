import { Component, Prop, Vue } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './ic-view-more-tile.vue';

@Component({
  mixins: [template],
})
export default class IcViewMoreTile extends Vue {
  @Prop()
  link: mws.ImageLink;

  @Prop()
  colourTheme: string;

  get imageStyle(): {[key: string]: string} {
    return {
      backgroundImage: this.link.image?.url ? `url("${this.link.image.url}")` : null,
    };
  }

  get elementClass(): string {
    return this.colourTheme != null && this.colourTheme === "dark" ? 
      "bgfunds-carousel-item bginsights-insight bginsights-insight" :
      "bgfunds-carousel-item bginsights-insight bginsights-insight--light";
  }
}
