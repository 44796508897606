import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './footer.vue';
import TimeService from '../shared/TimeService';
import GenericLink from '../shared/components/link/GenericLink';
import * as mws from '../shared/MwsViewModels';
import { ReactInVue  } from 'vuera'
import FooterWithImagesAndLinksPanel from '../react/rebrand-lite/panels/FooterWithImagesAndLinksPanel';

@Component({
  mixins: [template],
  components: {
    GenericLink,
    'FooterWithImagesAndLinksPanel': ReactInVue(FooterWithImagesAndLinksPanel)
  },
})
export default class Footer extends Mixins(TimeService) {
  @Prop()
  rebrandLiteFooter?: mws.FooterWithImagesAndLinksPanel;

  public get currentYear(): number {
    return this.getTime().getFullYear();
  }

  get links(): mws.ILink[] {
    return this.$store.getters.pageData.footer.items;
  }
}
