import { Component, Prop } from 'vue-property-decorator';
import { EventElement } from '@/shared/MwsViewModels';
import template from './event-listing-details.vue';
import Translatable from '../../../../translation/Translatable';

@Component({
  mixins: [template],
})
export default class EventListingDetails extends Translatable {
  @Prop()
  item: EventElement;

  async created() {
    this.loadDictionary([
      'event_listing_details_label',
      'event_listing_location_label',
      'event_listing_view_location',
    ]);
  }

  get eventDates(): string {
    const end = this.item.endDate ? ` - ${this.item.endDate}` : '';
    return this.item.startDate + end;
  }

  get fullAddress(): string {
    const { address } = this.item;
    return [
      address.addressTitle,
      address.addressLine1,
      address.addressLine2,
      address.addressLine3,
      address.addressLine4,
      address.addressLine5,
    ].filter((s) => s != null && s !== '')
      .join(', ');
  }

  get mapUrl(): string {
    const { address } = this.item;
    const suffix = [
      address.addressTitle,
      address.addressLine1,
      address.addressLine2,
      address.addressLine3,
      address.addressLine4,
      address.addressLine5,
      address.postcode,
    ].filter((s) => s != null && s !== '')
      .map((s) => encodeURI(s))
      .join(',');
    return `https://www.google.co.uk/maps/search/${suffix}`;
  }
}
