import { AddressElement } from '@/shared/MwsViewModels';
import { AddressCssClasses } from './AddressCssClasses';

export default class OfficeAddressData {
  constructor(
    address: AddressElement,
    addressCssClasses: AddressCssClasses,
    addressesNumber: number,
  ) {
    this.address = address;
    this.addressCssClasses = addressCssClasses;
    this.addressesNumber = addressesNumber;
  }

  address: AddressElement = null;

  addressCssClasses: AddressCssClasses = null;

  addressesNumber: number;
}
