import { Table } from '@/shared/MwsViewModels';
import { Vue, Component, Prop } from 'vue-property-decorator';
import template from './usm-distribution-table.vue';

@Component({
  mixins: [template]
})
export default class UsmDistributionTable extends Vue {
  @Prop()
  table: Table;
}
