import { useCallback, useEffect } from 'react'
import * as React from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
  ref: React.RefObject<HTMLElement>,
  clickOutsideCallBack: () => void,
  initializedClick = false
) {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node

      if (ref?.current && target && !ref?.current?.contains(target)) {
        clickOutsideCallBack && clickOutsideCallBack()
      }
    },
    [clickOutsideCallBack, ref]
  )

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    // Bind the event listener

    if (initializedClick) {
      document.addEventListener('click', handleClickOutside, false)
    }
    return () => {
      // Unbind the event listener on clean up
      if (initializedClick) {
        document.removeEventListener('click', handleClickOutside, false)
      }
    }
  }, [ref, initializedClick, handleClickOutside])
}

export default useOutsideAlerter
