import { Vue, Component, Prop } from 'vue-property-decorator';
import * as mws from '@/shared/MwsViewModels';
import template from './fund-side-people-panel.vue';
import Person from './person/Person';

@Component({
  mixins: [template],
  components: {
    Person,
  },
})
export default class FundSidePeoplePanel extends Vue {
  @Prop()
  data: mws.FundSidePeoplePanel;

  private people: Person[] = [];
  mounted() {
    this.people = this.$refs.person as Person[];
  }

  onPersonClick(clickedComponentId): void {
    this.people.forEach((person) => {
      if(person.$attrs.id != clickedComponentId) {
        // @ts-ignore
        if(person.isOpen) {
          // @ts-ignore
          person.onAccordionClick();
        }
      }
    })
  }
}
